import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { getErrorMessage } from '../../../../api/api';
import openNotification from '../../../../components/Toastr';
import restockOrdersAPI from '../../../../api/restockV2024';

function PrintLabelButton({
  order,
  shipmentId,
  printLabelAmazon,
  setPrintLabelAmazon,
  partnered,
}) {
  const [form] = useForm();
  const [showModal, setShowModal] = useState(false);
  const [printingLabels, setPrintingLabels] = useState(false);
  const { t } = useTranslation();
  const pageTypes = [
    {
      value: 'PackageLabel_Letter_2',
      label: '2 etiquetas por página formato carta.',
    },
    {
      value: 'PackageLabel_Letter_6',
      label: '6 etiquetas por página formato carta.',
    },
    ...(partnered
      ? [
          {
            value: 'PackageLabel_Thermal',
            label: 'Para uso en impresora térmica. Una etiqueta por página.',
          },
        ]
      : [
          {
            value: 'PackageLabel_Thermal_NonPCP',
            label: 'Para uso en impresora térmica. Una etiqueta por página.',
          },
        ]),
  ];
  const labelTypes = [
    { value: 'UNIQUE', label: 'Imprimir etiquetas de Amazon para cada caja.' },
    ...(order?.isPalletized
      ? [
          {
            value: 'PALLET',
            label: 'Imprimir etiquetas de Amazon para cada pallet.',
          },
        ]
      : []),
  ];

  const onCancel = () => {
    setShowModal(false);
    setPrintLabelAmazon(false);
  };

  useEffect(() => {
    if (showModal) {
      form.resetFields();
    }
  }, [showModal]);

  useEffect(() => {
    setShowModal(printLabelAmazon);
  }, [printLabelAmazon]);

  const doPrintLabels = async (values) => {
    setPrintingLabels(true);
    const shipmentPallets = order.pallets?.find(
      (orderPallets) => orderPallets.shipmentId === shipmentId
    );
    const palletCount = shipmentPallets?.pallets?.length;
    const query = {
      shipmentId,
      options: {
        pageType: values.pageType,
        labelType: values.labelType,
        ...(values.labelType === 'PALLET' && { numberOfPallets: palletCount }),
      },
    };
    try {
      const response = await restockOrdersAPI.getLabels(order.id, query);
      if (response.data !== null && response.data !== undefined)
        window.location.href = response.data.labels.url;
      setShowModal(false);
      setPrintLabelAmazon(false);
      form.resetFields();
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
    setPrintingLabels(false);
  };

  const printLabels = () => {
    form.submit();
  };

  return (
    <>
      {/* <Button type="text" loading={printingLabels} onClick={() => { setShowModal(true) }} icon={<PrinterFilled height="15" width="15" />} /> */}
      <Modal
        title="Imprimir etiquetas"
        centered
        width={1000}
        visible={showModal}
        onOk={printLabels}
        onCancel={onCancel}
        cancelText={t('orders.newOrder.table.modal.buttonCancel')}
        okText="Imprimir"
        okButtonProps={{ loading: printingLabels }}
      >
        <Form
          layout="vertical"
          form={form}
          preserve={false}
          onFinish={doPrintLabels}
        >
          <Form.Item
            name="pageType"
            label="Tipo de página"
            rules={[{ required: true }]}
          >
            <Select options={pageTypes} />
          </Form.Item>
          <Form.Item
            name="labelType"
            label="Tipo de label"
            rules={[{ required: true }]}
          >
            <Select options={labelTypes} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

PrintLabelButton.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number.isRequired,
    restockOrderBoxes: PropTypes.arrayOf(
      PropTypes.shape({
        box: PropTypes.shape({
          length: PropTypes.number,
          width: PropTypes.number,
          height: PropTypes.number,
        }),
        weight: PropTypes.number,
      })
    ).isRequired,
    isPalletized: PropTypes.bool,
    pallets: PropTypes.arrayOf(Object),
  }).isRequired,
  shipmentId: PropTypes.string,
  printLabelAmazon: PropTypes.bool.isRequired,
  setPrintLabelAmazon: PropTypes.func.isRequired,
  partnered: PropTypes.bool.isRequired,
};

PrintLabelButton.defaultProps = {
  shipmentId: null,
};

export default PrintLabelButton;
