import {
  Avatar,
  Button,
  Col,
  Collapse,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Progress,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
  Dropdown,
} from 'antd';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import { useEffect, useState } from 'react';
import {
  DownOutlined,
  PictureOutlined,
  PlusOutlined,
  UpOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import CreateBoxModal from '../../couriers/Fedex/components/CreateBoxModal';
import openNotification from '../../../../../components/Toastr';
import Pallets from './Pallets';
import { orderGeneralStates } from '../../../../../utils/const';

function CaseMixedTable({
  shipmentId,
  order,
  items,
  boxes,
  setBoxes,
  onFinishProductAssignment,
  unit,
  pallets,
  setPallets,
  formRefs,
  saveDraft,
  shipmentIndex,
  convertWeightUnit,
}) {
  const { t } = useTranslation();
  const { Panel } = Collapse;
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState();
  const [isCreateBoxModalOpen, setIsCreateBoxModalOpen] = useState(false);
  const [boxModalInfo, setBoxModalInfo] = useState({});
  const [shippingAmount, setShippingAmount] = useState();
  const [currentProgressQuantity, setCurrentProgressQuantity] = useState(0);
  const [boxColumnWidth, setBoxColumnWidth] = useState();
  const [mixedBoxes, setMixedBoxes] = useState([]);
  const [currentQuantity, setCurrentQuantity] = useState({});
  const [columns, setColumns] = useState([]);
  const [updateColumnWidth, setUpdateColumnWidth] = useState(false);
  const [totalFob, setTotalFob] = useState(null);
  const [totalUnits, setTotalUnits] = useState(null);
  const unitMeasure = unit === 'cm' ? 'CM' : 'IN';
  const expandIcon = ({ isActive }) =>
    isActive ? <DownOutlined /> : <UpOutlined />;

  const disableForm = order.state !== orderGeneralStates.WORKING;

  const weightUnit = unit === 'cm' ? 'kg' : 'lb';
  const lengthUnit = unit === 'cm' ? 'cm' : 'in';
  const maxBoxWeightUnit = { kg: 50, inch: 110 };

  const formatterNumber = (value) => value.replace(',', '.');
  const parserNumber = (value) => parseFloat(value.replace(',', '.'));

  const getTotalCurrentQuantity = (newCurrentQuantity) => {
    let total = 0;
    Object.keys(newCurrentQuantity).forEach((boxName) => {
      Object.keys(newCurrentQuantity[boxName]).forEach((index) => {
        total += newCurrentQuantity[boxName][index];
      });
      setCurrentProgressQuantity(total);
    });
    return total;
  };

  const onBoxQuantityChange = (boxNumber, productIndex, quantity) => {
    if (Object.keys(currentQuantity).length > 0) {
      const newCurrentQuantity = currentQuantity;
      if (!newCurrentQuantity[boxNumber][`${productIndex}`])
        newCurrentQuantity[boxNumber][productIndex] = 0;
      newCurrentQuantity[boxNumber][productIndex] = quantity;
      setCurrentQuantity(newCurrentQuantity);
      getTotalCurrentQuantity(newCurrentQuantity);
    }
  };

  const addBox = async (e) => {
    if (e.key === 'newBox') {
      setBoxModalInfo({
        shipmentId,
        name: '',
        productIndex: e.key,
      });
      setIsCreateBoxModalOpen(true);
    } else {
      let boxToAdd;
      boxToAdd = boxes.find((b) => b.id === Number(e.key));
      if (!boxToAdd && e.id) {
        boxToAdd = e;
      }
      setMixedBoxes([...mixedBoxes, boxToAdd]);
      const newCurrentQuantity = currentQuantity;
      newCurrentQuantity[mixedBoxes.length] = {};
      setCurrentQuantity(newCurrentQuantity);
      const newColumn = {
        title: `${t('orders.newOrder.boxes.mixedBoxesReferences.box')} ${
          mixedBoxes.length + 1
        }`,
        dataIndex: `quantity-box-${mixedBoxes.length}`,
        key: mixedBoxes.length,
        width: `${boxColumnWidth}%`,
        align: 'center',
        render: (text, record, index) => {
          return (
            <Form.Item
              name={['products', shipmentId, index, `${mixedBoxes.length}`]}
              initialValue={record[`quantity-box-${mixedBoxes.length}`] || 0}
              rules={[{ required: true, message: 'Debe llenar la caja' }]}
            >
              <InputNumber
                min={0}
                max={record.quantity}
                onChange={(value) =>
                  onBoxQuantityChange(mixedBoxes.length, index, value)
                }
                formatter={formatterNumber}
                parser={parserNumber}
                disabled={disableForm}
                defaultValue={0}
              />
            </Form.Item>
          );
        },
      };
      setColumns([...columns, newColumn]);
      setUpdateColumnWidth(true);
    }
  };

  const createBoxCallback = (newBox) => {
    setBoxes([
      ...boxes,
      {
        ...newBox,
        length: String(newBox.length),
        width: String(newBox.width),
        height: String(newBox.height),
        weight: String(newBox.weight),
      },
    ]);
    addBox({ ...newBox, key: newBox.id });
  };

  const isShipmentComplete = (values) => {
    const totalQuantity = dataSource.reduce((total, product) => {
      let newTotal = total;
      newTotal += product.quantity;
      return newTotal;
    }, 0);
    const currentShipmentQuantity = values.products[shipmentId].reduce(
      (total, product) => {
        let newTotal = total;
        let current = 0;
        mixedBoxes.forEach((_mixedBox, index) => {
          current += product[index] ? product[index] : 0;
        });
        newTotal += current;
        return newTotal;
      },
      0
    );
    if (totalQuantity === currentShipmentQuantity) return true;
    return false;
  };

  const validateShipmentForm = (values) => {
    if (!isShipmentComplete(values)) {
      openNotification({
        status: false,
        content: 'Debes distribuir todos los productos en una caja.',
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    const amount = order?.products.reduce((total, product) => {
      let newTotal = total;
      newTotal += product.quantity;
      return newTotal;
    }, 0);
    setShippingAmount(amount);
  }, [order]);

  const boxesTableColumns = [
    {
      title: `# ${t('orders.newOrder.boxes.mixedBoxesReferences.box')}`,
      dataIndex: 'index',
      key: 'index',
      align: 'center',
      render: (text, record, index) => (
        <Form.Item
          name={['boxes', shipmentId, index, 'boxId']}
          initialValue={record.id}
        >
          <Input
            placeholder={`${index + 1}. ${record.name}`}
            disabled
            initialValue={record.id}
            hidden
          />
          <Typography.Text>
            {index + 1}. {record.name}
          </Typography.Text>
        </Form.Item>
      ),
    },
    {
      title: t('orders.newOrder.boxes.mixedBoxesReferences.length'),
      dataIndex: 'length',
      key: 'length',
      align: 'center',
      render: (_text, record, index) => {
        return (
          <Form.Item
            name={['boxes', shipmentId, index, 'length']}
            initialValue={record.length}
            rules={[
              {
                required: true,
                message: 'Requerido',
              },
            ]}
          >
            <InputNumber
              min={0}
              defaultValue={record.length}
              addonAfter={<Text>{lengthUnit.toUpperCase()}</Text>}
              formatter={formatterNumber}
              parser={parserNumber}
              disabled
            />
          </Form.Item>
        );
      },
    },
    {
      title: t('orders.newOrder.boxes.mixedBoxesReferences.width'),
      dataIndex: 'width',
      key: 'width',
      align: 'center',
      render: (_text, record, index) => {
        return (
          <Form.Item
            name={['boxes', shipmentId, index, 'width']}
            initialValue={record.width}
            rules={[
              {
                required: true,
                message: 'Requerido',
              },
            ]}
          >
            <InputNumber
              min={0}
              defaultValue={record.width}
              addonAfter={<Text>{lengthUnit.toUpperCase()}</Text>}
              formatter={formatterNumber}
              parser={parserNumber}
              disabled
            />
          </Form.Item>
        );
      },
    },
    {
      title: t('orders.newOrder.boxes.mixedBoxesReferences.height'),
      dataIndex: 'height',
      key: 'height',
      align: 'center',
      render: (_text, record, index) => {
        return (
          <Form.Item
            name={['boxes', shipmentId, index, 'height']}
            initialValue={record.height}
            rules={[
              {
                required: true,
                message: 'Requerido',
              },
            ]}
          >
            <InputNumber
              min={0}
              defaultValue={record.height}
              addonAfter={<Text>{lengthUnit.toUpperCase()}</Text>}
              formatter={formatterNumber}
              parser={parserNumber}
              disabled
            />
          </Form.Item>
        );
      },
    },
    {
      title: t('orders.newOrder.boxes.mixedBoxesReferences.weight'),
      dataIndex: 'weight',
      key: 'weight',
      align: 'center',
      render: (_text, record, index) => (
        <Form.Item
          name={['boxes', shipmentId, index, 'weight']}
          initialValue={record.weight}
          rules={[
            {
              required: true,
              message: 'Requerido',
            },
          ]}
        >
          <InputNumber
            min={0}
            max={maxBoxWeightUnit[weightUnit]}
            addonAfter={<Text>{weightUnit.toUpperCase()}</Text>}
            formatter={formatterNumber}
            parser={parserNumber}
            disabled={disableForm}
          />
        </Form.Item>
      ),
    },
  ];

  useEffect(() => {
    if (updateColumnWidth) {
      setBoxColumnWidth(75 / columns.length);
      const newColumns = [];
      columns.forEach((column) => {
        const newColumn = column;
        newColumn.width = `${boxColumnWidth}%`;
        newColumns.push(newColumn);
      });
      setColumns(newColumns);
      setUpdateColumnWidth(false);
    }
  }, [updateColumnWidth]);

  useEffect(() => {
    const formatBoxesDataSource = () => {
      const orderBoxes = order?.restockOrderBoxes.filter(
        (box) => box.shipmentId === shipmentId
      );
      const data = orderBoxes.map((box) => ({
        id: box.box.id,
        name: box.box.name,
        length: box.box.length,
        width: box.box.width,
        height: box.box.height,
        weight: box.weight,
      }));
      setMixedBoxes(data);
    };

    const initialBoxesColumns = () => {
      const orderBoxes = order?.restockOrderBoxes.filter(
        (box) => box.shipmentId === shipmentId
      );
      setBoxColumnWidth(75 / orderBoxes.length);
      const data = orderBoxes.map((box, boxIndex) => ({
        title: `${t('orders.newOrder.boxes.mixedBoxesReferences.box')} ${
          boxIndex + 1
        }`,
        dataIndex: `quantity-box-${boxIndex}`,
        key: boxIndex + 1,
        width: `${boxColumnWidth}%`,
        align: 'center',
        render: (_text, record, index) => {
          return (
            <Form.Item
              name={['products', shipmentId, index, boxIndex]}
              initialValue={record[`quantity-box-${boxIndex}`]}
            >
              <InputNumber
                min={0}
                max={record.quantity}
                formatter={formatterNumber}
                onChange={(value) =>
                  onBoxQuantityChange(boxIndex, index, value)
                }
                parser={parserNumber}
                disabled={disableForm}
              />
            </Form.Item>
          );
        },
      }));
      const initialColumns = [
        {
          title: t('orders.newOrder.boxes.mixedBoxesReferences.product'),
          dataIndex: 'name',
          key: 'name',
          width: '20%',
          align: 'center',
          ellipsis: {
            showTitle: false,
          },
          render: (text, record, index) => (
            <>
              <Form.Item
                name={['products', shipmentId, index, 'sku']}
                hidden
                initialValue={record.sku}
              />
              <Row align="middle" justify="start">
                <Col span={24}>
                  <Space>
                    {record?.image ? (
                      <Avatar
                        style={{ width: 40, height: 40 }}
                        shape="square"
                        className="home-listing-table-avatar"
                        src={<Image src={`${record?.image}`} />}
                      />
                    ) : (
                      <Avatar
                        style={{ width: 40, height: 40 }}
                        shape="square"
                        className="home-listing-table-avatar"
                        icon={<PictureOutlined />}
                      />
                    )}
                    <Row>
                      <Col span={24}>
                        <div style={{ textAlign: 'left' }}>
                          <Tooltip placement="topLeft" title={text}>
                            <Typography.Text style={{ color: '#67A4FF' }}>
                              {text}
                            </Typography.Text>
                          </Tooltip>
                        </div>
                      </Col>
                      <Col span={24}>
                        <div style={{ textAlign: 'left' }}>
                          <Tooltip title={text}>SKU: {record.sku}</Tooltip>
                        </div>
                      </Col>
                    </Row>
                  </Space>
                </Col>
              </Row>
            </>
          ),
        },
        {
          title: 'Peso Unitario Producto',
          dataIndex: 'productWeight',
          key: 'productWeight',
          align: 'center',
          width: '20%',
          render: (text, record, index) => (
            <Form.Item
              initialValue={convertWeightUnit(record.productWeightUnit, text)}
              name={['products', shipmentId, index, 'productWeight']}
            >
              <InputNumber
                min={0}
                defaultValue={convertWeightUnit(record.productWeightUnit, text)}
                addonAfter={weightUnit}
                formatter={formatterNumber}
                parser={parserNumber}
              />
            </Form.Item>
          ),
        },
        {
          title: t('orders.newOrder.boxes.mixedBoxesReferences.totalUnits'),
          dataIndex: 'quantity',
          key: 'quantity',
          width: '10%',
          align: 'center',
          render: (text, record, index) => (
            <Form.Item
              name={['products', shipmentId, index, 'quantity']}
              initialValue={text}
            >
              <Text>{record.quantity}</Text>
            </Form.Item>
          ),
        },
        ...data,
      ];

      return initialColumns;
    };
    if (mixedBoxes.length === 0) {
      formatBoxesDataSource();
    }
    if (columns.length === 0) {
      setColumns(initialBoxesColumns);
      setUpdateColumnWidth(true);
    }
  }, []);

  useEffect(() => {
    const formatDataSource = () => {
      let units = 0;
      let fob = 0;
      let index = 0;
      const productsQuantities = {};
      const data = items?.map((item) => {
        const product = order?.products?.find(
          (orderProduct) => item.SellerSKU === orderProduct.product.defaultCode
        );
        const sku = product?.product?.defaultCode;
        productsQuantities[sku] = {};
        order.restockOrderBoxes
          .filter((orderBox) => orderBox.shipmentId === shipmentId)
          .forEach((orderBox, boxIndex) => {
            const boxedProduct = orderBox.products.find(
              (p) => p.product.defaultCode === product.product.defaultCode
            );

            if (boxedProduct) {
              productsQuantities[sku][`quantity-box-${boxIndex}`] =
                boxedProduct?.quantity;
              const newCurrentQuantity = currentQuantity;
              if (!newCurrentQuantity[boxIndex])
                newCurrentQuantity[boxIndex] = {};
              if (boxedProduct?.quantity)
                newCurrentQuantity[boxIndex][index] = boxedProduct?.quantity;
              setCurrentQuantity(newCurrentQuantity);
            }
          });
        let image;
        if (
          product?.product?.attributes &&
          Array.isArray(product?.product?.attributes)
        )
          image = product?.product?.attributes.main_offer_image_locator
            ? product?.product?.attributes.main_offer_image_locator[0]
                ?.media_location
            : null;
        else
          image = product?.product?.attributes.main_offer_image_locator
            ? product?.product?.attributes.main_offer_image_locator[0]
                ?.media_location
            : null;

        if (product?.product?.productTemplate.unitFOB && item.Quantity) {
          fob += product.product.productTemplate.unitFOB * item.Quantity;
        }
        units += item.Quantity;
        index += 1;
        return {
          id: product?.product?.id,
          name: product?.product?.title,
          sku: product?.product?.defaultCode,
          quantity: item.Quantity,
          productWeight: product?.product?.weight,
          productWeightUnit: product?.product?.weightUnity,
          image,
          expirationDate: product?.expirationDate,
          fob: product?.product?.productTemplate.unitFOB,
          ...productsQuantities[sku],
        };
      });
      setDataSource(data);
      setTotalFob(fob);
      setTotalUnits(units);
    };
    if (!dataSource && boxes) {
      formatDataSource();
    }
  }, [boxes]);

  useEffect(() => {
    getTotalCurrentQuantity(currentQuantity);
  }, [dataSource]);

  const formatSendBoxes = async (values) => {
    const selectedBoxes = [];
    // Assign product to boxes
    if (values.boxes) {
      Object.keys(values.boxes).forEach((boxShipmentId) => {
        values.boxes[boxShipmentId].forEach((box, boxIndex) => {
          const currentBox = box;
          if (!currentBox.products) currentBox.products = [];
          currentBox.shipmentId = boxShipmentId;
          currentBox.numberOfBoxes = 1;
          values.products[boxShipmentId].forEach((product) => {
            const productQuantity = product[boxIndex];
            if (productQuantity) {
              const orderProduct = dataSource.find(
                (orderProductRow) => orderProductRow?.sku === product.sku
              );
              const formattedProduct = {
                id: orderProduct.id,
                sku: product.sku,
                quantity: productQuantity,
                expirationDate: orderProduct.expirationDate,
                fob: orderProduct?.fob || 0,
              };
              currentBox.products.push(formattedProduct);
            }
          });
          selectedBoxes.push(currentBox);
        });
      });
      return selectedBoxes;
    }
    return null;
  };

  const onFinish = async (values) => {
    let valid = true;
    if (!saveDraft) {
      valid = validateShipmentForm(values);
    }
    if (valid) {
      const payload = await formatSendBoxes(values);
      await onFinishProductAssignment(payload, saveDraft);
    }
  };

  const setFormRef = (formName, formRef) => {
    // eslint-disable-next-line no-param-reassign
    formRefs.current = {
      ...formRefs.current,
      [formName]: formRef,
    };
  };

  const buttonOptions = boxes?.map((box) => ({
    label: `${box.name} (${box.height}x${box.width}x${box.length})`,
    key: `${box.id}`,
  }));

  buttonOptions.unshift({
    label: (
      <>
        <PlusOutlined />{' '}
        {t('orders.newOrder.boxes.mixedBoxesReferences.newBox')}
      </>
    ),
    key: 'newBox',
  });

  const buttonMenuProps = {
    items: buttonOptions,
    onClick: addBox,
  };

  return (
    <Collapse
      className="collapse-header"
      expandIcon={expandIcon}
      defaultActiveKey={[shipmentId]}
    >
      <Panel
        key={shipmentId}
        header={
          <Row justify="space-between">
            <Col span={12}>
              <Typography.Text strong>{`${t(
                'orders.newOrder.boxes.mixedBoxesReferences.destination'
              )} ${shipmentIndex + 1}`}</Typography.Text>
              <Divider type="vertical" style={{ height: '100%' }} />
              <Typography.Text>{`${shipmentId}`}</Typography.Text>
            </Col>
            <Col span={12}>
              <div style={{ textAlign: 'right', height: '100%' }}>
                {t('orders.newOrder.boxes.mixedBoxesReferences.boxes')}:{' '}
                <Typography.Text strong>{mixedBoxes.length}</Typography.Text>
                <Divider type="vertical" style={{ height: '100%' }} />
                {t('orders.newOrder.boxes.mixedBoxesReferences.FOB')}:{' '}
                <Typography.Text strong>
                  US ${parseFloat(totalFob).toFixed(2) || 'calculando'}{' '}
                </Typography.Text>
                {totalFob > 2500 && totalFob < 3000 && (
                  <>
                    &nbsp;
                    <Tooltip title="Para este envío se requiere un importador ">
                      <WarningTwoTone twoToneColor="#eb2f96" />
                    </Tooltip>
                  </>
                )}
                {totalFob > 3000 && (
                  <>
                    &nbsp;
                    <Tooltip title="Para este envío se requiere un importador y un agente de aduana">
                      <WarningTwoTone twoToneColor="#eb2f96" />
                    </Tooltip>
                  </>
                )}
              </div>
            </Col>
          </Row>
        }
      >
        <Row gutter={[24, 24]}>
          <Col span={12} offset={12}>
            <Row>
              <Col span={12}>
                <Typography.Text>
                  {t('orders.newOrder.boxes.mixedBoxesReferences.unitsInOrder')}
                  :{' '}
                </Typography.Text>
                <Typography.Text strong>{`${currentProgressQuantity} de ${
                  totalUnits || '0'
                } `}</Typography.Text>
              </Col>
              <Col span={12}>
                <Progress
                  percent={(
                    (currentProgressQuantity / totalUnits) *
                    100
                  ).toFixed(0)}
                />
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
            >
              <Typography.Text className="shipment-step-title">
                {t('orders.newOrder.boxes.productDistribution')}
              </Typography.Text>
            </div>
          </Col>
          <Col span={12}>
            {isCreateBoxModalOpen && boxModalInfo && (
              <CreateBoxModal
                showCreateBoxModal={isCreateBoxModalOpen}
                setShowCreateBoxModal={setIsCreateBoxModalOpen}
                unitMeasure={unitMeasure}
                callback={createBoxCallback}
              />
            )}
            <div style={{ display: 'flex', justifyContent: 'right' }}>
              <Dropdown
                menu={buttonMenuProps}
                trigger={['click']}
                disabled={
                  !!(
                    currentProgressQuantity >= shippingAmount ||
                    mixedBoxes.length >= shippingAmount
                  ) || disableForm
                }
              >
                <Button className="okButtonNewDestinationModal">
                  <Space>
                    <PlusOutlined className="addBoxIcon" />
                    {t('orders.newOrder.boxes.mixedBoxesReferences.addBox')}
                  </Space>
                </Button>
              </Dropdown>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} span={24}>
            {dataSource && columns.length > 0 && (
              <Form
                name="productAssignmentForm"
                form={form}
                onFinish={onFinish}
                ref={(actualForm) => {
                  setFormRef(shipmentId, actualForm);
                }}
              >
                <Row gutter={[24, 24]}>
                  <Col span={24}>
                    <Table
                      columns={columns}
                      dataSource={dataSource}
                      pagination={false}
                      sticky
                    />
                  </Col>
                  {mixedBoxes.length > 0 && (
                    <>
                      <Col span={24}>
                        <Typography.Text className="shipment-step-title">
                          {t('orders.newOrder.boxes.mixedBoxesSecondSubtitle')}
                        </Typography.Text>
                      </Col>
                      <Col span={24}>
                        <Table
                          dataSource={mixedBoxes}
                          columns={boxesTableColumns}
                          pagination={false}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              </Form>
            )}
            {order.isPalletized && (
              <Pallets
                form={form}
                pallets={pallets}
                setPallets={setPallets}
                shipmentId={shipmentId}
                unit={unit}
                disabled={disableForm}
              />
            )}
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
}

CaseMixedTable.propTypes = {
  shipmentId: PropTypes.string.isRequired,
  order: PropTypes.shape({
    restockOrderBoxes: PropTypes.arrayOf(
      PropTypes.shape({
        box: PropTypes.shape({
          length: PropTypes.number,
          width: PropTypes.number,
          height: PropTypes.number,
        }),
        weight: PropTypes.number,
      })
    ).isRequired,
    isPalletized: PropTypes.bool.isRequired,
    unity: PropTypes.string.isRequired,
    products: PropTypes.instanceOf(Object),
    state: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      FulfillmentNetworkSKU: PropTypes.string,
      Quantity: PropTypes.number,
      SellerSKU: PropTypes.string,
    })
  ).isRequired,
  boxes: PropTypes.arrayOf(
    PropTypes.shape({
      length: PropTypes.number,
      width: PropTypes.number,
      height: PropTypes.number,
      weight: PropTypes.number,
    })
  ).isRequired,
  setBoxes: PropTypes.func.isRequired,
  onFinishProductAssignment: PropTypes.func.isRequired,
  unit: PropTypes.string.isRequired,
  pallets: PropTypes.arrayOf(
    PropTypes.shape({
      pallets: PropTypes.arrayOf({
        length: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        weight: PropTypes.number,
      }),
      shipmentId: PropTypes.string,
    })
  ).isRequired,
  setPallets: PropTypes.func.isRequired,
  formRefs: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  saveDraft: PropTypes.bool.isRequired,
  shipmentIndex: PropTypes.number.isRequired,
  convertWeightUnit: PropTypes.func.isRequired,
};
export default CaseMixedTable;
