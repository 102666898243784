/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip, Typography } from 'antd';

const { Text, Link } = Typography;

const getLinkAmazon = (item, domain) => {
  return item?.asin ? (
    <Tooltip placement="topLeft" title={item?.title}>
      <Link
        href={`https://www.amazon.${domain}/dp/${item.asin}`}
        target="_blank"
      >
        {' '}
        {item?.title?.length > 40
          ? `${item?.title.substring(0, 40)}...`
          : item?.title}
      </Link>
    </Tooltip>
  ) : (
    <Tooltip placement="topLeft" title={item?.title}>
      <Text> {`${item?.title}`}</Text>
    </Tooltip>
  );
};

const getLinkToStore = {
  Amazon: (item) => getLinkAmazon(item, 'com'),
  'Amazon Vendor': (item) => getLinkAmazon(item, 'com'),
  'Amazon Mexico': (item) => getLinkAmazon(item, 'com.mx'),
  'Amazon Canada': (item) => getLinkAmazon(item, 'ca'),
  'Amazon Brazil': (item) => getLinkAmazon(item, 'br'),
  'Amazon Spain': (item) => getLinkAmazon(item, 'es'),
  'Amazon France': (item) => getLinkAmazon(item, 'fr'),
  'Amazon Italy': (item) => getLinkAmazon(item, 'it'),
  'Amazon Germany': (item) => getLinkAmazon(item, 'de'),
  'Amazon UK': (item) => getLinkAmazon(item, 'co.uk'),
  'Amazon Belgium': (item) => getLinkAmazon(item, 'be'),
  'Amazon Netherlands': (item) => getLinkAmazon(item, 'nl'),
  'Amazon Sweden': (item) => getLinkAmazon(item, 'se'),
  'Amazon Poland': (item) => getLinkAmazon(item, 'pl'),
  'Amazon Egypt': (item) => getLinkAmazon(item, 'eg'),
  'Amazon Turkey': (item) => getLinkAmazon(item, 'tr'),
  'Amazon Saudi Arabia': (item) => getLinkAmazon(item, 'sa'),
  'Amazon United Arab Emirates': (item) => getLinkAmazon(item, 'ae'),
  'Amazon India': (item) => getLinkAmazon(item, 'in'),
  Ebay: (item) =>
    item?.asin ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href={`https://www.ebay.com/itm/${item?.asin}`} target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  'Ebay Canada': (item) =>
    item?.externalId ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link
          href={`https://www.ebay.ca/itm/${item?.externalId}`}
          target="_blank"
        >
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  'Ebay Spain': (item) =>
    item?.externalId ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link
          href={`https://www.ebay.es/itm/${item?.externalId}`}
          target="_blank"
        >
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  'Ebay Germany': (item) =>
    item?.externalId ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link
          href={`https://www.ebay.de/itm/${item?.externalId}`}
          target="_blank"
        >
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  Walmart: (item) =>
    item?.externalId ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link
          href={`https://www.walmart.com/ip/${item?.externalId}`}
          target="_blank"
        >
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  'Walmart Mexico': (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href={item.link} target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  Shopify: (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href={`https://${item?.link}`} target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  'Shopify Chile': (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item.title}>
        <Link href={`https://${item.link}`} target="_blank">
          {' '}
          {`${item.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item.title}>
        <Text> {`${item.title}`}</Text>
      </Tooltip>
    ),
  'Shopify Mexico': (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item.title}>
        <Link href={`https://${item.link}`} target="_blank">
          {' '}
          {`${item.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item.title}>
        <Text> {`${item.title}`}</Text>
      </Tooltip>
    ),
  'Shopify United States': (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item.title}>
        <Link href={`https://${item.link}`} target="_blank">
          {' '}
          {`${item.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item.title}>
        <Text> {`${item.title}`}</Text>
      </Tooltip>
    ),
  'Shopify Peru': (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item.title}>
        <Link href={`https://${item.link}`} target="_blank">
          {' '}
          {`${item.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item.title}>
        <Text> {`${item.title}`}</Text>
      </Tooltip>
    ),
  'Shopify Brazil': (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item.title}>
        <Link href={`https://${item.link}`} target="_blank">
          {' '}
          {`${item.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item.title}>
        <Text> {`${item.title}`}</Text>
      </Tooltip>
    ),
  'Shopify Colombia': (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item.title}>
        <Link href={`https://${item.link}`} target="_blank">
          {' '}
          {`${item.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item.title}>
        <Text> {`${item.title}`}</Text>
      </Tooltip>
    ),
  'Mercadolibre Chile': (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href={item.link} target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  'Mercadolibre Mexico': (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href={item.link} target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  'Mercadolibre Colombia': (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href={item.link} target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  Coppel: (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href={item?.link} target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  Liverpool: (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href={item?.link} target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  Ripley: (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href={item?.link} target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  Edicommerce: (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href="#" target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  Magento: (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href={item?.link} target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  Woocommerce: (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href={item?.link} target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  Falabella: (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href={item?.link} target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  Linio: (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href={item?.link} target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  Prestashop: (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href={item?.link} target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
  Dafiti: (item) =>
    item?.link ? (
      <Tooltip placement="topLeft" title={item?.title}>
        <Link href={item?.link} target="_blank">
          {' '}
          {`${item?.title}`}
        </Link>
      </Tooltip>
    ) : (
      <Tooltip placement="topLeft" title={item?.title}>
        <Text> {`${item?.title}`}</Text>
      </Tooltip>
    ),
};

export default getLinkToStore;
