import { Button, Col, Form, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import couriersAPI from '../../../../../../api/courier';
import { translateErrors } from '../../../../../../utils/functions';
import { createPickUpErrors } from '../../../../../../utils/errors';
import createPickUpTooltip from '../../../../../../utils/tooltips';
import openNotification from '../../../../../../components/Toastr';

function FedexPickup(
  {
    restockOrderId,
    warehouseId,
    courierCredentialId,
    orderCourierShipments,
    callback,
    showConfirmButton,
    setDateTime,
    pickUpId,
    formRef,
  },
  ref
) {
  const { t } = useTranslation();
  const [pickupAvailability, setPickupAvailability] = useState();
  const [selectedPickupDate, setSelectedPickupDate] = useState();
  const [loadingCreatePickup, setLoadingCreatePickup] = useState(false);
  const [loadingPickupAvailability, setLoadingPickupAvailability] =
    useState(false);
  const [actualSpan, setActualSpan] = useState(8);

  const [form] = Form.useForm();
  React.useEffect(() => {
    if (formRef) {
      // eslint-disable-next-line no-param-reassign
      formRef.current = form;
    }
  }, [formRef, form]);
  const [courierCredentialsState, setCourierCredentialsState] = useState();

  const getPickupAvailability = async () => {
    const payload = {
      warehouseId,
    };
    setLoadingPickupAvailability(true);
    try {
      const credentials = await couriersAPI.getCourierCredentials(100, 1);
      const courierCredentials = credentials?.data?.results?.find(
        (cred) => parseInt(courierCredentialId, 10) === cred.id
      );
      if (courierCredentials) {
        setCourierCredentialsState(courierCredentials);
        const response = await couriersAPI.getPickupAvailability(
          courierCredentials.courierCompanyName,
          payload
        );
        const options = response.data.filter(
          (option, index, self) =>
            self.findIndex((opt) => opt.pickupDate === option.pickupDate) ===
              index && option.available
        );
        setPickupAvailability(options);
      } else {
        throw new Error();
      }
    } catch (error) {
      openNotification({
        status: false,
        content:
          'No se pudo obtener los horarios de pickup. Por favor actualice e intente nuevamente.',
      });
    }
    setLoadingPickupAvailability(false);
  };

  const onPickupFinish = async (values) => {
    setLoadingCreatePickup(true);
    const payload = {
      courierCompanyName: courierCredentialsState.courierCompanyName,
      pickupDate: values.pickupDate,
      warehouseId,
      readyTime: values.readyTime,
      closeTime: values.closeTime,
    };
    try {
      if (pickUpId) {
        payload.courierShipmentIds = [orderCourierShipments?.id];
        await couriersAPI.updatePickup(
          courierCredentialsState.courierCompanyName,
          pickUpId,
          payload
        );
      } else {
        if (restockOrderId) {
          payload.restockOrderId = restockOrderId;
        } else if (orderCourierShipments?.id) {
          payload.courierShipmentIds = [orderCourierShipments?.id];
        }
        await couriersAPI.createPickup(
          courierCredentialsState.courierCompanyName,
          payload
        );
      }
      callback();
    } catch (error) {
      const translatePath = translateErrors(
        error,
        createPickUpErrors,
        createPickUpTooltip
      );
      const textError = t(translatePath.error);
      openNotification({
        status: false,
        content: textError,
        info: translatePath?.titleError && t(translatePath.titleError),
        helpUrl: translatePath?.tooltip,
      });
    } finally {
      setLoadingCreatePickup(false);
    }
  };

  const onPickupDateSelect = (value) => {
    const option = pickupAvailability.find((opt) => opt.pickupDate === value);
    setSelectedPickupDate(option);
  };

  useEffect(() => {
    if (!pickupAvailability) getPickupAvailability();
    if (showConfirmButton) setActualSpan(6);
  }, []);

  const exportValues = () => {
    if (
      setDateTime?.length > 0 &&
      form.getFieldValue('pickupDate') &&
      form.getFieldValue('readyTime') &&
      form.getFieldValue('closeTime')
    ) {
      setDateTime({
        pickupDate: form.getFieldValue('pickupDate'),
        readyTime: form.getFieldValue('readyTime'),
        closeTime: form.getFieldValue('closeTime'),
      });
    }
  };

  useImperativeHandle(ref, () => ({
    onPickupFinish,
  }));

  useEffect(() => {
    if (!courierCredentialId)
      openNotification({
        status: false,
        content: t('orders.shipment.pickUp.error.noCredential'),
      });
  }, [courierCredentialId]);

  return (
    <Form layout="vertical" onFinish={onPickupFinish} form={form}>
      <Row justify="space-between" gutter={16} align="bottom">
        <Col span={actualSpan}>
          <Form.Item
            label="Fecha de recolección"
            name="pickupDate"
            rules={[
              {
                required: true,
                message: 'Ingrese la fecha de pickup.',
              },
            ]}
          >
            <Select
              disabled={orderCourierShipments ? !orderCourierShipments : false}
              placeholder="Seleccione la fecha"
              onSelect={onPickupDateSelect}
              loading={loadingPickupAvailability}
              onChange={exportValues}
            >
              {pickupAvailability &&
                pickupAvailability.map((option) => (
                  <Select.Option key={option.pickupDate}>
                    {option.pickupDate}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={actualSpan}>
          <Form.Item
            label="Hora"
            name="readyTime"
            rules={[
              {
                required: true,
                message:
                  'Ingrese la hora a la que su envío estará listo para recolectar.',
              },
            ]}
          >
            <Select
              disabled={
                !selectedPickupDate || orderCourierShipments
                  ? !orderCourierShipments
                  : false
              }
              placeholder="Seleccione la hora"
              onChange={exportValues}
            >
              {selectedPickupDate &&
                selectedPickupDate.readyTimeOptions.map((hour) => (
                  <Select.Option key={hour}>{hour}</Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={actualSpan}>
          <Form.Item
            label="Hora máxima"
            name="closeTime"
            rules={[
              {
                required: true,
                message:
                  'Ingrese la hora máxima a la que pueden recolectar su envío.',
              },
            ]}
          >
            <Select
              disabled={
                !selectedPickupDate || orderCourierShipments
                  ? !orderCourierShipments
                  : false
              }
              placeholder="Seleccione la hora"
              onChange={exportValues}
            >
              {selectedPickupDate &&
                selectedPickupDate.latestTimeOptions.map((hour) => (
                  <Select.Option key={hour}>{hour}</Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={actualSpan}>
          <Button
            className="restock-primary-btn"
            loading={loadingCreatePickup}
            type="text"
            hidden={!showConfirmButton}
            onClick={() => form.submit()}
          >
            Agendar
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

FedexPickup.propTypes = {
  warehouseId: PropTypes.number.isRequired,
  courierCredentialId: PropTypes.string.isRequired,
  orderCourierShipments: PropTypes.arrayOf(Object),
  callback: PropTypes.func,
  restockOrderId: PropTypes.number,
  showConfirmButton: PropTypes.bool,
  setDateTime: PropTypes.func,
  pickUpId: PropTypes.number,
  formRef: PropTypes.shape({
    current: PropTypes.instanceOf(Element),
  }),
};

FedexPickup.defaultProps = {
  orderCourierShipments: null,
  restockOrderId: null,
  callback: async () => {},
  showConfirmButton: true,
  setDateTime: () => {},
  pickUpId: null,
  formRef: null,
};

export default forwardRef(FedexPickup);
