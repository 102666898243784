import { Alert, Col, Collapse, Row, Tag } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function DeliveryFees({
  shippingOnly,
  logisticService,
  currency,
  rateWithoutDiscount,
  rateWithDiscount,
  detailedRates,
  discount,
}) {
  const { t } = useTranslation();
  const parseCurrency = (currencyCode) => {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: currencyCode,
    });
  };
  const collapseHeader = (
    <Col span={24}>
      <Row>
        <Col span={16}>
          {t('calculator.shippingCost')} {logisticService}
        </Col>
        {rateWithDiscount ? (
          <Col
            span={8}
            className={`priceColumns ${discount > 0 ? 'strikedFee' : ''}`}
          >
            {`${parseCurrency(currency).format(rateWithoutDiscount)}`}
          </Col>
        ) : (
          <Col span={8} className="priceColumns">
            {currency} 0
          </Col>
        )}
      </Row>
      {discount > 0 && (
        <Row>
          <Col
            span={15}
            offset={1}
            className="priceRow"
            style={{ fontSize: 13 }}
          >
            {t('calculator.withLAPDiscount')}
            <Tag
              color="#00E5A6"
              style={{
                borderRadius: 8,
                color: '#010C33',
                fontWeight: 700,
                marginLeft: 8,
                fontSize: 11,
              }}
            >
              {discount * 100}% off
            </Tag>
          </Col>
          <Col span={8} className="priceColumns">
            {`${parseCurrency(currency).format(rateWithDiscount)}`}
          </Col>
        </Row>
      )}
    </Col>
  );
  const priceDetail = detailedRates?.map((rate) => (
    <Col span={24}>
      <Row>
        <Col span={15} className="priceRow" style={{ fontSize: 13 }}>
          {rate.name}
        </Col>
        <Col span={8} className="priceColumns">
          {`${parseCurrency(currency).format(rate.value)}`}
        </Col>
      </Row>
    </Col>
  ));
  const dutiesAndTaxesNotIncludedAlert = (
    <Alert
      style={{ marginTop: 15 }}
      type="warning"
      message={t('calculator.deliveryFeesAlert')}
    />
  );
  return (
    <Row className="feeRow">
      <Col span={24}>
        {shippingOnly && (
          <Collapse>
            <Collapse.Panel header={collapseHeader} ghost>
              {priceDetail}
              {dutiesAndTaxesNotIncludedAlert}
            </Collapse.Panel>
          </Collapse>
        )}
        {!shippingOnly && (
          <>
            <Row>
              <Col span={16}>
                {t('calculator.deliveryFees')} {logisticService}
              </Col>
              {rateWithoutDiscount ? (
                <Col
                  span={8}
                  className={`priceColumns ${discount > 0 ? 'strikedFee' : ''}`}
                >
                  -{`${parseCurrency(currency).format(rateWithoutDiscount)}`}
                </Col>
              ) : (
                <Col span={8} className="priceColumns">
                  {currency} 0
                </Col>
              )}
            </Row>
            {discount > 0 && (
              <Row>
                <Col
                  span={15}
                  offset={1}
                  className="priceRow"
                  style={{ fontSize: 13 }}
                >
                  {t('calculator.withLAPDiscount')}
                  <Tag
                    color="#00E5A6"
                    style={{
                      borderRadius: 8,
                      color: '#010C33',
                      fontWeight: 700,
                      marginLeft: 8,
                      fontSize: 11,
                    }}
                  >
                    {discount * 100}% off
                  </Tag>
                </Col>
                <Col span={8} className="priceColumns">
                  {`${parseCurrency(currency).format(rateWithDiscount)}`}
                </Col>
              </Row>
            )}
          </>
        )}
      </Col>
    </Row>
  );
}
DeliveryFees.propTypes = {
  shippingOnly: PropTypes.bool.isRequired,
  logisticService: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  rateWithoutDiscount: PropTypes.number.isRequired,
  rateWithDiscount: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  detailedRates: PropTypes.arrayOf({
    type: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
};

export default DeliveryFees;
