import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Select,
  Button,
  Form,
  Typography,
  Skeleton,
  Space,
} from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/lib/form/Form';
import { useSelector } from 'react-redux';
import moment from 'moment';
import OrderSalesTabs from './OrderSalesTabs';
import orderSalesApi from '../../../api/order-sales';
import dashboardApi from '../../../api/dashboard';
import 'moment/locale/es';
import openNotification from '../../../components/Toastr';
import { getErrorMessage } from '../../../api/api';
import { sellerMarketplaces, dateTypes } from '../../../utils/const';
import FulfillmentModal from '../../home/components/FulfillmentModal';
import {
  convertToISOString,
  mixPanelCreateEvent,
  printLabelsWithOrderSales,
} from '../../../utils/functions';

const { Item } = Form;
const { Option } = Select;
const { Text } = Typography;
const dateFormat = 'YYYY-MM-DD';

const DateNow = moment();

function OrderSales() {
  const { t } = useTranslation();
  const session = useSelector((store) => store.Session.session);
  const [form] = useForm();
  const [fieldsForm, setFieldsForm] = useState({});
  const [printLoading, setPrintLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPartners] = useState(false);
  const [partners] = useState([]);
  const [data, setData] = useState([]);
  const [isFullfilmentModalVisible, setFullfilmentModalVisible] = useState({
    state: false,
    data: [],
  });
  const [tableSelectedRows, setTableSelectedRows] = useState([]);
  const [showShipmentModal, setShowShipmentModal] = useState(false);
  const [showTrackingModal, setShowTrackingModal] = useState(false);
  const [listMarketplaceAvailable, setListMarketplaceAvailable] = useState([]);

  const getMarketplaces = async () => {
    const defaultFilters = {
      dashboard: 'sales',
      partner: [session?.userInfo?.commercial_partner_id[0]],
      sku: [],
      marketplace: Object.values(sellerMarketplaces),
      category: ['sales', 'stock'],
      compare: false,
      dateType: dateTypes.PURCHASE_DATE,
      dateRangeOne: convertToISOString([moment().add(-1, 'M'), moment()]),
      dateRangeTwo: [],
      fulfillment: '',
      period: 'currentMonth',
      currency: localStorage.getItem('defaultCurrency'),
    };

    await dashboardApi
      .getMarketplacesPerClient(defaultFilters)
      .then((response) => {
        const marketAvailable = [];
        if (response?.data?.length > 0) {
          response?.data.forEach((e) => {
            if (e.name === 'Amazon') {
              marketAvailable.unshift(e.name);
            } else {
              marketAvailable.push(e.name);
            }
          });
        }
        setListMarketplaceAvailable(marketAvailable);
      })
      .catch((error) => {
        openNotification({ status: false, content: getErrorMessage(error) });
      });
  };

  const onFinish = (filterCustom) => {
    let values = {};
    if (filterCustom?.dateRange) {
      values = filterCustom;
    } else {
      values = form.getFieldsValue();
      values.dateRange = [moment().add(-30, 'days'), moment()];
    }
    values.partnerId = !session?.userInfo?.isAdmin
      ? session?.userInfo?.commercial_partner_id[0]
      : values?.partnerId;
    setLoading(true);
    orderSalesApi
      .getByFilter(values)
      .then((res) => {
        setData(res.data?.Orders);
        setLoading(false);
        mixPanelCreateEvent('API Rest', {
          status: true,
          message: 'Search Orders Data Returned',
          method: 'PUT',
          url: res.config.url,
        });
      })
      .catch(() => {
        mixPanelCreateEvent('API Rest', {
          status: false,
          message: 'Search Orders Data not Returned',
        });
        setLoading(false);
      });
  };

  const updateOrders = () => {
    onFinish(fieldsForm);
  };

  const handlePersonalizedButton = () => {
    const filters = form.getFieldValue();
    if (filters.period === 'currentWeek') {
      form.setFieldsValue({
        ...filters,
        dateRange: [moment().add(-moment().weekday(), 'd'), moment()],
      });
      setFieldsForm({
        ...filters,
        dateRange: [moment().add(-moment().weekday(), 'd'), moment()],
      });
      onFinish({
        ...filters,
        dateRange: [moment().add(-moment().weekday(), 'd'), moment()],
      });
    } else if (filters.period === 'last7days') {
      form.setFieldsValue({
        ...filters,
        dateRange: [moment().add(-6, 'd'), moment()],
      });
      setFieldsForm({
        ...filters,
        dateRange: [moment().add(-6, 'd'), moment()],
      });
      onFinish({
        ...filters,
        dateRange: [moment().add(-6, 'd'), moment()],
      });
    } else if (filters.period === 'currentMonth') {
      form.setFieldsValue({
        ...filters,
        dateRange: [moment().startOf('month'), moment()],
      });
      setFieldsForm({
        ...filters,
        dateRange: [moment().startOf('month'), moment()],
      });
      onFinish({
        ...filters,
        dateRange: [moment().startOf('month'), moment()],
      });
    } else if (filters.period === 'lastMonth') {
      form.setFieldsValue({
        ...filters,
        dateRange: [
          moment().subtract(1, 'months').startOf('month'),
          moment().subtract(1, 'months').endOf('month'),
        ],
      });
      setFieldsForm({
        ...filters,
        dateRange: [
          moment().subtract(1, 'months').startOf('month'),
          moment().subtract(1, 'months').endOf('month'),
        ],
      });
      onFinish({
        ...filters,
        dateRange: [
          moment().subtract(1, 'months').startOf('month'),
          moment().subtract(1, 'months').endOf('month'),
        ],
      });
    } else if (filters.period === 'last30days') {
      form.setFieldsValue({
        ...filters,
        dateRange: [moment().add(-30, 'd'), moment()],
      });
      setFieldsForm({
        ...filters,
        dateRange: [moment().add(-30, 'd'), moment()],
      });
      onFinish({
        ...filters,
        dateRange: [moment().add(-30, 'd'), moment()],
      });
    } else if (filters.period === 'currentYear') {
      form.setFieldsValue({
        ...filters,
        dateRange: [moment().startOf('year'), moment()],
      });
      setFieldsForm({
        ...filters,
        dateRange: [moment().startOf('year'), moment()],
      });
      onFinish({
        ...filters,
        dateRange: [moment().startOf('year'), moment()],
      });
    } else if (filters.period === 'last12months') {
      form.setFieldsValue({
        ...filters,
        dateRange: [moment().add(-12, 'M'), moment()],
      });
      setFieldsForm({
        ...filters,
        dateRange: [moment().add(-12, 'M'), moment()],
      });
      onFinish({
        ...filters,
        dateRange: [moment().add(-12, 'M'), moment()],
      });
    }
  };

  const formDefault = {
    dateRange: [
      moment(moment().add(-30, 'days'), dateFormat),
      moment(DateNow, dateFormat),
    ],
  };

  const getSyncStatus = async () => {
    const partnerId = !session?.userInfo?.isAdmin
      ? session?.userInfo?.commercial_partner_id[0]
      : form.getFieldsValue()?.partnerId || null;
    if (!partnerId) {
      return;
    }
    try {
      const { syncStatusData } = await orderSalesApi.getHistoricalSyncStatus(
        partnerId
      );
      if (!syncStatusData?.finished) {
        setTimeout(() => {
          getSyncStatus();
        }, 60000);
      }
    } catch (error) {
      openNotification({ status: false, content: error });
    }
  };

  useEffect(() => {
    getMarketplaces();
    const defaultForm = {
      dateRange: [moment().add(-30, 'days'), moment()],
    };
    setFieldsForm(defaultForm);
    form.setFieldValue('dateRange', [moment().add(-30, 'days'), moment()]);
    form.submit();
    getSyncStatus();
  }, []);

  const onChangeMarketplaces = (e) => {
    const filterSubmit = {
      ...fieldsForm,
      marketplace: e,
    };
    setFieldsForm(filterSubmit);
    onFinish(filterSubmit);
  };

  const fullflimentSearch = (e) => {
    const filterSubmit = {
      ...fieldsForm,
      fulfillmentType: e,
    };
    setFieldsForm(filterSubmit);
    onFinish(filterSubmit);
  };

  const handleShipmentOnClick = () => {
    setShowShipmentModal(true);
  };

  const handleTrackingOnClick = () => {
    setShowTrackingModal(true);
  };

  const downloadLabels = () => {
    setPrintLoading(true);
    printLabelsWithOrderSales(tableSelectedRows);
    setPrintLoading(false);
  };

  return (
    <>
      <Row className="home-listing-main-row orders-status">
        <Col span={24} xs={24} sm={24} md={24} lg={24} xl={24}>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Card>
              <Form
                name="formulario"
                layout="vertical"
                form={form}
                onFinish={onFinish}
                initialValues={formDefault}
                className="text-align-left"
              >
                <Row gutter={24}>
                  <Col xs={24} lg={4} md={4}>
                    <Form.Item
                      name="marketplace"
                      label={t('orders.filter.marketplace')}
                    >
                      <Select
                        defaultValue={null}
                        onChange={onChangeMarketplaces}
                      >
                        <Option value={null}>Todos</Option>
                        {listMarketplaceAvailable?.map((mkp) => (
                          <Option key={mkp} value={sellerMarketplaces[mkp]}>
                            {t(
                              `dashboard.marketplaces.${mkp.replace(' ', '')}`
                            )}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={4} md={4}>
                    <Form.Item name="period" label={t('orders.filter.period')}>
                      <Select
                        defaultValue="last30days"
                        onChange={handlePersonalizedButton}
                        options={
                          form.getFieldValue('partner') !== ''
                            ? [
                                {
                                  value: `currentWeek`,
                                  label: 'Semana actual',
                                },
                                {
                                  value: `last7days`,
                                  label: 'Últimos 7 días',
                                },
                                {
                                  value: `currentMonth`,
                                  label: 'Mes actual',
                                },
                                {
                                  value: `lastMonth`,
                                  label: 'Mes Anterior',
                                },
                                {
                                  value: `last30days`,
                                  label: 'Últimos 30 días',
                                },
                                {
                                  value: `currentYear`,
                                  label: 'Año actual',
                                },
                                {
                                  value: `last12months`,
                                  label: 'Últimos 12 meses',
                                },
                                {
                                  value: 'custom',
                                  label: 'Personalizado',
                                },
                              ]
                            : []
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={4} md={4}>
                    <Form.Item
                      name="fulfillmentType"
                      label={t('orders.filter.orderManagement')}
                    >
                      <Select
                        defaultValue={null}
                        bordered
                        onChange={fullflimentSearch}
                      >
                        <Option value={null}> Todos </Option>
                        <Option value="FBA"> Automático </Option>
                        <Option value="FBM"> Manual</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {session?.userInfo?.isAdmin && (
                    <Col
                      span={3}
                      style={{ fontSize: '13px', marginRight: '10px' }}
                    >
                      <Row>
                        <Item
                          name="partnerId"
                          style={{ width: '100%' }}
                          label={<Text strong>Partner</Text>}
                          rules={[
                            {
                              required: true,
                              message: `Partner ${t('isRequired')}`,
                            },
                          ]}
                        >
                          <Select
                            defaultValue="Seleccione partner"
                            bordered
                            loading={loadingPartners}
                            allowClear
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            showSearch
                          >
                            {partners?.map((partner) => (
                              <Option value={partner.id} key={partner.id}>
                                {partner?.name}
                              </Option>
                            ))}
                          </Select>
                        </Item>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Form>
            </Card>
            <Card>
              {loading ? (
                <div className="generic-spinner" style={{ marginTop: 50 }}>
                  <Skeleton active />
                </div>
              ) : (
                <OrderSalesTabs
                  orderSales={data.map((item, index) => ({
                    ...item,
                    key: index,
                  }))}
                  setModalVisible={setFullfilmentModalVisible}
                  tableSelectedRows={tableSelectedRows}
                  setTableSelectedRows={setTableSelectedRows}
                  showShipmentModal={showShipmentModal}
                  setShowShipmentModal={setShowShipmentModal}
                  showTrackingModal={showTrackingModal}
                  setShowTrackingModal={setShowTrackingModal}
                  updateOrders={updateOrders}
                />
              )}
            </Card>
            <Row style={{ position: 'absolute', right: '2%', top: 160 }}>
              <Space size={8}>
                <Col>
                  <Button
                    disabled={
                      tableSelectedRows.length < 1 ||
                      tableSelectedRows[0].orderItems.length < 1 ||
                      tableSelectedRows[0].orderItems.find(
                        (orderItem) =>
                          orderItem.fulfillmentType === 'FBM' &&
                          (!('fbmCourierShipmentId' in orderItem) ||
                            orderItem.fbmCourierShipmentId === null)
                      ) ||
                      !tableSelectedRows[0].orderItems.find(
                        (orderItem) => orderItem.fulfillmentType === 'FBM'
                      )
                    }
                    onClick={downloadLabels}
                    loading={printLoading}
                  >
                    {t('common.print')} <PrinterOutlined />
                  </Button>
                </Col>
                <Col>
                  <Button
                    disabled={
                      tableSelectedRows.length < 1 ||
                      tableSelectedRows[0].orderItems.length < 1 ||
                      !tableSelectedRows[0].orderItems.find(
                        (orderItem) =>
                          orderItem.fulfillmentType === 'FBM' &&
                          (!('fbmCourierShipmentId' in orderItem) ||
                            orderItem.fbmCourierShipmentId === null)
                      ) ||
                      !tableSelectedRows[0].orderItems.find(
                        (orderItem) => orderItem.fulfillmentType === 'FBM'
                      )
                    }
                    className="btn-basic-green"
                    onClick={handleShipmentOnClick}
                  >
                    Crear envio
                  </Button>
                </Col>
                <Col>
                  <Button
                    disabled={
                      tableSelectedRows.length < 1 ||
                      tableSelectedRows[0].orderItems.length < 1 ||
                      !tableSelectedRows[0].orderItems.find(
                        (orderItem) =>
                          orderItem.fulfillmentType === 'FBM' &&
                          (!('fbmCourierShipmentId' in orderItem) ||
                            orderItem.fbmCourierShipmentId === null)
                      ) ||
                      !tableSelectedRows[0].orderItems.find(
                        (orderItem) => orderItem.fulfillmentType === 'FBM'
                      ) ||
                      tableSelectedRows[0].marketplace !== 'Walmart'
                    }
                    className="btn-basic-green"
                    onClick={handleTrackingOnClick}
                  >
                    Actualizar Tracking
                  </Button>
                </Col>
              </Space>
            </Row>
          </Space>
        </Col>
      </Row>
      <FulfillmentModal
        setModalVisible={setFullfilmentModalVisible}
        isModalVisible={isFullfilmentModalVisible}
      />
    </>
  );
}

export default React.memo(OrderSales);
