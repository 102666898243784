import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {
  Table,
  Typography,
  Tooltip,
  Image,
  Button,
  Row,
  Col,
  Space,
  Avatar,
} from 'antd';
import { FileDoneOutlined, PictureOutlined } from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { orderSaleStates } from '../../../utils/const';
import fbaLogo from '../../../assets/fbaLogo.png';
import Logo99min from '../../../assets/int99logo.png';
import LogoeDarkStore from '../../../assets/logo_eDarkstore.svg';
import 'antd/dist/antd.min.css';
import { getSvgMarketPlace } from '../../../utils/functions';
import ShipmentModal from './ShipmentModal';
import getLinkToStore from '../../home/components/linkToStore';
import phones from '../../../utils/json/phones.json';
import UpdateTrackingModal from './UpdateTrackingModal';

const { Text } = Typography;

function TableOrderSales({
  orderSales,
  setModalVisible,
  hasRefunds,
  tableSelectedRows,
  setTableSelectedRows,
  orderStatus,
  showShipmentModal,
  setShowShipmentModal,
  showTrackingModal,
  setShowTrackingModal,
  updateOrders,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState();
  const [tableSelectedRowsKeys, setTableSelectedRowsKeys] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [destinationCountryFilters, setDestinationCountryFilters] = useState();

  useEffect(() => {
    const getDestinationCountryFilters = (orderSalesData) => {
      const filters = [];
      orderSalesData.forEach((record) => {
        const country = phones.find(
          (phone) => phone.code === record?.destinationAddress?.countryCode
        );
        if (
          country &&
          !filters.find((filter) => filter.value === country?.code)
        ) {
          filters.push({
            text: `${country?.flag} ${country?.name}`,
            value: country?.code,
          });
        }
      });
      return filters;
    };

    setData(orderSales);
    setDestinationCountryFilters(getDestinationCountryFilters(orderSales));
  }, [orderSales]);

  const handleFulfillmentModal = (record) => {
    setModalVisible({ state: true, order: record });
  };

  const convertShippedDate = (date, format) => {
    const orderDate = new Date(date);
    return moment(orderDate).format(format);
  };

  const renderProductColumn = (value, record) => {
    const itemsToRender = record?.orderItems.slice(0, 5) || [];
    const rows = itemsToRender.map((item) => (
      <Row align="middle" justify="start">
        <Col
          span={24}
          style={{
            marginBottom: -10,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              paddingBottom: 20,
            }}
          >
            <Space size="middle">
              {item?.mainImage ? (
                <Avatar
                  shape="square"
                  size="large"
                  src={<Image src={`${item?.mainImage}`} />}
                />
              ) : (
                <Avatar
                  shape="square"
                  size="large"
                  icon={<PictureOutlined />}
                />
              )}
              <Space direction="vertical" style={{ textAlign: 'left' }}>
                <p
                  style={{
                    marginBottom: -10,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {getLinkToStore[record?.marketplace](item) || 'Sin datos'}
                </p>
                <p style={{ marginBottom: -10, fontSize: '12px' }}>{`SKU: ${
                  item?.sku || 'Sin datos'
                } / Cantidad: ${item?.quantity || 'Sin datos'}`}</p>
                <p style={{ marginBottom: -10, fontSize: '12px' }}>{`ID: ${
                  item?.itemId || 'Sin datos'
                }`}</p>
              </Space>
            </Space>
          </div>
        </Col>
      </Row>
    ));

    if (record?.orderItems.length > itemsToRender.length) {
      const difference =
        (record?.orderItems.length || itemsToRender.length) -
        itemsToRender.length;
      const moreItem = (
        <Row align="middle" justify="start">
          <Col
            span={24}
            style={{
              marginBottom: -10,
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            <p style={{ fontSize: '12px' }}>{`+${difference} productos más`}</p>
          </Col>
        </Row>
      );
      rows.push(moreItem);
    }

    return rows;
  };

  const onClickDetail = (record) => {
    history.push(`pedidos/${record.id}`);
  };

  const saleOrderColumns = [
    {
      title: 'Fecha de compra',
      dataIndex: 'purchaseDate',
      key: 'purchaseDate',
      align: 'center',
      width: '10%',
      render: (text, record) => (
        <div style={{ textAlign: 'center' }}>
          <Text style={{ fontSize: 12 }}>
            {convertShippedDate(record?.purchaseDate, 'DD/MM/YY')}
          </Text>
          <br />
          <Text style={{ fontSize: 12 }}>
            {convertShippedDate(record?.purchaseDate, 'HH:mm:ss')}
          </Text>
        </div>
      ),
    },
    {
      title: 'Marketplace',
      dataIndex: 'marketplace',
      key: 'marketplace',
      align: 'center',
      width: '12%',
      render: (value) => getSvgMarketPlace(value, true),
    },
    {
      title: 'Detalles del pedido',
      dataIndex: 'orderDetail',
      key: 'orderDetail',
      align: 'center',
      width: '30%',
      render: (text, record) =>
        record?.orderItems.length > 0 ? renderProductColumn(text, record) : [],
    },
    {
      title: 'Cliente',
      dataIndex: 'client',
      key: 'client',
      width: '22%',
      align: 'center',
      filters: destinationCountryFilters,
      onFilter: (value, record) =>
        record.destinationAddress?.countryCode === value,

      render: (text, record) => {
        const country = phones.find(
          (phone) => phone.code === record?.destinationAddress?.countryCode
        );
        return (
          <div style={{ fontSize: 12, textAlign: 'center' }}>
            <Text>
              {country?.flag}{' '}
              {record?.buyerInfo?.name ||
                record?.buyerInfo?.email ||
                'Sin datos disponibles'}
            </Text>
            <br />
            <Text style={{ fontSize: 10 }}>
              {/* eslint-disable-next-line no-nested-ternary */}
              {record?.destinationAddress?.city
                ? `${record?.destinationAddress?.city} ${
                    record?.destinationAddress?.postalCode || ''
                  }, ${country?.name || ''}`
                : record?.buyerInfo?.name || record?.buyerInfo?.email
                ? 'Sin datos disponibles'
                : ''}
            </Text>
          </div>
        );
      },
    },
    /*
    {
      title: 'Fecha de entrega',
      dataIndex: 'purchaseShipping',
      key: 'purchaseShipping',
      align: 'center',
      render: (text, record) => (
        <div style={{ textAlign: 'center'}}>
        <Text style={{ fontSize: 12 }}>Estimada: {convertShippedDate(record?.purchaseDate, 'HH:mm:ss')}</Text><br/>
        <Text>Real: {convertShippedDate(record?.purchaseDate, 'DD/MM/YYYY')}</Text>
        </div>
      ),
    },
    */
    {
      title: 'Courier',
      dataIndex: 'fulfillment',
      key: 'fulfillment',
      align: 'center',
      width: '10%',
      render: (text, record) => {
        let component;
        switch (record?.fulfillmentChannel) {
          case 'FBA':
            if (record.marketplace === 'Shopify') {
              component = (
                <Text
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleFulfillmentModal(record);
                  }}
                >
                  {record.fbmCourier === 'n/a' ? 'Manual' : record.fbmCourier}
                </Text>
              );
            } else {
              component = (
                <Image
                  style={{ cursor: 'pointer' }}
                  height={27}
                  preview={false}
                  onClick={() => {
                    handleFulfillmentModal(record);
                  }}
                  src={fbaLogo}
                />
              );
            }
            break;
          case 'FBM':
            if (record.fbmCourier === 'n/a') {
              component = (
                <Text
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleFulfillmentModal(record);
                  }}
                >
                  Manual
                </Text>
              );
            } else {
              component = (
                <Text
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleFulfillmentModal(record);
                  }}
                >
                  {record.fbmCourier}
                </Text>
              );
            }
            break;
          case 'MF':
            component = (
              <Text
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleFulfillmentModal(record);
                }}
              >
                Mixto
              </Text>
            );
            break;
          case 'FBO':
            if (record.marketplace === 'Edicommerce') {
              component = (
                <Text
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleFulfillmentModal(record);
                  }}
                >
                  <Image
                    style={{ cursor: 'pointer' }}
                    height={50}
                    preview={false}
                    src={LogoeDarkStore}
                  />
                </Text>
              );
            } else {
              component = (
                <Text
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleFulfillmentModal(record);
                  }}
                >
                  <Image
                    style={{ cursor: 'pointer' }}
                    height={50}
                    preview={false}
                    src={Logo99min}
                  />
                </Text>
              );
            }
            break;
          default:
            component = (
              <Text
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleFulfillmentModal(record);
                }}
              >
                Por definir
              </Text>
            );
            break;
        }
        return component;
      },
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: '12%',
      render: (text, record) => (
        <Text style={{ textAlign: 'center', width: '50%' }}>
          {record?.orderStatus === 'Shipped' &&
          record?.orderFulfillmentStatus === 'Completed' &&
          record?.marketplace !== 'Edicommerce' ? (
            <div className="pillBox withoutError">
              {t('shipments.status.Delivered')}
            </div>
          ) : (
            ''
          )}
          {record?.orderFulfillmentStatus === 'Completed' &&
          record?.marketplace === 'Edicommerce' ? (
            <div className="pillBox withoutError">
              {t('shipments.status.Delivered')}
            </div>
          ) : (
            ''
          )}
          {(record?.orderStatus === 'Pending' ||
            record?.orderStatus === 'InvoiceUnconfirmed' ||
            record?.orderStatus === 'Unshipped' ||
            (record?.orderStatus === 'Shipped' &&
              record?.orderFulfillmentStatus === 'None') ||
            (record?.orderStatus === 'Shipped' &&
              record?.orderFulfillmentStatus === 'New') ||
            (record?.orderStatus === 'Shipped' &&
              record?.orderFulfillmentStatus === 'Planning') ||
            (record?.orderStatus === 'Shipped' &&
              record?.orderFulfillmentStatus === 'Received') ||
            record?.orderStatus === 'PendingAvailability') &&
          record?.marketplace !== 'Edicommerce' ? (
            <div className="pillBox">{t('common.preparation')}</div>
          ) : (
            ''
          )}
          {record?.orderFulfillmentStatus === 'Pending' &&
          record?.marketplace === 'Edicommerce' ? (
            <div className="pillBox">{t('common.preparation')}</div>
          ) : (
            ''
          )}
          {(record?.orderStatus === 'Canceled' ||
            record?.orderStatus === 'Unfulfillable' ||
            (record?.orderStatus === 'Shipped' &&
              record?.orderFulfillmentStatus === 'Canceled') ||
            (record?.orderStatus === 'Shipped' &&
              record?.orderFulfillmentStatus === 'Unfulfillable') ||
            (record?.orderStatus === 'Shipped' &&
              record?.orderFulfillmentStatus === 'Invalid') ||
            record?.orderStatus === 'Incident') &&
          record?.marketplace !== 'Edicommerce' ? (
            <div className="pillBox withError">{t('common.cancelled')}</div>
          ) : (
            ''
          )}
          {record?.orderFulfillmentStatus === 'Canceled' &&
          record?.marketplace === 'Edicommerce' ? (
            <div className="pillBox withError">{t('common.cancelled')}</div>
          ) : (
            ''
          )}
          {(record?.orderStatus === 'ReadyForPickup' ||
            (record?.orderStatus === 'Shipped' &&
              record?.orderFulfillmentStatus === 'Processing') ||
            record?.orderFulfillmentStatus === 'PartiallyShipped') &&
          record?.marketplace !== 'Edicommerce' ? (
            <div className="pillBox inTransit">
              {t('shipments.status.InTransit')}
            </div>
          ) : (
            ''
          )}
          {record?.orderFulfillmentStatus === 'Created' &&
          record?.marketplace === 'Edicommerce' ? (
            <div className="pillBox inTransit">
              {t('shipments.status.InTransit')}
            </div>
          ) : (
            ''
          )}
        </Text>
      ),
    },
    {
      title: t('shipments.columns.actions'),
      dataIndex: 'status',
      key: 'actions',
      align: 'center',
      width: '8%',
      render: (text, record) => (
        <>
          {(record?.orderStatus === 'ReadyForPickup' ||
            (record?.orderStatus === 'Shipped' &&
              record?.orderFulfillmentStatus === 'Processing') ||
            record?.orderFulfillmentStatus === 'PartiallyShipped') &&
          record?.marketplace !== 'Edicommerce' ? (
            <Tooltip placement="topLeft" title={t('shipments.buttons.edit')}>
              <Button
                ghost
                icon={<FileDoneOutlined className="grey-button" />}
                onClick={() => onClickDetail(record)}
                disabled={
                  record.type === 'SaleOrder' || record.status === 'Cancelled'
                }
              />
            </Tooltip>
          ) : (
            ''
          )}
          {record?.orderFulfillmentStatus === 'Created' &&
          record?.marketplace === 'Edicommerce' ? (
            <Tooltip placement="topLeft" title={t('shipments.buttons.edit')}>
              <Button
                ghost
                icon={<FileDoneOutlined className="grey-button" />}
                onClick={() => onClickDetail(record)}
                disabled={
                  record.type === 'SaleOrder' || record.status === 'Cancelled'
                }
              />
            </Tooltip>
          ) : (
            ''
          )}
        </>
      ),
    },
  ];

  const refundColums = [
    {
      title: 'REFUNDS',
      dataIndex: 'refunds',
      key: 'refunds',
      width: 150,
      render: (text, record) => <Text>{record?.refunds}</Text>,
    },
  ];

  const getColumns = () => {
    if (hasRefunds && saleOrderColumns) {
      const columns = [];
      const actionColumn = saleOrderColumns.slice(
        saleOrderColumns.length - 1,
        saleOrderColumns.length
      );
      const restColumns = saleOrderColumns.slice(
        0,
        saleOrderColumns.length - 1
      );
      refundColums.push(...actionColumn);
      columns.push(...restColumns, ...refundColums);
      return columns;
    }
    return null;
  };

  const columns = hasRefunds ? [...getColumns()] : saleOrderColumns;
  return (
    <>
      {(data && orderStatus === orderSaleStates.PENDING) ||
      orderStatus === orderSaleStates.READY_FOR_PICKUP ? (
        <Table
          className=""
          columns={columns}
          dataSource={data}
          scroll={{ y: 600 }}
          rowSelection={{
            hideSelectAll: true,
            type: 'checkbox',
            selectedRowKeys: tableSelectedRowsKeys,
            width: 5,
            onChange: (selectedRowsKeys, selectedRows) => {
              if (selectedRowsKeys.length === 0) {
                setSelectedCountry(undefined);
              }
              setTableSelectedRowsKeys(selectedRowsKeys);
              setTableSelectedRows(selectedRows);
              setShowShipmentModal(false);
              setShowTrackingModal(false);
            },
            onSelect: (record) => {
              setSelectedCountry(record?.destinationAddress?.countryCode);
            },
            getCheckboxProps: (record) => ({
              disabled:
                record.fulfillmentChannel === 'FBA' ||
                record.fulfillmentChannel === 'FBO' ||
                (record.fulfillmentChannel === 'FBM' &&
                  selectedCountry &&
                  record.destinationAddress.countryCode !== selectedCountry),
            }),
          }}
        />
      ) : (
        <Table
          className=""
          columns={columns}
          dataSource={data}
          scroll={{ y: 600 }}
        />
      )}
      {orderStatus === orderSaleStates.PENDING &&
        tableSelectedRows.length >= 1 && (
          <div>
            <ShipmentModal
              orders={tableSelectedRows}
              showShipmentModal={showShipmentModal}
              setShowShipmentModal={setShowShipmentModal}
              orderType="saleOrder"
              updateOrders={updateOrders}
              setTableSelectedRows={setTableSelectedRows}
            />
            <UpdateTrackingModal
              orders={tableSelectedRows}
              showTrackingModal={showTrackingModal}
              setShowTrackingModal={setShowTrackingModal}
            />
          </div>
        )}
    </>
  );
}

TableOrderSales.propTypes = {
  orderSales: PropTypes.arrayOf(Object).isRequired,
  setModalVisible: PropTypes.func.isRequired,
  hasRefunds: PropTypes.bool,
  tableSelectedRows: PropTypes.arrayOf(Object).isRequired,
  setTableSelectedRows: PropTypes.func.isRequired,
  orderStatus: PropTypes.string.isRequired,
  showShipmentModal: PropTypes.bool.isRequired,
  setShowShipmentModal: PropTypes.func.isRequired,
  showTrackingModal: PropTypes.bool.isRequired,
  setShowTrackingModal: PropTypes.func.isRequired,
  updateOrders: PropTypes.func.isRequired,
};

TableOrderSales.defaultProps = {
  hasRefunds: false,
};

export default TableOrderSales;
