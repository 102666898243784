const couriers = [
  'UPS',
  'USPS',
  'FedEx',
  'Airborne',
  'OnTrac',
  'DHL Ecommerce - US',
  'DHL',
  'LS',
  'UDS',
  'UPSMI',
  'FDX',
  'PILOT',
  'ESTES',
  'SAIA',
  'FDS Express',
  'Seko Worldwide',
  'HIT Delivery',
  'FEDEXSP',
  'RL Carriers',
  'Metropolitan Warehouse & Delivery',
  'China Post',
  'YunExpress',
  'Yellow Freight Sys',
  'AIT Worldwide Logistics',
  'Chukou1',
  'Sendle',
  'Landmark Global',
  'Sunyou',
  'Yanwen',
  '4PX',
  'GLS',
  'OSM Worldwide',
  'FIRST MILE',
  'AM Trucking',
  'CEVA',
  'India Post',
  'SF Express',
  'CNE',
  'TForce Freight',
  'AxleHire',
  'LSO',
  'Royal Mail',
  'ABF Freight System',
  'WanB',
  'Roadrunner Freight',
  'Meyer Distribution',
  'AAA Cooper',
  'Canada Post',
  'Southeastern Freight Lines',
  'Japan Post',
  'Correos de Mexico',
  'XPO Logistics',
  'JD Logistics',
  'YDH',
  'JCEX',
  'Flyt',
  'Deutsche Post',
  'Better Trucks',
  'Asendia',
  'SFC',
  'UBI',
  'ePost Global',
  'YF Logistics',
  'RXO',
  'Estes Express',
  'Shypmax',
  'WIN.IT America',
  'PITT OHIO',
  'PostNord Sweden',
  'Equick',
  'Whistl',
  'Tusou',
  'Shiprocket',
  'DTDC',
  'PTS',
];

export default couriers;
