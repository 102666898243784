import {
  Button,
  Col,
  Form,
  Modal,
  Space,
  Row,
  Typography,
  Select,
  Input,
} from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import couriers from './couriers/enum/courier-names';
import salesOrderApi from '../../../api/order-sales';
import openNotification from '../../../components/Toastr';

function UpdateTrackingModal({
  orders,
  showTrackingModal,
  setShowTrackingModal,
}) {
  const [form] = Form.useForm();

  const [loading] = useState();

  const { Title } = Typography;

  const onCancel = () => {
    form.resetFields();
    setShowTrackingModal(false);
  };

  const onOk = async (values) => {
    const { id, marketplace } = orders[0];
    try {
      await salesOrderApi.updateSaleOrderTracking(id, {
        marketplace,
        carrierName: values.carrierName,
        trackingNumber: values.trackingNumber,
      });
      openNotification({
        status: true,
        content: 'Tracking number actualizado con éxito',
      });
    } catch (err) {
      openNotification({
        status: false,
        content: 'Ocurrió un error al actualizar el tracking number',
      });
    }
  };

  return (
    <Modal
      open={showTrackingModal}
      closable
      footer={[null]}
      onCancel={onCancel}
    >
      <Row justify="center">
        <Title level={2} style={{ fontStyle: 'italic' }}>
          Actualizar Tracking
        </Title>
      </Row>
      <Form
        form={form}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 24,
        }}
        className="text-align-left"
        autoComplete="off"
        onFinish={onOk}
      >
        <Col>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <div>
              <span>Destino</span>{' '}
            </div>
            {orders.map((order) => (
              <Col justify="start">
                <div style={{ fontSize: 15 }}>
                  {order.destinationAddress?.address1 || 'sin datos'}
                </div>
                <div style={{ fontSize: 12 }}>
                  {`${order.buyerInfo?.name || 'Sin nombre'} / ${
                    order.buyerInfo?.email || 'Sin email'
                  } / Tel: ${
                    order.destinationAddress?.phone || 'Sin telefono'
                  }` || ''}
                </div>
              </Col>
            ))}
            <div>
              <span>Courier</span>{' '}
            </div>
            <Form.Item
              name="courier"
              rules={[
                {
                  required: true,
                  message: 'Seleccione un courier.',
                },
              ]}
            >
              <Select
                placeholder="Seleccionar courier"
                style={{ width: '100%' }}
                size="large"
              >
                {couriers.map((courier) => (
                  <Select.Option key={courier} value={courier}>
                    {courier}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="tracking_number"
              rules={[
                {
                  required: true,
                  message: 'Ingrese un número de seguimiento.',
                },
              ]}
            >
              <Input placeholder="Ingresar Tracking Number" />
            </Form.Item>
          </Space>
        </Col>
        <Col justify="center" style={{ marginTop: 64 }}>
          <Row justify="center">
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="ant-btn ant-btn-primary btn-dimensions"
            >
              Actualizar
            </Button>
          </Row>
        </Col>
      </Form>
    </Modal>
  );
}

UpdateTrackingModal.propTypes = {
  orders: PropTypes.arrayOf(Object).isRequired,
  showTrackingModal: PropTypes.bool.isRequired,
  setShowTrackingModal: PropTypes.func.isRequired,
};

export default UpdateTrackingModal;
