const en = {
  addressErrors: {
    default: 'An error occurred while obtaining address details.',
    postalCode:
      'A zip code associated with the address entered was not found. Please complete it manually.',
  },
  amazonError: {
    createInboundShipment: {
      default: {
        text: 'Error creating your shipment on Amazon, please contact us through the support chat.',
      },
    },
    default: {
      text: 'Error with Amazon, please contact us via support chat.',
    },
    estimateTransport: {
      default: {
        text: 'Error when estimating the shipping cost from the USA to Amazon, please contact us through the support chat.',
      },
    },
    putTransportDetails: {
      default: {
        text: 'Error providing FedEx tracking number to Amazon, please contact us via support chat.',
      },
    },
    submitFBAInboundCartonContent: {
      default: {
        text: 'Error when saving box measurement and weight information on Amazon, please contact us through the support chat.',
      },
    },
    updateInboundShipment: {
      default: {
        text: 'Error updating shipping status on Amazon, please contact us via support chat.',
      },
      expired: {
        text: 'This order is already created on Amazon or has expired',
      },
    },
  },
  analytics: {
    marketing: {
      subtitle: 'Lorem Ipsum is simply dummy text of the printing and',
      title: 'Marketing',
    },
    sales: {
      subtitle: 'View Sales data.',
      title: 'Sales',
    },
    stock: {
      subtitle: 'View Stock data.',
      title: 'stock',
    },
    subtitle:
      'View the sales and inventory of each marketplace in a given period of time',
    title: 'Analytics',
    visits: {
      subtitle: 'Lorem Ipsum is simply dummy text of the printing and',
      title: 'Visits and Impressions',
    },
  },
  assistant: {
    chat: 'Chat',
    errors: {
      get: 'An error occurred while retrieving your chats.',
      send: 'Error sending message.',
    },
    newChat: 'New chat',
    options: {
      alcohol: 'Alcohol',
      children: "Children's products",
      cosmetics: 'Cosmetics',
      docs: 'Documentation',
      electro: 'Electro',
      fertilizers: 'Fertilizers',
      food: 'Food',
      jewelry: 'Jewelry',
      labelling: 'Labelling',
      regulations: 'Regulations',
      supplements: 'Supplements',
      textiles: 'Textiles',
    },
    request:
      "I need all the information about {{ topic }} to export products of the category '{{ productType }}' produced in {{ countryOfManufacture }} to {{ destinationCountry }}.",
    title: 'A.I. Assistant',
    writing: 'Writing...',
  },
  calculator: {
    addressInput: {
      errors: [
        'An error occurred while obtaining location details.',
        'A zip code or city could not be found for the location you entered. Try a different or more specific location.',
      ],
      placeholder: 'Enter and select a location',
    },
    calculate: 'Calculate',
    calculating: 'Calculating costs...',
    category: {
      emptyError: 'Please select a category',
      placeholder: 'Select a category',
      title: 'Category',
      tooltip:
        'Select the category to which your product belongs within the selected marketplace.',
    },
    countryOfManufacture: {
      emptyError: 'Please select the country of manufacture',
      placeholder: 'Select a country',
      title: 'Country of Manufacture',
      tooltip:
        'Indicates the country of manufacture of the product. This information is necessary to determine the regulations that must be met and the taxes that must be paid in the destination country.',
    },
    deliveryFees: 'Shipping to consumer',
    deliveryFeesAlert:
      'The rate includes an estimate of import duties, fees, and taxes, but the actual amounts may vary in practice. You will be responsible for any final adjustments or additional customs clearance charges.',
    destinationAddress: {
      emptyError: 'Please enter a destination location',
      title: 'Destination location',
      tooltip:
        'Indicate the location where you would like to sell your products. You can try different cities or countries and compare the associated costs.',
    },
    disclaimer: {
      description:
        'This calculator considers the main costs associated with selling on global marketplaces. However, it is important to note that',
      seeMore: 'To learn more, visit the following link in our help center ',
      warning: ' it is an estimate and costs may vary in reality.',
    },
    findCalculations: 'Filter calculations by name',
    harmonizedCode: {
      emptyError: 'Please fill the harmonized code',
      placeholder: '0000.00',
      title: 'Harmonized Code',
      tooltip:
        'Indicates the harmonized code of the product. This code is used to classify products according to their nature and specific characteristics. It is used to establish the regulations and documents required according to the type of product.',
    },
    loadCalculation: {
      error:
        'The calculation could not be loaded. Please try again later. ({{ mistake }})',
      success: 'The calculation has been uploaded successfully',
    },
    logisticService: {
      emptyError: 'Please select a logistics operator',
      placeholder: 'Select a logistics operator',
      title: 'Logistic operator',
      tooltip:
        'Select the logistics operator (courier or fulfillment center) that will send your products to the final consumer.',
    },
    logisticType: {
      destination: {
        description:
          'Select this option if you want to send stock of several units of your products to a fulfillment center, such as Amazon. They will be in charge of storing your products in the country where they are sold and shipping them to the end customer every time there is a sale.',
        title: 'Inventory at destination',
      },
      emptyError: 'Please select the type of logistics',
      origin: {
        description:
          'Select this option if you want to keep your products stored in your country of origin and ship each time there is a purchase directly to the end customer in the destination country using a courier such as FedEx.',
        title: 'Inventory at origin',
      },
      placeholder: 'Select a type of logistics',
      title: 'Logistics type',
      tooltip:
        'Enter the price at which you plan to sell your product on the marketplace. You can try any price and then adjust it based on the margin you want to make.',
    },
    mainSubtitle:
      'Calculate the costs of selling your products and define the ideal price to achieve your goals!',
    mainTitle: 'Cost chain',
    margin: 'Margin',
    marketplace: {
      emptyError: 'Please select a marketplace',
      placeholder: 'Select a marketplace',
      title: 'Marketplace',
      tooltip:
        'Select the international marketplace where you plan to sell your products. Each marketplace has its own policies and commission structures.',
    },
    marketplaceFees: 'Marketplace Commission',
    myCalculations: 'My calculations',
    newProduct: {
      subtitle: 'Post it in the catalog section!',
      title: 'New product (not listed)',
    },
    originAddress: {
      emptyError: 'Please enter a home location',
      title: 'Origin Location',
      tooltip:
        'Indicate the location from where you will send your products to the end customer or fulfillment center (normally your own home, warehouse or warehouse).',
    },
    others: 'Others',
    packaging: {
      title: 'Packaging measurements',
      type: {
        box: 'box',
        box_plural: 'boxes',
        default: 'package',
        default_plural: 'packages',
        emptyError: 'Please select the type of packaging',
        pallet: 'pallet',
        pallet_plural: 'pallets',
        placeholder: 'Select the type of packaging',
        title: 'Packaging type',
        tooltip:
          'Select the type of packaging in which you will send your products to the fulfillment center.',
      },
      units: {
        emptyError: 'Please enter the number of {{ packagingType }}',
        title: 'Number of {{ packagingType }}',
        tooltip: 'Number of packages in the shipment.',
      },
      unitsPerPackaging: {
        emptyError: 'Please enter units by {{ packagingType }}',
        title: 'Units per {{ packagingType }}',
        tooltip:
          'Number of units of the product to be sent contained in a package.',
      },
      weight: {
        emptyError: 'Please fill in the total weight by {{ packagingType }}',
        title: 'Total weight per {{ packagingType }}',
        tooltip: 'Total weight of a package including its contained products.',
      },
    },
    price: {
      emptyError: 'Please fill in the sales price',
      title: 'Sale price',
      tooltip:
        'Enter the price at which you plan to sell your product on the marketplace. You can try any price and then adjust it based on the margin you want to make.',
    },
    product: 'product',
    productUnits: 'Product measurements',
    saveCalculation: {
      error:
        'The calculation could not be saved. Please try again later. ({{ mistake }})',
      helper: 'Enter a name to save the calculator result',
      placeholder: 'LAP Mug',
      success: 'The calculation has been saved successfully',
      title: 'Save calculation',
    },
    serviceTitle: 'Service: ',
    serviceTooltip:
      'Select one of the available services, which may vary in cost and delivery time.',
    shippingCost: 'Cost of shipping',
    shippingOnly: 'Quote shipping only',
    storageFees: 'Warehouse',
    totalRestockFees: 'Total shipping cost',
    totalUnits: '({{ units }} units): ',
    unit: {
      emptyError: 'Please complete the {{ unit }} of the {{ object }}',
      height: 'Height',
      length: 'Length',
      tooltip: '{{ unit }} of {{ object }}',
      weight: 'Weight',
      width: 'Width',
    },
    unitRestockFees: 'Shipping to fulfillment center (Fedex)',
    withLAPDiscount: 'With LAP agreement',
  },
  catalogue: {
    banner: {
      syncImportDescription:
        'You have one or more product categories in the process of being published, once completed we will send you a notification.',
      syncImportTitle: 'Publication in loading status.',
    },
    confirmDeleteUnpublishedProduct:
      'Are you sure you want to delete the unpublished product?',
    deleteUnpublishedProduct: 'Delete unpublished product',
    editProductModal: {
      bulletPoint: 'Bullet Point',
      errorRemoveImage: 'Error deleting image.',
      errorUploadImage: 'Error saving image.',
      newBulletPoint: 'bullet point',
      newBulletPointPlaceholder: 'Enter bullet point',
      newBulletPointTooltip: 'Maximum 500 characters',
      newDescriptionTooltip: 'Maximum 2000 characters',
      newMainImgTooltip:
        'Please note that the main image may only show the product being sold. The use of text, logos, people or artifacts that are not included in the product itself is prohibited and must have a completely white background (no background). The image must have a resolution greater than 1000 x 1000 pixels and be in JPG or PNG format. Be sure to provide a high-quality square image that clearly shows the product and its details.',
      newPriceTooltip:
        'Only numbers, not include currency symbol. Only use point for decimals.',
      newProductDescription: 'Description',
      newProductDescriptionPlaceholder:
        'Enter description (maximum 2000 characters).',
      newProductMainImage: 'New main image',
      newProductPrice: 'Price',
      newProductPricePlaceholder: '15.99',
      newProductSecondaryImages: 'New secondary images',
      newProductTitle: 'Qualification',
      newProductTitlePlaceholder: 'Enter title',
      newSecondaryImgTooltip:
        'Please note that images may only show the product being sold. The use of text, logos, people or artifacts that are not included in the product itself is prohibited and must have a completely white background (no background). The image must have a resolution greater than 1000 x 1000 pixels and be in JPG or PNG format. Be sure to provide a high-quality square image that clearly shows the product and its details.',
      newTitleTooltip: 'Maximum 60 characters.',
      productDescription: 'Description',
      productMainImage: 'Main image',
      productPrice: 'Price',
      productSecondaryImages: 'secondary images',
      productTitle: 'Qualification',
      saveButton: 'Save Changes',
      subtitleOne: 'Product Detail',
      subtitleTwo: 'New product information',
      title: 'Edit product in',
      uploadImage: 'Upload',
      warningAmazonParent:
        'If you edit a parent Amazon product, your changes will be reflected in all child products.',
    },
    errorDeletingUnpublishedProduct: 'Error deleting product',
    exportProductsModal:
      'Export products from {{ originMarketplace }} to {{ marketplaceToExport }}',
    noMarketplaceConnected:
      'You do not yet have a synchronized marketplace to publish products.',
    publishProductsModal: {
      autocompleteColumns: {
        checkbox: [
          'Autocomplete columns with AI?',
          '(You can only autofill up to {{ limit }} products per load)',
        ],
        tooltip:
          'The column values are auto-completed based on the product information in the source marketplace publication. If you need to process more products than the limit, contact the support chat.',
      },
      destinationCategories: 'Target categories',
      downloadTemplateCategory: 'Download and complete the following form',
      excludePublished: 'Exclude products published on {{ marketplace }}?',
      filterBySku: 'Filter by SKU',
      firstStepsCheckbox: {
        activeSellerAccount: {
          primaryText: 'Do you have your Amazon Seller account active?',
          secondaryText: 'If you are not sure, select',
        },
        brandApproved: {
          primaryText: 'Did you request approval of your brand?',
        },
        productsBarCode: {
          primaryText: 'Do you have the barcode (EAN or UPC) of your products?',
          secondaryText:
            "You can request a GTIN exception if you don't have it yet",
        },
      },
      firstStepsCompletedText:
        'When you complete the steps above, select a category for your products',
      limitError: 'You cannot upload more than {{ limit }} products',
      moreInfo:
        'If you have questions about completing the fields on the form, we invite you to review the following section in our help center',
      notFoundCategories: 'SKUs without category suggestions: {{ productIds }}',
      searchCategoriesPlaceholder:
        'Search for a category in English... example: coffee',
      searchCategoriesTooltip:
        'Write keywords in English to find the category that best suits your products.',
      searching: 'Searching categories...',
      secondTitle:
        'Check the boxes once you have completed the requested steps, since without them you will not be able to progress correctly',
      selectOriginCategory: 'Select an origin category',
      spanishSearchCategoriesPlaceholder:
        'Search for a category in Spanish... example: café',
      spanishSearchCategoriesTooltip:
        'Write keywords in Spanish to find the category that best suits your products.',
      title: 'Publish on {{marketplace}}',
      unsupportedModalBodyPartOne:
        'The selected product category can only be published directly to your Amazon seller account.',
      unsupportedModalBodyPartTwo: 'Select an option to publish your products:',
      unsupportedModalDescriptionOne:
        'allows you to mass publish products from a category.',
      unsupportedModalDescriptionTwo:
        'allows you to publish only one product at a time.',
      unsupportedModalTitle: 'Publish on Amazon',
      unsupportedModalUploadSheet: 'Publish by spreadsheet',
      unsupportedModalUploadSingle: 'Post one by one',
      unsupportedModalmoreInfo: 'Learn more about how to publish on Amazon',
      uploadAmazon: 'Publishing by Amazon',
      uploadLAP: 'Publication by LAP',
      uploadTemplateCategory: 'Attach the form with the completed data',
    },
    removeUnpublishedProductSuccessfully: 'Product deleted',
    select: {
      all: 'All',
      page: 'Current page',
      selectedListings: 'Selected listings',
      selectedProducts: 'Selected products',
    },
    showingParents: 'showing {{ count }} parents',
    uploadProductModal: {
      badRequestException:
        'The request could not be processed. One or more of the fields entered contain errors.',
      brand: {
        emptyError: 'Please enter the brand of the product',
        placeholder: 'LAP',
        title: 'Brand',
        tooltip: 'Brand of the product',
      },
      countryOfManufacture: {
        emptyError: 'Please select the country of origin of the product',
        placeholder: '🇨🇱 Chile',
        title: 'Country of origin',
        tooltip:
          'Select the country in which the product was manufactured or produced. This information is necessary to determine customs regulations and requirements, as well as taxes that apply in the destination country.',
      },
      defaultCodeError:
        'There is already a product associated with your account with the SKU entered.',
      defaultError:
        'An error occurred while uploading the product. Try again later.',
      description: {
        emptyError: 'Please enter a product description',
        maxLengthError: 'Description cannot exceed 800 characters',
        placeholder: 'Ex: White porcelain mug with LAP logo.',
        title: 'Description',
        tooltip: 'Product description. 800 character limit.',
      },
      harmonizedCode: {
        emptyError: 'Please enter the product tariff code',
        placeholder: '04.09',
        seeMore:
          'https://ayuda.lapmarketplace.com/hc/es-cl/articles/12743698364955-C%C3%B3digo-Tariff',
        title: 'Harmonized code',
        tooltip:
          'Indicates the harmonized code of the product. This code is used to classify products according to their nature and specific characteristics. It is used to establish the regulations and documents required according to the type of product.',
      },
      mainImage: {
        emptyError: 'Please upload an image of the product',
        title: 'Image',
        tooltip:
          'Please note that the main image can only show the product that will be published. The use of text, logos, people or artifacts that are not included in the product itself is prohibited, and must have a completely white background (no background). The image must have a resolution greater than 1000 x 1000 pixels and be in JPG or PNG format. Be sure to provide a high-quality square image that clearly shows the product and its details.',
      },
      modalTitle: 'Upload product to LAP',
      sku: {
        emptyError: 'Please enter a SKU for the product',
        placeholder: 'LAP-CUP',
        title: 'SKU',
        tooltip: 'Product reference code',
      },
      success: 'Product has been added successfully',
      title: {
        emptyError: 'Please enter the product title',
        placeholder: 'LAP Mug',
        title: 'Qualification',
        tooltip: 'Product Title',
      },
      unitFOB: {
        emptyError: 'Please enter the unit FOB value',
        placeholder: '199.99',
        seeMore:
          'https://ayuda.lapmarketplace.com/hc/es-cl/articles/12321298472859-FOB-Declarado',
        title: 'FOB Unit',
        tooltip:
          'Indicates the value in USD that is declared for the product as merchandise in cargo transportation.',
      },
    },
  },
  checkout: {
    couponCode: 'Discount code',
    doYouHaveCoupon: 'Do you have any discount coupons?',
    error: {
      billingInfo: 'Please review the entered billing details and try again.',
      invalidCoupon: 'The coupon entered is not valid.',
      noAdditionalPlanFound: 'No additional plans were found available.',
      stripe:
        'An error occurred while starting your Stripe payment session. Try again later.',
      unavailablePaymentMethod: 'Payment method not available for this plan.',
    },
    free: 'free',
    freeTrial: {
      DAY: '{{count}} free day',
      DAY_plural: '{{count}} days free',
      MONTH: '{{count}} month free',
      MONTH_plural: '{{count}} months free',
      WEEK: '{{count}} free week',
      WEEK_plural: '{{count}} free weeks',
      YEAR: '{{count}} year free',
      YEAR_plural: '{{count}} years free',
    },
    frequencyType: {
      DAY: 'day',
      MONTH: 'month',
      WEEK: 'week',
      YEAR: 'year',
    },
    pay: 'Pay',
    paymentMethod: 'Payment method',
    plan: 'Plan',
    planTypes: {
      KAM: 'KAM',
      TECH: 'Base',
    },
    product: 'Product',
    summary: 'Summary',
    taxes: 'Taxes',
    total: 'Total',
    wantAdditionalPlan: 'Do you want to add an additional plan?',
  },
  common: {
    accept: 'Accept',
    apply: 'Apply',
    assign: 'Assign',
    box: 'Box',
    boxQuantity: 'Amount of boxes',
    boxes: 'Boxes',
    cancel: 'Cancel',
    cancelled: 'Cancelled',
    clean: 'Clean',
    client: 'Client',
    close: 'Close',
    confirm: 'Confirm',
    connect: 'Connect',
    connectMarket: 'Connect marketplace',
    connected: 'Connected',
    content: 'Content',
    countries: {
      CA: 'Canada',
      CL: 'Chile',
      CO: 'Colombia',
      MX: 'Mexico',
      US: 'United States',
    },
    countryOfManufacture: 'Country of manufacture',
    creation: 'Creation',
    currency: 'Currency',
    date: 'Date',
    defaultErrorMessage:
      'An unexpected error has occurred. Please try again later.',
    delivered: 'Delivered',
    description: 'Description',
    destination: 'Destination',
    details: 'Details',
    discount: 'Discount',
    download: 'Download',
    dropOff: 'Drop off',
    error: 'Error',
    estimatedDateOfDelivery: 'Estimated delivery date',
    expirationDate: 'Expiration date',
    failedRequest: 'The request could not be completed.',
    filters: 'Filters',
    harmonizedCode: 'Harmonized code',
    height: 'Height',
    inTransit: 'In transit',
    information: 'Information',
    items: 'Items',
    labels: 'Labels',
    length: 'Length',
    listing: 'Listing',
    listing_plural: 'Listings',
    load: 'Load',
    name: 'Name',
    next: 'Next',
    no: 'No',
    notes: 'Notes',
    ok: 'Got it',
    option: 'Option',
    order: 'Order',
    origin: 'Origin',
    parent: 'Parent',
    parent_plural: 'Parents',
    payment: 'Payment',
    periodicity: {
      day: 'Daily',
      month: 'Monthly',
      week: 'Weekly',
      year: 'Yearly',
    },
    preparation: 'Preparing',
    price: 'Price',
    print: 'Print',
    product: 'Product',
    product_plural: 'Products',
    quantity: 'Quantity',
    query: 'Query',
    recommended: 'Recommended',
    remove: 'Delete',
    required: 'Required',
    reset: 'Reset',
    return: 'Return',
    saleOrder: 'Sale Order',
    save: 'Save',
    search: 'Look for',
    seeMore: 'SEE MORE',
    selectedFile: 'Selected file',
    send: 'Send',
    sent: 'Sent',
    shippingPrice: 'Shipping cost',
    soon: 'Soon',
    status: 'Status',
    subtotal: 'Subtotal',
    success: 'Success',
    successfulRequest: 'Request completed successfully.',
    tax: 'Tax',
    title: 'Title',
    total: 'Total',
    unitaryFob: 'FOB unit',
    units: 'Units',
    update: 'Update',
    uploadDoc: 'Upload document',
    uploadFile: 'Upload file',
    viewAll: 'See all',
    wantKnowMore: 'want to know more',
    weight: 'Weight',
    width: 'Width',
    yes: 'Yes',
  },
  dashboard: {
    button: 'Apply filter',
    categories: {
      sales: 'Sales',
      stock: 'stock',
    },
    deleteQuery: 'The query was successfully deleted.',
    fields: {
      byLastUpdateDate: 'Last status change',
      byPurchaseDate: 'Date of purchase',
      category: 'Performance',
      compare: 'Compare',
      dateRangeOne: 'Select the period',
      dateRangeTwo: 'Compare with',
      expandable: 'Expand',
      marketplace: 'Marketplace',
      marketplace_plural: 'Marketplaces',
      partner: 'Seller',
      period: 'Period',
      sku: 'SKU',
      timezone: 'Time zone',
      type: 'By type',
    },
    filter: {
      currentMonth: 'Current month',
      currentWeek: 'Current week',
      currentYear: 'Current year',
      custom: 'Custom',
      last12months: 'Last 12 months',
      last30days: 'Last 30 days',
      last7days: 'Last 7 days',
      lastMonth: 'Last month',
    },
    marketplaces: {
      Amazon: 'Amazon US',
      AmazonVendor: 'Amazon Vendor',
      AmazonBelgium: 'Amazon BE',
      AmazonBrazil: 'Amazon BR',
      AmazonCanada: 'Amazon CA',
      AmazonEgypt: 'Amazon EG',
      AmazonFrance: 'Amazon FR',
      AmazonGermany: 'Amazon DE',
      AmazonIndia: 'Amazon IN',
      AmazonItaly: 'Amazon IT',
      AmazonMexico: 'Amazon MX',
      AmazonNetherlands: 'Amazon NL',
      AmazonPoland: 'Amazon PL',
      AmazonSaudiArabia: 'Amazon S.A.',
      AmazonSpain: 'Amazon UK',
      AmazonSweden: 'Amazon SE',
      AmazonTurkey: 'Amazon TR',
      AmazonUK: 'Amazon UK',
      'AmazonUnited Arab Emirates': 'Amazon AE',
      Coppel: 'Coppel',
      Dafiti: 'Dafiti',
      DafitiArgentina: 'Dafiti AR',
      DafitiBrazil: 'Dafiti BR',
      DafitiChile: 'Dafiti CL',
      DafitiColombia: 'Dafiti CO',
      Ebay: 'Ebay US',
      EbayCanada: 'Ebay CA',
      EbayGermany: 'Ebay DE',
      EbaySpain: 'eBay',
      Edicommerce: 'Walmart DSV MX',
      EdicommerceMexico: 'Walmart DSV MX',
      Falabella: 'Falabella',
      Linio: 'Linio',
      Liverpool: 'Liverpool',
      Magento: 'Magento',
      Mercadolibre: 'Mercadolibre AR',
      MercadolibreChile: 'Mercadolibre CL',
      MercadolibreColombia: 'Mercadolibre CO',
      MercadolibreMexico: 'Mercadolibre MX',
      Prestashop: 'Prestashop',
      Ripley: 'Ripley',
      Shopify: 'Shopify US',
      ShopifyBrazil: 'Shopify BR',
      ShopifyChile: 'Shopify CL',
      ShopifyColombia: 'Shopify CO',
      ShopifyMexico: 'Shopify MX',
      ShopifyPeru: 'Shopify PE',
      'ShopifyUnited States': 'Shopify US',
      Walmart: 'Walmart US',
      WalmartMexico: 'Walmart MX',
      Woocommerce: 'Woocommerce',
    },
    messages: {
      noData:
        'There are no synchronized Marketplaces. You must synchronize a seller.',
      noDataAds: 'There is no data to display, please try again later.',
    },
    required: {
      rangeOne: 'Please enter a date range.',
      rangeTwo: 'Please enter a date range.',
    },
    saveToLocalStorageOk: 'The query was saved correctly.',
    table: {
      price: {
        title: 'Price',
        tooltip: 'Current published sales price.',
      },
      soldUnits: {
        title: 'Units Sold',
        tooltip: 'Total units sold in selected period.',
      },
      totalOrders: {
        title: 'Total Orders',
        tooltip: 'Number of orders placed in the period.',
      },
      totalSales: {
        title: 'Total Sales',
        tooltip: 'Total sales in selected periods (expressed in USD).',
      },
    },
  },
  description: {
    part1: 'To get started, edit <1>src/App.js</1> and save to reload.',
    part2: 'Switch language between english and german using buttons above.',
  },
  documents: {
    add: 'Add',
    confirmDelete: 'Are you sure you want to delete this document?',
    edit: 'Edit',
    emptyFields: 'You must complete all fields.',
    link: 'Link',
    linkFormatError: 'The link entered must be valid.',
    modalTitle: 'Videos and Documents',
    pin: 'Pin',
    pinnedSuccessfully: 'Pinned correctly.',
    remove: 'Delete',
    removedSuccessfully: 'Successfully deleted.',
    save: 'Save',
    savedSuccessfully: 'Saved successfully.',
    textTitle: 'Documents',
    textTitleUrl: 'URLs',
    title: 'Qualification',
    titleLengthError: 'The title must be at least 5 characters.',
    unpin: 'Unpin',
    unpinnedSuccessfully: 'Unpinned successfully.',
    videoTitle: 'Tutorial videos',
  },
  download: {
    csv: 'Download CSV',
    csvError: 'Could not download CSV file',
    pdf: 'Download PDF',
    pdfError: 'Could not download PDF file',
  },
  externalShipment: {
    deliveryOffice: {
      reminder: 'Remember to go to deliver the order to the Fedex office',
    },
    pickUp: {
      required: 'Pick up date and time required',
      pickupState: 'Pickup status',
    },
    step1Title: 'Complete shipping details',
    step2Title: 'Choose packaging and loading settings',
    step3Title: 'Choose a system of measurement units',
    step4Title:
      'Select the products and quantities you want to include in the shipment',
    step5Title: 'Define the measurements and total weight of each box',
    step5TitlePalletized:
      'Defines the measurements and total weight of each pallet',
    step6Title: 'Step 6: Service and shipping cost',
    step7Title: 'Step 7: Choose the pick up date and time',
  },
  fedexError: {
    cancelPickUp: {
      default: {
        text: 'Your shipment cannot be completed due to a problem with your FedEx account. Please contact us via support chat so we can provide you with the necessary assistance and resolve this issue.',
        title: 'FedEx account error',
      },
      fedexDown: {
        text: 'FedEx service is temporarily out of service. We recommend you try again later.',
        title: 'FedEx error',
      },
    },
    createCourierShipments: {
      default: {
        text: 'Please contact us via support chat so we can provide you with the necessary assistance and resolve this issue.',
        title: 'Error creating shipment',
      },
      invalidHarmonizedCode: {
        text: 'The tariff code provided is not valid',
        title: 'Invalid tariff code',
      },
      invalidRecipientStateProvinceCode: {
        text: "The recipient's state or province code is not valid",
        title: 'Invalid state or province code',
      },
      suspendedAccount: {
        text: 'The shipment could not be created. Please contact FedEx directly. This suspension may be due to a debt or a particular problem with FedEx.',
        title: 'Fedex account suspended',
      },
      invalidCommodityWeight: {
        text: 'Commodity weight is missing or invalid. Please update and try again.',
        title: 'Invalid weight',
      },
    },
    createPickUp: {
      default: {
        text: 'Your shipment cannot be completed due to a problem with your FedEx account. Please contact us via support chat so we can provide you with the necessary assistance and resolve this issue.',
        title: 'FedEx account error',
      },
      fedexDown: {
        text: 'FedEx service is temporarily out of service. We recommend you try again later.',
        title: 'FedEx error',
      },
      invalidDate: {
        text: 'FedEx does not have availability to make the pick up at the time you have selected. We recommend you select a later time',
        title: 'Date and time not available',
      },
      missWeight: {
        text: 'Your product does not have weight and measurement information in the selected marketplace. You need to update these values in order to complete the shipment, as they are required by FedEx',
        title: 'Invalid or missing weight value',
      },
      invalidDay: {
        text: 'FedEx cannot schedule a pick up on the day you have selected. Please try again using a different day.',
        title: 'Date not available',
      },
    },
    uploadDocument: {
      default: {
        text: 'An error occurred while uploading your document.',
        title: 'Document Upload Error',
      },
      cutOffTimeReached: {
        text: 'The cutoff time has been reached for the document upload of this shipment. Your document upload has not been processed. Please print your documents and attach them to your shipment.',
        title: 'Document Upload Error',
      },
    },
  },
  fedexOptions: {
    serviceType: 'Shipping type',
    totalFedExCost: 'FedEx Total Cost',
    totalLAPCost: 'Total LAP cost',
  },
  forgotPassword: {
    buttons: {
      confirm: 'Confirm',
      sendConfirmationCode: 'Send recovery code',
    },
    codeNotReceived: "Didn't you receive the code?",
    confirmPassword: {
      nonEmpty: 'Enter your password again.',
      notEqual: 'The passwords entered are not the same.',
    },
    formLabels: {
      confirmPassword: 'Confirm Password',
      enterCode: 'Enter the code',
      newPassword: 'New Password',
      userEmail: 'user email',
    },
    notifications: {
      codeSent: 'A verification code has been sent to your email.',
      error:
        'An error occurred while trying to recover your password. Please try again.',
      passwordChangeSuccess: 'The password has been reset successfully.',
    },
    passwordValidations: {
      lowerCase: 'The password must have at least 1 lowercase letter.',
      minLength: 'The password must be at least 8 characters long.',
      nonEmpty: 'Enter your password.',
      number: 'The password must have at least 1 number.',
      specialCharacter: 'The password must have at least 1 special character.',
      upperCase: 'The password must have at least 1 capital letter.',
    },
    resendCode: ' Resend code',
    title: 'Recover your password',
    validateCode: 'You must enter the code sent to your email.',
    validateEmail: "Enter the user's email.",
  },
  fulfillmentError: {
    changeFulfillment: {
      default: {
        text: 'There was an error when changing the product logistics',
        title: 'Error when changing logistics',
      },
      nonExistingProduct: {
        text: 'The product does not exist in the selected fulfillment center',
        title: 'Non-existent product',
      },
    },
  },
  home: {
    charts: {
      breakdownByMarketplace: 'Breakdown by marketplace',
      breakdownBySku: 'Breakdown by SKU',
      dailyView: 'Daily view',
      emptyCountryData: 'No available data for the selected country.',
      lastPeriod: 'Previous period',
      location: 'Location',
      monthlyView: 'Monthly view',
      pendingOrders: {
        title: 'Pending orders',
        tooltip:
          'Total orders that have been placed but payment has not been verified yet. In some cases, the payment and order verification processes may take up to 21 days to complete.',
      },
      soldUnits: {
        title: 'Sold units',
        tooltip:
          'Sum of the total units sold in all selected marketplaces during the selected time period. Includes the units of completed orders and pending orders.',
      },
      soldUnitsBySku: 'Sold units by SKU',
      tablePerState: {
        orders: {
          title: 'Orders',
          tooltip: 'Amount of orders by state or province.',
        },
        percentage: {
          title: 'Percentage',
          tooltip: 'Total orders percentage by state or province',
        },
        stateOrProvince: {
          title: 'State',
          tooltip: 'State or Province',
        },
      },
      topFiveSales: 'Top 5 sales',
      totalOrders: {
        title: 'Orders completed',
        tooltip:
          'Total orders that have been placed but payment has not been verified yet. In some cases, the payment and order verification processes may take up to 21 days to complete.',
      },
      totalSales: {
        completedSales: 'Sales completed',
        pendingSales: 'Pending sales',
        title: 'Total sales',
        tooltip:
          'Sum of total sales of all selected marketplaces during the selected time period (expressed in USD).',
      },
      totalSalesByMarketplace: 'Total sales by Marketplace',
      totalSalesBySku: 'Total sales by SKU',
      totalUnits: 'Total units',
    },
    fulfillmentModal: {
      alert:
        'You must create the fulfillment manually due to the following errors when trying to automatically.',
      cancelError: 'An error occurred while canceling the shipping order.',
      canceled: 'Fulfillment cancelled',
      createErrors: [
        'The shipping order could not be created. You must update your origin address.',
        'The shipping order could not be created. An order already exists for this order.',
      ],
      downloadLabels: 'Download labels',
      fulfillmentToFBA: 'Fulfillment sent to FBA',
      orderedQuantity: 'Ordered quantity',
      readyToShip: 'Ready to ship',
      salesChannel: 'Sales channel',
      selectSellerSku: 'Select the correct SKU for FBA',
      shipmentAddress: {
        address1: 'Address 1',
        address2: 'Address 2',
        address3: 'Address 3',
        city: 'City',
        countryCode: 'Country code',
        phone: 'Phone',
        postalCode: 'Postal code',
        stateOrProvince: 'State or province',
        title: 'Shipping address',
      },
      shipmentOrder: {
        cancel: 'Cancel shipping order',
        generate: 'Generate shipping order',
      },
      shipmentPreference: 'Shipping Preference',
      skuFBA:
        'The SKU that is in FBA for the item requested in the sales order. We know that SKUs may be different between marketplaces.',
      soldTo: 'Sold to',
      successfulSave: 'Data saved successfully.',
      successfulSync: 'FBA stock synced successfully.',
      successfulUpdate: 'Data updated successfully.',
      updatedFulfillment: 'Fulfillment updated',
    },
    fullProfile: 'Complete profile',
    greetSubtitle: "Manage the world's main digital channels from one place!",
    incompleteProfile: 'Incomplete profile',
    inventory: {
      errorDownloadFile: 'Could not download inventory excel file',
      errorUploadFile: 'Could not upload inventory excel file',
      successUploadFile:
        'Uploaded inventory file successfully. Your file will be processed and updated stock should be available later.',
    },
    listing: {
      actions: 'ACTIONS',
      add: 'Listing',
      category: 'CATEGORY',
      confirmUpdateCanPublish:
        'Are you sure you want to change this permission?',
      edit: 'Edit',
      error: 'Error when obtaining listings from marketplaces',
      export: 'Export',
      generateOrder: 'Generate order',
      homologateListing: 'Homologate',
      homologateListingConfirm:
        'Are you sure you want to approve the selected listings?',
      homologateMarketplaces: 'Select',
      image: 'IMAGE',
      marketplaceState: 'MARKETPLACE STATUS',
      modalFBM: {
        ebayWarning:
          'Please remember that eBay usually limits quantities in inventory. We recommend validating your account with the LAP administrator.',
        marketplaces: 'MARKETPLACES',
        marketplacesDescription:
          'The current sku has a presence in the following marketplaces. Select which one(s) you want to apply the update to',
        syncStock: {
          success: 'Stock synced with FBM successfully',
          warning: 'Are you sure you want to update stock?',
        },
      },
      name: 'NAME',
      new: 'New Listing',
      onboarding: 'ONBOARDING',
      orders: 'ORDERS',
      price: 'PRICE',
      salesChannels: 'SALES CHANNELS',
      sku: 'SKU',
      state: 'LISTING STATUS',
      stock: 'STOCK',
      subTitle: 'Your active publications in marketplaces',
      successfulLogistics: 'Updated logistics',
      successfulPermissions: 'Updated permission',
      successfulShipping: 'Updated shipments',
      syncListings: 'Listings',
      syncStockFBA: 'stock',
      title: 'LIST BY GROUP',
      tooltipCanAutofulfillmentFBA:
        'Automatically manage outbound fulfillment with FBA?',
      tooltipCanPublish: 'Can the client synchronize their seller and publish?',
      tooltipHomologateListing: 'Copy listing on selected marketplaces',
      tooltipSyncListings: 'Obtain listings from marketplaces',
      tooltipSyncStockFBA: 'Synchronize with FBA and Mercado Libre inventory',
      tooltipUnlinkMatchesFBA: 'Unlink matches for FBA',
      unlinkMatchesFBA: 'unlink',
      unsuccessfulLogistics: 'Error updating marketplace logistics.',
      unsuccessfulPermissions: 'Error updating partner permissions',
      unsuccessfulShipping: 'Error updating marketplace shipments.',
    },
    newListing: 'New listing',
    onboarding: {
      steps: [
        'Connect marketplace and advertising',
        'Connect courier',
        'Publish products',
        'First shipment',
        'Performance visualization',
      ],
      subtitle: 'Click on them to see the steps you need to follow',
      title: 'Complete these 5 to expand your business internationally',
      warning: {
        description:
          'Once closed you can only recover it by requesting it through the support chat',
        title: "Are you sure you want to turn off the 'step by step' tutorial?",
      },
    },
    'sale-order': {
      confirmCancel: 'Are you sure you want to cancel?',
      subTitle: 'Status of your Orders in all Marketplaces',
      title: 'Orders',
    },
    viewStock: 'See stock',
    welcome: 'Welcome to LAP',
  },
  homologation: {
    attributes: {
      add: 'Add attribute',
    },
    categories: {
      destination: 'Destination',
      marketplaceOrigin: 'Marketplace',
      origin: 'Origin',
      reset: 'Clean',
      submit: 'Homologate',
      submitSuccess: 'Successfully approved category',
      title: 'Homologation of categories',
    },
  },
  imports: {
    fields: {
      backToCatalogue: 'Return to catalog',
      uploadFile: 'Upload file',
    },
    generic: 'Genérico',
    mainSubtitle:
      'In this section, you can view the loading status of your products in the different marketplaces. In order for them to be correctly published, you must correct all the errors specified in the "Status" column and re-upload the form.',
    mainTitle: 'Loading status',
    table: {
      createdAt: {
        emptyValue: 'No creation date',
        title: 'Created at',
      },
      fileName: {
        emptyValue: 'No name',
        title: 'File name',
      },
      status: {
        completed: 'Completed',
        processing: 'Processing',
        publishing: 'Publishing',
        title: 'Status',
        warning: 'Warning',
      },
      updateExcel: 'Update spreadsheet',
      updatedAt: {
        emptyValue: 'No last update date',
        title: 'Updated at',
      },
    },
    uploadError: 'An error occurred while uploading the catalog',
  },
  inventory: {
    actions: {
      syncInventory: 'Synchronize inventory',
    },
    sync: {
      modal: {
        message:
          'The inventory stock of the secondary marketplaces will be updated according to that of the main marketplace.',
        okButton: 'Sync up',
        primaryMarketplace: 'Main Marketplace',
        primaryMarketplacePlaceholder: 'Select a marketplace',
        removeButton: 'Delete',
        removeError: 'An error occurred while deleting inventory groups.',
        removeOk: 'Inventory groups were successfully deleted.',
        secondaryMarketplaces: 'Secondary Marketplaces',
        secondaryMarketplacesPlaceholder: 'Select one or more marketplaces',
        sendError:
          'An error occurred while synchronizing inventory. Please try again.',
        sendOk: 'Inventory synchronization was completed successfully.',
        title: 'Inventory Sync',
      },
    },
    table: {
      headers: {
        availableStock: 'Available stock',
        fulfillment: 'Logistics',
        last30days: 'Last 30 days',
        last90days: 'Last 90 days',
        product: 'Product',
        shipping: 'Shipping',
        soldUnits: 'Sold units',
        totalStock: 'Total stock',
      },
      tooltips: {
        availableStock: 'Total number of units available for sale right now.',
        fulfillment: 'Logistics center.',
        last30days:
          'Sum of the total units sold in the selected marketplace during the last 30 days.',
        last90days:
          'Sum of the total units sold in the selected marketplace during the last 90 days.',
        shipping: ' Shipping Center',
        totalStock:
          'Total number of units. It corresponds to the sum of available units, units that are being moved, and lost or damaged units.',
      },
    },
  },
  isRequired: 'it is required!',
  joyride: {
    back: 'Back',
    end: 'Finish tutorial',
    next: 'Following',
  },
  listings: {
    fields: {
      allMarketplaces: 'All marketplaces',
      allSkus: 'All products',
      allStatus: 'All statuses',
      marketplace: 'Marketplace',
      marketplacePlaceholder: 'Selected Marketplace',
      sku: 'SKU',
      skuPlaceholder: 'Selected SKU',
      status: 'Status',
      statusPlaceholder: 'Selected status',
      title: 'Qualification',
      titlePlaceholder: 'Enter a title',
    },
  },
  login: {
    IncorrectEmailOrPassword: 'The email and/or password are incorrect.',
    changePassword: 'Generate your new password',
    createAccount: 'Create Account',
    email: 'Email',
    emailEmpty: 'You must enter an email',
    emailPlaceholder: 'collaborator@lapmail.com',
    emailSent: 'Email sent successfully.',
    enter: 'Log in',
    formHeader: [
      'Welcome!',
      {
        lapAcademy: 'Log in to LAP Academy with your account',
        normal: 'Log in with your account info',
      },
    ],
    header: [
      'Turning local sellers into',
      'global businesses',
      "Manage the world's main digital channels from one place!",
    ],
    invalidEmail: 'The email entered is not valid.',
    loginError: 'Error logging in',
    loginLink: 'I already have an account. Log in',
    password: 'Password',
    passwordChanged: 'Password has been changed successfully',
    passwordEmpty: 'You must enter a password',
    passwordLengthError: 'The password must be at least 8 characters long',
    passwordLowerCaseError: 'Password must have at least 1 lowercase letter',
    passwordNumber: 'The password must have at least one number',
    passwordNumberError: 'Password must have at least 1 number',
    passwordPlaceholder: 'Password',
    passwordSpecialCharError: 'Password must have at least 1 special character',
    passwordUpperCaseError: 'Password must have at least 1 capital letter',
    passwordsDoesntMatch: 'Passwords must match',
    passwordsMinLength: 'The password must be at least 5 characters',
    recoverPassword: 'Forgot your password?',
    recoverPasswordTitle: 'Request password change',
    rememberPassword: 'Remember password',
    send: 'Send',
    signUp: "Don't have an account yet? Sign Up!",
    tipPassword: 'Your password must be a minimum of 5 characters.',
    tryAgain: 'Please, try again.',
    usernameEmpty: 'You must enter a contact name',
  },
  logistics: {
    tooltip: {
      FBA: 'Available in the USA, Canada, Mexico and Europe.',
      FedExCB: 'For international shipments.',
      FedExLocal: 'For local shipments within the United States.',
      Fulfill99: 'Available in Chile and Mexico',
      Melonn: 'Available in Mexico and Colombia',
      couriers:
        'A courier is a company that delivers packages and documents quickly and safely locally or internationally, offering tracking and complete logistics.',
      eDarkstore: 'Available for Walmart DSV Mexico',
      fulfillment:
        'Sellers send their products to a storage and distribution center in the destination country. They are stored there until a purchase order is generated and then sent to the end customer.',
      minutos99: 'For local shipments within Chile, Mexico, Colombia or Peru.',
    },
  },
  marketing: {
    indexGraphics: {
      acos: {
        title: 'ACOS',
        tooltip:
          'Advertising Cost Of Sales = (Investment/Advertising Sales). It is the percentage between what you invest in advertising and the income you generate with it.',
      },
      adsPercentage:
        'Advertising percentage = (Advertising sales / Total sales). It corresponds to the percentage of your sales that were made thanks to the user clicking on an advertising ad.',
      clicks: {
        title: 'Clicks',
        tooltip:
          'Sum of the number of times users clicked on your ads in the selected marketplace during the selected time period.',
      },
      cpc: {
        title: 'CPC',
        tooltip: 'Cost per Click = Average cost of each click.',
      },
      ctr: {
        title: 'CTR',
        tooltip:
          'Click Through Rate = (Clicks / Impressions. Corresponds to the percentage of views that achieved a click.',
      },
      cvr: {
        title: 'CVR',
        tooltip:
          'Conversion Rate = (Units per advertising / Clicks). Corresponds to the percentage of clicks that achieved a sale.',
      },
      impressions: {
        title: 'Impressions',
        tooltip:
          'Sum of the total views that your ads had in the selected marketplace during the selected time period.',
      },
      investment: {
        title: 'Investment',
        tooltip:
          'Total investment amount in the selected marketplace during the selected time period. It is the sum of the cost of the clicks on your advertising ads.',
      },
      organicSales: {
        title: 'Organic sales',
        tooltip:
          'Sum of the total sales that were made thanks to the customer clicking on a product that they found in the search engine organically (without advertising).',
      },
      organicUnits: {
        title: 'Organic units',
        tooltip:
          'Sum of the total units sold thanks to the customer clicking on a product that they found in the search engine organically (without advertising).',
      },
      salesPerAd: {
        title: 'Advertising sales',
        tooltip:
          'Sum of the total sales that were made thanks to the user clicking on an advertising ad for your product.',
      },
      soldUnits: {
        title: 'Units sold',
        tooltip:
          'Sum of the total units sold in the selected marketplaces during the selected time period. It is the sum of the advertising units and the organic units.',
      },
      tacos:
        'Total Advertising Cost Of Sales = (Investment / Total Sales). It is the percentage between what you invest in advertising and your total income.',
      totalSales: {
        title: 'Total sales',
        tooltip:
          'Sum of the total sales of the selected marketplace during the selected time period (expressed in USD). It is the sum of advertising sales and organic sales.',
      },
      unitsPerAd: {
        title: 'Units per ad',
        tooltip:
          'Sum of the total units sold thanks to the customer clicking on an advertisement for your product.',
      },
    },
    msjSync: 'You must sync your Marketing account',
    msjWaitingDataInitial:
      'No data available at this time, please try in 10min',
    notConnected: {
      button: 'Connect advertising',
      description:
        'Connect advertising in the marketplaces section to see the performance of your campaigns.',
      title: 'You have not yet connected advertising',
    },
    performanceByCampaignTable: {
      campaignNameTooltip: [
        'This is the name that was assigned to the campaign when it was created in the marketplace.',
        'We recommend that you name them always following the same pattern so that you can easily identify them.',
      ],
      headers: {
        acos: 'ACOS',
        campaignName: 'Campaign name',
        clicks: 'Clicks',
        cpc: 'CPC',
        ctr: 'CTR',
        cvr: 'CVR',
        impressions: 'Impressions',
        investment: 'Investment',
        salesPerCampaign: 'Advertising sales',
        unitsPerCampaign: 'Units per campaign',
      },
    },
    performanceByCampaignTitle: 'Performance by campaign',
    performanceByKeywordTable: {
      headers: {
        acos: 'ACOS',
        clicks: 'Clicks',
        cpc: 'CPC',
        ctr: 'CTR',
        cvr: 'CVR',
        impressions: 'Impressions',
        investment: 'Investment',
        keyword: 'Keyword',
        query: 'query',
        salesPerCampaign: 'Advertising sales',
        unitsPerCampaign: 'Units per campaign',
      },
    },
    performanceByKeywordTitle: 'Performance by keyword',
    performanceBySkuTable: {
      headers: {
        acos: 'ACOS',
        adsSalesPercentage: 'Ads %',
        clicks: 'Clicks',
        cpc: 'CPC',
        ctr: 'CTR',
        cvr: 'CVR',
        impressions: 'Impressions',
        investment: 'Investment',
        product: 'Product',
        salesPerCampaign: 'Advertising sales',
        tacos: 'TACOS',
        totalSales: 'Total sales',
        totalUnits: 'Sold units',
        unitsPerCampaign: 'Units per campaign',
      },
    },
    performanceBySkuTitle: 'Performance by SKU',
  },
  menu: {
    advertising: 'Advertising',
    assistant: 'AI Assistant',
    calculator: 'Calculator',
    catalogue: 'Catalogue',
    clients: 'Clients',
    dashboards: 'Dashboards',
    helpCenter: 'Help Center',
    inventory: 'Inventory',
    lapAcademy: 'LAP Academy',
    listings: 'Listing',
    myAccount: 'My account',
    myDocuments: 'My documents',
    myMarketplaces: 'My marketplaces',
    redirecting: 'Redirecting...',
    saleOrders: 'Orders',
    sales: 'Sales',
    shipments: 'Shipments',
    shopping: 'Shopping',
    soon: 'soon',
    status: 'Status',
    summary: 'Summary',
    support: 'Support',
    tooltips: {
      calculadora:
        'In this section you can simulate the cost chain of selling a new product in different markets using different types of logistics.',
      inventario:
        'In this section you can see the details of the inventory you have available in each of your markets. You can analyze the average number of units sold for each of your products and plan new shipments to avoid out of stock.',
      publicdad:
        'In this section you can see the details of the results of the advertising campaigns of each of your Marketplaces. You can access key metrics and indicators that will help you get the most out of your investment.',
      resumen:
        'In this section you can easily see the performance and most important metrics of all your markets in one place.',
      ventas:
        'In this section you can see the details of the sales of each of your markets. You can compare your results with previous periods and analyze the performance of each of your products.',
    },
    your_products: 'Your products',
    your_publications: 'Your publications',
  },
  modalAddProductVariant: {
    alertMessage: 'Variation',
    input1: 'Qualification',
    input10: 'Weight',
    input11: 'Flavor',
    input12: 'Reference price',
    input13: 'SKU',
    input14: 'EAN/UPC code',
    input15: 'Pack',
    input2: 'Brand',
    input3: 'Bullet Point 1',
    input4: 'Bullet Point 2',
    input5: 'Bullet Point 3',
    input6: 'Bullet Point 4',
    input7: 'Bullet Point 5',
    input8: 'Description',
    input9: 'Ingredients or components',
  },
  myAccount: {
    accountInfo: {
      name: {
        label: 'Brand name',
        placeholder: 'NexTech Solutions',
      },
      title: 'Account details',
      userEmail: {
        label: 'User email',
        placeholder: 'johndoe@gmail.com',
      },
      userName: {
        label: 'User name',
        placeholder: 'John Doe',
      },
    },
    banner: {
      syncMarketplacesDescription:
        'You have one or more marketplaces in the synchronization process, once completed we will send you a notification.',
      syncMarketplacesTitle: 'Marketplace in sync.',
    },
    billingInfo: {
      address: {
        additionalAddressInfo: 'Office No. / Apartment / Other',
        city: 'City or commune',
        countryCode: {
          label: 'Billing country',
          placeholder: 'Select country',
        },
        postalCode: 'Postal code',
        stateOrProvinceCode: 'State or province',
        streetName: 'Billing address',
      },
      backToPlans: 'Back to plans',
      contactName: {
        label: 'Billing contact name',
        placeholder: 'John Doe',
      },
      email: {
        label: 'Billing contact email',
        placeholder: 'johndoe@gmail.com',
      },
      fantasyName: {
        label: 'Company name',
        placeholder: 'ABC Services, Inc.',
      },
      industry: {
        label: 'Business line',
        placeholder: 'Food producer',
      },
      ref: {
        label: 'Tax ID',
        placeholder: '7654321-9',
      },
      title: 'Billing details',
    },
    checkSP: 'Connect',
    defaultError:
      'An error has occurred. Please refresh the page and try again.',
    failedMessage: 'Review credentials.',
    listMarketplaces: {
      stepByStep: 'See step by step',
    },
    save: {
      btn: 'Save data',
      error: 'An error occurred while saving account data.',
      success: 'Your account details have been successfully updated.',
    },
    sellerAccount: {
      AmazonInputs: {
        input3Description:
          'This is your Amazon Web Service account ID. Step 2, Item 7: Create an IAM user.',
        input4Description:
          'Obtained by downloading the AWS secret access keys, after creating an IAM user. Step 2, Item 8: Create an IAM user.',
        input5Description:
          'Obtained by downloading the AWS secret access keys, after creating an IAM user. Step 2, Item 8: Create an IAM user.',
        input6Description:
          'This is the name you entered in point 7 of step 4: Create an IAM role.',
        input7Description:
          'Obtained in point 8 of step 4: Create an IAM function/role.',
        input8Description:
          'Sign in to Seller Central and click Settings > Account Information > Your seller Profile.',
        input9Description:
          'Sign in to Seller Central and click Apps & Services > Develop Apps > View under the LWA credentials for the app you want.',
      },
      EbayInputs: {
        fulfillmentPolicy: 'Shipping Policy',
        input3Description: 'It is your Ebay account ID.',
        input4Description: 'Obtained in..........',
        input5Description: 'Obtained in..........',
        input6Description: 'Obtained in..........',
        input7Description: 'Obtained in..........',
        input8Description: 'Obtained in..........',
        input9Description: 'Obtained in..........',
        inventoryLocationPolicy: 'Inventory Location',
        loadingPolicies: 'Account Policies',
        paymentPolicy: 'Payment policy',
        returnPolicy: 'Return policy',
      },
      WalmartInputs: {
        input3Description: 'It is your Walmart account ID.',
        input4Description: 'Obtained in..........',
        input5Description: 'Obtained in..........',
        input6Description: 'Obtained in..........',
        input7Description: 'Obtained in..........',
        input8Description: 'Obtained in..........',
        input9Description: 'Obtained in..........',
      },
      card1: 'Documents',
      card1Button: 'View Document',
      card1DescriptionAmazon:
        'Download our manual with the step by step to create your seller account on Amazon',
      card1DescriptionEbay:
        'Download our manual with the step by step to create your seller account on Ebay',
      card1DescriptionMeli:
        'Download our manual with the step by step to create your seller account in Mercado Libre',
      card2: 'Schedule meeting',
      card2Description: 'Select date',
      card3: 'Schedule meeting',
      card3Description: 'Select date',
      credentials: {
        description1:
          'Open the full guide and start with the next step, within Seller Central.',
        description2: 'Continue with',
        description3:
          'To complete the entries below, complete the following step.',
        description4:
          'To complete the entries below, complete the following step.',
        description5: 'Then complete the step',
        description6: 'Finish this section with the step.',
        description7:
          'To complete the entries below, start with the following link (within your Seller Central, you must fill out a form).',
        description8: 'Then get your credentials at',
        step1: 'REGISTERING AS A DEVELOPER',
        step2: 'STEP 1. CREATE AN AWS ACCOUNT',
        step3: 'STEP 2. CREATE AN IAM USER',
        step4: 'STEP 3. CREATE AN IAM POLICY.',
        step5: 'STEP 4. CREATE AN IAM ROLE',
        step6: 'STEP 5. ADD AN SECURITY TOKEN SERVICE TO IAM USER.',
        step7: 'REGISTERING YOUR APPLICATION.',
        step8: 'SP CREDENTIALS.',
      },
      descriptionCard1:
        'You must go to the following link to view the guide and complete all the steps: Register as a developer, Create and configure IAM policies and entities, and Register your application. In each entry, you can find help about the ⓘ symbol.',
      descriptionCard2: 'Lorem Ipsum is simply dummy text of the printing and',
      divider1: 'Account in Amazon Web Service (AWS)',
      divider2: 'Account in Seller Central (SP)',
      ebayLinkToPolicies: 'Link to your policies',
      ebayPoliciesAlert:
        'Remember to set your policies to the eBay region you want to create listings for.',
      falsePolicy: 'Policy not configured',
      'input1.1': 'I have account',
      'input1.2': 'Use Partner account',
      'input1.3': 'Select an account',
      'input1.4': 'Connect my advertising account',
      input1Description: 'Yeah',
      input2: 'State',
      'input2Description1.1': 'Earring',
      'input2Description1.2':
        'When your seller account is validated, you can start working on your listings.',
      'input2Description2.1': 'Active account',
      inputShopDescription: 'Please enter your Shopify store ID',
      migrateListing: 'Migration',
      migrateListingInfo:
        'You must convert existing eBay listings to API objects in order to sync.',
      shop: 'Store',
      switchInfo:
        "Attention: If you select 'No' the account will be unlinked and you will have to associate it again",
      truePolicy: 'Configured policy',
    },
    successfulMessage1: 'Successfully verified account.',
    successfulMessage2: 'Credentials saved successfully.',
  },
  navMenu: {
    couriers: 'Logistics operators',
    data: 'Account details',
    logout: 'Log out',
    marketplaces: 'Marketplaces',
    regulation: 'Regulations',
    subscriptions: 'Subscriptions',
    warehouses: 'Warehouses',
  },
  no: 'No',
  notFound: {
    goBack: 'Back to home',
    header: 'Page Not Found',
  },
  notifications: {
    empty: 'You have no new notifications',
    message: {
      ACCOUNT_STATUS_CHANGED: [
        {
          'At risk': 'At risk',
          Deactivated: 'Deactivated',
          Normal: 'Normal',
        },
        'Your account status on {{ marketplace }} has changed from "{{ previousAccountStatus }}" to "{{ actualAccountStatus }}". This may be due to a problem with the payment method or missing data. Please solve the problem with your account in the marketplace.',
        'Possible causes: ',
        {
          Mercadolibre: {
            address_empty_state: 'The address is missing the "State" field.',
            address_empty_city: 'The address is missing the "City" field.',
            rejected_by_regulations: 'Rejected by regulations.',
          },
          defaultError: 'Error code:',
        },
      ],
      FAILED_CATALOGUE_UPLOAD:
        "Not all of your products could be loaded correctly. Check the details in 'Upload Status'.",
      FAILED_SUBSCRIPTION_BILLING:
        'The charge for {{ amount }} could not be completed to renew the plan {{ plan }}. Check that you have sufficient funds on your card',
      FULFILLMENT_SALE_ORDER_ERROR:
        'An error occurred while processing fulfillment on sales order {{ externalOrderId }} on {{ fulfillmentCompany }}.',
      LOW_STOCK: [
        'A low level of stock was detected in some of your products:',
        "Remember to replenish your inventory in the 'Restock' or 'Inventory' section to avoid stockouts.",
      ],
      NEW_SALE_ORDER: [
        'You have new sales orders pending processing:',
        {
          orders: '{{ count }} order',
          orders_plural: '{{ count }} orders',
        },
        "Remember to attend to them as soon as possible in the 'Orders' section, to maintain an excellent reputation as a seller.",
      ],
      OUT_OF_STOCK: [
        'You are out of stock on some of your products:',
        "We recommend you replenish your inventory in the 'Restock' or 'Inventory' section to avoid lost sales.",
      ],
      SET_FBM_INITIAL_STOCK: '',
      SUCCESSFUL_CATALOGUE_UPLOAD:
        "Your products have been successfully uploaded to {{ marketplace }}. You can view them in the 'Catalogue' section.",
      SYNCED_MARKETPLACE:
        'Synchronization of products, inventory and orders in your {{ marketplace }} account has been successfully completed.',
      UPCOMING_SUBSCRIPTION_BILLING:
        'Your {{ amount }} subscription for the {{ plan }} plan will be renewed in 5 days. Remember to have enough funds on your card to avoid losing its benefits.',
    },
    title: 'Notification Center',
    type: {
      ACCOUNT_STATUS_CHANGED: 'Your account status has changed',
      FAILED_CATALOGUE_UPLOAD: 'Product loading failed',
      FAILED_SUBSCRIPTION_BILLING: 'Subscription billing failed',
      FULFILLMENT_SALE_ORDER_ERROR: 'Fulfillment error on sales order.',
      LOW_STOCK: 'Products with low stock level',
      NEW_SALE_ORDER: 'New sales orders',
      OUT_OF_STOCK: 'Products with stock out',
      SET_FBM_INITIAL_STOCK: 'Products available to adjust initial stock',
      SUCCESSFUL_CATALOGUE_UPLOAD: 'Product upload successful',
      SYNCED_MARKETPLACE: 'Synchronized Marketplace',
      UPCOMING_SUBSCRIPTION_BILLING: 'Upcoming subscription billing',
    },
  },
  onboarding: {
    acceptedProposal: {
      description: 'You can now continue with the following stages',
      nextStep: 'Next step',
      remake: 'Reformulate',
      title: 'You accepted the proposal.',
    },
    add: 'Add',
    alertButtonClose: 'Eliminate',
    alertButtonGoBack: 'Return',
    alertButtonSave: 'Save draft',
    alertButtonSend: 'Send',
    alertMessage: 'Your LAP manager put together this custom proposal.',
    confirmDelete: 'Are you sure you want to delete the listing?',
    confirmGoBack: 'Are you sure you want to go back to the previous step?',
    draft: {
      brand: 'Brand',
      components: 'Ingredients',
      createAnotherListing: 'Create another listing',
      createNewListing: 'Create listing',
      createOrder: 'Create sales order',
      createUpdateNewListing: 'Create/Update listing',
      exportExcel: 'Export Excel',
      height: 'Height',
      images: 'Images',
      length: 'Length',
      measures: 'Measures',
      modalTitle: 'Preview',
      name: 'Name',
      preferredPrice: 'Ideal price',
      preview: 'Preview',
      ready: 'Everything is ready!',
      readyDescription:
        'Drink in moderation, its sale to minors under 18 years of age is prohibited.',
      sku: 'SKU',
      subtitle: 'Summary of your listing',
      syncOrderItems: 'Sync Order Items',
      syncOrderItemsDescription:
        'Obtain the details of the items of each order not yet synchronized',
      title: 'Summary',
      titleDescription: 'Preview your publication in',
      validateListing: 'Validate listing',
      weight: 'Weight',
      width: 'Width',
    },
    draftSavedSuccessfully: 'Draft saved successfully',
    fulfillment: {
      FBA: 'FBA - Fulfillment by Amazon',
      FBADescription:
        'Sellers send their products to an Amazon storage and distribution center in the destination country. They are stored there until a purchase order is generated and then sent to the end customer.',
      FBL: 'FBL - Fulfillment by LAP',
      FBLDescription:
        'The sellers take responsibility for storage (warehouse in the destination or origin country), along with the management of LAP for the distribution of the products to the end customer.',
      FBM: 'FBM - Fulfillment by Merchant/Seller',
      FBMDescription:
        'Sellers take full responsibility for logistics, storage and distribution to the end customer (Amazon warehouses are not used), whether using warehouses in the destination country or in origin.',
      LAPComment: 'LAP Comment',
      LAPCommentDescription: 'LAP Comment',
      title: 'Fulfillment:',
      titleDescription:
        'Process of receiving, packaging and shipping merchandise',
      type: 'Fulfillment Type',
      typeDescription:
        'Select the type of fulfillment that will be used to ship your products.',
    },
    images: {
      alertMessage: 'Upload your photos according to the guidelines.',
      amazonFormSubtitle: '',
      descriptionPanelCollapse: '',
      draggerMessage: 'Click or drag the file here.',
      ebayFormSubtitle: 'Lorem Ipsum is simply dummy text of the printing',
      formSubtitle:
        'Share the YouTube link of a complementary video to publish your listing. It is a requirement that the seller have a previously configured brand registration (Trademark)',
      formTitle1: 'Images',
      formTitle2: 'Optional',
      formTitle3: 'Variations',
      headerPanelCollapse:
        'Standards of all product images, for the selected market.',
      input1: 'Images for Category Approval',
      input1Description: 'Category Approval - up to 10 images and 2 required',
      input2: 'Listing Main Image',
      input2Description:
        'Add the main photo of the product with a white background.',
      input3: 'Complementary product photo:',
      input3Description:
        'Add professional explanatory photos of the product, showing all its faces, differentiating features, how to use it, etc.',
      input4: 'Complementary photo of product 2:',
      input4Description:
        'Add professional explanatory photos of the product, showing all its faces, differentiating features, how to use it, etc.',
      input5: 'Complementary photo of product 3:',
      input5Description:
        'Add professional explanatory photos of the product, showing all its faces, differentiating features, how to use it, etc.',
      input6: 'Video',
      input6Description: 'Optional video capsule of the brand and/or product.',
      inputTextArea: 'LAP Comments',
      inputTextAreaDescription:
        'Lorem Ipsum is simply dummy text of the printing and',
      subtitle: 'Professional photographs of your products on Amazon',
      title: 'Images',
      walmartFormSubtitle: 'Lorem Ipsum is simply dummy text of the printing',
    },
    measuresAndPrices: {
      LAPComment: 'LAP Comment',
      LAPCommentDescription: 'LAP Comment',
      costoUnitari: 'UNIT SHIPPING COST',
      costoUnitariItem1:
        'Shipping cost by plane or boat depending on the decision made with the equipment',
      costoUnitariItem2: 'Backed by a shipping quote',
      costsDescription: 'Product costs',
      costsTitle: 'Product costs',
      freeLap: 'LAP FEE',
      freeLapItem1: 'LAP sales commission',
      freeLapItem2:
        'Agreed upon and supported in the signed Terms and Conditions',
      freeLapItem3: '% applies to the sale price',
      fulfillment: 'FULFILLMENT (FBA)',
      fulfillmentItem1:
        'Amazon cost for shipping from its warehouses to the end customer',
      fulfillmentItem2:
        'Depends on the dimensions and weight of the sales unit',
      fulfillmentItem3: 'Fixed amount per sales unit',
      height: 'Height',
      heightDescription: 'Height',
      help: 'Aid',
      infoItem:
        'This price chain does not include the shipping cost associated with exporting your products. For more information you can contact your Onboarding manager.',
      length: 'Length',
      lengthDescription: 'Length',
      measureUnity: 'Units',
      netWeight: 'Net weight',
      netWeightUnity: 'Units',
      referral: 'REFERRAL FEE',
      referralItem1: 'Amazon cost to be published',
      referralItem2: 'Depends on the product category',
      referralItem3: '% applies to the sale price',
      storage: 'STORAGE',
      storageItem1: 'Amazon cost for storing products in its warehouses',
      storageItem2: 'Depends on the dimensions of the sales unit',
      storageItem3: 'Fixed monthly amount per sales unit',
      subtitle: 'Dimensions and Weight of the Sales Unit',
      subtitleDescription: ' ',
      title: 'Pricing',
      titleDescription:
        'Complete the following table with the measurements and weight of the selected sales unit (unit product or pack as appropriate) F',
      volume: 'Volume',
      volumeDescription: 'Volume',
      weight: 'Gross weight',
      weightDescription: 'Weight',
      weightUnity: 'Units',
      width: 'Width',
      widthDescription: 'Width',
    },
    modalVariantsButtonSave: 'Save',
    order: {
      btnSearchCategoriesForLAP: 'Search categories',
      divider1: 'Attributes',
      formListing: {
        age_range_description: ' ',
        arch_type: ' ',
        brand: 'P&M Foods',
        bullet_point:
          '100 calories per serving, so it contains 30% of the calories of a traditional muffin. No added sugar making it a product suitable for people with diabetes. 6 grams of protein and 5 grams of dietary fiber per serving',
        bullet_point0:
          '100 calories per serving, so it contains 30% of the calories of a traditional muffin. No added sugar making it a product suitable for people with diabetes. 6 grams of protein and 5 grams of dietary fiber per serving',
        bullet_point1: 'Contains prebiotics for better digestive health',
        bullet_point2:
          'Free of gluten and lactose, suitable for celiacs and lactose intolerant',
        bullet_point3:
          'It contains a complete and good quality protein, as it contains a balanced combination of legumes and cereals',
        bullet_point4:
          'Balanced combination of ingredients with a satiating effect, that is, it eliminates hunger for hours',
        bullet_point5: ' ',
        child_parent_sku_relationship: ' ',
        color: ' ',
        condition_note: ' ',
        embellishment_feature: ' ',
        externally_assigned_product_identifier: ' ',
        fcc_radio_frequency_emission_compliance: ' ',
        fulfillment_availability: ' ',
        fur: ' ',
        generic_keyword: ' ',
        hazmat: ' ',
        height_map: ' ',
        import_designation: ' ',
        item_name:
          ' Chocolate Flavor Legume Muffin, Mixture ready to prepare easily and in minutes. With only 30% of the calories of a traditional muffin',
        item_type_keyword: 'Muffin Mix',
        item_type_name: ' ',
        lifestyle: ' ',
        lining_description: ' ',
        merchant_release_date: ' ',
        merchant_shipping_group: ' ',
        merchant_suggested_asin: ' ',
        model_name: ' ',
        model_number: ' ',
        occasion_type: ' ',
        pattern_type: ' ',
        pesticide_marking: ' ',
        product_description:
          'Mixture of powdered ingredients, to easily prepare a chocolate-flavored legume muffin in minutes, with only 30% of the calories of a traditional muffin, ideal for a guilt-free indulgence and without fear of consuming excessive amounts of calories. . Contains lentils, chickpeas and peas. No added sugar, source of dietary fiber and protein. Low intake of total fat and low intake of saturated fat. Contains prebiotics for better digestive health, real cocoa and natural chocolate flavor. Free of gluten, lactose and egg, suitable for vegetarians and flexitarians. All you need to do is add water and oil, mix and bake. It can also be prepared in the microwave, for which you add the mixture to a cup, stir with a spoon and microwave at maximum power for 1 minute and 30 seconds. Take your chocolate-flavored legume muffin as a snack and enjoy it at any time. Contains 2 sachets of 140 grams each. Duration 12 months from the date of production. With the chocolate legume muffin from P&M Foods, what you like to eat so much is now good for you.',
        product_tax_code: ' ',
        safety_data_sheet_url: ' ',
        sole_material: ' ',
        special_feature: ' ',
        special_size_type: ' ',
        specific_uses_for_product: ' ',
        sport_type: ' ',
        style: ' ',
        subject_character: ' ',
        theme: ' ',
        toe_style: ' ',
      },
      inputCategoryForLAP: 'Select the category that identifies your product',
      inputCategoryForPartner:
        'Enter the name of the category that identifies your product',
      step1Input1: 'Category',
      step1Input10: 'Bullet Point 3',
      step1Input10Description:
        'Differentiating attribute of the product. Example: Characteristics, origin, benefit, certifications, way of use.',
      step1Input11: 'Bullet Point 4',
      step1Input11Description:
        'Differentiating attribute of the product. Example: Characteristics, origin, benefit, certifications, way of use.',
      step1Input12: 'Bullet Point 5',
      step1Input12Description:
        'History of the brand, company, product, differentiating elements',
      step1Input13Description: 'Lorem Ipsum is simply',
      step1Input14: 'Ingredients or components',
      step1Input14Description: 'Lorem Ipsum is simply',
      step1Input15: 'Weight',
      'step1Input15-1': 'Unit',
      'step1Input15-1Description':
        'Unit of measurement of gross weight: Gr, Kilos, Pounds, ml, cm, Inches, Oz.',
      step1Input15Description:
        'Gross weight of the sales unit (product plus packaging)',
      step1Input16: 'Flavor',
      step1Input16Description: 'Lorem Ipsum is simply',
      step1Input17: 'Reference price',
      step1Input17Description: 'Marketing price',
      step1Input18: 'SKU',
      step1Input18Description:
        'Internal of the client if applicable or created by LAP if it is not completed',
      step1Input19: 'EAN/UPC code',
      step1Input19Description:
        'Barcode the product. (It is mandatory that the code be verified by GS1)',
      step1Input1Description:
        'Simplified internal product name. For example: "Bottles – Pack of 2"',
      step1Input2: 'Sales channel',
      step1Input20: 'Pack',
      step1Input20Description:
        'Select only if the sales unit corresponds to a pack, that is, the sales unit includes more than one product. Example: Pack of 4 units of jams.',
      step1Input21: 'LAP Comments',
      step1Input21Description: 'Lorem Ipsum is simply',
      step1Input22: 'Variants',
      step1Input22Description: 'Lorem Ipsum is simply',
      step1Input23: 'CPU',
      step1Input23Description: 'Lorem Ipsum is simply',
      step1Input2Description: 'Select the channels you want to market to',
      step1Input3: 'Variants',
      step1Input3Description:
        'It corresponds to flavors, sizes, weights, color, etc.',
      step1Input4: 'Reference',
      step1Input5: 'Qualification',
      step1Input5Description: 'Corresponds to the product title on Amazon',
      step1Input6Description: 'Lorem Ipsum is simply',
      step1Input7: 'Brand',
      step1Input7Description:
        'Differentiating attribute of the product. Example: Characteristics, origin, benefit, certifications, way of use.',
      step1Input8: 'Bullet Point 1',
      step1Input8Description:
        'Differentiating attribute of the product. Example: Characteristics, origin, benefit, certifications, way of use.',
      step1Input9: 'Bullet Point 2',
      step1Input9Description:
        'Differentiating attribute of the product. Example: Characteristics, origin, benefit, certifications, way of use.',
      step1Variant1: 'It is a unique product',
      step1Variant2: 'It has variants',
      title: 'Listing',
      titleDescription: '',
    },
    pending: {
      description:
        'Your LAP manager will analyze the information and send you a tailored proposal. You will be notified via email to validate or reformulate.',
      title: "Now it's our turn",
    },
    price: {
      actions: 'ACTIONS',
      add: 'Add',
      btnSearchCategoriesForLAP: 'Search categories',
      clientFile: 'File for client',
      clientFileDescription: 'Client file description',
      clientFilePlaceholder: 'File URL',
      competitors: 'Competitor study',
      competitorsDescription: 'Competitors description',
      delete: 'Eliminate',
      groupTitle: 'Product Reference Name',
      groupTitleDescription:
        'Enter the general name of the product family. Do not consider specific characteristics of variations. For example, if they are flavored cereal bars, write only "cereal bars."',
      inputCategoryForLAP: 'Select the category that identifies your product',
      inputCategoryForPartner:
        'Enter the name of the category that identifies your product',
      loadingProductType: 'Verifying the Product Type',
      name: 'QUALIFICATION',
      price: 'SALE PRICE',
      product: 'SKU',
      productTypeSuccessMessage:
        'The selected category is associated with a Product Type supported by the Amazon API. You will be able to create the product.',
      productTypeWarningMessage:
        'The selected category is associated with a Product Type not supported, temporarily, by the Amazon API. You will not be able to create the product.',
      suggestedPrice: 'Suggested price',
      suggestedPriceDescription: 'Description',
      title: 'Product Definition',
      titleDescription:
        'Select the marketplace to which you want to upload a new product and the category to which it belongs.',
      uploadClientFile: 'Upload file',
    },
    proposal: {
      accept: 'Accept',
      remake: 'Reformulate',
      title: 'Your LAP manager put together this custom proposal.',
    },
    rejectMessage: 'Comment',
    rejectProposal: 'Reformulate proposal',
    search: 'Look for',
    section: 'Onboarding',
    sentSuccessfully: 'Submitted successfully',
    sideBarItem1: 'Product Definition',
    sideBarItem2: 'Listing',
    sideBarItem3: 'Pricing',
    sideBarItem4: 'Images',
    sideBarItem5: 'Create draft',
    sideBarItem6: 'Fulfillment',
    steps: {
      clickOn: 'Click on ',
      courier: [
        'your user name',
        [
          "If you don't have an account number yet, click ",
          '"See step by step"',
          ' to obtain it',
        ],
        'your country and write your FedEx account number',
        ' when the requested data is complete',
        '"Connect courier"',
      ],
      dashboards: [
        [
          'On the dashboards of the section ',
          ' we have an overview of different statistics of all the marketplaces combined',
        ],
        ' to see the next board',
        ['On ', ' we can see statistics for each marketplace in more detail'],
        ' to see the last board',
        ['On ', ' we can see the performance of your Amazon campaigns'],
        [
          'As a help, in all sections you will find these symbols. If you place the cursor over them, there will appear ',
          '"help tooltips"',
          ', where the information indicated is explained',
        ],
      ],
      endTutorial: 'End tutorial',
      finishedTour: 'You have finished the tour',
      marketplace: [
        'your user name',
        'Choose',
        [
          "If you don't have an account yet, click ",
          '"See step by step"',
          ' to create it',
        ],
        'To connect advertising click on ',
        'Connect marketplace and advertising',
      ],
      newAccount: [
        'WELCOME TO LAP!',
        'We recommend you follow the 5 stages that we have designed to help you expand your business internationally.',
        'each of them to access the tutorial and discover the steps you need to follow to complete them.',
        'FOLLOW TUTORIAL',
        'SKIP TUTORIAL',
        'In this modal you will find the 6 tutorials necessary to use the platform and start selling your products',
        'GOT IT!',
      ],
      publish: [
        [
          'To view this tour you must first ',
          'connect a marketplace',
          'Connect marketplace',
        ],
        [
          '"Publish products"',
          ' and select the marketplace in which you want to publish',
        ],
        [
          'Read each of the steps carefully. If you need more information, click on "SEE MORE"',
          'When you have completed them, check the boxes to keep progressing',
          'Checking all boxes will enable the category section',
        ],
        'In this step, you must select the category that most closely resembles your products',
        'You must download the form and complete all the requested information',
        'Now, you must upload the completed form to be able to publish',
        ['Finally, click on ', '"Publish"'],
        ['"See uploads status"', ' to track the products you just published'],
        [
          'In this table you can see the uploading status of your products. The column ',
          'cshows the completeness of the process or which errors need to be resolved.',
        ],
        'If you want to know more or watch a listing upload video, you can find it at ',
      ],
      select: 'Select',
    },
    subtitle: 'Lorem Ipsum is simply dummy text of the printing and',
    subtitle1: 'Lorem Ipsum is simply dummy text of the printing and',
    tab1: 'Your proposal',
    tab2: 'LAP Proposal',
    title1: 'Listing request',
  },
  option: {
    listingsCargados: 'You have already started uploading your Listings!',
    mustAddSeller: 'You must synchronize a seller',
    needSyncPermission: 'You are now enabled to start uploading Listings',
    personalComplete: 'General data completed',
    personalIncomplete: 'Complete the My Account section to continue',
    sellerSync: 'Synchronized Seller',
  },
  orders: {
    alertButtonClose: 'Cancel',
    alertButtonGoBack: 'Return',
    alertButtonSave: 'Save draft',
    alertButtonSend: 'Following',
    alertMessage: 'Your LAP manager put together this custom proposal.',
    boxes: {
      create: 'Create box',
      enterHeight: 'Enter the height of the box.',
      enterLength: 'Enter the length of the box.',
      enterName: 'Enter the box name.',
      enterWeight: 'Enter the weight of the box.',
      enterWidth: 'Enter the width of the box.',
      error: {
        name: 'The name cannot contain the character',
        sameDimension: 'There is already a box with those dimensions.',
      },
      exampleBoxName: 'Ex: Box XXL',
      height: 'Height',
      heightPlaceholder: 'Ingut height',
      lengthPlaceholder: 'Input length',
      measure: 'Box measurements',
      name: 'Box name',
      new: 'New box',
      save: 'Save box',
      width: 'Width',
      widthPlaceholder: 'Input width',
    },
    buttonAdd: 'Add',
    buttonSave: 'Save',
    buttonSearch: 'Look for',
    confirmDelete: 'Are you sure you want to delete the order?',
    confirmGoBack: 'Are you sure you want to go back to the previous step?',
    contents: {
      expirationDateTooltip:
        'It is not required to specify an expiration date for this product.',
    },
    documents: {
      AMAZON_BOX_LABEL: {
        comment:
          'Instructions: Glue one to the side of the box. Identification: Review SKU codes on the purchase order. FOR 1 SKU PER BOX Identification: Check numbering on the purchase order. FOR +1 SKU PER BOX',
        label: 'Amazon Box Label',
      },
      AMAZON_PRODUCT_LABEL: {
        comment:
          'Instructions: Paste one on each product, covering/removing the other barcodes. Identification: Review FNSKU codes on the purchase order.',
        label: 'Amazon Product Tag',
      },
      COMMERCIAL_INVOICE: {
        comment: '',
        label: 'Commercial Invoice',
        copies: '3 copies',
      },
      FEDEX_BOX_LABEL: {
        comment:
          'Instructions: Glue one to the side of the box. Select a box as the main one (number 1). Request a sticky window (transparent envelope) from the collector and stick it on document box 1. Box 1 - Paste Label: Page 1 Box 1 - Window: Page 2, 3 and 4 + Commercial Invoice + Certificate of Origin + Prior Notice Box 2 - Paste Label: Page 5 Box 3 - Paste Label: Page 6 And so on until box no.',
        label: 'FedEX Box Label',
        copies: '3 copies',
      },
      ORIGIN_CERTIFICATE: {
        comment: '',
        label: 'Certificate of origin',
        copies: '1 copy',
      },
      OTHER: {
        comment: '',
        label: 'Other',
      },
      PRIOR_NOTICE: {
        comment: '',
        label: 'Prior Notice',
        copies: '1 copy',
      },
      COMMERCIAL_INVOICE_INCLUDED:
        'The commercial invoice is included on FedEx labels file.',
      document_uploaded: 'You have already uploaded this document.',
      commercial_invoice_information_box:
        'You must upload the Commercial Invoice for this origin/destination after you create the shipment.',
      no_electronic_upload:
        'For this shipment you must print and include the following documents on pick up/drop-off:',
      error:
        'We were unable to obtain documentation information for this origin/destination. Please contact us for this information. You can proceed with the shipment.',
    },
    draftSavedSuccessfully: 'Draft saved successfully',
    filter: {
      marketplace: 'Marketplace',
      orderManagement: 'Order management',
      period: 'Period',
    },
    inputSearch: 'View information',
    modalVariantsButtonSave: 'Save',
    newOrder: {
      boxes: {
        LAPComment: 'LAP Comment',
        LAPCommentDescription: 'LAP Comment',
        boxDataColumns: {
          height: 'HEIGHT',
          id: 'BOX ID',
          long: 'LONG',
          weight: 'WEIGHT',
          width: 'WIDTH',
        },
        customsBroker:
          'Orders with an FOB greater than US$3000 require a customs broker.',
        errors: {
          productBoxWeight:
            'Depending on the weight of the product in the marketplace, the minimum weight of this box must be:',
        },
        importer: {
          fda: 'All products regulated by the FDA must have an importer. It is currently automatically added to LAP Imports for a cost of US $44.90.',
          price:
            'Orders with an FOB greater than US $2500 must have an importer. It is currently automatically added to LAP Imports for a cost of US $44.90.',
        },
        input8: 'Grades',
        medidasTooltip: 'Maximum 25 in / 63.5 cm',
        mixedBoxes: 'Mixed box',
        mixedBoxesReferences: {
          FOB: 'Total FOB',
          addBox: 'Add box',
          box: 'Box',
          boxes: 'Boxes',
          destination: 'Destination',
          height: 'Height',
          length: 'Length',
          newBox: 'New box',
          product: 'Product',
          totalUnits: 'Total Units',
          unitsInOrder: 'Units in order',
          weight: 'Weight',
          width: 'Width',
        },
        mixedBoxesSecondSubtitle: 'Defines the total weight of each box',
        palletBoxes: 'Palletized load',
        pesoTooltip: 'Maximum 50Kgs / 110Lbs',
        productDistribution: 'Product Distribution',
        radioGroup1: 'Shipping type',
        radioGroup1Checkbox1: 'Only one type of product per box.',
        radioGroup1Checkbox2: 'More than one type of product per box.',
        radioGroup1Checkbox3: 'Separate individual boxes.',
        radioGroup1Checkbox4: 'Boxes grouped on a pallet as a single package.',
        radioGroup1Description: 'Definition of box type for shipping.',
        radioGroup2: 'Unit of measurement',
        radioGroup2Description: 'Description',
        separatedBoxes: 'Separate boxes',
        skuPerBoxColumns: {
          amountBoxes: 'AMOUNT OF BOXES',
          box: 'BOX',
          boxes: 'BOXES',
          sku: 'SKU',
          units: 'SALES UNITS',
          unitsPerBox: 'UNITS PER BOX',
        },
        spanSkuPerBoxTable: 'TOTAL PER BOX',
        subtitle1: 'Box data',
        subtitle2: 'SKU per box',
        subtitle2text1: 'UNITS IN THE ORDER',
        subtitle2text2: 'BOXED UNITS',
        subtitle3: 'Attachments',
        subtitle4: 'Records',
        subtitleDescription1: 'Definition of measurements and weights per box.',
        subtitleDescription2: 'Distribution of units by product and by box.',
        subtitleDescription3: 'Description',
        subtitleDescription4: 'Description',
        success: {
          description: 'You have completed this stage successfully',
          title: 'Box configuration',
        },
        textAreaForNote: 'Cash Notes',
        textAreaForNoteDescription: 'Description',
        title: 'Boxes',
        titleDescription:
          'Configuration of the boxes and distribution of the units.',
        titleForm: 'Box configuration',
        titleStep1: 'Choose a system of measurement units',
        titleStep2: 'Defines the box configuration of each destination',
        unitsPerBox: 'Maximum 150 units.',
        validationFail: 'You must add boxes on all targets.',
        warningMessage1: 'This option could result in a',
        warningMessage2: 'shipping cost increase',
        warningMessage3: 'in case Amazon assigns more than one destination.',
      },
      changeStateModal: {
        title: 'Status',
      },
      confirmButton: 'Confirm',
      contents: {
        LAPComment: 'LAP Comment',
        LAPCommentDescription: 'LAP Comment',
        addCourier: 'Link new Courier',
        chooseCourier: 'Select a courier',
        chooseDestination: 'Select a fulfillment center',
        chooseWarehouse: 'Select one of your warehouses',
        courier: 'Logistic operator',
        destination: 'Destination',
        input1: 'Account',
        input2: 'Type of transport',
        input3: 'Number of pallets',
        input3subinput1: 'Number',
        input3subinput2: 'Link',
        input4: 'Origin',
        input5: 'Destination',
        input6: 'Order shipping cost in Dollars',
        input7: 'Transportation notes and shipping costs',
        input8: 'Grades',
        nameShipment: 'Shipment name',
        newWarehouse: 'New Warehouse',
        origin: 'Origin',
        knownPackingInformation: 'Is packing information known upfront?',
        knownPackingInformationTooltip:
          'If you do not know the packing information, you can leave it blank.',
        shippingDocumentCreate: 'Create transport document',
        shippingDocumentUpdate: 'Edit transport document',
        subtitle1: 'Transportation and shipping cost',
        subtitle2: 'Listings',
        subtitle3: 'Attachments',
        subtitle4: 'Records/Notes',
        subtitleDescription1: 'Transport configuration.',
        subtitleDescription2: 'Definition of quantities per product.',
        subtitleDescription3:
          'Relevant transportation documents, permits and guides.',
        subtitleDescription4: 'Record of events and observations.',
        success: {
          description: 'You have completed this stage successfully',
          title: 'Detail and content settings',
        },
        title: 'Detail and content',
        titleCheckBox:
          'Important considerations before creating your restock shipment',
        titleDescription:
          'Transportation configuration and definition of total quantities.',
        titleStep1: 'Complete shipping details',
        titleStep2: 'Select the type of box',
        titleStep3: 'Select the type of load',
        titleStep4:
          'Select the products and quantities you want to include in the shipment',
        titleStepShipsmartInsurance: 'Shipsmart Specific Information',
        uniformBoxes: 'uniform box',
      },
      draftButton: 'Draft',
      exportButton: 'Export',
      exportButtonBoxes: 'Export by box',
      final: {
        title: 'Status',
      },
      pickUp: {
        radioOneText:
          'Select the date on which you will deliver your products to the courier.',
        radioOneTitle: 'Schedule pick up',
        radioTwoText:
          'Select the date on which you will deliver your products to the courier.',
        radioTwoTitle: 'Deliver to office',
      },
      revision: {
        title: 'Shipping Review',
      },
      shipment: {
        chooseCollect: 'Select the collection mode',
        step3Subtitle1: 'Select the type of service and shipping cost',
        success: {
          description: 'You have completed this stage successfully',
          title: 'Shipping options',
        },
        title: 'Shipping options',
      },
      shippingButton: 'Pack off',
      state: {
        LAPComment: 'LAP Comment',
        LAPCommentDescription: 'LAP Comment',
        cancelByFedEX: 'Cancel shipment at FedEX',
        confirmCancelByFedEX:
          'Are you sure you want to cancel the shipment on FedEX?',
        confirmSendByFedEX: 'Are you sure you want to ship it via FedEX?',
        getByFedEX: 'Check shipping in FedEX',
        sendByFedEX: 'Ship by FedEX',
        shipmentByFedEXModalTitle: 'FedEX Shipping Information',
        subtitle1: 'Attachments',
        subtitle2: 'Records',
        subtitle3: 'Add Note',
        subtitleDescription1: 'Description',
        success: {
          description: 'You have completed this stage successfully',
          title: 'Shipment creation',
        },
        title: 'Shipment',
        titleDescription: 'Cargo shipment status.',
        titleStep1: 'Step 1: Summary, labels and documents',
      },
      switchText: 'only orders',
      tab1: 'Order',
      tab2: 'Boxes',
      table: {
        buttonFilter: 'Filters',
        buttonModal: 'Massive actions',
        buttonNewOrder: 'New shipment',
        filterOptions: {
          account: 'Customer',
          asin: 'ASIN',
          input: 'Look for',
          order_id: 'LAP ID',
          results: 'results',
          shipping_id: 'Amazon ID',
          sku: 'SKU',
        },
        modal: {
          buttonCancel: 'Cancel',
          buttonSend: 'Send',
          buttonUpdate: 'Update',
          changeStateForm: {
            noteTextArea: 'Add note',
            noteTextAreaInfo: 'Record of events and observations.',
            stateInput: 'Order Status',
            stateInputInfo: 'Cargo shipment status.',
          },
          notificationsForm: {
            contentTextArea: 'Content',
            contentTextAreaInfo: 'Record of events and observations.',
            templateInput: 'Template',
            templateInputInfo: 'Record of events and observations.',
          },
          tab1: 'Transport document',
          tab1Title: 'Assign or modify transport documents',
          tab2: 'Change Status',
          tab2Title: 'Change order status',
          tab3: 'Notifications',
          tab3Title: 'Mass notifications for these orders',
          title: 'Massive actions',
          transportDocumentsForm: {
            containerInput: 'Container',
            containerInputInfo: 'Lorep ipsum .......',
            documentInput: 'Transport document',
            documentInputInfo:
              'Document associated with reservation of shipment.',
            shippingtypeInput: 'Shipping type',
            shippingtypeInputInfo: 'Lorep ipsum .......',
          },
        },
        orderColumns: {
          account: 'CUSTOMER',
          actions: 'ACTIONS',
          amount: 'UNITS',
          cost: 'COST',
          created: 'CREATION',
          id: 'LAP ID',
          stage: 'STAGE',
        },
        search: 'Look for',
        title: 'Order List',
      },
    },
    nextStep: 'Go to the next step',
    notifySectionOne: {
      message1:
        'Check the regulations of the destination country for your products.',
      message2:
        'Review the documents required by the courier to avoid delays or problems.',
      message3:
        'Make sure your products, boxes and pallets are well prepared (proper packaging, protection and labelling).',
    },
    notifySectionThree: {
      linkText: 'help Center.',
      message1:
        'Each product must have its Amazon label and be properly packaged',
      message2:
        'Each box must have its corresponding Amazon label and FedEx label attached to the side',
      message3:
        'The main box of each shipment must have a sticky window with the necessary documentation:',
      message4:
        'For more information on labelling, packaging and necessary documentation, visit our section of the',
      submessage1: 'FedEx Master Label (3 copies)',
      submessage2: 'Commercial Invoice (3 copies)',
      submessage3: 'Certificate of Origin (1 copy)',
      submessage4:
        'Prior Notice (Only applies to products regulated by the FDA - 1 copy)',
      title: 'Guarantee a successful shipment:',
    },
    pallets: {
      add: 'Add pallet',
      determine: 'Determine the measurements and final weight of each pallet',
      enterHeight: 'Enter the height of the pallet.',
      enterLength: 'Enter the length of the pallet.',
      enterWeight: 'Enter the weight of the pallet.',
      enterWidth: 'Enter the width of the pallet.',
      erase: 'Are you sure you want to delete this pallet?',
      selectPalletPlaceholder: 'Select pallet',
      create: 'Create pallet',
      name: 'Pallet name',
      new: 'New pallet',
      save: 'Save pallet',
      examplePalletName: 'Ex: Normal Pallet',
    },
    rejectMessage: 'Comment',
    rejectProposal: 'Reformulate proposal',
    section1: 'Restock',
    section2: 'Restock > New',
    sentSuccessfully: 'Submitted successfully',
    shipment: {
      boxConfiguration: {
        addBoxesMessage: 'Please add at least one box',
        boxSize: 'Box size',
        saveError: 'An error occurred while updating boxes',
        title: 'Step 3: Determine the configuration of the boxes',
        unitsPerBox: 'Units per box',
      },
      detailShipments: 'Order details',
      fbmConsideration:
        'Important considerations before creating your FBM shipment',
      unpaidAlert:
        'The shipment must be paid to enable labels and documents download.',
      pickUp: {
        error: {
          noCredential:
            'You do not have a logistics operator connected in your account.',
        },
        officeDelivery: {
          description:
            'Select the date on which you will deliver the order to the courier.',
          required: 'Enter delivery date in Fedex',
          title: 'Deliver to office',
        },
        schedule: {
          description:
            'Select the date and time for the courier to pick up the order.',
          required: 'Enter pick up date and times',
          title: 'Schedule pick up',
        },
        title: 'Step 2: coordinate your shipment with Fedex',
      },
      products: {
        actionsButton: 'Are you sure you want to delete this product?',
        code: 'Code',
        countryOfManufacture: 'Country of manufacture',
        countryOfManufactureTooltip:
          'This information is necessary to determine the regulations that must be met and the taxes that must be paid in the destination country.',
        declaredFob: 'FOB declared',
        declaredFobTooltip: 'Value declared for the cargo being transported.',
        description: 'Description',
        descriptionValidation:
          'The product description must be at least 6 characters long.',
        harmonizedCode: 'Tariff code',
        harmonizedCodeValidation: 'Tariff code should be 6 digits.',
        harmonizedCodeTooltip:
          'This code is used to classify products according to their nature and characteristics to determine the rate of customs duty or tax applicable to the import or export of the product.',
        productDescription: 'Product description',
        quantity: 'Quantity',
        saveError: 'An error occurred while updating the products',
        select: 'Select',
        title: 'Step 2: Fill in the shipping products information',
        unitFob: 'FOB unit',
        unitFobTooltip:
          'Indicates the value in USD that you declare for the cargo being transported.',
        unitPrice: 'Unit price',
        unitWeight: 'Unit weight',
      },
      serviceType: {
        title: 'Step 2: Select shipping service',
      },
      successDraft: 'Data saved successfully',
      summary: {
        boxConfiguration: 'Box configuration',
        boxProductConfiguration: 'Box configuration by product',
        boxSize: 'Box size',
        boxType: 'Type of box',
        boxUnitWeight: 'Box unit weight',
        cargoType: 'Type of load',
        completePickUpInfo: 'Please complete the pickup information',
        content: 'Content',
        courierInformation: 'Courier information',
        deliveryDate: 'Date of delivery',
        editPickUp: 'Edit pick up',
        noPickUpScheduled: 'No pick up scheduled',
        schedulePickUp: 'Schedule pick up',
        labelsAndDocuments: 'Labels and documents',
        labelsForBoxesPallets: 'Labels for your boxes and pallets',
        labelsForProducts: 'Labels for your products',
        numberOfBoxes: 'Number of boxes',
        palletConfiguration: 'Pallet configuration',
        perBox: 'per box',
        pickUpDate: 'Pick up date',
        pickUpMode: 'Collection mode',
        pickUpTime: 'Pick up time',
        products: 'Products',
        ratesNotFound:
          'There are no rates available for the submitted shipment details.',
        seeAddress: 'See address',
        seeAllInformation: 'See all information',
        sendTo: 'Send to',
        serviceType: 'Type of service',
        shipmentCost: 'Cost of shipping',
        shipmentError: 'An error occurred while creating the shipment',
        shipmentInformation: 'Shipping Information',
        shipmentType: 'Shipping type',
        success: 'Submission created successfully',
        successfullShipment: {
          amazonLabel:
            'Each product must have its Amazon label and be properly packaged.',
          boxLabel:
            'Each box should have its corresponding Amazon label and Fedex label attached to the side.',
          thirdPartyShipmentBoxLabel:
            'Each box must have its corresponding label attached to the side.',
          thirdPartyShipmentPalletLabel:
            'Each pallet must have its corresponding label attached to the side.',
          title: 'Important considerations for a successful shipment:',
        },
        title: 'Step 3: summary, tags and documents',
        title2: 'Shipping Summary',
        unitsPerBox: 'Units per box',
      },
      unitMeasurement: {
        imperial: 'Imperial',
        metric: 'Metric',
        title: 'Step 1: Choose a system of measurement units',
      },
      uploadDocument: {
        success: 'Your doucment was uploaded successfully',
      },
    },
    sideBarItem1: 'Content',
    sideBarItem2: 'Boxes',
    sideBarItem3: 'Status',
    state: {
      pickUp: {
        failPickUp:
          'Your pick up was not successful? You can schedule another pick up',
        finishOfficeDelivery:
          'Now you have to leave the order at a Fedex branch',
        officeDelivery: 'Deliver to Fedex office',
        referenceDate: 'This is just a reference date',
        reschedulePickUp: 'Schedule new pick up',
        schedule: 'Schedule',
        title: 'Step 2: Fedex Shipping Options',
      },
    },
    status: {
      Cancelled: 'Cancelled',
      CheckedIn: 'Registered',
      Closed: 'Received',
      Deleted: 'Deleted',
      Delivered: 'Delivered',
      Draft: 'Draft',
      Error: 'Mistake',
      InTransit: 'In transit',
      ReadyToShip: 'Ready for shipment',
      Receiving: 'Receiving',
      Shipped: 'Sent',
      Working: 'In preparation',
    },
    subtitle:
      'Planning of orders for shipments to distribution centers of sales platforms.',
    tab1: 'Your proposal',
    tab2: 'LAP Proposal',
  },
  plans: {
    contact: 'Contact us!',
    currentPlan: 'Current plan',
    header: ['Custom plans,', 'unlimited growth'],
    inactiveBoldMessage: 'Your account is inactive.',
    inactiveMessage:
      'Subscribe to a plan or contact us via support chat to expand your business internationally!',
    kam: 'expert team',
    kamMeeting: {
      MONTHLY: 'monthly',
      WEEKLY: 'weekly',
    },
    kamMeetingDescription: 'strategic meeting',
    kamPlans: 'Additional services',
    kamPlansDescription:
      'Additionally, you can hire the support of an expert team that will accompany you in the strategy of entering new markets and managing your accounts in international marketplaces. Your executive will work together with technicians specialized in marketing and logistics and their ultimate goal will be to help you make informed decisions and maximize your results.',
    kamRestriction: 'You must subscribe to a base plan first',
    marketplaces: 'Marketplaces',
    oneProductLineAmount: ' product line',
    priceDetails: 'per month + VAT*',
    priceDetailsMessage: '* VAT only applies to billing to Chilean companies',
    productLinesAmount: ' product lines',
    skus: 'SKUs',
    subscribe: 'Subscribe',
    subscriptionConfirmationModal: {
      description:
        'Thank you very much for your purchase. You will now be able to use LAP Marketplace according to the plan purchased.',
      detailsTitle: 'Order detail:',
      okButton: 'Understood',
      product: 'Product:',
      productCode: 'Product code:',
      subscriptionDate: 'Date:',
      subscriptionFrequencyType: 'Billing cycle:',
      subscriptionPaid: 'Paid',
      title: 'Your purchase has been confirmed!',
      validating: 'Validating subscription...',
    },
    subscriptionDetails: 'Subscription details',
    tech: 'base',
    techPlans: 'Base Plan',
    techPlansDescription:
      'By subscribing to one of our plans, you will have full access to our platform, which includes a variety of tools to integrate global marketplaces, e-commerce platforms, logistics and payment gateways. This will allow you to manage your business efficiently from one place and expand your reach globally.',
    transactions: 'Transactions',
    unlimited: {
      f: ' unlimited',
      m: ' unlimited',
    },
    updatePlan: 'Update plan',
  },
  productTemplates: {
    fields: {
      withPublishedProducts: 'With published products',
      withoutPublishedProducts: 'No published products',
    },
  },
  products: {
    errors: {
      categoryNotAllowed:
        'The publication of products in this category through third parties is not allowed. Please list directly on Amazon or select another category.',
    },
    fields: {
      addProduct: 'Add product',
      allMarketplaces: 'Unified',
      allProducts: 'All products',
      allSkus: 'All products',
      allStatus: 'All statuses',
      category: 'Category',
      categoryPlaceholder: 'Selected Category',
      destinationCategory: 'Target category',
      downloadProductsFile: 'Download',
      uploadProductsFile: 'Upload stock from file',
      downloadTemplate: 'Download products',
      exportProducts: 'Export products',
      groupedMarketplaces: 'Grouped',
      marketplace: 'Marketplace',
      marketplacePlaceholder: 'Selected Marketplace',
      originProducts: 'Origin products',
      productName: 'Product',
      productNamePlaceholder: 'Product name',
      publishProducts: 'Publish products',
      searchCategories: 'Select a category',
      searchCategoriesPlaceholder:
        'Write a category for your product in English',
      seeImports: 'See uploading status',
      sku: 'SKU',
      skuPlaceholder: 'Selected SKU',
      spanishSearchCategoriesPlaceholder: 'Search for a category...',
      status: 'Status',
      statusPlaceholder: 'Selected status',
      title: 'Qualification',
      titlePlaceholder: 'Enter product name',
      uploadAction: 'Post',
      uploadTemplate: 'Upload products',
    },
    marketplaces: {
      Amazon: 'Amazon US',
      AmazonBrazil: 'Amazon BR',
      AmazonCanada: 'Amazon CA',
      AmazonFrance: 'Amazon FR',
      AmazonGermany: 'Amazon DE',
      AmazonItaly: 'Amazon IT',
      AmazonMexico: 'Amazon MX',
      AmazonSpain: 'Amazon UK',
      Ebay: 'Ebay US',
      EbayCanada: 'Ebay CA',
      EbayGermany: 'Ebay DE',
      EbaySpain: 'eBay',
      Mercadolibre: 'Mercadolibre AR',
      MercadolibreChile: 'Mercadolibre CL',
      MercadolibreMexico: 'Mercadolibre MX',
      Shopify: 'Shopify',
      Walmart: 'Walmart US',
    },
  },
  register: {
    companyName: {
      error: 'You must enter a brand name',
      label: 'Brand name',
      placeholder: 'NexTech Solutions',
    },
    confirmPassword: {
      error: 'The passwords entered are not the same',
      label: 'Confirm password',
      placeholder: 'Repeat password',
    },
    errors: {
      existingUser: 'A user with this email is already registered.',
    },
    formHeader: {
      lapAcademy: 'Sign up to LAP Academy',
      normal: 'Sign up to start growing',
    },
    newPassword: 'New password',
    termsAndConditions: [
      'I accept the',
      'Terms and Conditions',
      'and the',
      'Privacy Policy',
      'from LAP Global',
    ],
    userName: {
      label: 'User Name',
      placeholder: 'John Doe',
    },
    verificationCodeSent: 'A verification code has been sent to your email.',
    verificationError:
      'An error occurred while verifying the user. Please try again.',
    verificationModal: {
      description:
        'We send you a 6-digit code to your registered email to verify that it is you. This will be valid for the next 2 minutes.',
      input: 'Enter the code:',
      title: 'We need to verify you',
    },
  },
  regulations: {
    documents: 'Documents',
    linkAccount: {
      error: 'Error connecting your {{ label }} account.',
      success: 'Your {{ label }} account has been successfully connected.',
    },
    priorNotify: {
      connectAccount: 'Connect Prior Notify account',
      requiredApiKey: 'Enter your Prior Notify API Key',
    },
  },
  requestAccount: {
    CL: {
      doc1: {
        bullet_point1:
          'You must fill out the application in writing, then scan it and upload it in PDF format.',
        bullet_point2:
          'No fields should be left empty except those that are for the exclusive use of FedEx.',
        title: 'Account opening request',
      },
      doc2: {
        bullet_point1:
          'You must fill out the application digitally, without omitting information and with your signature.',
        bullet_point2: 'You must upload it in PDF format.',
        title: 'Electronic billing annex',
      },
    },
    CO: {
      doc1: {
        bullet_point1:
          'You must fill out the application document and save it in PDF format to be able to upload it.',
        bullet_point2: 'There should not be any empty fields.',
        title: 'Account opening request',
      },
      doc2: {
        bullet_point1:
          'You must fill out the promissory note and save it in PDF format.',
        bullet_point2:
          'For more information on how to fill out the document you can see the following',
        bullet_point2_linkText: 'instructive.',
        title: 'I will pay FedEx',
      },
      doc3: {
        bullet_point1: 'You must upload the document in PDF format.',
        title: 'Current Chamber of Commerce (No more than 30 days)',
      },
      doc4: {
        bullet_point1: 'You must upload the RUT in PDF format.',
        title: 'RUT - Single Tax Registry',
      },
      doc5: {
        bullet_point1: 'You must upload the ID in PDF format.',
        title: 'Legal Representative ID',
      },
    },
    MX: {
      moral: {
        doc1: {
          bullet_point1:
            'You must fill out the application digitally, without omitting information, with the signature and name of the legal representative.',
          bullet_point2:
            'The main contact telephone numbers, commercial reference and bank must be landline telephone numbers.',
          bullet_point3:
            'No fields should be left empty except those that are for the exclusive use of FedEx.',
          bullet_point4:
            'The signature on the account opening application must be handwritten.',
          title: 'Account opening request',
        },
        doc2: {
          bullet_point1:
            'You must fill out the privacy notice with the name and signature of the legal representative.',
          title: 'Notice of Privacy',
        },
        doc3: {
          bullet_point1:
            'It must be a copy in the format of the Tax Status Certificate (format that includes tax address and QR code). You can get the document',
          bullet_point1_linkText: 'here.',
          bullet_point2: 'It must be valid for no more than 30 days.',
          title: 'RFC (Certificate of Tax Situation)',
        },
        doc4: {
          bullet_point1:
            "Copy of the legal representative's current identification, which can be INE (copy on both sides), passport, professional license or temporary residence visa.",
          title: 'Official identification of legal representative',
        },
        doc5: {
          bullet_point1:
            'It must be proof of tax address in the name of the company.',
          bullet_point2: 'It must be valid for no more than 3 months.',
          bullet_point3:
            'The address must match the RFC, if the tax or collection address is different, proof of address for both addresses in the name of the company must be attached.',
          title: 'Proof of address',
        },
        doc6: {
          bullet_point1: 'It must be a copy of the articles of incorporation.',
          bullet_point2:
            'Deliver complete document and, when applicable, include modifications.',
          title: 'Constitutive Act',
        },
        doc7: {
          bullet_point1:
            'Credit bureau report, corresponding to the month of the request. You can get the report',
          bullet_point1_linkText: 'here.',
          bullet_point2:
            'You must present a history of punctual payments and have no arrears on your acquired credits.',
          title: 'Credit bureau',
        },
        doc8: {
          bullet_point1:
            'This document is required only if the legal representative is not mentioned in the articles of incorporation.',
          title: 'Power of attorney of the Legal Representative (optional)',
        },
      },
      natural: {
        doc4: {
          bullet_point1:
            "Copy of the applicant's current identification, which can be INE (copy on both sides), passport, professional license or temporary residence visa.",
          title: 'Official ID',
        },
        doc5: {
          bullet_point1:
            'It may be proof of a service contracted at home in the name of the applicant.',
          bullet_point2: 'It must be valid for no more than 3 months.',
          bullet_point3:
            'The address must match the RFC, if the tax or collection address is different, proof of address for both addresses must be attached in the name of the applicant.',
          title: 'Proof of address',
        },
        doc6: {
          bullet_point1:
            'Credit bureau report, corresponding to the month of the request. You can get the report',
          bullet_point1_linkText: 'here.',
          bullet_point2:
            'You must present a history of punctual payments and have no arrears on your acquired credits.',
          title: 'Credit bureau',
        },
      },
    },
    contents: {
      cancel: 'Are you sure you want to cancel the request?',
      legalEntity: 'Moral person',
      legalEntityDescription: 'I am a legal representative of a company.',
      naturalPerson: 'Physical person',
      naturalPersonDescription: 'I am a natural person with business activity.',
      title: 'FedEx account opening application',
      titleStep1: 'Select the account country',
      titleStep2: 'Select the type of person',
      titleStep3: 'Uploading required documents',
    },
    error: {
      missingDocuments:
        'Please upload the {{missingFilesAmount}} documents before submitting.',
    },
    success: {
      message1: 'We have received your request successfully!',
      message2:
        'To ensure approval of your application, we will review your documentation to confirm it meets the requirements before sending it to FedEx. If modifications are necessary, we will contact you.',
      message3:
        'If your documentation meets the requirements, we will send you the FedEx Mexico account number by email within approximately 20 business days and you will be able to access our preferential rates.',
    },
  },
  selectPlaceholder: 'Select',
  shipments: {
    buttons: {
      cancel: 'Cancel',
      cancelWarning: 'Are you sure you want to cancel this shipment?',
      checklist: 'View documents and information',
      createShipment: 'Create shipment',
      edit: 'Edit',
      newShipment: 'New shipment',
      track: 'Track shipment(s)',
    },
    columns: {
      actions: 'Actions',
      createdAt: 'Creation',
      destination: 'Destination',
      name: 'Shipping name',
      origin: 'Origin',
      status: 'Status',
      type: 'Type',
    },
    errors: {
      cancelShipment: {
        success: 'The shipment has been successfully cancelled.',
      },
      createRestockOrder: 'Error creating restock',
      createThirdPartyShipment:
        'Error when trying to create shipment to third parties',
      deleteRestockOrder: {
        error: 'Error deleting restock',
        success: 'The record has been successfully deleted.',
      },
      getCourierCredentials: 'Error getting your couriers',
      getCourierNamesByCompany: 'Error when obtaining your fulfillment centers',
      noFulfillmentCenter:
        'There is no connected fulfillment center to do restocking.',
    },
    filter: {
      period: 'Period',
      periodRanges: {
        lastMonth: 'Last month',
        past12Months: 'Last 12 months',
        past30Days: 'Last 30 days',
        past7Days: 'Last 7 days',
        thisMonth: 'Current month',
        thisWeek: 'Current week',
        thisYear: 'Current year',
        today: 'Today',
      },
      status: 'Status',
      type: 'Shipping type',
    },
    pickup: {
      state: {
        completed: 'Confirmed',
        failed: 'Failed',
        pending: 'Pending',
      },
      noPickup: 'No pickup',
    },
    shipmentTracking: {
      pickUpScheduled: 'Scheduled Pick Up',
      shipmentCreated: 'Submission created',
      shipmentTracking: 'Shipment tracking',
    },
    status: {
      All: 'All statuses',
      Cancelled: 'Cancelled',
      CheckedIn: 'Checked in',
      Closed: 'Received',
      Deleted: 'Deleted',
      Delivered: 'Delivered',
      Draft: 'Draft',
      Error: 'Error',
      InTransit: 'In transit',
      ReadyToShip: 'Ready for shipment',
      Receiving: 'Receiving',
      Shipped: 'Sent',
      Working: 'Working',
    },
    types: {
      all: 'All',
      restock: 'Restock',
      saleOrder: 'Orders',
      thirdPartyShipments: 'Third parties',
      toThirdPartyShipments: 'To thirds',
    },
  },
  shipsmart: {
    federalId: 'State Tax ID',
    federalTax: 'Federal Tax ID',
    fiscalNote: 'Fiscal Note',
    hireInsurance: 'Do you want to take out insurance?',
    receiver: 'Addressee',
    sender: 'Sender',
    taxesPayer: 'Who will pay the taxes?',
  },
  subscriptions: {
    consume: {
      error: 'An error occurred while obtaining the consumption.',
      title: 'My plan consumption',
    },
    currentSubscription: {
      cancelConfirmation:
        'Are you sure you want to cancel your current subscription?',
      cancelSubscription: 'Cancel subscription',
      cancelWarning:
        'By canceling your subscription, you will continue to have access to its benefits until the termination date. This is an irreversible action.',
      cancelledSubscription: 'Subscription canceled',
      cancellingSubscription: 'Canceling subscription...',
      daysLeft: 'Remaining days',
      endOfSubscription: 'End of subscription',
      external: 'External payment',
      freeTrial: 'Free trial',
      kam: 'Additional service',
      lastPayment: 'Last payment',
      nextPayment: 'Next payment',
      paymentMethod: 'Payment method',
      processing: 'In progress',
      subscriptionCode: 'Subscription code',
      tech: 'Base plan',
    },
    paymentProcessing:
      'Your payment for the {{plan}} plan is still being processed. If it cannot be credited, you will have to try the payment again.',
  },
  thirdPartyShipments: {
    boxes: {
      newBox: 'New box',
      selectBoxPlaceholder: 'Select box',
    },
    couriers: {
      noCouriersAvailable: 'There is no courier available for this route.',
      tooltip:
        'Select the company in charge of collecting, transporting and delivering your shipment.',
    },
    destination: {
      originTooltip:
        'Select your warehouse location where the courier service, such as FedEx, will pick up your shipment.',
      phoneExtensionUsaError:
        'Telephone extension must be from the USA or Canada',
      phoneUsaError: 'The phone number must be 10 digits for USA or Canada.',
      placeholder: 'Select a destination address',
      tooltip:
        'Complete the requested destination information by clicking in the designated space.',
    },
    errors: {
      loadShipmentData: 'An error occurred while obtaining shipments.',
      productBoxWeight:
        'The total weight of the products cannot be greater than the total weight of the boxes. The minimum weight of the total boxes must be:',
    },
    int99minutos: {
      errorGetCoverage:
        'An unexpected error occurred while obtaining 99Minutos coverage. Try again later.',
      finalMessage:
        'The pick up will be made according to your 99 minute shipping preferences',
      noCoverage:
        'The 99Minutos service is not available for origin and destination addresses.',
    },
    isPack: 'loose boxes',
    isPackDescription: 'Separate individual boxes.',
    palletized: 'Palletized load',
    palletizedDescription: 'Boxes grouped on a pallet as a single package.',
    pallets: {
      newPallet: 'New pallet',
      selectPalletPlaceholder: 'Select pallet',
    },
    shipsmart: {
      finalMessage: 'The pick up will be made by the selected courier',
    },
    step2Subtitle1: 'Select the type of service and shipping cost',
    step2Subtitle2: 'Select the collection mode',
    table: {
      infoTooltip: 'Review shipping information',
      needCourier:
        'You need to connect a FedEx or 99Minutos account. Review the Logistics Operators section.',
    },
    titleStep2: 'Shipping and pick up cost',
  },
  title: 'Welcome to React using: react-i18next',
  warehouses: {
    confirmDelete: 'Are you sure you want to delete this hold?',
    createForm: {
      address: {
        additionalAddressInfo: 'Department',
        additionalAddressInfoPlaceholder: 'Office/department/other number',
        city: 'City or commune',
        cityPlaceholder: 'City or commune',
        countryCode: 'Country',
        placeholderStreetName: 'Street and numbering',
        postalCode: 'Postal Code',
        stateOrProvinceCode: 'State or province',
        streetName: 'Address',
      },
      contactName: 'Contact name',
      email: 'E-mail',
      name: 'Warehouse name',
      phoneNumber: 'Phone',
      title: 'Add destination',
      validEmail: 'Please enter a valid email',
      companyName: 'Company',
    },
    createNewWarehouse: 'Create warehouse',
    edit: {
      error: 'An error occurred while editing the warehouse',
      success: 'Correctly updated warehouse.',
      title: 'Edit Warehouse',
      tooltip: 'Edit warehouse information',
    },
    title: 'My Warehouses',
  },
  benchmark: {
    title: 'Benchmark',
    classificationComplete: 'Classification complete!',
    form: {
      productCategory: 'Product Category',
      productCategoryError: 'Please select a product category!',
      productCategoryPlaceholder: 'Select product category',
      searchKeywords: 'Search Keywords',
      searchKeywordsError: 'Please input keywords!',
      searchKeywordsPlaceholder: 'Enter product keywords',
      useExactMatch: 'Search using exact match',
      exactMatch: 'Exact Match',
      exactMatchError: 'Please input exact match keywords!',
      exactMatchPlaceholder: 'Enter exact match keywords',
      maxNumberOfCompetitors: 'Max Number of Competitors',
      maxNumberOfCompetitorsError:
        'How many competitors do you want to retrieve?',
      maxNumberOfCompetitorsPlaceholder: 'Enter max number of competitors',
      searchButton: 'Search',
      marketplace: 'Marketplace',
      marketplaceError: 'Error selecting marketplace',
      marketplacePlaceholder: 'Amazon Marketplace',
      maxNumberOfCompetitorsTooltip:
        'The maximum number of competitors must be between 1 and 50.',
    },
    downloadExcel: 'Download Excel',
    uploadExcel: 'Upload Excel',
    excelUploadSuccess: 'Excel uploaded successfully',
    generateReport: 'Generate Report',
    reportGeneratedSuccess: 'Report generated successfully',
    statistics: {
      topBrand: 'Top Brand',
      totalCompetitors: 'Total Competitors',
      averagePrice: 'Average Price',
      totalRevenue: 'Total Revenue',
      averageRating: 'Average Rating',
      topFormat: 'Most Sold Format',
      topUnitsPerPack: 'Most Sold Units/Pack',
    },
    table: {
      link: 'Link',
      viewOnAmazon: 'Amazon',
      productTitle: 'Title',
      brand: 'Brand',
      price: 'Price',
      sales: 'Sales',
      revenue: 'Revenue',
      fulfillmentType: 'Fulfillment',
      productFormatQuantity: 'Format Qty',
      productFormatUnit: 'Format Unit',
      unitsPerPack: 'Units/Pack',
      standardizedFormatUnit: 'Std. Unit (Kg, L, Unit)',
      standardizedFormatQuantity: 'Std. Qty',
      totalFormatQuantity: 'Total Qty',
      pricePerTotalQuantity: 'Price/Total Qty',
      sheetName: 'Competitors',
      rating: 'Rating',
      reviews: 'Reviews',
      category: 'Category',
      actions: {
        delete: 'Delete',
        save: 'Save',
        cancel: 'Cancel',
        edit: 'Edit',
      },
    },
    errors: {
      invalidCompetitorCount:
        'The number of competitors must be between 1 and 50.',
    },
  },
  yes: 'Yes',
};

export default en;
