import React, { useState, useEffect } from 'react';
import {
  Table,
  Spin,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Card,
  Checkbox,
  Select,
  Progress,
  Tooltip,
  Statistic,
} from 'antd';
import {
  InfoCircleOutlined,
  DownloadOutlined,
  SearchOutlined,
  FileTextOutlined,
  UploadOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { utils, writeFile, read } from 'xlsx';

import benchmarkApi from '../../api/benchmark';
import openNotification from '../../components/Toastr';
import { getErrorMessage } from '../../api/api';

const { Text, Title } = Typography;

function Benchmark() {
  const { t } = useTranslation();
  const [competitors, setCompetitors] = useState([]);
  const [productCategory, setProductCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [classificationProgress, setClassificationProgress] = useState(0);
  const [reportLoading, setReportLoading] = useState(false);

  // State variables for summary statistics
  const [totalCompetitors, setTotalCompetitors] = useState(0);
  const [averagePrice, setAveragePrice] = useState(0);
  const [averageRating, setAverageRating] = useState(0);
  const [topBrand, setTopBrand] = useState('');
  const [topFormat, setTopFormat] = useState('');
  const [topUnitsPerPack, setTopUnitsPerPack] = useState('');

  // Editing states
  const [editingRow, setEditingRow] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [tempValue, setTempValue] = useState('');

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  const calculateSummaryStatistics = () => {
    const total = competitors.length;

    const avgPrice =
      competitors.reduce((sum, comp) => sum + parseFloat(comp.price || 0), 0) /
      (total || 1);

    const avgRating =
      competitors.reduce((sum, comp) => sum + parseFloat(comp.rating || 0), 0) /
      (total || 1);

    const brandCounts = competitors.reduce((acc, comp) => {
      const brand = comp.brand || 'Unknown';
      acc[brand] = (acc[brand] || 0) + 1;
      return acc;
    }, {});

    const leadingBrand = Object.entries(brandCounts).reduce(
      (maxBrand, [brand, count]) =>
        count > maxBrand.count ? { brand, count } : maxBrand,
      { brand: '', count: 0 }
    ).brand;

    const formatCounts = competitors.reduce((acc, comp) => {
      const format =
        `${comp.formatNumber || ''} ${comp.formatUnit || ''}`.trim() ||
        'Unknown';
      acc[format] = {
        count: (acc[format]?.count || 0) + 1,
        sales: (acc[format]?.sales || 0) + (parseInt(comp.sales, 10) || 0),
      };
      return acc;
    }, {});

    const leadingFormat = Object.entries(formatCounts).reduce(
      (max, [format, data]) =>
        data.sales > max.sales ? { format, sales: data.sales } : max,
      { format: '', sales: 0 }
    ).format;

    const unitsPerPackCounts = competitors.reduce((acc, comp) => {
      const units = comp.quantity || 'Unknown';
      acc[units] = {
        count: (acc[units]?.count || 0) + 1,
        sales: (acc[units]?.sales || 0) + (parseInt(comp.sales, 10) || 0),
      };
      return acc;
    }, {});

    const leadingUnitsPerPack = Object.entries(unitsPerPackCounts).reduce(
      (max, [units, data]) =>
        data.sales > max.sales ? { units, sales: data.sales } : max,
      { units: '', sales: 0 }
    ).units;

    setTotalCompetitors(total);
    setAveragePrice(Number.isNaN(avgPrice) ? 0 : avgPrice);
    setAverageRating(Number.isNaN(avgRating) ? 0 : avgRating);
    setTopBrand(leadingBrand);
    setTopFormat(leadingFormat);
    setTopUnitsPerPack(leadingUnitsPerPack);
  };

  useEffect(() => {
    if (competitors.length > 0) {
      calculateSummaryStatistics();
    } else {
      setTotalCompetitors(0);
      setAveragePrice(0);
      setAverageRating(0);
      setTopBrand('');
      setTopFormat('');
      setTopUnitsPerPack('');
    }
  }, [competitors]);

  const marketplaces = [
    { value: 'Amazon', label: 'Amazon (AMAZON US)' },
    { value: 'Amazon Mexico', label: 'Amazon Mexico' },
    { value: 'Amazon Canada', label: 'Amazon Canada' },
    { value: 'Amazon Brazil', label: 'Amazon Brazil' },
    { value: 'Amazon Vendor Brazil', label: 'Amazon Vendor Brazil' },
    { value: 'Amazon Europe', label: 'Amazon Europe' },
    { value: 'Amazon Spain', label: 'Amazon Spain' },
    { value: 'Amazon UK', label: 'Amazon UK' },
    { value: 'Amazon France', label: 'Amazon France' },
    { value: 'Amazon Belgium', label: 'Amazon Belgium' },
    { value: 'Amazon Netherlands', label: 'Amazon Netherlands' },
    { value: 'Amazon Germany', label: 'Amazon Germany' },
    { value: 'Amazon Italy', label: 'Amazon Italy' },
    { value: 'Amazon Sweden', label: 'Amazon Sweden' },
    { value: 'Amazon Poland', label: 'Amazon Poland' },
    { value: 'Amazon Egypt', label: 'Amazon Egypt' },
    { value: 'Amazon Turkey', label: 'Amazon Turkey' },
    { value: 'Amazon Saudi Arabia', label: 'Amazon Saudi Arabia' },
    {
      value: 'Amazon United Arab Emirates',
      label: 'Amazon United Arab Emirates',
    },
    { value: 'Amazon India', label: 'Amazon India' },
  ];

  const benchmarkProduct = async (
    searchKeywords,
    searchCompetitors,
    useExactMatch,
    exactMatch,
    marketplace,
    categoryValue
  ) => {
    if (searchCompetitors < 1 || searchCompetitors > 50) {
      openNotification({
        status: false,
        content: t('benchmark.errors.invalidCompetitorCount'),
      });
      return;
    }

    setLoading(true);
    setClassificationProgress(0);
    try {
      let combinedKeywords = searchKeywords;
      if (useExactMatch && exactMatch) {
        combinedKeywords = `${searchKeywords} ${exactMatch}`;
      }
      const params = {
        marketplace,
        keywords: combinedKeywords,
        numberOfCompetitors: searchCompetitors,
        productCategory: categoryValue,
      };

      if (useExactMatch) {
        params.exactMatch = [exactMatch];
      }

      const result = await benchmarkApi.getBenchmark(params);
      const initialCompetitors = result.data.competitors;
      setCompetitors(initialCompetitors);

      const totalCompetitorsCount = initialCompetitors.length;

      if (totalCompetitorsCount === 0) {
        setLoading(false);
      } else {
        let classifiedCount = 0;

        initialCompetitors.forEach(async (competitor, index) => {
          try {
            const response = await benchmarkApi.classifyCompetitor(
              competitor,
              marketplace
            );
            const classifiedCompetitor = response.data;

            setCompetitors((prevCompetitors) => {
              const newCompetitors = [...prevCompetitors];
              newCompetitors[index] = classifiedCompetitor;
              return newCompetitors;
            });
          } catch (error) {
            openNotification({
              status: false,
              content: getErrorMessage(error),
            });
          } finally {
            classifiedCount += 1;
            setClassificationProgress(
              Math.round((classifiedCount / totalCompetitorsCount) * 100)
            );
            if (classifiedCount === totalCompetitorsCount) {
              setLoading(false);
            }
          }
        });
      }
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(error),
      });
      setLoading(false);
    }
  };

  const onFinish = (values) => {
    const {
      searchKeywords,
      searchCompetitors,
      useExactMatch,
      exactMatch,
      marketplace,
      productCategory: categoryValue,
    } = values;
    setProductCategory(categoryValue);
    benchmarkProduct(
      searchKeywords,
      searchCompetitors,
      useExactMatch,
      exactMatch,
      marketplace,
      categoryValue
    );
  };

  const exportToExcel = () => {
    const dataToExport = competitors.map((comp) => ({
      [t('benchmark.table.link')]: comp.url,
      [t('benchmark.table.productTitle')]: comp.title,
      Asin: comp.externalId,
      [t('benchmark.table.brand')]: comp.brand,
      [t('benchmark.table.price')]: comp.price,
      [t('benchmark.table.sales')]: comp.sales,
      [t('benchmark.table.revenue')]: comp.revenue,
      [t('benchmark.table.fulfillmentType')]: comp.fulfillmentType,
      [t('benchmark.table.productFormatQuantity')]: comp.formatNumber,
      [t('benchmark.table.productFormatUnit')]: comp.formatUnit,
      [t('benchmark.table.unitsPerPack')]: comp.quantity,
      [t('benchmark.table.standardizedFormatUnit')]: comp.standardizedUnit,
      [t('benchmark.table.standardizedFormatQuantity')]:
        comp.standardizedQuantity,
      [t('benchmark.table.totalFormatQuantity')]: comp.totalFormatQuantity,
      [t('benchmark.table.pricePerTotalQuantity')]: comp.pricePerTotalQuantity,
      [t('benchmark.table.rating')]: comp.rating,
      [t('benchmark.table.reviews')]: comp.reviews,
      [t('benchmark.table.category')]: comp.groupName,
    }));

    const ws = utils.json_to_sheet(dataToExport);

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, t('benchmark.table.sheetName'));

    writeFile(wb, 'competitors.xlsx');
  };

  const generateReport = async () => {
    if (competitors.length === 0) {
      openNotification({
        status: false,
        content: t('benchmark.errors.noCompetitors'),
      });
      return;
    }

    setReportLoading(true);
    try {
      const response = await benchmarkApi.generateReport(
        competitors,
        productCategory
      );

      const blob = new Blob([response.data], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'benchmark-report.pdf';
      link.click();

      window.URL.revokeObjectURL(link.href);

      openNotification({
        status: true,
        content: t('benchmark.reportGeneratedSuccess'),
      });
    } catch (error) {
      openNotification({
        status: false,
        content: getErrorMessage(error),
      });
    } finally {
      setReportLoading(false);
    }
  };

  const handleExcelUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: 'array' });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = utils.sheet_to_json(worksheet);

        const transformedData = jsonData.map((row) => ({
          url: row[t('benchmark.table.link')],
          title: row[t('benchmark.table.productTitle')],
          externalId: row.Asin,
          brand: row[t('benchmark.table.brand')],
          price: row[t('benchmark.table.price')],
          sales: row[t('benchmark.table.sales')],
          revenue: row[t('benchmark.table.revenue')],
          fulfillmentType: row[t('benchmark.table.fulfillmentType')],
          formatNumber: row[t('benchmark.table.productFormatQuantity')],
          formatUnit: row[t('benchmark.table.productFormatUnit')],
          quantity: row[t('benchmark.table.unitsPerPack')],
          standardizedUnit: row[t('benchmark.table.standardizedFormatUnit')],
          standardizedQuantity:
            row[t('benchmark.table.standardizedFormatQuantity')],
          totalFormatQuantity: row[t('benchmark.table.totalFormatQuantity')],
          pricePerTotalQuantity:
            row[t('benchmark.table.pricePerTotalQuantity')],
          rating: row[t('benchmark.table.rating')],
          reviews: row[t('benchmark.table.reviews')],
          groupName: row[t('benchmark.table.category')],
        }));

        setCompetitors(transformedData);
        openNotification({
          status: true,
          content: t('benchmark.excelUploadSuccess'),
        });
      };

      reader.readAsArrayBuffer(file);
    } catch (error) {
      openNotification({
        status: false,
        content: t('benchmark.errors.excelUploadError'),
      });
    }
  };

  const handleDeleteCompetitor = (externalId) => {
    setCompetitors((prevCompetitors) =>
      prevCompetitors.filter(
        (competitor) => competitor.externalId !== externalId
      )
    );
  };

  const startEditing = (record, field) => {
    setEditingRow(record.externalId);
    setEditingField(field);
    setTempValue(record[field]);
  };

  const cancelEditing = () => {
    setEditingRow(null);
    setEditingField(null);
    setTempValue('');
  };

  const saveEditing = () => {
    setCompetitors((prev) =>
      prev.map((comp) =>
        comp.externalId === editingRow
          ? { ...comp, [editingField]: tempValue }
          : comp
      )
    );
    cancelEditing();
  };

  // Helper function to render editable cell
  const renderEditableCell = (text, record, field) => {
    const isEditing =
      editingRow === record.externalId && editingField === field;

    if (isEditing) {
      return (
        <div
          role="button"
          tabIndex={0}
          onClick={stopPropagation}
          onKeyDown={(e) => {
            if (e.key === 'Enter') saveEditing();
          }}
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: '100%',
          }}
        >
          <Input
            value={tempValue}
            onChange={(e) => setTempValue(e.target.value)}
            style={{ width: '60%', marginRight: 8 }}
          />
          <Button
            type="primary"
            size="small"
            onClick={saveEditing}
            style={{ padding: '0 8px' }}
          >
            ✓
          </Button>
          <Button
            size="small"
            onClick={cancelEditing}
            style={{ marginLeft: 4, padding: '0 8px' }}
          >
            ✕
          </Button>
        </div>
      );
    }

    return (
      <div
        role="button"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation();
          startEditing(record, field);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.stopPropagation();
            startEditing(record, field);
          }
        }}
        style={{ display: 'inline-block' }}
      >
        <Text>{text}</Text>
        <Button
          type="link"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            startEditing(record, field);
          }}
          style={{ marginLeft: 8 }}
          icon={<EditOutlined />}
        />
      </div>
    );
  };

  const columns = [
    {
      title: t('benchmark.table.actions.delete'),
      key: 'actions',
      align: 'center',
      width: 80,
      render: (_, record) => (
        <Button
          type="text"
          danger
          icon={<DeleteOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            handleDeleteCompetitor(record.externalId);
          }}
        />
      ),
    },
    // Title column editable
    {
      title: t('benchmark.table.productTitle'),
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      ellipsis: true,
      render: (text, record) => renderEditableCell(text, record, 'title'),
      onHeaderCell: () => ({
        style: { maxWidth: '20vw' },
      }),
      onCell: () => ({
        style: {
          maxWidth: '20vw',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }),
    },
    {
      title: t('benchmark.table.brand'),
      dataIndex: 'brand',
      key: 'brand',
      align: 'center',
      ellipsis: true,
      render: (text, record) => renderEditableCell(text, record, 'brand'),
    },
    {
      title: t('benchmark.table.price'),
      dataIndex: 'price',
      key: 'price',
      align: 'center',
      ellipsis: true,
      render: (text, record) => (
        <div style={{ color: 'green' }}>
          {renderEditableCell(text, record, 'price')}
        </div>
      ),
    },
    {
      title: t('benchmark.table.sales'),
      dataIndex: 'sales',
      key: 'sales',
      align: 'center',
      ellipsis: true,
      render: (text, record) => renderEditableCell(text, record, 'sales'),
    },
    {
      title: t('benchmark.table.revenue'),
      dataIndex: 'revenue',
      key: 'revenue',
      align: 'center',
      ellipsis: true,
      render: (text, record) => renderEditableCell(text, record, 'revenue'),
    },
    {
      title: t('benchmark.table.fulfillmentType'),
      dataIndex: 'fulfillmentType',
      key: 'fulfillmentType',
      align: 'center',
      ellipsis: true,
      render: (text, record) =>
        renderEditableCell(text, record, 'fulfillmentType'),
    },
    {
      title: t('benchmark.table.productFormatQuantity'),
      dataIndex: 'formatNumber',
      key: 'formatNumber',
      align: 'center',
      ellipsis: true,
      render: (text, record) =>
        renderEditableCell(text, record, 'formatNumber'),
    },
    {
      title: t('benchmark.table.productFormatUnit'),
      dataIndex: 'formatUnit',
      key: 'formatUnit',
      align: 'center',
      ellipsis: true,
      render: (text, record) => renderEditableCell(text, record, 'formatUnit'),
    },
    {
      title: t('benchmark.table.unitsPerPack'),
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
      ellipsis: true,
      render: (text, record) => renderEditableCell(text, record, 'quantity'),
    },
    {
      title: t('benchmark.table.standardizedFormatUnit'),
      dataIndex: 'standardizedUnit',
      key: 'standardizedUnit',
      align: 'center',
      ellipsis: true,
      render: (text, record) =>
        renderEditableCell(text, record, 'standardizedUnit'),
    },
    {
      title: t('benchmark.table.standardizedFormatQuantity'),
      dataIndex: 'standardizedQuantity',
      key: 'standardizedQuantity',
      align: 'center',
      ellipsis: true,
      render: (text, record) =>
        renderEditableCell(text, record, 'standardizedQuantity'),
    },
    {
      title: t('benchmark.table.totalFormatQuantity'),
      dataIndex: 'totalFormatQuantity',
      key: 'totalFormatQuantity',
      align: 'center',
      ellipsis: true,
      render: (text, record) =>
        renderEditableCell(text, record, 'totalFormatQuantity'),
    },
    {
      title: t('benchmark.table.pricePerTotalQuantity'),
      dataIndex: 'pricePerTotalQuantity',
      key: 'pricePerTotalQuantity',
      align: 'center',
      ellipsis: true,
      render: (text, record) => (
        <div style={{ color: 'blue' }}>
          {renderEditableCell(text, record, 'pricePerTotalQuantity')}
        </div>
      ),
    },
    {
      title: t('benchmark.table.rating'),
      dataIndex: 'rating',
      key: 'rating',
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        const displayedText = text ? text.toFixed(1) : 'N/A';
        return renderEditableCell(displayedText, record, 'rating');
      },
    },
    {
      title: t('benchmark.table.reviews'),
      dataIndex: 'reviews',
      key: 'reviews',
      align: 'center',
      ellipsis: true,
      render: (text, record) => renderEditableCell(text, record, 'reviews'),
    },
    {
      title: t('benchmark.table.category'),
      dataIndex: 'groupName',
      key: 'groupName',
      align: 'center',
      ellipsis: true,
      render: (text, record) => renderEditableCell(text, record, 'groupName'),
    },
  ];

  return (
    <Card style={{ padding: '30px' }}>
      <Row>
        <Col span={24}>
          <Title level={3}>{t('benchmark.title')}</Title>
        </Col>
      </Row>

      <Form layout="vertical" onFinish={onFinish}>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={t('benchmark.form.marketplace')}
              name="marketplace"
              rules={[
                {
                  required: true,
                  message: t('benchmark.form.marketplaceError'),
                },
              ]}
            >
              <Select
                placeholder={t('benchmark.form.marketplacePlaceholder')}
                options={marketplaces}
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={
                <span>
                  {t('benchmark.form.maxNumberOfCompetitors')}{' '}
                  <Tooltip
                    title={t('benchmark.form.maxNumberOfCompetitorsTooltip')}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </span>
              }
              name="searchCompetitors"
              rules={[
                {
                  required: true,
                  message: t('benchmark.form.maxNumberOfCompetitorsError'),
                },
              ]}
            >
              <Input
                placeholder={t(
                  'benchmark.form.maxNumberOfCompetitorsPlaceholder'
                )}
                type="number"
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={t('benchmark.form.searchKeywords')}
              name="searchKeywords"
              rules={[
                {
                  required: true,
                  message: t('benchmark.form.searchKeywordsError'),
                },
              ]}
            >
              <Input
                placeholder={t('benchmark.form.searchKeywordsPlaceholder')}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <Form.Item
              label={t('benchmark.form.productCategory')}
              name="productCategory"
              rules={[
                {
                  required: true,
                  message: t('benchmark.form.productCategoryError'),
                },
              ]}
            >
              <Input
                placeholder={t('benchmark.form.productCategoryPlaceholder')}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={12} md={8}>
            <Form.Item name="useExactMatch" valuePropName="checked">
              <Checkbox>{t('benchmark.form.useExactMatch')}</Checkbox>
            </Form.Item>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.useExactMatch !== currentValues.useExactMatch
              }
            >
              {({ getFieldValue }) =>
                getFieldValue('useExactMatch') ? (
                  <Form.Item
                    label={t('benchmark.form.exactMatch')}
                    name="exactMatch"
                    rules={[
                      {
                        required: true,
                        message: t('benchmark.form.exactMatchError'),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t('benchmark.form.exactMatchPlaceholder')}
                    />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16} justify="start">
          <Col>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                icon={<SearchOutlined />}
              >
                {t('benchmark.form.searchButton')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row style={{ marginTop: '16px' }} justify="start" gutter={16}>
        <Col>
          <Button
            type="default"
            onClick={exportToExcel}
            disabled={competitors.length === 0}
            icon={<DownloadOutlined />}
          >
            {t('benchmark.downloadExcel')}
          </Button>
        </Col>
        <Col>
          <input
            type="file"
            accept=".xlsx,.xls"
            style={{ display: 'none' }}
            onChange={handleExcelUpload}
            id="excel-upload"
          />
          <Button
            type="default"
            onClick={() => document.getElementById('excel-upload').click()}
            icon={<UploadOutlined />}
          >
            {t('benchmark.uploadExcel')}
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={generateReport}
            disabled={competitors.length === 0}
            loading={reportLoading}
            icon={<FileTextOutlined />}
          >
            {t('benchmark.generateReport')}
          </Button>
        </Col>
      </Row>

      {classificationProgress > 0 && classificationProgress < 100 && (
        <Progress
          percent={classificationProgress}
          status="active"
          style={{ marginTop: '20px' }}
        />
      )}

      {competitors.length > 0 && (
        <Row gutter={[16, 16]} style={{ marginTop: '20px' }} justify="center">
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card hoverable style={{ textAlign: 'center' }}>
              <Statistic
                title={
                  <span style={{ fontSize: '16px', fontWeight: 600 }}>
                    {t('benchmark.statistics.totalCompetitors')}
                  </span>
                }
                value={totalCompetitors}
                valueStyle={{ color: '#191970' }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card hoverable style={{ textAlign: 'center' }}>
              <Statistic
                title={
                  <span style={{ fontSize: '16px', fontWeight: 600 }}>
                    {t('benchmark.statistics.averagePrice')}
                  </span>
                }
                value={averagePrice}
                precision={2}
                prefix="$"
                valueStyle={{ color: '#191970' }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card hoverable style={{ textAlign: 'center' }}>
              <Statistic
                title={
                  <span style={{ fontSize: '16px', fontWeight: 600 }}>
                    {t('benchmark.statistics.averageRating')}
                  </span>
                }
                value={averageRating}
                precision={1}
                valueStyle={{ color: '#191970' }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card hoverable style={{ textAlign: 'center' }}>
              <Statistic
                title={
                  <span style={{ fontSize: '16px', fontWeight: 600 }}>
                    {t('benchmark.statistics.topBrand')}
                  </span>
                }
                value={topBrand}
                valueStyle={{ color: '#191970' }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card hoverable style={{ textAlign: 'center' }}>
              <Statistic
                title={
                  <span style={{ fontSize: '16px', fontWeight: 600 }}>
                    {t('benchmark.statistics.topFormat')}
                  </span>
                }
                value={topFormat}
                valueStyle={{ color: '#191970' }}
              />
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={6}>
            <Card hoverable style={{ textAlign: 'center' }}>
              <Statistic
                title={
                  <span style={{ fontSize: '16px', fontWeight: 600 }}>
                    {t('benchmark.statistics.topUnitsPerPack')}
                  </span>
                }
                value={topUnitsPerPack}
                valueStyle={{ color: '#191970' }}
              />
            </Card>
          </Col>
        </Row>
      )}

      <Spin spinning={loading}>
        <Table
          dataSource={competitors}
          columns={columns}
          rowKey="externalId"
          pagination={{ pageSize: 10 }}
          style={{ marginTop: '20px' }}
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'even-row' : 'odd-row'
          }
          onRow={(record) => {
            return {
              onClick: () => {
                if (editingRow === null) {
                  window.open(record.url, '_blank');
                }
              },
              style: {
                cursor: 'pointer',
              },
            };
          }}
          scroll={{ x: 'max-content' }}
        />
      </Spin>
    </Card>
  );
}

export default Benchmark;
