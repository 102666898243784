import { Space, Spin } from 'antd';
import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { orderGeneralStates } from '../../../../utils/const';
import restockOrdersAPI from '../../../../api/restock-orders';
import restockOrdersV2024API from '../../../../api/restockV2024';
import ShipmentTypeAndPriceCard from '../../../order/components/ShipmentTypeAndPriceCard';
import openNotification from '../../../../components/Toastr';
import { getErrorMessage } from '../../../../api/api';
import FBMCouriers from '../../../../utils/FBMCouriers';

function GetShipmentOptions(
  {
    order,
    courier,
    selectedService,
    setSelectedService,
    setLoadingConfirmShipments,
    isPickup,
  },
  ref
) {
  const { t } = useTranslation();
  const [loadingShipmentOptions, setLoadingShipmentOptions] = useState(true);
  const language = useSelector((store) => store.Session.language);
  const [shipmentOptions, setShipmentOptions] = useState([]);
  const [services, setServices] = useState({});

  const handleConfirmShipments = async () => {
    setLoadingConfirmShipments(true);
    try {
      const params = {};
      const body = { shipments: [] };
      const inboundShipmentPlans = order.shipmentPlan.InboundShipmentPlans;
      inboundShipmentPlans.forEach((plan, index) => {
        const option = shipmentOptions[index].options.find(
          (o) => o.rate.serviceName === selectedService
        );
        const deliveryWindow = shipmentOptions[
          index
        ].deliveryWindowOptions.find(
          (dw) =>
            dw.availabilityType === 'AVAILABLE' &&
            moment(dw.startDate).isSameOrAfter(moment().add(3, 'days'))
        );
        if (!deliveryWindow) throw new Error('No valid delivery window found');
        const shipmentBody = {
          shipmentId: plan.ShipmentId,
          identifiers: {
            deliveryWindowOptionId: deliveryWindow.deliveryWindowOptionId,
            transportationOptionId: option.metadata.transportationOptionId,
          },
          shipmentData: {
            courierName: courier,
            serviceType: {
              name:
                courier === FBMCouriers.FEDEX
                  ? option.rate.code
                  : option.rate.serviceName,
              code: option.rate.code,
              ...(option.rate.externalId && {
                externalId: option.rate.externalId,
              }),
            },
            pickup: {
              type: isPickup ? 'SCHEDULE' : 'DROP_OFF',
            },
            rate: {
              value: option.rate.totalRate,
              currency: option.rate.currency,
            },
          },
        };
        body.shipments.push(shipmentBody);
      });
      await restockOrdersV2024API.confirmShipment(order.id, body, params);
      await restockOrdersAPI.update(order.id, {
        state: orderGeneralStates.READY_TO_SHIP,
      });
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
      throw error;
    } finally {
      setLoadingConfirmShipments(false);
    }
  };

  useImperativeHandle(ref, () => ({
    handleConfirmShipments,
  }));

  useEffect(() => {
    const getOrderRates = async () => {
      if (!courier) return;
      setSelectedService(undefined);
      setLoadingShipmentOptions(true);
      let result;
      try {
        const { data } = await restockOrdersV2024API.getShipmentOptions(
          order.id,
          { courier }
        );
        setShipmentOptions(data.shipmentOptions);
        result = data.shipmentOptions;
      } catch (error) {
        openNotification({ status: false, content: getErrorMessage(error) });
      } finally {
        setLoadingShipmentOptions(false);
      }
      if (!result?.length) return;
      const servicesByShipment = result.map((so) => {
        return new Set(so.options.map((o) => o.rate.serviceName));
      });
      const commonServices = servicesByShipment
        ? Array.from(
            servicesByShipment.reduce(
              (intersection, set) =>
                new Set([...intersection].filter((item) => set.has(item))),
              servicesByShipment[0]
            )
          )
        : [];
      const newServices = {};
      const promises = [];
      commonServices.forEach((commonService) => {
        const promise = new Promise((resolve) => {
          const commonServiceRates = {
            name: commonService,
            totalRate: 0,
            totalRateWithoutDiscount: 0,
          };
          result.forEach((optionsByShipment) => {
            const matchingOption = optionsByShipment.options.find(
              (option) => option.rate.serviceName === commonService
            );
            commonServiceRates.currency = matchingOption.rate.currency;
            if (!commonServiceRates.estimatedDeliveryDate) {
              const matchingDate = matchingOption.rate.estimatedDeliveryDate;
              commonServiceRates.estimatedDeliveryDate = matchingDate
                ? new Date(matchingDate)
                : null;
            }
            commonServiceRates.totalRate += matchingOption.rate.totalRate;
            commonServiceRates.totalRateWithoutDiscount +=
              matchingOption.rate.totalRateWithoutDiscount.value;
          });
          commonServiceRates.discount = (
            ((commonServiceRates.totalRateWithoutDiscount -
              commonServiceRates.totalRate) /
              commonServiceRates.totalRateWithoutDiscount) *
            100
          ).toFixed(2);
          newServices[commonService] = commonServiceRates;
          resolve();
        });
        promises.push(promise);
      });
      await Promise.all(promises);
      setServices(newServices);
    };
    getOrderRates();
  }, [courier]);

  return order
    ? ![orderGeneralStates.DRAFT].includes(order.state) && (
        <Spin spinning={loadingShipmentOptions}>
          <Space
            direction="vertical"
            size="middle"
            style={{ width: '100%', marginTop: 15 }}
          >
            {Object.entries(services).map(([key, option]) => (
              <ShipmentTypeAndPriceCard
                key={key}
                tag={option.discount > 0 ? `${option.discount} % OFF` : null}
                title={option.name}
                date={`${t('common.estimatedDateOfDelivery')}: ${
                  option.estimatedDeliveryDate
                    ? option.estimatedDeliveryDate.toLocaleDateString(language)
                    : '-'
                }`}
                totalCost={new Intl.NumberFormat('es-CL', {
                  style: 'currency',
                  currency: option.currency,
                }).format(option.totalRate)}
                discountCost={
                  option.discount > 0
                    ? new Intl.NumberFormat('es-CL', {
                        style: 'currency',
                        currency: option.currency,
                      }).format(option.totalRateWithoutDiscount)
                    : null
                }
                cardKey={key}
                checked={selectedService === key}
                onChange={(e) => {
                  setSelectedService(e.target.value);
                }}
              />
            ))}
          </Space>
        </Spin>
      )
    : null;
}

GetShipmentOptions.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.number,
    courier: PropTypes.string,
    warehouse: PropTypes.shape({
      id: PropTypes.number,
    }),
    shipments: PropTypes.arrayOf(Object),
    shipmentPlan: PropTypes.shape({
      InboundShipmentPlans: PropTypes.arrayOf(
        PropTypes.shape({
          ShipmentId: PropTypes.string,
        })
      ),
    }),
    courierShipments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        pickup: PropTypes.shape({
          id: PropTypes.number,
          pickupTime: PropTypes.string,
          latestPickupTime: PropTypes.string,
          pickupDate: PropTypes.string,
        }),
      })
    ),
    steps: {
      step: PropTypes.string,
    },
    boxes: PropTypes.instanceOf(Object),
    isPalletized: PropTypes.bool.isRequired,
    products: PropTypes.instanceOf(Object),
    state: PropTypes.string,
  }),
  courier: PropTypes.string.isRequired,
  selectedService: PropTypes.string.isRequired,
  setSelectedService: PropTypes.func.isRequired,
  setLoadingConfirmShipments: PropTypes.func.isRequired,
  isPickup: PropTypes.bool.isRequired,
};

GetShipmentOptions.defaultProps = {
  order: null,
};

export default forwardRef(GetShipmentOptions);
