/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import { Row, Spin, Form, Radio, Typography, Steps, Col } from 'antd';
import {
  ExclamationCircleTwoTone,
  InfoCircleOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import 'antd/dist/antd.min.css';
import { useTranslation } from 'react-i18next';
import {
  orderGeneralStates,
  orderStates,
  orderSteps,
  validateMessages,
  RestockOrderStepsStateEnum,
} from '../../../../utils/const';
import {
  convertUnitWeight,
  mixPanelCreateEvent,
} from '../../../../utils/functions';
import openNotification from '../../../../components/Toastr';
import ProductAssignmentTable from '../../../order/components/steps/components/ProductAssignmentTable';
import restockOrdersAPI from '../../../../api/restock-orders';
import restockV2024 from '../../../../api/restockV2024';
import boxApi from '../../../../api/box';
import FBMCouriers from '../../../../utils/FBMCouriers';
import couriersApi from '../../../../api/courier';
import CheckCards from '../../../order/components/steps/components/CheckCards';
import InformationBox from '../../../order/components/steps/components/InformationBox';
import StickyAlert from '../../../order/components/steps/components/StickyAlert';
import '../steps.css';
import { getErrorMessage } from '../../../../api/api';

const { Item } = Form;
const { Step } = Steps;

function Boxes({
  orderId,
  setSelected,
  selected,
  setSteps,
  inputRadioActive,
  title,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();
  const [radioDefault, setRadioTypeOfBox] = useState(false);
  const [isUniformBox, setIsUniformBox] = useState();
  const [isPalletized, setIsPalletized] = useState();
  const [unit, setUnit] = useState();
  const [radioDefaultPallet, setRadioDefaultPallet] = useState(false);
  const [radioDefaultUnit, setRadioDefaultUnit] = useState(false);
  const [isMetric, setIsMetric] = useState(null);
  const [saveDraft, setSaveDraft] = useState(false);
  const [sendContentLoading, setSendContentLoading] = useState(false);
  const [boxes, setBoxes] = useState(null);
  const [pallets, setPallets] = useState([]);
  const [isPartnered, setIsPartnered] = useState(false);
  const [dataSourceCourier, setDataSourceCourier] = useState();
  const [selectedCourier, setSelectedCourier] = useState('');
  const [allowMixedBoxes, setAllowMixedBoxes] = useState(true);
  const [knownPackingInformation, setKnownPackingInformation] = useState();
  const [stepStatus, setStepStatus] = useState();
  const formRefs = useRef({});

  const getOrder = async (id) => {
    setLoading(true);
    try {
      const response = await restockOrdersAPI.getContent(id);
      setOrder(response?.data);

      const boxStep = response?.data.steps.find(
        (step) => step.step === orderSteps.CAJAS
      );
      if (boxStep && boxStep.state === RestockOrderStepsStateEnum.COMPLETED) {
        setStepStatus(false);
      } else if (
        boxStep &&
        boxStep.state === RestockOrderStepsStateEnum.PENDING
      ) {
        setStepStatus(true);
      }

      setSteps(response.data?.steps);
      setUnit(response.data?.unity);
      setIsMetric(response.data?.unity === 'cm');
      setRadioDefaultUnit(!!response.data?.unity);

      const partnered =
        response.data?.warehouse?.address?.countryCode ===
        response.data?.destination;

      setIsPartnered(partnered);

      if (response.data?.isPack !== null) {
        setRadioTypeOfBox(true);
        setIsUniformBox(response.data?.isPack);
      }
      if (response.data?.isPalletized !== null) {
        setRadioDefaultPallet(true);
        setIsPalletized(response.data?.isPalletized);
      }
      if (response.data?.isPalletized !== null && response?.data?.pallets)
        setPallets(response.data?.pallets);
      if (response.data?.shipmentPlan?.packingOptionInformation) {
        if (response.data?.shipmentPlan?.packingOptionInformation.length > 1) {
          setAllowMixedBoxes(false);
        } else {
          setAllowMixedBoxes(true);
        }
      }
      if (response.data?.shipmentPlan?.knownPackingInformation) {
        setKnownPackingInformation(
          response.data?.shipmentPlan?.knownPackingInformation
        );
      }
    } catch (error) {
      openNotification({
        status: false,
        content: 'Error al obtener la orden.',
      });
    }
    setLoading(false);
  };

  const handleCancelForm = async () => {
    setLoading(true);
    try {
      await restockV2024.cancelShipment(orderId);
      window.location = '/envios';
    } catch (error) {
      openNotification({
        status: false,
        content: 'Ha ocurrido un error al intentar cancelar la orden.',
      });
      setLoading(false);
    }
  };

  const sendBoxes = async (payload, draft = true) => {
    try {
      await restockOrdersAPI.deleteBoxes(orderId);
      await restockOrdersAPI.createBoxes(orderId, payload);
      if (draft) {
        openNotification({
          status: true,
          content: 'Borrador guardado con éxito.',
        });
      }
    } catch (error) {
      setSaveDraft(false);
      setSendContentLoading(false);
      openNotification({
        status: false,
        content: 'Error en la asignación de cajas.',
      });
    }
    setSaveDraft(false);
    setLoading(false);
  };

  const formatSendBoxesCasePacked = async (values) => {
    const selectedBoxes = [];
    Object.keys(values.products).forEach((shipmentPlanId) => {
      values.products[shipmentPlanId].forEach((product) => {
        // Packed boxes can be repeated
        const orderProduct = order?.products?.find(
          (p) => p?.product?.defaultCode === product.sku
        );
        if (orderProduct) {
          selectedBoxes.push({
            shipmentId: shipmentPlanId,
            numberOfBoxes:
              product.numberOfBoxes ?? product.quantity / product.unitsPerPack,
            boxId: product.selectedBox,
            weight: product.weight,
            products: [
              {
                ...product,
                id: orderProduct.product?.id,
                unitsPerPack: product.unitsPerPack,
                expirationDate: orderProduct.expirationDate,
              },
            ],
          });
        }
      });
    });
    return selectedBoxes;
  };

  // const setShipmentsFoodRequirement = async () => {
  //   // Get all form keys as an array
  //   const formKeys = Object.keys(formRefs.current);

  //   // Map over the keys and return an array of promises
  //   const shipments = formKeys.map((key) => {
  //     const values = formRefs.current[key].getFieldsValue();
  //     return {
  //       shipmentPlanId: key,
  //       hasFood: values.hasFood,
  //       courierShipmentId: null,
  //     };
  //   });
  //   await restockOrdersAPI.update(order.id, {
  //     shipments,
  //   });
  // };

  const getSendBoxesPayload = async (flat = true) => {
    // Get all form keys as an array
    const formKeys = Object.keys(formRefs.current);

    // Map over the keys and return an array of promises
    const promises = formKeys.map(async (key) => {
      const formValues = await formRefs.current[key].getFieldsValue();
      return formatSendBoxesCasePacked(formValues);
    });

    // Wait for all promises to resolve
    const payloads = await Promise.all(promises);

    // Flatten the array of arrays into a single array
    if (flat) return payloads.flat();
    return payloads;
  };

  const createPallets = async () => {
    const payload = {
      pallets,
    };
    try {
      await restockOrdersAPI.update(order.id, payload);
    } catch (error) {
      openNotification({
        status: false,
        content: 'Error al guardar información de pallets.',
      });
    }
  };

  const buildPayload = (formValues) => {
    const data = {
      isPack: formValues.isPack,
      isPalletized: formValues.isPalletized,
      unity: formValues.unit,
    };

    return data;
  };

  const handleSaveBoxes = async (sendDraftLoading = false) => {
    setLoading(true);

    const formFields = form.getFieldsValue();
    const updateRestockOrderPayload = buildPayload(formFields);
    const payload = await getSendBoxesPayload();

    if (isUniformBox) {
      const allProducts = payload.flatMap((entry) => entry.products);
      updateRestockOrderPayload.products = allProducts;
    }

    await restockOrdersAPI.updateContent(orderId, updateRestockOrderPayload);

    if (order.isPack || updateRestockOrderPayload.isPack) {
      await sendBoxes(payload, sendDraftLoading);
    } else if (sendDraftLoading) {
      setSaveDraft(true);
    } else {
      await Promise.all(
        Object.keys(formRefs.current).map(async (key) => {
          await formRefs.current[key].submit();
        })
      );
    }
    if (order.isPalletized || updateRestockOrderPayload.isPalletized)
      await createPallets();

    setLoading(false);
  };

  useEffect(async () => {
    if (saveDraft) {
      await Promise.all(
        Object.keys(formRefs.current).map(async (key) => {
          await formRefs.current[key].submit();
        })
      );
    }
  }, [saveDraft]);

  const validateWeight = (boxesData) => {
    let allBoxesValid = true;
    const unitWeight = isMetric ? 'kg' : 'lb';

    boxesData.forEach((box) => {
      const boxWeight = parseFloat(box.weight);

      const boxedProductsWeight = box.products
        .map((boxedProduct) => {
          const orderProduct = order.products.find(
            (product) => product.product.defaultCode === boxedProduct.sku
          );

          if (boxedProduct.unitsPerPack) {
            return orderProduct
              ? convertUnitWeight(
                  (parseFloat(boxedProduct.productWeight) *
                    boxedProduct.quantity) /
                    boxedProduct.numberOfBoxes,
                  orderProduct.product.weightUnity,
                  unitWeight
                )
              : 0;
          }

          return orderProduct
            ? convertUnitWeight(
                parseFloat(boxedProduct.productWeight) * boxedProduct.quantity,
                orderProduct.product.weightUnity,
                unitWeight
              )
            : 0;
        })
        .reduce((acum, productWeight) => acum + productWeight, 0);
      if (boxWeight < boxedProductsWeight) {
        openNotification({
          status: false,
          content: `El peso mínimo de una caja en el destino ${boxesData[0].shipmentId} (peso asignado: ${boxWeight} ${unitWeight}) es de ${boxedProductsWeight} ${unitWeight}`,
        });

        allBoxesValid = false;
      }
    });

    return allBoxesValid;
  };

  const validateBeforeSend = (payload) => {
    if (!payload) {
      openNotification({
        status: false,
        content: t('orders.newOrder.boxes.validationFail'),
      });
      return false;
    }
    let valid = true;
    const palletsForAllShipments =
      pallets &&
      pallets.length === order?.shipmentPlan?.InboundShipmentPlans?.length;
    const palletsEmpty = (
      pallets && pallets.map((shipment) => shipment.pallets)
    ).includes([]);
    const boxesAreEmpty = payload && payload.length === 0;

    if (order.isPalletized) {
      if (!palletsForAllShipments || palletsEmpty) {
        valid = false;
        openNotification({
          status: false,
          message: 'Debe ingresar la información de los pallets.',
        });
      }
    }
    if (boxesAreEmpty) {
      valid = false;
      openNotification({
        status: false,
        message: 'Debe configurar las cajas de esta orden.',
      });
    }
    if (!isPartnered) valid = valid && validateWeight(payload);
    return valid;
  };

  const validateBoxes = async () => {
    try {
      // Other validations
      const payloads = await getSendBoxesPayload(false);
      await Promise.all(
        payloads.map(async (payload) => validateBeforeSend(payload))
      );
      // Validate forms fields
      await Promise.all(
        Object.keys(formRefs.current).map(async (key) => {
          await formRefs.current[key].validateFields();
        })
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  const mixedBoxesWarning = (
    <Typography.Text className="w-400">
      <strong>{t('orders.newOrder.boxes.mixedBoxes')}:</strong>{' '}
      {t('orders.newOrder.boxes.warningMessage1')}
      <strong>{t('orders.newOrder.boxes.warningMessage2')}</strong>
      {t('orders.newOrder.boxes.warningMessage3')}
    </Typography.Text>
  );

  const sendContent = async () => {
    setLoading(true);
    try {
      setSendContentLoading(true);
      mixPanelCreateEvent('Action Button', {
        action: 'Save Box Restock',
        step: selected,
      });
      const validBoxes = await validateBoxes();
      if (!validBoxes) {
        setSendContentLoading(false);
        setLoading(false);
        return;
      }
      await handleSaveBoxes();
      setLoading(true);
      await restockV2024.setPackingInformation(orderId);
      await getOrder(orderId);
      openNotification({
        status: true,
        content: t('orders.newOrder.boxes.success.description'),
        info: t('orders.newOrder.boxes.success.title'),
      });
      setSelected(2);
      setLoading(false);
    } catch (error) {
      openNotification({ status: false, message: getErrorMessage(error) });
      setSendContentLoading(false);
      setLoading(false);
    }
  };

  useEffect(async () => {
    if (!order) {
      await getOrder(orderId);
      const response = await couriersApi.getCourierCredentials(false, 1);
      const data = response.data.results.map((value) => ({
        key: value.id,
        name: value.courierCompanyName,
        accountNumber: value.accountNumber,
      }));
      setDataSourceCourier(data);
    }
  }, []);

  useEffect(async () => {
    if (order) {
      if (order.courier && dataSourceCourier) {
        const chosenCourier = dataSourceCourier.find(
          (item) => item.key === Number(order.courier)
        );
        if (chosenCourier) setSelectedCourier(chosenCourier.name);
      }
    }
  }, [dataSourceCourier]);

  const getCompanyBoxes = async () => {
    try {
      const unitSent = unit === 'inch' ? 'IN' : unit;
      const response = await boxApi.getByCompanyId(unitSent);
      setBoxes(response.data);
    } catch (error) {
      openNotification({ status: false, content: 'Error al obtener cajas.' });
    }
  };

  useEffect(() => {
    if (unit && radioDefaultPallet) {
      getCompanyBoxes();
    }
  }, [unit, radioDefaultPallet]);

  const convertWeightUnit = (fromUnit, value) => {
    if (!fromUnit || !value) return undefined;
    const standardUnit = {
      kilograms: 'kg',
      kilogram: 'kg',
      kg: 'kg',
      milligrams: 'mg',
      milligram: 'mg',
      ounces: 'oz',
      oz: 'oz',
      pounds: 'lb',
      pound: 'lb',
      lb: 'lb',
      grams: 'g',
      gram: 'g',
      g: 'g',
    };
    if (!standardUnit[fromUnit.toLowerCase()]) return undefined;
    const conversions = {
      kg: { kg: 1, lb: 2.20462 },
      lb: { kg: 0.453592, lb: 1 },
      g: { kg: 0.001, lb: 0.00220462 },
      mg: { kg: 0.000001, lb: 0.00000220462 },
      oz: { kg: 0.0283495, lb: 0.0625 },
    };
    const weightUnit = unit === 'cm' ? 'kg' : 'lb';
    return (
      conversions[standardUnit[fromUnit.toLowerCase()]][weightUnit] * value
    ).toFixed(3);
  };

  useEffect(() => {
    if (!formRefs.current) return;
    Object.keys(formRefs.current).forEach((shipmentId) => {
      const formValues = formRefs.current?.[shipmentId]?.getFieldsValue();
      const shipmentProducts = formValues.products[shipmentId];
      shipmentProducts.forEach((product, index) => {
        if (product && product.productWeight !== undefined) {
          const updatedWeight = convertWeightUnit(
            unit === 'cm' ? 'lb' : 'kg',
            parseFloat(product.productWeight)
          );
          shipmentProducts[index].productWeight = updatedWeight;
        }
      });
      formRefs.current?.[shipmentId]?.setFieldValue('products', {
        ...formValues.products,
        [shipmentId]: shipmentProducts,
      });
    });
  }, [unit]);

  return (
    <>
      <div className="title-content">{title}</div>

      <Spin spinning={loading || saveDraft || sendContentLoading}>
        <Form
          name="boxesForm"
          form={form}
          layout="vertical"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          onFinish={sendContent}
          validateMessages={validateMessages}
          className="text-align-left form-padding-top restockStepOne"
          scrollToFirstError
        >
          <Row gutter={[24, 24]} style={{ margin: '0 15px' }}>
            <Steps progressDot direction="vertical" className="detail-steps">
              <Step
                key="1"
                title={
                  <Typography.Text className="shipment-step-title">
                    {t('orders.newOrder.contents.titleStep2')}
                  </Typography.Text>
                }
                status="finish"
                description={
                  order && (
                    <>
                      <Item
                        noStyle
                        className="input-form-margin-bottom"
                        name="isPack"
                        rules={[
                          {
                            required: true,
                            message: 'Requerido',
                          },
                        ]}
                        initialValue={order.isPack}
                        tooltip={{
                          title: t(
                            'orders.newOrder.boxes.radioGroup1Description'
                          ),
                          icon: <InfoCircleOutlined />,
                        }}
                      >
                        <Radio.Group
                          className="cardRadioGroupContent"
                          name="isPack"
                          disabled={!stepStatus}
                          onChange={(e) => {
                            setRadioTypeOfBox(true);
                            setIsUniformBox(e.target.value);
                          }}
                          style={{ marginTop: 12, marginBottom: 24 }}
                        >
                          <CheckCards
                            isPack={isUniformBox}
                            radioDefault={radioDefault}
                            inputRadioActive={inputRadioActive}
                            tag
                            title={t('orders.newOrder.contents.uniformBoxes')}
                            text={t(
                              'orders.newOrder.boxes.radioGroup1Checkbox1'
                            )}
                            value
                          />
                          <CheckCards
                            isPack={isUniformBox}
                            radioDefault={radioDefault}
                            inputRadioActive={inputRadioActive}
                            tag={false}
                            title={t('orders.newOrder.boxes.mixedBoxes')}
                            text={t(
                              'orders.newOrder.boxes.radioGroup1Checkbox2'
                            )}
                            value={false}
                            hidden={!allowMixedBoxes}
                          />
                        </Radio.Group>
                      </Item>
                      <div id="warning-mixed-box">
                        <InformationBox
                          icon={
                            <ExclamationCircleTwoTone twoToneColor="rgb(250, 173, 20, .5)" />
                          }
                          textInformationBox={mixedBoxesWarning}
                          status="warning"
                          display={isUniformBox === false ? 'block' : 'none'}
                        />
                      </div>
                    </>
                  )
                }
              />
              <Step
                key="2"
                title={
                  <Typography.Text className="shipment-step-title">
                    {t('orders.newOrder.contents.titleStep3')}
                  </Typography.Text>
                }
                status={radioDefault ? 'finish' : 'wait'}
                description={
                  radioDefault && (
                    <Item
                      noStyle
                      className="input-form-margin-bottom"
                      name="isPalletized"
                      rules={[
                        {
                          required: true,
                          message: 'Requerido',
                        },
                      ]}
                      initialValue={order.isPalletized}
                      tooltip={{
                        title: t(
                          'orders.newOrder.boxes.radioGroup1Description'
                        ),
                        icon: <InfoCircleOutlined />,
                      }}
                    >
                      <Radio.Group
                        className="cardRadioGroupContent"
                        name="isPalletized"
                        disabled={!stepStatus}
                        onChange={(e) => {
                          setRadioDefaultPallet(true);
                          setIsPalletized(e.target.value);
                        }}
                        style={{ marginTop: 12, marginBottom: 24 }}
                      >
                        <CheckCards
                          isPack={isPalletized}
                          radioDefault={radioDefaultPallet}
                          inputRadioActive={inputRadioActive}
                          tag={false}
                          title={t('orders.newOrder.boxes.separatedBoxes')}
                          text={t('orders.newOrder.boxes.radioGroup1Checkbox3')}
                          value={false}
                        />
                        <CheckCards
                          isPack={isPalletized}
                          radioDefault={radioDefaultPallet}
                          inputRadioActive={inputRadioActive}
                          tag={false}
                          title={t('orders.newOrder.boxes.palletBoxes')}
                          text={t('orders.newOrder.boxes.radioGroup1Checkbox4')}
                          value
                          hidden={selectedCourier === FBMCouriers.SHIPSMART}
                        />
                      </Radio.Group>
                    </Item>
                  )
                }
              />
              <Step
                key="3"
                title={
                  <Typography.Text className="shipment-step-title">
                    {t('orders.newOrder.boxes.titleStep1')}
                  </Typography.Text>
                }
                status={radioDefaultPallet ? 'finish' : 'wait'}
                description={
                  radioDefaultPallet && (
                    <Item
                      noStyle
                      className="input-form-margin-bottom"
                      name="unit"
                      rules={[
                        {
                          required: true,
                          message: 'Requerido',
                        },
                      ]}
                      initialValue={unit}
                    >
                      <Radio.Group
                        className="cardRadioGroupContent"
                        name="unit"
                        disabled={!stepStatus}
                        onChange={(e) => {
                          setRadioDefaultUnit(true);
                          setIsMetric(e.target.value === 'cm');
                          setUnit(e.target.value);
                        }}
                        style={{ marginTop: 12, marginBottom: 24 }}
                      >
                        <CheckCards
                          isPack={isMetric}
                          radioDefault={radioDefaultUnit}
                          inputRadioActive={inputRadioActive}
                          radioOptionValue="cm"
                          tag={false}
                          title="Métrico"
                          text="CM / KG"
                          value
                        />
                        <CheckCards
                          isPack={isMetric}
                          radioDefault={radioDefaultUnit}
                          inputRadioActive={inputRadioActive}
                          radioOptionValue="inch"
                          tag={false}
                          title="Imperial"
                          text="IN / LB"
                          value={false}
                        />
                      </Radio.Group>
                    </Item>
                  )
                }
              />
              <Step
                key="4"
                title={
                  <Typography.Text className="shipment-step-title">
                    {t('orders.newOrder.boxes.titleStep2')}
                  </Typography.Text>
                }
                status={unit && radioDefaultPallet ? 'finish' : 'wait'}
                disabled={!stepStatus}
                description={
                  unit && order && boxes && radioDefaultPallet ? (
                    !knownPackingInformation ? (
                      <Row gutter={[16, 16]}>
                        {order?.shipmentPlan?.InboundShipmentPlans?.map(
                          (shipment, index) => (
                            <Col span={24} key={shipment.ShipmentId}>
                              {index === 0 && (
                                <div id="warning-mixed-box">
                                  <InformationBox
                                    icon={
                                      <ExclamationCircleTwoTone twoToneColor="rgb(250, 173, 20, .5)" />
                                    }
                                    textInformationBox={
                                      <div style={{ width: '100%' }}>
                                        <Typography.Text className="w-400">{`Amazon ha asignado un total de `}</Typography.Text>
                                        <Typography.Text className="w-600">
                                          {order?.shipmentPlan
                                            ?.InboundShipmentPlans?.length > 1
                                            ? `${order?.shipmentPlan?.InboundShipmentPlans?.length} direcciones de destino `
                                            : `${order?.shipmentPlan?.InboundShipmentPlans?.length} dirección de destino `}
                                        </Typography.Text>
                                        <Typography.Text className="w-400">
                                          para este envío.
                                        </Typography.Text>
                                      </div>
                                    }
                                    status="warning"
                                    display="block"
                                  />
                                </div>
                              )}
                              <ProductAssignmentTable
                                items={shipment.Items}
                                order={order}
                                boxes={boxes}
                                setBoxes={setBoxes}
                                onFinishProductAssignment={sendBoxes}
                                isPack={isUniformBox}
                                unit={unit}
                                shipmentId={shipment.ShipmentId}
                                pallets={pallets}
                                setPallets={setPallets}
                                formRefs={formRefs}
                                index={index}
                                saveDraft={saveDraft}
                                convertWeightUnit={convertWeightUnit}
                              />
                            </Col>
                          )
                        )}
                      </Row>
                    ) : (
                      <Row gutter={[16, 16]}>
                        {order?.shipmentPlan?.packingOptionInformation?.map(
                          (packingGroup, index) => (
                            <Col span={24} key={packingGroup.packingGroupId}>
                              <ProductAssignmentTable
                                items={packingGroup.Items}
                                order={order}
                                boxes={boxes}
                                setBoxes={setBoxes}
                                onFinishProductAssignment={sendBoxes}
                                isPack={isUniformBox}
                                unit={unit}
                                shipmentId={packingGroup.packingGroupId}
                                pallets={pallets}
                                setPallets={setPallets}
                                formRefs={formRefs}
                                index={index}
                                saveDraft={saveDraft}
                                convertWeightUnit={convertWeightUnit}
                                knownPackingInformation={
                                  knownPackingInformation
                                }
                                disableForm={!stepStatus}
                              />
                            </Col>
                          )
                        )}
                      </Row>
                    )
                  ) : null
                }
              />
            </Steps>
          </Row>
        </Form>

        {order && (
          <StickyAlert
            handleCancelForm={handleCancelForm}
            handleSaveDraft={handleSaveBoxes}
            sendContent={sendContent}
            firstDisabled={
              order?.state !== orderGeneralStates.DRAFT &&
              order?.state !== orderGeneralStates.WORKING
            }
            secondDisabled={order?.state !== orderGeneralStates.WORKING}
            thirdDisabled={
              order.steps?.find((step) => step.step === orderSteps.CAJAS)
                .state === orderStates.COMPLETED
            }
            forthDisabled={
              order.steps?.find((step) => step.step === orderSteps.CAJAS)
                .state === orderStates.COMPLETED
            }
            sendContentLoading={sendContentLoading}
            saveDraftLoading={saveDraft}
          />
        )}
      </Spin>
    </>
  );
}

Boxes.propTypes = {
  orderId: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
  setSteps: PropTypes.func.isRequired,
  inputRadioActive: PropTypes.shape({
    border: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default Boxes;
