import React, { useEffect, useRef, useState } from 'react';
import {
  Row,
  Button,
  Spin,
  Form,
  Radio,
  notification,
  Typography,
  Steps,
  Col,
} from 'antd';
import { FrownOutlined, ExclamationCircleTwoTone } from '@ant-design/icons';
import PropTypes from 'prop-types';
import 'antd/dist/antd.min.css';
import { useTranslation } from 'react-i18next';
import {
  orderGeneralStates,
  orderStates,
  orderSteps,
  validateMessages,
} from '../../../../utils/const';
import {
  convertUnitWeight,
  mixPanelCreateEvent,
  translateErrors,
} from '../../../../utils/functions';
import openNotification from '../../../../components/Toastr';
import ProductAssignmentTable from './components/ProductAssignmentTable';
import restockOrdersAPI from '../../../../api/restock-orders';
import {
  createInboundShipmentErrors,
  defaultAmazonErrors,
  estimateTransportErrors,
  putTransportDetailsErrors,
  submitFBAInboundCartonContentErrors,
} from '../../../../utils/amazon_errors';
import boxApi from '../../../../api/box';
import FBMCouriers from '../../../../utils/FBMCouriers';
import couriersApi from '../../../../api/courier';
import CheckCards from './components/CheckCards';
import InformationBox from './components/InformationBox';
import StickyAlert from './components/StickyAlert';
import './steps.css';
import { getErrorMessage } from '../../../../api/api';

const { Item } = Form;
const { Step } = Steps;

function Boxes({
  orderId,
  setSelected,
  selected,
  setSteps,
  inputRadioActive,
  title,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState();
  const [unit, setUnit] = useState();
  const [radioDefault, setRadioDefault] = useState(false);
  const [isMetric, setIsMetric] = useState();
  const [saveDraft, setSaveDraft] = useState(false);
  const [sendContentLoading, setSendContentLoading] = useState(false);
  const [boxes, setBoxes] = useState(null);
  const [pallets, setPallets] = useState([]);
  const [isPartnered, setIsPartnered] = useState(false);
  const [translatePath, setTranslatePath] = useState({});
  const [dataSourceCourier, setDataSourceCourier] = useState();
  const formRefs = useRef({});

  const getOrder = async (id) => {
    setLoading(true);
    try {
      const response = await restockOrdersAPI.getContent(id);
      setOrder(response?.data);

      if (response?.data?.pallets) setPallets(response.data?.pallets);

      setSteps(response.data?.steps);
      setUnit(response.data?.unity);
      setIsMetric(response.data?.unity === 'cm');
      setRadioDefault(!!response.data?.unity);

      const partnered =
        response.data?.warehouse?.address?.countryCode ===
        response.data?.destination;

      setIsPartnered(partnered);
    } catch (error) {
      openNotification({
        status: false,
        content: 'Error al obtener la orden.',
      });
    }
    setLoading(false);
  };

  const handleCancelForm = async () => {
    try {
      await restockOrdersAPI.deleteRestockOrder(orderId);
      window.location = '/envios';
    } catch (error) {
      openNotification({
        status: false,
        content: 'Ha ocurrido un error al intentar cancelar la orden.',
      });
    }
  };

  const sendBoxes = async (payload, draft = true) => {
    try {
      await restockOrdersAPI.deleteBoxes(orderId);
      await restockOrdersAPI.createBoxes(orderId, payload);
      if (draft) {
        openNotification({
          status: true,
          content: 'Borrador guardado con éxito.',
        });
      }
    } catch (error) {
      setSaveDraft(false);
      setSendContentLoading(false);
      openNotification({
        status: false,
        content: 'Error en la asignación de cajas.',
      });
    }
    setSaveDraft(false);
    setSendContentLoading(false);
  };

  const formatSendBoxesCasePacked = async (values) => {
    const selectedBoxes = [];
    Object.keys(values.products).forEach((shipmentPlanId) => {
      values.products[shipmentPlanId].forEach((product) => {
        // Packed boxes can be repeated
        const orderProduct = order?.products?.find(
          (p) => p?.product?.defaultCode === product.sku
        );
        if (orderProduct) {
          selectedBoxes.push({
            hasFood: values.hasFood,
            shipmentId: shipmentPlanId,
            numberOfBoxes: product.numberOfBoxes,
            boxId: product.selectedBox,
            weight: product.weight,
            products: [
              {
                ...product,
                id: orderProduct.product?.id,
                unitsPerPack: product.unitsPerPack,
                expirationDate: orderProduct.expirationDate,
              },
            ],
          });
        }
      });
    });
    return selectedBoxes;
  };

  const setShipmentsFoodRequirement = async () => {
    // Get all form keys as an array
    const formKeys = Object.keys(formRefs.current);

    // Map over the keys and return an array of promises
    const shipments = formKeys.map((key) => {
      const values = formRefs.current[key].getFieldsValue();
      return {
        shipmentPlanId: key,
        hasFood: values.hasFood,
        courierShipmentId: null,
      };
    });
    await restockOrdersAPI.update(order.id, {
      shipments,
    });
  };

  const getSendBoxesPayload = async (flat = true) => {
    // Get all form keys as an array
    const formKeys = Object.keys(formRefs.current);

    // Map over the keys and return an array of promises
    const promises = formKeys.map(async (key) => {
      const formValues = await formRefs.current[key].getFieldsValue();
      return formatSendBoxesCasePacked(formValues);
    });

    // Wait for all promises to resolve
    const payloads = await Promise.all(promises);

    // Flatten the array of arrays into a single array
    if (flat) return payloads.flat();
    return payloads;
  };

  const createPallets = async () => {
    const payload = {
      pallets,
    };
    try {
      await restockOrdersAPI.update(order.id, payload);
    } catch (error) {
      openNotification({
        status: false,
        content: 'Error al guardar información de pallets.',
      });
    }
  };

  const handleSaveBoxes = async (sendDraftLoading = false) => {
    if (sendDraftLoading) setSaveDraft(true);
    if (unit) {
      await restockOrdersAPI.updateContent(orderId, { unity: unit });
    }
    await setShipmentsFoodRequirement();
    if (order.isPack) {
      const payload = await getSendBoxesPayload(); // Returns array of shipment payload
      if (order.isPalletized) await createPallets();
      await sendBoxes(payload, sendDraftLoading);
    } else {
      Object.keys(formRefs.current).map(async (key) => {
        await formRefs.current[key].submit();
      });
    }
  };

  const validateWeight = (boxesData) => {
    let allBoxesValid = true;
    const unitWeight = isMetric ? 'kg' : 'lb';

    boxesData.forEach((box) => {
      const boxWeight = parseFloat(box.weight);

      const boxedProductsWeight = box.products
        .map((boxedProduct) => {
          const orderProduct = order.products.find(
            (product) => product.product.defaultCode === boxedProduct.sku
          );

          if (boxedProduct.unitsPerPack) {
            return orderProduct
              ? convertUnitWeight(
                  (parseFloat(boxedProduct.productWeight) *
                    boxedProduct.quantity) /
                    boxedProduct.numberOfBoxes,
                  orderProduct.product.weightUnity,
                  unitWeight
                )
              : 0;
          }

          return orderProduct
            ? convertUnitWeight(
                parseFloat(boxedProduct.productWeight) * boxedProduct.quantity,
                orderProduct.product.weightUnity,
                unitWeight
              )
            : 0;
        })
        .reduce((acum, productWeight) => acum + productWeight, 0);
      if (boxWeight < boxedProductsWeight) {
        openNotification({
          status: false,
          content: `El peso mínimo de una caja en el destino ${boxesData[0].shipmentId} (peso asignado: ${boxWeight} ${unitWeight}) es de ${boxedProductsWeight} ${unitWeight}`,
        });

        allBoxesValid = false;
      }
    });

    return allBoxesValid;
  };

  const validateBeforeSend = (payload) => {
    if (!payload) {
      openNotification({
        status: false,
        content: t('orders.newOrder.boxes.validationFail'),
      });
      return false;
    }
    let valid = true;
    const palletsForAllShipments =
      pallets &&
      pallets.length === order?.shipmentPlan?.InboundShipmentPlans?.length;
    const palletsEmpty = (
      pallets && pallets.map((shipment) => shipment.pallets)
    ).includes([]);
    const boxesAreEmpty = payload && payload.length === 0;

    if (order.isPalletized) {
      if (!palletsForAllShipments || palletsEmpty) {
        valid = false;
        openNotification({
          status: false,
          message: 'Debe ingresar la información de los pallets.',
        });
      }
    }
    if (boxesAreEmpty) {
      valid = false;
      openNotification({
        status: false,
        message: 'Debe configurar las cajas de esta orden.',
      });
    }
    if (!isPartnered) valid = valid && validateWeight(payload);
    return valid;
  };

  const handlePartneredOrder = async () => {
    await restockOrdersAPI.submitFBAInboundCartonContent(orderId);
    if (isPartnered && !order.isPalletized) {
      await restockOrdersAPI.putTransportDetails(orderId);
      await restockOrdersAPI.estimateTransport(orderId);
    }
    await restockOrdersAPI.update(orderId, {
      state: orderGeneralStates.READY_TO_SHIP,
    });
  };

  const handleNextStep = async (createInbound = true) => {
    try {
      if (createInbound) await restockOrdersAPI.createInboundShipment(orderId);
      if (isPartnered) {
        await handlePartneredOrder();
      }
      if (isPartnered) {
        const step = order.steps.find((s) => s.step === orderSteps.CAJAS);
        await restockOrdersAPI.updateOrderStep(orderId, step.id, {
          state: 'Completado',
        });
        getOrder(orderId);
        openNotification({
          status: true,
          content: t('orders.newOrder.boxes.success.description'),
          info: t('orders.newOrder.boxes.success.title'),
        });
        setSelected(3);
      }
    } catch (error) {
      if (error?.config?.url.includes('FBAInboundCartonContent')) {
        setTranslatePath(
          translateErrors(error, submitFBAInboundCartonContentErrors)
        );
      } else if (error?.config?.url.includes('transportDetails')) {
        setTranslatePath(translateErrors(error, putTransportDetailsErrors));
      } else if (error?.config?.url.includes('estimateTransport')) {
        setTranslatePath(translateErrors(error, estimateTransportErrors));
      } else if (
        error?.config?.url.includes('inboundShipment') &&
        error?.config?.method === 'post'
      ) {
        const translationErrors = translateErrors(
          error,
          createInboundShipmentErrors
        );
        if (
          translationErrors.error ===
          'amazonError.updateInboundShipment.expired.text'
        ) {
          const products = order.products.map((product) => {
            return {
              sku: product.product.defaultCode,
              asin: product.product.asin,
              quantity: product.quantity,
              unitsPerPack: product.unitsPerPack,
            };
          });
          await restockOrdersAPI.recreatePlan(orderId, products);
          await handleNextStep(false);
        } else {
          setTranslatePath(translationErrors);
        }
      } else if (
        error?.config?.url.includes('inboundShipment') &&
        error.config.method === 'put'
      ) {
        setTranslatePath(translateErrors(error, putTransportDetailsErrors));
      } else {
        setTranslatePath(translateErrors(error, defaultAmazonErrors));
      }
    }
  };

  useEffect(() => {
    if (Object.keys(translatePath).length !== 0) {
      const textError = t(translatePath.error);
      notification.error({
        style: { zIndex: 'inherit' },
        duration: 0,
        message: translatePath?.titleError
          ? t(translatePath.titleError)
          : 'Error',
        description: textError,
        ...(translatePath?.tooltip
          ? {
              btn: (
                <Button
                  style={{ color: '#00e5a6' }}
                  type="link"
                  href={translatePath?.tooltip}
                  target="_blank"
                >
                  {t('common.wantKnowMore')}
                </Button>
              ),
            }
          : {}),
        icon: (
          <FrownOutlined
            className="error"
            style={{ color: '#F81D22 !important' }}
          />
        ),
      });
    }
  }, [translatePath]);

  const validateBoxes = async () => {
    try {
      // Other validations
      const payloads = await getSendBoxesPayload(false);
      await Promise.all(
        payloads.map(async (payload) => validateBeforeSend(payload))
      );
      // Validate forms fields
      await Promise.all(
        Object.keys(formRefs.current).map(async (key) => {
          await formRefs.current[key].validateFields();
        })
      );
      return true;
    } catch (error) {
      return false;
    }
  };

  const sendContent = async () => {
    try {
      setSendContentLoading(true);
      mixPanelCreateEvent('Action Button', {
        action: 'Save Box Restock',
        step: selected,
      });
      const validBoxes = await validateBoxes();
      if (!validBoxes) {
        setSendContentLoading(false);
        return;
      }
      await handleSaveBoxes();
      const actualCourier = dataSourceCourier.find(
        (courier) => courier.key === Number(order.courier)
      );
      if (isPartnered && actualCourier.name === FBMCouriers.FEDEX) {
        handleNextStep();
      } else {
        await restockOrdersAPI.createInboundShipment(orderId);
        const step = order.steps.find((s) => s.step === orderSteps.CAJAS);
        await restockOrdersAPI.updateOrderStep(order.id, step.id, {
          state: 'Completado',
        });
        await getOrder(orderId);
        openNotification({
          status: true,
          content: t('orders.newOrder.boxes.success.description'),
          info: t('orders.newOrder.boxes.success.title'),
        });
        setSelected(2);
      }
    } catch (error) {
      openNotification({ status: false, message: getErrorMessage(error) });
      setSendContentLoading(false);
    }
  };

  useEffect(async () => {
    if (!order) {
      await getOrder(orderId);
      const response = await couriersApi.getCourierCredentials(false, 1);
      const data = response.data.results.map((value) => ({
        key: value.id,
        name: value.courierCompanyName,
        accountNumber: value.accountNumber,
      }));
      setDataSourceCourier(data);
    }
  }, []);

  const getCompanyBoxes = async () => {
    setLoading(true);
    try {
      const unitSent = unit === 'inch' ? 'IN' : unit;
      const response = await boxApi.getByCompanyId(unitSent);
      setBoxes(response.data);
    } catch (error) {
      openNotification({ status: false, content: 'Error al obtener cajas.' });
    }
    setLoading(false);
  };

  useEffect(() => {
    getCompanyBoxes();
  }, [unit]);

  const convertWeightUnit = (fromUnit, value) => {
    if (!fromUnit || !value) return undefined;
    const standardUnit = {
      kilograms: 'kg',
      kilogram: 'kg',
      kg: 'kg',
      milligrams: 'mg',
      milligram: 'mg',
      ounces: 'oz',
      oz: 'oz',
      pounds: 'lb',
      pound: 'lb',
      lb: 'lb',
      grams: 'g',
      gram: 'g',
      g: 'g',
    };
    if (!standardUnit[fromUnit.toLowerCase()]) return undefined;
    const conversions = {
      kg: { kg: 1, lb: 2.20462 },
      lb: { kg: 0.453592, lb: 1 },
      g: { kg: 0.001, lb: 0.00220462 },
      mg: { kg: 0.000001, lb: 0.00000220462 },
      oz: { kg: 0.0283495, lb: 0.0625 },
    };
    const weightUnit = unit === 'cm' ? 'kg' : 'lb';
    return (
      conversions[standardUnit[fromUnit.toLowerCase()]][weightUnit] * value
    ).toFixed(3);
  };

  useEffect(() => {
    if (!formRefs.current) return;
    Object.keys(formRefs.current).forEach((shipmentId) => {
      const formValues = formRefs.current?.[shipmentId]?.getFieldsValue();
      const shipmentProducts = formValues.products[shipmentId];
      shipmentProducts.forEach((product, index) => {
        if (product && product.productWeight !== undefined) {
          const updatedWeight = convertWeightUnit(
            unit === 'cm' ? 'lb' : 'kg',
            parseFloat(product.productWeight)
          );
          shipmentProducts[index].productWeight = updatedWeight;
        }
      });
      formRefs.current?.[shipmentId]?.setFieldValue('products', {
        ...formValues.products,
        [shipmentId]: shipmentProducts,
      });
    });
  }, [unit]);

  return (
    <div id="boxes" className="text-align-left">
      <Spin spinning={loading}>
        <div className="title-content">{title}</div>
        <Row>
          <Steps progressDot direction="vertical" className="detail-steps">
            <Step
              key="1"
              title={
                <Typography.Text className="shipment-step-title">
                  {t('orders.newOrder.boxes.titleStep1')}
                </Typography.Text>
              }
              status="finish"
              description={
                order && (
                  <Form
                    name="boxes-form"
                    form={form}
                    validateMessages={validateMessages}
                    className="form-padding-top"
                    style={{ marginBottom: 32 }}
                  >
                    {isMetric !== undefined && (
                      <Item
                        name="unit"
                        rules={[{ required: true }]}
                        initialValue={unit}
                      >
                        <Radio.Group
                          className="cardRadioGroupContent"
                          name="unit"
                          onChange={(e) => {
                            setRadioDefault(true);
                            setIsMetric(e.target.value === 'cm');
                            setUnit(e.target.value);
                          }}
                          disabled={order?.state !== orderGeneralStates.WORKING}
                        >
                          <CheckCards
                            isPack={isMetric}
                            radioDefault={radioDefault}
                            inputRadioActive={inputRadioActive}
                            radioOptionValue="cm"
                            tag={false}
                            title="Métrico"
                            text="CM / KG"
                            value
                          />
                          <CheckCards
                            isPack={isMetric}
                            radioDefault={radioDefault}
                            inputRadioActive={inputRadioActive}
                            radioOptionValue="inch"
                            tag={false}
                            title="Imperial"
                            text="IN / LB"
                            value={false}
                          />
                        </Radio.Group>
                      </Item>
                    )}
                  </Form>
                )
              }
            />
            <Step
              key="2"
              title={
                <Typography.Text className="shipment-step-title">
                  {t('orders.newOrder.boxes.titleStep2')}
                </Typography.Text>
              }
              status={unit ? 'finish' : 'wait'}
              description={
                unit &&
                order &&
                boxes &&
                pallets &&
                order?.shipmentPlan?.InboundShipmentPlans && (
                  <Row gutter={[16, 16]}>
                    {order?.shipmentPlan?.InboundShipmentPlans?.map(
                      (shipment, index) => (
                        <Col span={24} key={shipment.ShipmentId}>
                          {index === 0 && (
                            <div id="warning-mixed-box">
                              <InformationBox
                                icon={
                                  <ExclamationCircleTwoTone twoToneColor="rgb(250, 173, 20, .5)" />
                                }
                                textInformationBox={
                                  <div style={{ width: '100%' }}>
                                    <Typography.Text className="w-400">{`Amazon ha asignado un total de `}</Typography.Text>
                                    <Typography.Text className="w-600">
                                      {order?.shipmentPlan?.InboundShipmentPlans
                                        ?.length > 1
                                        ? `${order?.shipmentPlan?.InboundShipmentPlans?.length} direcciones de destino `
                                        : `${order?.shipmentPlan?.InboundShipmentPlans?.length} dirección de destino `}
                                    </Typography.Text>
                                    <Typography.Text className="w-400">
                                      para este envío.
                                    </Typography.Text>
                                  </div>
                                }
                                status="warning"
                                display="block"
                              />
                            </div>
                          )}
                          <ProductAssignmentTable
                            items={shipment.Items}
                            order={order}
                            boxes={boxes}
                            setBoxes={setBoxes}
                            onFinishProductAssignment={sendBoxes}
                            isPack={order.isPack}
                            unit={unit}
                            shipmentId={shipment.ShipmentId}
                            pallets={pallets}
                            setPallets={setPallets}
                            formRefs={formRefs}
                            index={index}
                            saveDraft={saveDraft}
                            convertWeightUnit={convertWeightUnit}
                          />
                        </Col>
                      )
                    )}
                  </Row>
                )
              }
            />
          </Steps>
        </Row>

        {order && (
          <StickyAlert
            handleCancelForm={handleCancelForm}
            handleSaveDraft={handleSaveBoxes}
            sendContent={sendContent}
            firstDisabled={
              order?.state !== orderGeneralStates.DRAFT &&
              order?.state !== orderGeneralStates.WORKING
            }
            secondDisabled={order?.state !== orderGeneralStates.WORKING}
            thirdDisabled={order.state !== orderGeneralStates.WORKING}
            forthDisabled={
              order.steps?.find((step) => step.step === orderSteps.CAJAS)
                .state === orderStates.COMPLETED
            }
            sendContentLoading={sendContentLoading}
            saveDraftLoading={saveDraft}
          />
        )}
      </Spin>
    </div>
  );
}

Boxes.propTypes = {
  orderId: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
  setSteps: PropTypes.func.isRequired,
  inputRadioActive: PropTypes.shape({
    border: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default Boxes;
