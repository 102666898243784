const es = {
  addressErrors: {
    default: 'Ocurrió un error al obtener los detalles de la dirección.',
    postalCode:
      'No se encontró un código postal asociado a la dirección ingresada. Por favor, complétalo manualmente.',
  },
  amazonError: {
    createInboundShipment: {
      default: {
        text: 'Error al crear tu envío en Amazon, por favor, contáctanos a través del chat de soporte.',
      },
    },
    default: {
      text: 'Error con Amazon, por favor, contáctanos a través del chat de soporte.',
    },
    estimateTransport: {
      default: {
        text: 'Error al estimar el costo de envío desde USA a Amazon, por favor, contáctanos a través del chat de soporte.',
      },
    },
    putTransportDetails: {
      default: {
        text: 'Error al proporcionar el tracking number de FedEx a  Amazon, por favor, contáctanos a través del chat de soporte.',
      },
    },
    submitFBAInboundCartonContent: {
      default: {
        text: 'Error al guardar la información de medidas y peso de cajas en Amazon, por favor, contáctanos a través del chat de soporte.',
      },
    },
    updateInboundShipment: {
      default: {
        text: 'Error al actualizar el estado de envío en Amazon, por favor, contáctanos a través del chat de soporte.',
      },
      expired: {
        text: 'Esta orden ya está creada en Amazon o ha expirado',
      },
    },
  },
  analytics: {
    marketing: {
      subtitle: 'Lorem Ipsum is simply dummy text of the printing and',
      title: 'Marketing',
    },
    sales: {
      subtitle: 'Visualiza datos de Ventas.',
      title: 'Ventas',
    },
    stock: {
      subtitle: 'Visualiza datos de Stock.',
      title: 'Stock',
    },
    subtitle:
      'Visualiza las ventas e inventario de cada marketplaces en un período de tiempo determinado',
    title: 'Analytics',
    visits: {
      subtitle: 'Lorem Ipsum is simply dummy text of the printing and',
      title: 'Visitas e Impresiones',
    },
  },
  assistant: {
    chat: 'Chat',
    errors: {
      get: 'Ha ocurrido un error al obtener tus conversaciones.',
      send: 'Error al enviar el mensaje.',
    },
    newChat: 'Nuevo chat',
    options: {
      alcohol: 'Alcohol',
      children: 'Productos infantiles',
      cosmetics: 'Cosméticos',
      docs: 'Documentación',
      electro: 'Electro',
      fertilizers: 'Fertilizantes',
      food: 'Alimentos',
      jewelry: 'Joyas',
      labelling: 'Etiquetado',
      regulations: 'Regulaciones',
      supplements: 'Suplementos',
      textiles: 'Textiles',
    },
    request:
      "Necesito toda la información sobre {{ topic }} para exportar productos de la categoría '{{ productType }}' producidos en {{ countryOfManufacture }} hacia {{ destinationCountry }}.",
    title: 'Asistente I.A.',
    writing: 'Escribiendo...',
  },
  calculator: {
    addressInput: {
      errors: [
        'Ocurrió un error al obtener los detalles de la ubicación.',
        'No se pudo encontrar un código postal o una ciudad para la ubicación ingresada. Intenta una ubicación diferente o más específica.',
      ],
      placeholder: 'Ingresa y selecciona una ubicación',
    },
    calculate: 'Calcular',
    calculating: 'Calculando costos...',
    category: {
      emptyError: 'Por favor, selecciona una categoría',
      placeholder: 'Selecciona una categoría',
      title: 'Categoría',
      tooltip:
        'Selecciona la categoría a la que pertenece tu producto dentro del marketplace seleccionado.',
    },
    countryOfManufacture: {
      emptyError: 'Por favor, selecciona el país de manufactura',
      placeholder: 'Selecciona un país',
      title: 'País de Manufactura',
      tooltip:
        'Indica el país de fabricación del producto. Esta información es necesaria para determinar las regulaciones que deben cumplirse y los impuestos que deben pagarse en el país de destino.',
    },
    deliveryFees: 'Envío al consumidor',
    deliveryFeesAlert:
      'La tarifa incluye una estimación de los aranceles, tasas e impuestos de importación. Sin embargo, estos valores podrían variar en la práctica. Serás responsable de cualquier ajuste final o cargos adicionales por despacho aduanero.',
    destinationAddress: {
      emptyError: 'Por favor, ingresa una ubicación de destino',
      title: 'Ubicación de destino',
      tooltip:
        'Indica la ubicación a la que te gustaría vender tus productos. Puedes probar con diferentes ciudades o países y comparar los costos asociados.',
    },
    disclaimer: {
      description:
        'Esta calculadora considera los principales costos asociados a la venta en marketplaces globales. Sin embargo, es importante tener en cuenta que ',
      seeMore:
        'Para saber más, visita el siguiente enlace en nuestro centro de ayuda ',
      warning: ' es una estimación y los costos pueden variar en la realidad.',
    },
    findCalculations: 'Filtrar cálculos por nombre',
    harmonizedCode: {
      emptyError: 'Por favor ingresa el código arancelario',
      placeholder: '0000.00',
      title: 'Código arancelario',
      tooltip:
        'Indica el código arancelario del producto. Este código se utiliza para clasificar los productos según su naturaleza y características específicas. Se utiliza para establecer las regulaciones y documentos exigidos según el tipo de producto.',
    },
    loadCalculation: {
      error:
        'No se pudo cargar el cálculo. Por favor, inténtalo más tarde. ({{ error }})',
      success: 'Se ha cargado el cálculo con éxito',
    },
    logisticService: {
      emptyError: 'Por favor, selecciona un operador logístico',
      placeholder: 'Selecciona un operador logístico',
      title: 'Operador logístico',
      tooltip:
        'Selecciona el operador logístico (courier o centro de fulfillment) que enviará tus productos al consumidor final.',
    },
    logisticType: {
      destination: {
        description:
          'Selecciona esta opción si deseas enviar stock de varias unidades de tus productos a un centro de fulfillment, como Amazon. Ellos se encargarán de almacenar tus productos en el país donde se venden y de enviarlos al cliente final cada vez que haya una venta.',
        title: 'Inventario en destino',
      },
      emptyError: 'Por favor, selecciona el tipo de logística',
      origin: {
        description:
          'Selecciona esta opción si deseas mantener tus productos almacenados en tu país de origen y realizar envíos cada vez que haya una compra directamente al cliente final en el país de destino utilizando un courier como FedEx.',
        title: 'Inventario en origen',
      },
      placeholder: 'Selecciona un tipo de logística',
      title: 'Tipo de logística',
      tooltip:
        'Ingresa el precio al que planeas vender tu producto en el marketplace. Puedes probar con cualquier precio y luego ajustarlo según el margen que desees obtener.',
    },
    mainSubtitle:
      '¡Calcula los costos de vender tus productos y define el precio ideal para lograr tus metas!',
    mainTitle: 'Cadena de costos',
    margin: 'Margen',
    marketplace: {
      emptyError: 'Por favor, selecciona un marketplace',
      placeholder: 'Selecciona un marketplace',
      title: 'Marketplace',
      tooltip:
        'Selecciona el marketplace internacional en el que planeas vender tus productos. Cada marketplace tiene sus propias políticas y estructuras de comisión.',
    },
    marketplaceFees: 'Comisión del Marketplace',
    myCalculations: 'Mis cálculos',
    newProduct: {
      subtitle: '¡Publícalo en la sección de catálogo!',
      title: 'Producto nuevo (no listado)',
    },
    originAddress: {
      emptyError: 'Por favor, ingresa una ubicación de origen',
      title: 'Ubicación de origen',
      tooltip:
        'Indica la ubicación desde donde enviarás tus productos al cliente final o al centro de fulfillment (normalmente tu propio domicilio, almacén o bodega).',
    },
    others: 'Otros',
    packaging: {
      title: 'Medidas del empaque',
      type: {
        box: 'caja',
        box_plural: 'cajas',
        default: 'empaque',
        default_plural: 'empaques',
        emptyError: 'Por favor, selecciona el tipo de empaque',
        pallet: 'pallet',
        pallet_plural: 'pallets',
        placeholder: 'Selecciona el tipo de empaque',
        title: 'Tipo de empaque',
        tooltip:
          'Seleccione el tipo de empaque en el que enviarás tus productos al centro de fulfillment.',
      },
      units: {
        emptyError: 'Por favor, ingresa el número de {{ packagingType }}',
        title: 'Número de {{ packagingType }}',
        tooltip: 'Número de empaques en el envío.',
      },
      unitsPerPackaging: {
        emptyError: 'Por favor, ingresa las unidades por {{ packagingType }}',
        title: 'Unidades por {{ packagingType }}',
        tooltip:
          'Cantidad de unidades del producto a enviar contenidas en un empaque.',
      },
      weight: {
        emptyError: 'Por favor, completa el peso total por {{ packagingType }}',
        title: 'Peso total por {{ packagingType }}',
        tooltip:
          'Peso total de un empaque incluyendo sus productos contenidos.',
      },
    },
    price: {
      emptyError: 'Por favor, completa el precio de venta',
      title: 'Precio de venta',
      tooltip:
        'Ingresa el precio al que planeas vender tu producto en el marketplace. Puedes probar con cualquier precio y luego ajustarlo según el margen que desees obtener.',
    },
    product: 'producto',
    productUnits: 'Medidas del producto',
    saveCalculation: {
      error:
        'No se pudo guardar el cálculo. Por favor, inténtalo más tarde. ({{ error }})',
      helper: 'Ingresa un nombre para guardar el resultado de la calculadora',
      placeholder: 'Taza LAP',
      success: 'Se ha guardado el cálculo con éxito',
      title: 'Guardar cálculo',
    },
    serviceTitle: 'Servicio: ',
    serviceTooltip:
      'Selecciona uno de los servicios de carrier disponibles, los cuales pueden variar en el costo y tiempo de entrega.',
    shippingCost: 'Costo de envío',
    shippingOnly: 'Cotizar sólo envío',
    storageFees: 'Bodegaje',
    totalRestockFees: 'Costo total de envío',
    totalUnits: '({{ units }} unidades): ',
    unit: {
      emptyError: 'Por favor, completa el {{ unit }} del {{ object }}',
      height: 'Alto',
      length: 'Largo',
      tooltip: '{{ unit }} del {{ object }}',
      weight: 'Peso',
      width: 'Ancho',
    },
    unitRestockFees: 'Envío al centro de fulfillment (Fedex)',
    withLAPDiscount: 'Con convenio LAP',
  },
  catalogue: {
    banner: {
      syncImportDescription:
        'Tienes una o más categorías de productos en proceso para ser publicados, una vez completado te enviaremos una notificación.',
      syncImportTitle: 'Publicación en estado de carga.',
    },
    confirmDeleteUnpublishedProduct:
      '¿Está seguro que desea eliminar el producto no publicado?',
    deleteUnpublishedProduct: 'Eliminar producto no publicado',
    editProductModal: {
      bulletPoint: 'Bullet Point',
      errorRemoveImage: 'Error al eliminar la imagen.',
      errorUploadImage: 'Error al guardar la imagen.',
      newBulletPoint: 'Bullet point',
      newBulletPointPlaceholder: 'Ingrese bullet point',
      newBulletPointTooltip: 'Máximo 500 caracteres',
      newDescriptionTooltip: 'Máximo 2000 caracteres',
      newMainImgTooltip:
        'Por favor, ten en cuenta que la imagen principal solo puede mostrar el producto que se está vendiendo. Está prohibido el uso de texto, logos, personas o artefactos que no estén incluidos en el producto en sí y debe tener un fondo completamente blanco (sin fondo). La imagen debe tener una resolución mayor a 1000 x 1000 píxeles y estar en formato JPG o PNG. Asegúrate de proporcionar una imagen cuadrada de alta calidad que muestre claramente el producto y sus detalles.',
      newPriceTooltip:
        'Sólo números, no incluir símbolo de moneda. Sólo usar punto para decimales.',
      newProductDescription: 'Descripción',
      newProductDescriptionPlaceholder:
        'Ingrese descripción (máximo 2000 caracteres).',
      newProductMainImage: 'Nueva imagen principal',
      newProductPrice: 'Precio',
      newProductPricePlaceholder: '15.99',
      newProductSecondaryImages: 'Nuevas imágenes secundarias',
      newProductTitle: 'Título',
      newProductTitlePlaceholder: 'Ingrese título',
      newSecondaryImgTooltip:
        'Por favor, ten en cuenta que las imágenes solo pueden mostrar el producto que se está vendiendo. Está prohibido el uso de texto, logos, personas o artefactos que no estén incluidos en el producto en sí y debe tener un fondo completamente blanco (sin fondo). La imagen debe tener una resolución mayor a 1000 x 1000 píxeles y estar en formato JPG o PNG. Asegúrate de proporcionar una imagen cuadrada de alta calidad que muestre claramente el producto y sus detalles.',
      newTitleTooltip: 'Máximo 60 caracteres.',
      productDescription: 'Descripción',
      productMainImage: 'Imagen principal',
      productPrice: 'Precio',
      productSecondaryImages: 'Imágenes secundarias',
      productTitle: 'Título',
      saveButton: 'Guardar cambios',
      subtitleOne: 'Detalle del producto',
      subtitleTwo: 'Nueva información del producto',
      title: 'Editar producto en ',
      uploadImage: 'Subir',
      warningAmazonParent:
        'Si edita un producto padre de Amazon, los cambios se verán reflejados en todos los productos hijos.',
    },
    errorDeletingUnpublishedProduct: 'Error al eliminar el producto',
    exportProductsModal:
      'Exportar productos de {{ originMarketplace }} a {{ marketplaceToExport }}',
    noMarketplaceConnected:
      'Aún no tienes un marketplace sincronizado para publicar productos.',
    publishProductsModal: {
      autocompleteColumns: {
        checkbox: [
          '¿Autocompletar columnas con IA?',
          '(Sólo se pueden autocompletar hasta {{ limit }} productos por carga)',
        ],
        tooltip:
          'Los valores de las columnas son autocompletados en base a la información del producto en la publicación del marketplace de origen. Si necesitas procesar más productos del límite, contacta al chat de soporte.',
      },
      destinationCategories: 'Categorías de destino',
      downloadTemplateCategory: 'Descarga y completa la siguiente planilla',
      excludePublished: '¿Excluir productos publicados en {{ marketplace }}?',
      filterBySku: 'Filtrar por SKU',
      firstStepsCheckbox: {
        activeSellerAccount: {
          primaryText: '¿Tienes tu cuenta Amazon Seller activa?',
          secondaryText: 'Si no estás seguro, selecciona',
        },
        brandApproved: {
          primaryText: '¿Solicitaste la aprobación de tu marca?',
        },
        productsBarCode: {
          primaryText:
            '¿Tienes el código de barras (EAN o UPC) de tus productos?',
          secondaryText:
            'Puedes solicitar un GTIN exception si aún no lo tienes',
        },
      },
      firstStepsCompletedText:
        'Cuando completes los pasos anteriores, selecciona una categoría para tus productos',
      limitError: 'No se pueden cargar más de {{ limit }} productos',
      moreInfo:
        'Si tienes dudas para completar los campos de la planilla, te invitamos a revisar la siguiente sección en nuestro centro de ayuda',
      notFoundCategories: 'SKUs sin sugerencias de categoría: {{ productIds }}',
      searchCategoriesPlaceholder:
        'Busca una categoría en inglés... ejemplo: coffee',
      searchCategoriesTooltip:
        'Escribe en inglés palabras claves para encontrar la categoria que más se adecúe a tus productos.',
      searching: 'Buscando categorías...',
      secondTitle:
        'Marca las casillas una vez hayas completado los pasos solicitados, ya que sin ellos no podrás avanzar correctamente',
      selectOriginCategory: 'Selecciona una categoría de origen',
      spanishSearchCategoriesPlaceholder:
        'Busca una categoría en español... ejemplo: café',
      spanishSearchCategoriesTooltip:
        'Escribe en español palabras claves para encontrar la categoria que más se adecúe a tus productos.',
      title: 'Publicar en {{marketplace}}',
      unsupportedModalBodyPartOne:
        'La categoría de productos seleccionada solo puede ser publicada directamente en tu cuenta seller de Amazon. ',
      unsupportedModalBodyPartTwo:
        'Selecciona una opción para publicar tus productos:',
      unsupportedModalDescriptionOne:
        'permite realizar la publicación masiva de productos de una categoría.',
      unsupportedModalDescriptionTwo:
        'permite realizar la publicación de un solo producto a la vez.',
      unsupportedModalTitle: 'Publicar en Amazon',
      unsupportedModalUploadSheet: 'Publicar por planilla',
      unsupportedModalUploadSingle: 'Publicar uno a uno',
      unsupportedModalmoreInfo: 'Conocer más sobre como publicar en Amazon',
      uploadAmazon: 'Publicación por Amazon',
      uploadLAP: 'Publicación por LAP',
      uploadTemplateCategory: 'Adjunta la planilla con los datos completados',
    },
    removeUnpublishedProductSuccessfully: 'Producto eliminado',
    select: {
      all: 'Todos',
      page: 'Página actual',
      selectedListings: 'Listings seleccionados',
      selectedProducts: 'Products seleccionados',
    },
    showingParents: 'mostrando {{ count }} padres',
    uploadProductModal: {
      badRequestException:
        'No se pudo procesar la solicitud. Uno o más de los campos ingresados contienen errores.',
      brand: {
        emptyError: 'Por favor, ingresa la marca del producto',
        placeholder: 'LAP',
        title: 'Marca',
        tooltip: 'Marca del producto',
      },
      countryOfManufacture: {
        emptyError: 'Por favor, selecciona el país de origen del producto',
        placeholder: '🇨🇱 Chile',
        title: 'País de origen',
        tooltip:
          'Selecciona el país en el cual el producto fue fabricado o producido. Esta información es necesaria para determinar las regulaciones y requisitos aduaneros, así como los impuestos que se aplican en el país de destino.',
      },
      defaultCodeError:
        'Ya existe un producto asociado a tu cuenta con el SKU ingresado.',
      defaultError:
        'Ocurrió un error al subir el producto. Inténtalo más tarde.',
      description: {
        emptyError: 'Por favor, ingresa una descripción del producto',
        maxLengthError: 'La descripción no puede exceder los 800 caracteres',
        placeholder: 'Ej: Taza blanca de porcelana con logotipo LAP.',
        title: 'Descripción',
        tooltip: 'Descripción del producto. Límite de 800 caracteres.',
      },
      harmonizedCode: {
        emptyError: 'Por favor, ingresa el código arancelario del producto',
        placeholder: '04.09',
        seeMore:
          'https://ayuda.lapmarketplace.com/hc/es-cl/articles/12743698364955-C%C3%B3digo-Arancelario',
        title: 'Código arancelario',
        tooltip:
          'Indica el código arancelario del producto. Este código se utiliza para clasificar los productos según su naturaleza y características específicas. Se utiliza para establecer las regulaciones y documentos exigidos según el tipo de producto.',
      },
      mainImage: {
        emptyError: 'Por favor, sube una imagen del producto',
        title: 'Imagen',
        tooltip:
          'Por favor, ten en cuenta que la imagen principal sólo puede mostrar el producto que se publicará. Está prohibido el uso de texto, logos, personas o artefactos que no estén incluidos en el producto en sí, y debe tener un fondo completamente blanco (sin fondo). La imagen debe tener una resolución mayor a 1000 x 1000 píxeles y estar en formato JPG o PNG. Asegúrate de proporcionar una imagen cuadrada de alta calidad que muestre claramente el producto y sus detalles.',
      },
      modalTitle: 'Cargar producto a LAP',
      sku: {
        emptyError: 'Por favor, ingresa un SKU para el producto',
        placeholder: 'LAP-CUP',
        title: 'SKU',
        tooltip: 'Código de referencia del producto',
      },
      success: 'Se ha agregado el producto con éxito',
      title: {
        emptyError: 'Por favor, ingresa el título del producto',
        placeholder: 'Taza LAP',
        title: 'Título',
        tooltip: 'Título del producto',
      },
      unitFOB: {
        emptyError: 'Por favor, ingresa el valor del FOB unitario',
        placeholder: '199.99',
        seeMore:
          'https://ayuda.lapmarketplace.com/hc/es-cl/articles/12321298472859-FOB-Declarado',
        title: 'FOB Unitario',
        tooltip:
          'Indica el valor en USD que se declara para el producto como mercancía en un transporte de carga.',
      },
    },
  },
  checkout: {
    couponCode: 'Código de descuento',
    doYouHaveCoupon: '¿Tienes algún cupón de descuento?',
    error: {
      billingInfo:
        'Revisa los datos de facturación ingresados e inténtalo nuevamente.',
      invalidCoupon: 'El cupón ingresado no es válido.',
      noAdditionalPlanFound:
        'No se encontraron planes adicionales disponibles.',
      stripe:
        'Ocurrió un error al iniciar la sesión de pago de Stripe. Inténtalo más tarde.',
      unavailablePaymentMethod: 'Método de pago no disponible para este plan.',
    },
    free: 'gratis',
    freeTrial: {
      DAY: '{{count}} día gratis',
      DAY_plural: '{{count}} días gratis',
      MONTH: '{{count}} mes gratis',
      MONTH_plural: '{{count}} meses gratis',
      WEEK: '{{count}} semana gratis',
      WEEK_plural: '{{count}} semanas gratis',
      YEAR: '{{count}} año gratis',
      YEAR_plural: '{{count}} años gratis',
    },
    frequencyType: {
      DAY: 'día',
      MONTH: 'mes',
      WEEK: 'semana',
      YEAR: 'año',
    },
    pay: 'Pagar',
    paymentMethod: 'Método de pago',
    plan: 'Plan',
    planTypes: {
      KAM: 'KAM',
      TECH: 'Base',
    },
    product: 'Producto',
    summary: 'Resumen',
    taxes: 'Impuestos',
    wantAdditionalPlan: '¿Deseas añadir un plan adicional?',
  },
  common: {
    accept: 'Aceptar',
    apply: 'Aplicar',
    assign: 'Asignar',
    box: 'Caja',
    boxQuantity: 'Cantidad de cajas',
    boxes: 'Cajas',
    cancel: 'Cancelar',
    cancelled: 'Cancelado',
    clean: 'Limpiar',
    client: 'Cliente',
    close: 'Cerrar',
    confirm: 'Confirmar',
    connect: 'Conectar',
    connectMarket: 'Conectar marketplace',
    connected: 'Conectado',
    content: 'Contenido',
    countries: {
      CA: 'Canadá',
      CL: 'Chile',
      CO: 'Colombia',
      MX: 'México',
      US: 'Estados Unidos',
    },
    countryOfManufacture: 'País de manufactura',
    creation: 'Creación',
    currency: 'Moneda',
    date: 'Fecha',
    defaultErrorMessage:
      'Ha ocurrido un error inesperado. Inténtalo más tarde.',
    delivered: 'Entregado',
    description: 'Descripción',
    destination: 'Destino',
    details: 'Detalles',
    discount: 'Descuento',
    download: 'Descargar',
    dropOff: 'Entrega en oficina',
    error: 'Error',
    estimatedDateOfDelivery: 'Fecha estimada de entrega',
    expirationDate: 'Fecha de expiración',
    failedRequest: 'No se pudo completar la petición.',
    filters: 'Filtros',
    harmonizedCode: 'Código arancelario',
    height: 'Alto',
    inTransit: 'En tránsito',
    information: 'Información',
    items: 'Elementos',
    labels: 'Etiquetas',
    length: 'Largo',
    listing: 'Listing',
    listing_plural: 'Listings',
    load: 'Cargar',
    name: 'Nombre',
    next: 'Siguiente',
    no: 'No',
    notes: 'Notas',
    ok: 'Entendido',
    option: 'Opción',
    order: 'Pedido',
    origin: 'Origen',
    parent: 'Padre',
    parent_plural: 'Padres',
    payment: 'Pago',
    periodicity: {
      day: 'Diario',
      month: 'Mensual',
      week: 'Semanal',
      year: 'Anual',
    },
    preparation: 'Por preparar',
    price: 'Precio',
    print: 'Imprimir',
    product: 'Producto',
    product_plural: 'Productos',
    quantity: 'Cantidad',
    query: 'Consulta',
    recommended: 'Recomendado',
    remove: 'Eliminar',
    required: 'Requerido',
    reset: 'Reiniciar',
    return: 'Volver',
    saleOrder: 'Orden de venta',
    save: 'Guardar',
    search: 'Buscar',
    seeMore: 'VER MÁS',
    selectedFile: 'Archivo seleccionado',
    send: 'Enviar',
    sent: 'Enviado',
    shippingPrice: 'Costo de envío',
    soon: 'Próximamente',
    status: 'Estado',
    subtotal: 'Subtotal',
    success: 'Éxito',
    successfulRequest: 'Petición completada correctamente.',
    tax: 'Impuesto',
    title: 'Título',
    total: 'Total',
    unitaryFob: 'FOB unitario',
    units: 'Unidades',
    update: 'Actualizar',
    uploadDoc: 'Subir documento',
    uploadFile: 'Subir archivo',
    viewAll: 'Ver Todo',
    wantKnowMore: 'Quiero saber más',
    weight: 'Peso',
    width: 'Ancho',
    yes: 'Sí',
  },
  dashboard: {
    button: 'Aplicar filtro',
    categories: {
      sales: 'Ventas',
      stock: 'Stock',
    },
    deleteQuery: 'La consulta se elimino correctamente.',
    fields: {
      byLastUpdateDate: 'Último cambio de estado',
      byPurchaseDate: 'Fecha de compra',
      category: 'Performance',
      compare: 'Comparar',
      dateRangeOne: 'Seleccione el período',
      dateRangeTwo: 'Comparar con',
      expandable: 'Expandir',
      marketplace: 'Marketplace',
      marketplace_plural: 'Marketplaces',
      partner: 'Vendedor',
      period: 'Período',
      sku: 'SKU',
      timezone: 'Zona horaria',
      type: 'Por tipo',
    },
    filter: {
      currentMonth: 'Mes actual',
      currentWeek: 'Semana actual',
      currentYear: 'Año actual',
      custom: 'Personalizado',
      last12months: 'Últimos 12 meses',
      last30days: 'Últimos 30 días',
      last7days: 'Últimos 7 días',
      lastMonth: 'Mes Anterior',
    },
    marketplaces: {
      Amazon: 'Amazon US',
      AmazonVendor: 'Amazon Vendor',
      AmazonBelgium: 'Amazon BE',
      AmazonBrazil: 'Amazon BR',
      AmazonCanada: 'Amazon CA',
      AmazonEgypt: 'Amazon EG',
      AmazonFrance: 'Amazon FR',
      AmazonGermany: 'Amazon DE',
      AmazonIndia: 'Amazon IN',
      AmazonItaly: 'Amazon IT',
      AmazonMexico: 'Amazon MX',
      AmazonNetherlands: 'Amazon NL',
      AmazonPoland: 'Amazon PL',
      AmazonSaudiArabia: 'Amazon SA',
      AmazonSpain: 'Amazon ES',
      AmazonSweden: 'Amazon SE',
      AmazonTurkey: 'Amazon TR',
      AmazonUK: 'Amazon UK',
      'AmazonUnited Arab Emirates': 'Amazon AE',
      Coppel: 'Coppel',
      Dafiti: 'Dafiti',
      DafitiArgentina: 'Dafiti AR',
      DafitiBrazil: 'Dafiti BR',
      DafitiChile: 'Dafiti CL',
      DafitiColombia: 'Dafiti CO',
      Ebay: 'Ebay US',
      EbayCanada: 'Ebay CA',
      EbayGermany: 'Ebay DE',
      EbaySpain: 'Ebay ES',
      Edicommerce: 'Walmart DSV MX',
      EdicommerceMexico: 'Walmart DSV MX',
      Falabella: 'Falabella',
      Linio: 'Linio',
      Liverpool: 'Liverpool',
      Magento: 'Magento',
      Mercadolibre: 'Mercadolibre AR',
      MercadolibreChile: 'Mercadolibre CL',
      MercadolibreColombia: 'Mercadolibre CO',
      MercadolibreMexico: 'Mercadolibre MX',
      Prestashop: 'Prestashop',
      Ripley: 'Ripley',
      Shopify: 'Shopify US',
      ShopifyBrazil: 'Shopify BR',
      ShopifyChile: 'Shopify CL',
      ShopifyColombia: 'Shopify CO',
      ShopifyMexico: 'Shopify MX',
      ShopifyPeru: 'Shopify PE',
      'ShopifyUnited States': 'Shopify US',
      Walmart: 'Walmart US',
      WalmartMexico: 'Walmart MX',
      Woocommerce: 'Woocommerce',
    },
    messages: {
      noData: 'No hay Marketplaces sincronizados. Debe sincronizar un seller.',
      noDataAds: 'No hay datos para mostrar, inténtelo más tarde.',
    },
    required: {
      rangeOne: 'Por favor, ingresar un rango de fecha.',
      rangeTwo: 'Por favor, ingresar un rango de fecha.',
    },
    saveToLocalStorageOk: 'La consulta se guardo correctamente.',
    table: {
      price: {
        title: 'Precio',
        tooltip: 'Precio de venta actual publicado.',
      },
      soldUnits: {
        title: 'Unidades Vendidas',
        tooltip: 'Total de unidades vendidas en período seleccionado.',
      },
      totalOrders: {
        title: 'Pedidos Totales',
        tooltip: 'Cantidad de pedidos realizados en el período.',
      },
      totalSales: {
        title: 'Ventas Totales',
        tooltip:
          'Total de ventas en períodos seleccionados (expresado en USD).',
      },
    },
  },
  description: {
    part1: 'To get started, edit <1>src/App.js</1> and save to reload.',
    part2: 'Switch language between english and german using buttons above.',
  },
  documents: {
    add: 'Agregar',
    confirmDelete: 'Está seguro que desea eliminar este documento?',
    edit: 'Editar',
    emptyFields: 'Debe completar todos los campos.',
    link: 'Link',
    linkFormatError: 'El link ingresado debe ser válido.',
    modalTitle: 'Videos y Documentos',
    pin: 'Destacar',
    pinnedSuccessfully: 'Destacado correctamente.',
    remove: 'Eliminar',
    removedSuccessfully: 'Eliminado correctamente.',
    save: 'Guardar',
    savedSuccessfully: 'Guardado correctamente.',
    textTitle: 'Documentos',
    textTitleUrl: 'Direcciones URL',
    title: 'Título',
    titleLengthError: 'El título debe tener al menos 5 caracteres.',
    unpin: 'No destacar',
    unpinnedSuccessfully: 'Destacado eliminado correctamente.',
    videoTitle: 'Videos Tutoriales',
  },
  download: {
    csv: 'Descargar CSV',
    csvError: 'No se pudo descargar el archivo CSV',
    pdf: 'Descargar PDF',
    pdfError: 'No se pudo descargar el archivo PDF',
  },
  externalShipment: {
    deliveryOffice: {
      reminder: 'Recuerde ir a entregar el pedido a oficina de Fedex',
    },
    pickUp: {
      required: 'Se necesita fecha y hora de pick up',
      pickupState: 'Estado de pickup',
    },
    step1Title: 'Completa los detalles de envío',
    step2Title: 'Elige la configuración del empaquetado y la carga',
    step3Title: 'Elige un sistema de unidades de medida',
    step4Title:
      'Selecciona los productos y cantidades que quieres incluir en el envío',
    step5Title: 'Define las medidas y peso total de cada caja',
    step5TitlePalletized: 'Define las medidas y peso total de cada pallet',
    step6Title: 'Paso 6: Servicio y costo de envío',
    step7Title: 'Paso 7: Elige la fecha y hora de pick up',
  },
  fedexError: {
    cancelPickUp: {
      default: {
        text: 'Tu envío no puede ser completado debido a un problema con tu cuenta de FedEx. Por favor, contáctanos a través del chat de soporte para que podamos brindarte la asistencia necesaria y resolver este problema',
        title: 'Error cuenta FedEx',
      },
      fedexDown: {
        text: 'El servicio de FedEx se encuentra temporalmente fuera de servicio. Te recomendamos intentarlo nuevamente más tarde',
        title: 'Error FedEx',
      },
    },
    createCourierShipments: {
      default: {
        text: 'Por favor, contáctanos a través del chat de soporte para que podamos brindarte la asistencia necesaria y resolver este problema',
        title: 'Error al crear el envío',
      },
      invalidHarmonizedCode: {
        text: 'El código arancelario proporcionado no es válido',
        title: 'Código arancelario inválido',
      },
      invalidRecipientStateProvinceCode: {
        text: 'El código de estado o provincia del destinatario no es válido',
        title: 'Código de estado o provincia inválido',
      },
      suspendedAccount: {
        text: 'No se ha podido crear el envío. Por favor contactarse directamente con FedEx. Esta suspensión puede deberse a una deuda o un problema particular con FedEx.',
        title: 'Cuenta Fedex suspendida',
      },
      invalidCommodityWeight: {
        text: 'El peso de uno o más productos es inválido. Por favor verifica que el peso sea correcto.',
        title: 'Peso inválido',
      },
    },
    createPickUp: {
      default: {
        text: 'Tu envío no puede ser completado debido a un problema con tu cuenta de FedEx. Por favor, contáctanos a través del chat de soporte para que podamos brindarte la asistencia necesaria y resolver este problema',
        title: 'Error cuenta FedEx',
      },
      fedexDown: {
        text: 'El servicio de FedEx se encuentra temporalmente fuera de servicio. Te recomendamos intentarlo nuevamente más tarde',
        title: 'Error FedEx',
      },
      invalidDate: {
        text: 'FedEx no cuenta con disponibilidad para realizar el pick up en el horario que has seleccionado. Te recomendamos seleccionar un horario posterior',
        title: 'Fecha y horario no disponible',
      },
      missWeight: {
        text: 'Tu producto no tiene información de peso y medidas en el marketplace seleccionado. Es necesario que actualices estos valores para poder completar el envío, ya que son requeridos por FedEx',
        title: 'Valor de peso inválido o faltante',
      },
      invalidDay: {
        text: 'FedEx no puede agendar un pick up en el día que has seleccionado. Por favor vuelve a intentarlo para otro día.',
        title: 'Fecha no disponible',
      },
    },
    uploadDocument: {
      default: {
        text: 'Ha ocurrido un error al subir su documento.',
        title: 'Error de subida de documento',
      },
      cutOffTimeReached: {
        text: 'Se ha alcanzado el tiempo límite para la carga de documentos de este envío. La carga de su documento no ha sido procesada. Por favor, imprima sus documentos y adjúntelos a su envío.',
        title: 'Error de subida de documento',
      },
    },
  },
  fedexOptions: {
    serviceType: 'Tipo de Envío',
    totalFedExCost: 'Costo total FedEx',
    totalLAPCost: 'Costo total LAP',
  },
  forgotPassword: {
    buttons: {
      confirm: 'Confirmar',
      sendConfirmationCode: 'Enviar código de recuperación',
    },
    codeNotReceived: '¿No recibiste el código?',
    confirmPassword: {
      nonEmpty: 'Ingrese su contraseña nuevamente.',
      notEqual: 'Las contraseñas ingresadas no son iguales.',
    },
    formLabels: {
      confirmPassword: 'Confirmar contraseña',
      enterCode: 'Ingresa el código',
      newPassword: 'Nueva contraseña',
      userEmail: 'Correo de usuario',
    },
    notifications: {
      codeSent: 'Se ha enviado un código de verificación a su correo.',
      error:
        'Ha ocurrido un error al intentar recuperar la contraseña. Por favor intente nuevamente.',
      passwordChangeSuccess:
        'La contraseña ha sido restablecida correctamente.',
    },
    passwordValidations: {
      lowerCase: 'La contraseña debe tener al menos 1 minúscula.',
      minLength: 'La contraseña debe tener un largo mínimo de 8 caracteres.',
      nonEmpty: 'Ingrese su contraseña.',
      number: 'La contraseña debe tener al menos 1 número.',
      specialCharacter:
        'La contraseña debe tener al menos 1 caracter especial.',
      upperCase: 'La contraseña debe tener al menos 1 mayúscula.',
    },
    resendCode: ' Reenviar código',
    title: 'Recupera tu contraseña',
    validateCode: 'Debe ingresar el código enviado a su correo.',
    validateEmail: 'Ingresa el correo del usuario.',
  },
  fulfillmentError: {
    changeFulfillment: {
      default: {
        text: 'Hubo un error al cambiar la logística del producto',
        title: 'Error al cambiar logística',
      },
      nonExistingProduct: {
        text: 'No existe el producto en el centro de fulfillment seleccionado',
        title: 'Producto no existente',
      },
    },
  },
  home: {
    charts: {
      breakdownByMarketplace: 'Desglose por marketplace',
      breakdownBySku: 'Desglose por SKU',
      dailyView: 'Vista diaria',
      emptyCountryData:
        'No existen datos disponibles para el país seleccionado.',
      lastPeriod: 'Período anterior',
      location: 'Localización',
      monthlyView: 'Vista mensual',
      pendingOrders: {
        title: 'Pedidos pendientes',
        tooltip:
          'Total de pedidos que han sido efectuados, pero que aún no se ha verificado el pago. En algunos casos, los procesos de verificación del pago y del pedido podrían tardar hasta 21 días en completarse.',
      },
      soldUnits: {
        title: 'Unidades vendidas',
        tooltip:
          'Suma del total de unidades vendidas en todos los marketplaces seleccionados durante el período de tiempo seleccionado. Incluye las unidades de los pedidos completados y de los pedidos pendientes.',
      },
      soldUnitsBySku: 'Unidades vendidas por SKU',
      tablePerState: {
        orders: {
          title: 'Pedidos',
          tooltip: 'Cantidad de pedidos realizados por estado o provincia.',
        },
        percentage: {
          title: 'Porcentaje',
          tooltip: 'Porcentaje total de ordenes para cada estado o provincia',
        },
        stateOrProvince: {
          title: 'Estado',
          tooltip: 'Estado o Provincia',
        },
      },
      topFiveSales: 'Top 5 ventas',
      totalOrders: {
        title: 'Pedidos completados',
        tooltip:
          'Total de pedidos que han sido efectuados, pero que aún no se ha verificado el pago. En algunos casos, los procesos de verificación del pago y del pedido podrían tardar hasta 21 días en completarse.',
      },
      totalSales: {
        completedSales: 'Ventas completadas',
        pendingSales: 'Ventas pendientes',
        title: 'Ventas totales',
        tooltip:
          'Suma del total de ventas de todos los marketplaces seleccionados durante el período de tiempo seleccionado (expresado en USD).',
      },
      totalSalesByMarketplace: 'Ventas totales por Marketplace',
      totalSalesBySku: 'Ventas totales por SKU',
      totalUnits: 'Unidades totales',
    },
    fulfillmentModal: {
      alert:
        'Debe crear el cumplimiento de manera manual debido a los siguientes errores ocurridos al intentar de manera automática.',
      cancelError: 'Ha ocurrido un error al cancelar la orden de envío.',
      cancelled: 'Fulfillment cancelado',
      createErrors: [
        'No se pudo crear la orden de envío. Debes actualizar tu dirección de origen.',
        'No se pudo crear la orden de envío. Ya existe una orden para este pedido.',
      ],
      downloadLabels: 'Descargar etiquetas',
      fulfillmentToFBA: 'Fulfillment enviado a FBA',
      orderedQuantity: 'Cantidad ordenada',
      readyToShip: 'Listo para enviar',
      salesChannel: 'Canal de venta',
      selectSellerSku: 'Seleccione el SKU correcto para FBA',
      shipmentAddress: {
        address1: 'Dirección 1',
        address2: 'Dirección 2',
        address3: 'Dirección 3',
        city: 'Ciudad',
        countryCode: 'Código de país',
        phone: 'Teléfono',
        postalCode: 'Código postal',
        stateOrProvince: 'Estado o provincia',
        title: 'Dirección de envío',
      },
      shipmentOrder: {
        cancel: 'Cancelar orden de envío',
        generate: 'Generar orden de envío',
      },
      shipmentPreference: 'Preferencia de envío',
      skuFBA:
        'El SKU que se encuentra en FBA para el item solicitado en la orden de venta. Sabemos que pueden ser diferentes los SKUs entre los marketplaces.',
      soldTo: 'Vendido a',
      successfulSave: 'Datos almacenados correctamente.',
      successfulSync: 'Sincronización de stock FBA con éxito',
      successfulUpdate: 'Datos actualizados correctamente.',
      updatedFulfillment: 'Fulfillment actualizado',
    },
    fullProfile: 'Perfil completo',
    greetSubtitle:
      '¡Gestiona los principales canales digitales del mundo desde un solo lugar!',
    incompleteProfile: 'Perfil incompleto',
    inventory: {
      errorDownloadFile: 'No se pudo descargar el archivo excel del inventario',
      errorUploadFile: 'No se ha podido cargar el archivo excel de inventario',
      successUploadFile:
        'Se ha subido el archivo de inventario correctamente. Su archivo será procesado y la disponibilidad de stock actualizada estará disponible más tarde.',
    },
    listing: {
      actions: 'ACCIONES',
      add: 'Listing',
      category: 'CATEGORIA',
      confirmUpdateCanPublish: 'Está seguro que desea cambiar dicho permiso?',
      edit: 'Editar',
      error: 'Error al obtener los listings desde los marketplaces',
      export: 'Exportar',
      generateOrder: 'Generar orden',
      homologateListing: 'Homologar',
      homologateListingConfirm:
        '¿Está seguro que desea homologar los listings seleccionados?',
      homologateMarketplaces: 'Seleccione',
      image: 'IMAGEN',
      marketplaceState: 'ESTADO MARKETPLACE',
      modalFBM: {
        ebayWarning:
          'Recuerde que eBay suele limitar las cantidades en el inventario. Recomendamos validar su cuenta con el administrador de LAP.',
        marketplaces: 'MARKETPLACES',
        marketplacesDescription:
          'El sku actual tiene presencia en los siguientes marketplaces. Seleccione en cuál/les desea aplicar la actualización',
        syncStock: {
          success: 'Sincronización de stock con FBM de manera exitosa',
          warning: 'Seguro que desea actualizar stock?',
        },
      },
      name: 'NOMBRE',
      new: 'New Listing',
      onboarding: 'ONBOARDING',
      orders: 'ORDENES',
      price: 'PRECIO',
      salesChannels: 'CANALES DE VENTA',
      sku: 'SKU',
      state: 'ESTADO LISTING',
      stock: 'STOCK',
      subTitle: 'Tus publicaciones activas en marketplaces',
      successfulLogistics: 'Logística actualizada',
      successfulPermissions: 'Permiso actualizados',
      successfulShipping: 'Envíos actualizados',
      syncListings: 'Listings',
      syncStockFBA: 'Stock',
      title: 'LISTADO POR GRUPO',
      tooltipCanAutofulfillmentFBA:
        'Gestionar automáticamente el fulfillment outbound con FBA?',
      tooltipCanPublish: 'El cliente puede sincronizar su seller y publicar?',
      tooltipHomologateListing: 'Copiar listing en marketplaces seleccionados',
      tooltipSyncListings: 'Obtiene listings desde los marketplaces',
      tooltipSyncStockFBA:
        'Sincroniza con el inventario de FBA y Mercado Libre',
      tooltipUnlinkMatchesFBA: 'Desvincular matcheos para FBA',
      unlinkMatchesFBA: 'Unlink',
      unsuccessfulLogistics:
        'Error al actualizar la logística del marketplace.',
      unsuccessfulPermissions: 'Error al actualizar los permisos del partner',
      unsuccessfulShipping: 'Error al actualizar los envíos del marketplace.',
    },
    newListing: 'Nuevo listing',
    onboarding: {
      steps: [
        'Conectar marketplace y publicidad',
        'Conectar courier',
        'Publicar productos',
        'Primer envío',
        'Visualización de rendimiento',
      ],
      subtitle:
        'Haz click en ellas para visualizar los pasos que necesitas seguir',
      title:
        'Completa estas 5 para expandir tu negocio a un nivel internacional',
      warning: {
        description:
          'Una vez cerrado solo podrás recuperarlo solicitándolo por el chat de soporte',
        title: '¿Estás seguro que deseas desactivar el paso a paso?',
      },
    },
    'sale-order': {
      confirmCancel: 'Está seguro que desea cancelar?',
      subTitle: 'Estado de tus Pedidos en todos los Marketplaces',
      title: 'Pedidos',
    },
    viewStock: 'Ver stock',
    welcome: 'Bienvenido a LAP',
  },
  homologation: {
    attributes: {
      add: 'Agregar atributo',
    },
    categories: {
      destination: 'Destino',
      marketplaceOrigin: 'Marketplace de ',
      origin: 'Origen',
      reset: 'Limpiar',
      submit: 'Homologar',
      submitSuccess: 'Categoría homologada con éxito',
      title: 'Homologación de categorías',
    },
  },
  imports: {
    fields: {
      backToCatalogue: 'Volver a catálogo',
      uploadFile: 'Subir archivo',
    },
    generic: 'Genérico',
    mainSubtitle:
      'En esta sección, puedes visualizar el estado de carga de tus productos en los distintos  marketplaces. Para que queden correctamente publicados debes corregir todos los errores especificados en la columna “Estado” y volver a subir la planilla.',
    mainTitle: 'Estado de cargas',
    table: {
      createdAt: {
        emptyValue: 'Sin fecha de creación',
        title: 'Fecha de creación',
      },
      fileName: {
        emptyValue: 'Sin nombre',
        title: 'Nombre archivo',
      },
      status: {
        completed: 'Completado',
        processing: 'Procesando',
        publishing: 'Publicando',
        title: 'Estado',
        warning: 'Advertencia',
      },
      updateExcel: 'Actualizar planilla',
      updatedAt: {
        emptyValue: 'Sin fecha de última actualización',
        title: 'Fecha de última actualización',
      },
    },
    uploadError: 'Ha ocurrido un error al subir el catálogo',
  },
  inventory: {
    actions: {
      syncInventory: 'Sincronizar inventario',
    },
    sync: {
      modal: {
        message:
          'El stock de inventario de los marketplaces secundarios se actualizará de acuerdo al del marketplace principal.',
        okButton: 'Sincronizar',
        primaryMarketplace: 'Marketplace principal',
        primaryMarketplacePlaceholder: 'Selecciona un marketplace',
        removeButton: 'Eliminar',
        removeError:
          'Ha ocurrido un error al eliminar los grupos de inventarios.',
        removeOk: 'Se eliminaron los grupos de inventario con éxito.',
        secondaryMarketplaces: 'Marketplaces secundarios',
        secondaryMarketplacesPlaceholder: 'Selecciona uno o más marketplaces',
        sendError:
          'Ha ocurrido un error al sincronizar el inventario. Inténtelo nuevamente.',
        sendOk: 'La sincronización de inventario fue realizada con éxito.',
        title: 'Sincronización de inventario',
      },
    },
    table: {
      headers: {
        availableStock: 'Stock disponible',
        fulfillment: 'Logística',
        last30days: 'Últimos 30 días',
        last90days: 'Últimos 90 días',
        product: 'Producto',
        shipping: 'Envíos',
        soldUnits: 'Unidades vendidas',
        totalStock: 'Stock total',
      },
      tooltips: {
        availableStock:
          'Número total de unidades disponibles para la venta ahora mismo.',
        fulfillment: 'Centro de logística.',
        last30days:
          'Suma del total de unidades vendidas en el marketplace seleccionado durante los últimos 30 días.',
        last90days:
          'Suma del total de unidades vendidas en el marketplace seleccionado durante los últimos 90 días.',
        shipping: ' Centro de envíos',
        totalStock:
          'Número total de unidades. Corresponde a la suma de las unidades disponibles, unidades que están siendo trasladadas y unidades extraviadas o dañadas.',
      },
    },
  },
  isRequired: 'es requerido!',
  joyride: {
    back: 'Atrás',
    end: 'Terminar tutorial',
    next: 'Siguiente',
  },
  listings: {
    fields: {
      allMarketplaces: 'Todos los marketplaces',
      allSkus: 'Todos los productos',
      allStatus: 'Todos los estados',
      marketplace: 'Marketplace',
      marketplacePlaceholder: 'Marketplace seleccionado',
      sku: 'SKU',
      skuPlaceholder: 'SKU seleccionado',
      status: 'Estado',
      statusPlaceholder: 'Estado seleccionado',
      title: 'Título',
      titlePlaceholder: 'Ingrese un título',
    },
  },
  login: {
    IncorrectEmailOrPassword: 'El correo y/o la contraseña están incorrectas.',
    changePassword: 'Genera tu nueva contraseña',
    createAccount: 'Crear cuenta',
    email: 'Email',
    emailEmpty: 'Debe ingresar un email',
    emailPlaceholder: 'colaborador@lapmail.com',
    emailSent: 'Email enviado con éxito.',
    enter: 'Iniciar sesión',
    formHeader: [
      '¡Bienvenido!',
      {
        lapAcademy: 'Ingresa a LAP Academy con tu cuenta',
        normal: 'Ingresa tus datos para iniciar',
      },
    ],
    header: [
      'Transformando vendedores locales en',
      'negocios globales',
      '¡Gestiona los principales canales digitales del mundo desde un solo lugar!',
    ],
    invalidEmail: 'El mail ingresado no es válido.',
    loginError: 'Error de inicio de sesión',
    loginLink: 'Ya tengo una cuenta. Iniciar sesión',
    password: 'Contraseña',
    passwordChanged: 'La contraseña ha sido cambiada con éxito',
    passwordEmpty: 'Debe ingresar una contraseña',
    passwordLengthError:
      'La contraseña debe tener un largo mínimo de 8 caracteres',
    passwordLowerCaseError: 'La contraseña debe tener al menos 1 minúscula',
    passwordNumber: 'La contraseña debe poseer al menos un número',
    passwordNumberError: 'La contraseña debe tener al menos 1 número',
    passwordPlaceholder: 'Contraseña',
    passwordSpecialCharError:
      'La contraseña debe tener al menos 1 caracter especial',
    passwordUpperCaseError: 'La contraseña debe tener al menos 1 mayúscula',
    passwordsDoesntMatch: 'Las contraseñas deben coincidir',
    passwordsMinLength: 'La contraseña debe poseer al menos 5 caracteres',
    recoverPassword: '¿Olvidaste la contraseña?',
    recoverPasswordTitle: 'Solicitar cambio de contraseña',
    rememberPassword: 'Recordar contraseña',
    send: 'Enviar',
    signUp: '¿Aún no tienes una cuenta? ¡Regístrate!',
    tipPassword: 'Tu contraseña debe tener un mínimo de 5 caracteres.',
    tryAgain: 'Por favor, inténtalo de nuevo.',
    usernameEmpty: 'Debe ingresar un nombre de contacto',
  },
  logistics: {
    tooltip: {
      FBA: 'Disponible en USA, Canadá, México y Europa.',
      FedExCB: 'Para envios internacionales.',
      FedExLocal: 'Para envíos locales dentro de Estados Unidos.',
      Fulfill99: 'Disponible en Chile y México',
      Melonn: 'Disponible en México y Colombia',
      couriers:
        'Un courier es una empresa que entrega paquetes y documentos de forma rápida y segura a nivel local o internacional, ofreciendo seguimiento y logística completa.',
      eDarkstore: 'Disponible para Walmart DSV México ',
      fulfillment:
        'Los vendedores o sellers envían sus productos a un centro de almacenamiento y distribución en el país de destino. Allí se almacenan hasta que se genera una orden de compra y luego se envía al cliente final.',
      minutos99:
        'Para envíos locales dentro de Chile, México, Colombia o Perú.',
    },
  },
  marketing: {
    indexGraphics: {
      acos: {
        title: 'ACOS',
        tooltip:
          'Advertising Cost Of Sales = (Inversión/Ventas por publicidad). Es el porcentaje entre lo que inviertes en publicidad y los ingresos que generas con ella.',
      },
      adsPercentage:
        'Advertising percentage = (Ventas por publicidad / Ventas totales). Corresponde al porcentaje de tus ventas que se concretaron gracias a que el usuario hizo click en un anuncio de publicidad.',
      clicks: {
        title: 'Clicks',
        tooltip:
          'Suma de la cantidad de veces que los usuarios hicieron click en tus anuncios en el marketplace seleccionado durante el período de tiempo seleccionado.',
      },
      cpc: {
        title: 'CPC',
        tooltip: 'Cost per Click = Costo promedio de cada click.',
      },
      ctr: {
        title: 'CTR',
        tooltip:
          'Click Through Rate = (Clicks / Impresiones. Corresponde al porcentaje de visualizaciones que lograron un click.',
      },
      cvr: {
        title: 'CVR',
        tooltip:
          'Conversion Rate = (Unidades por publicidad / Clicks). Corresponde al porcentaje de clicks que lograron una venta.',
      },
      impressions: {
        title: 'Impresiones',
        tooltip:
          'Suma del total de visualizaciones que tuvieron tus anuncios en el marketplace seleccionado durante el período de tiempo seleccionado.',
      },
      investment: {
        title: 'Inversión',
        tooltip:
          'Monto total de inversión en el marketplace seleccionado durante el período de tiempo seleccionado. Es la suma del costo de los clicks de tus anuncios de publicidad.',
      },
      organicSales: {
        title: 'Ventas orgánicas',
        tooltip:
          'Suma del total de ventas que se concretaron gracias a que el cliente hizo click en un producto que encontró en el buscador de manera orgánica (sin publicidad).',
      },
      organicUnits: {
        title: 'Uds. orgánicas',
        tooltip:
          'Suma del total de unidades vendidas gracias a que el cliente hizo click en un producto que encontró en el buscador de manera orgánica (sin publicidad).',
      },
      salesPerAd: {
        title: 'Ventas por publicidad',
        tooltip:
          'Suma del total de ventas que se concretaron gracias a que el usuario hizo click en un anuncio de publicidad de tu producto.',
      },
      soldUnits: {
        title: 'Unidades vendidas',
        tooltip:
          'Suma del total de unidades vendidas en el marketplaces seleccionado durante el período de tiempo seleccionado. Es la suma de las unidades por publicidad y las unidades orgánicas.',
      },
      tacos:
        'Total Advertising Cost Of Sales = (Inversión / Ventas totales). Es el porcentaje entre lo que inviertes en publicidad y tus ingresos totales.',
      totalSales: {
        title: 'Ventas totales',
        tooltip:
          'Suma del total de ventas del marketplace seleccionado durante el período de tiempo seleccionado (expresado en USD). Es la suma de las ventas por publicidad y las ventas orgánicas.',
      },
      unitsPerAd: {
        title: 'Uds. por publicidad',
        tooltip:
          'Suma del total de unidades vendidas gracias a que el cliente hizo click en un anuncio de publicidad de tu producto.',
      },
    },
    msjSync: 'Debe sincronizar su cuenta de Marketing',
    msjWaitingDataInitial:
      'No existen datos disponibles en este momento, por favor, intente en 10min',
    notConnected: {
      button: 'Conectar publicidad',
      description:
        'Conecta la publicidad en la sección de marketplaces para ver el desempeño de tus campañas.',
      title: 'Aún no has conectado publicidad',
    },
    performanceByCampaignTable: {
      campaignNameTooltip: [
        'Este es el nombre que se le asignó a la campaña cuando fue creada en el marketplace.',
        'Te recomendamos nombrarlas siguiendo siempre un mismo patrón para que puedas identificarlas fácilmente.',
      ],
      headers: {
        acos: 'ACOS',
        campaignName: 'Nombre campaña',
        clicks: 'Clicks',
        cpc: 'CPC',
        ctr: 'CTR',
        cvr: 'CVR',
        impressions: 'Impresiones',
        investment: 'Inversión',
        salesPerCampaign: 'Ventas por publicidad',
        unitsPerCampaign: 'Uds. por publicidad',
      },
    },
    performanceByCampaignTitle: 'Desempeño por campaña',
    performanceByKeywordTable: {
      headers: {
        acos: 'ACOS',
        clicks: 'Clicks',
        cpc: 'CPC',
        ctr: 'CTR',
        cvr: 'CVR',
        impressions: 'Impresiones',
        investment: 'Inversión',
        keyword: 'Keyword',
        query: 'Query',
        salesPerCampaign: 'Ventas por publicidad',
        unitsPerCampaign: 'Uds. por publicidad',
      },
    },
    performanceByKeywordTitle: 'Desempeño por keyword',
    performanceBySkuTable: {
      headers: {
        acos: 'ACOS',
        adsSalesPercentage: 'Ads %',
        clicks: 'Clicks',
        cpc: 'CPC',
        ctr: 'CTR',
        cvr: 'CVR',
        impressions: 'Impresiones',
        investment: 'Inversión',
        product: 'Producto',
        salesPerCampaign: 'Ventas por publicidad',
        tacos: 'TACOS',
        totalSales: 'Ventas totales',
        totalUnits: 'Unidades vendidas',
        unitsPerCampaign: 'Uds. por publicidad',
      },
    },
    performanceBySkuTitle: 'Desempeño por SKU',
  },
  menu: {
    advertising: 'Publicidad',
    assistant: 'Asistente IA',
    calculator: 'Calculadora',
    catalogue: 'Catálogo',
    clients: 'Clientes',
    dashboards: 'Dashboards',
    helpCenter: 'Centro de ayuda',
    inventory: 'Inventario',
    lapAcademy: 'LAP Academy',
    listings: 'Listing',
    myAccount: 'Mi cuenta',
    myDocuments: 'Mis documentos',
    myMarketplaces: 'Mis marketplaces',
    redirecting: 'Redirigiendo...',
    saleOrders: 'Pedidos',
    sales: 'Ventas',
    shipments: 'Envíos',
    shopping: 'Compras',
    soon: 'pronto',
    status: 'Estado',
    summary: 'Resumen',
    support: 'Soporte',
    tooltips: {
      calculadora:
        'En esta sección puedes simular la cadena de costos de vender un nuevo producto en distintos marketplaces utilizando distintos tipos de logística.',
      inventario:
        'En esta sección puedes ver el detalle del inventario que tienes disponible en cada uno de tus marketplaces. Puedes analizar el promedio de unidades vendidas de cada uno de tus productos y planificar nuevos envíos para evitar quebrar stock.',
      publicidad:
        'En esta sección puedes ver el detalle de los resultados de las campañas de publicidad de cada uno de tus marketplaces. Puedes acceder a métricas e  indicadores clave que te ayudarán a sacar el máximo rendimiento a tu inversión.',
      resumen:
        'En esta sección puedes ver fácilmente el desempeño y las métricas más importantes de todos tus marketplaces en un solo lugar.',
      ventas:
        'En esta sección puedes ver el detalle de las ventas de cada uno de tus marketplaces. Puedes comparar tus resultados con períodos anteriores y analizar el desempeño de cada uno de tus productos.',
    },
    your_products: 'Tus productos',
    your_publications: 'Tus publicaciones',
  },
  modalAddProductVariant: {
    alertMessage: 'Variación',
    input1: 'Título',
    input10: 'Peso',
    input11: 'Sabor',
    input12: 'Precio de referencia',
    input13: 'SKU',
    input14: 'Código EAN/UPC',
    input15: 'Pack',
    input2: 'Marca',
    input3: 'Bullet Point 1',
    input4: 'Bullet Point 2',
    input5: 'Bullet Point 3',
    input6: 'Bullet Point 4',
    input7: 'Bullet Point 5',
    input8: 'Descripción',
    input9: 'Ingredientes o componentes',
  },
  myAccount: {
    accountInfo: {
      name: {
        label: 'Nombre de la marca',
        placeholder: 'NexTech Solutions',
      },
      title: 'Datos de cuenta',
      userEmail: {
        label: 'Correo de usuario',
        placeholder: 'jperez@gmail.com',
      },
      userName: {
        label: 'Nombre de usuario',
        placeholder: 'Juan Pérez',
      },
    },
    banner: {
      syncMarketplacesDescription:
        'Tiene uno o más marketplaces en proceso de sincronización, una vez completado te enviaremos una notificación.',
      syncMarketplacesTitle: 'Marketplace en sincronización.',
    },
    billingInfo: {
      address: {
        additionalAddressInfo: 'N° Oficina / Departamento / Otro',
        city: 'Ciudad o comuna',
        countryCode: {
          label: 'País de facturación',
          placeholder: 'Seleccione país',
        },
        postalCode: 'Código postal',
        stateOrProvinceCode: 'Estado o provincia',
        streetName: 'Dirección de facturación',
      },
      backToPlans: 'Volver a planes',
      contactName: {
        label: 'Nombre contacto facturación',
        placeholder: 'Juan Pérez',
      },
      email: {
        label: 'Correo contacto facturación',
        placeholder: 'jperez@gmail.com',
      },
      fantasyName: {
        label: 'Razón social de la empresa',
        placeholder: 'Sociedad Comercial ABC',
      },
      industry: {
        label: 'Giro de negocio',
        placeholder: 'Productor de alimentos',
      },
      ref: {
        label: 'R.U.T o ID Tributario',
        placeholder: '7654321-9',
      },
      title: 'Datos de facturación',
    },
    checkSP: 'Conectar',
    defaultError:
      'Ha ocurrido un error. Por favor actualice la página e intente nuevamente.',
    failedMessage: 'Revise las credenciales.',
    listMarketplaces: {
      stepByStep: 'Ver paso a paso',
    },
    save: {
      btn: 'Guardar datos',
      error: 'Ha ocurrido un error al guardar datos de la cuenta.',
      success: 'Los datos de su cuenta se han actualizado correctamente.',
    },
    sellerAccount: {
      AmazonInputs: {
        input3Description:
          'Es el ID de su cuenta en Amazon Web Service. Punto 7 del paso 2: Cree un usuario de IAM.',
        input4Description:
          'Se obtiene al descargar las claves de acceso secreta de AWS, luego de crear un usuario IAM. Punto 8 del paso 2: Cree un usuario de IAM.',
        input5Description:
          'Se obtiene al descargar las claves de acceso secreta de AWS, luego de crear un usuario IAM. Punto 8 del paso 2: Cree un usuario de IAM.',
        input6Description:
          'Es el nombre que ingresó en el punto 7 del paso 4: Cree una función/rol de IAM.',
        input7Description:
          'Se obtiene en el punto 8 del paso 4: Cree una función/rol de IAM.',
        input8Description:
          'Inicie sesión en Seller Central y haga clic en Settings > Account Information > Your seller Profile.',
        input9Description:
          'Inicie sesión en Seller Central y haga clic en Apps & Services > Develop Apps > View debajo de las credenciales de LWA para la aplicación que desee.',
      },
      EbayInputs: {
        fulfillmentPolicy: 'Política de envío',
        input3Description: 'Es el ID de su cuenta en Ebay.',
        input4Description: 'Se obtiene en ..........',
        input5Description: 'Se obtiene en ..........',
        input6Description: 'Se obtiene en ..........',
        input7Description: 'Se obtiene en ..........',
        input8Description: 'Se obtiene en ..........',
        input9Description: 'Se obtiene en ..........',
        inventoryLocationPolicy: 'Ubicación de inventario',
        loadingPolicies: 'Políticas de la cuenta',
        paymentPolicy: 'Política de pago',
        returnPolicy: 'Política de devoluciones',
      },
      WalmartInputs: {
        input3Description: 'Es el ID de su cuenta en Walmart.',
        input4Description: 'Se obtiene en ..........',
        input5Description: 'Se obtiene en ..........',
        input6Description: 'Se obtiene en ..........',
        input7Description: 'Se obtiene en ..........',
        input8Description: 'Se obtiene en ..........',
        input9Description: 'Se obtiene en ..........',
      },
      card1: 'Documentos',
      card1Button: 'Ver Documento',
      card1DescriptionAmazon:
        'Descarga nuestro manual con el paso a paso para crear tu cuenta de seller en Amazon',
      card1DescriptionEbay:
        'Descarga nuestro manual con el paso a paso para crear tu cuenta de seller en Ebay',
      card1DescriptionMeli:
        'Descarga nuestro manual con el paso a paso para crear tu cuenta de seller en Mercado Libre',
      card2: 'Agendar reunión',
      card2Description: 'Seleccionar fecha',
      card3: 'Agendar reunión',
      card3Description: 'Seleccionar fecha',
      credentials: {
        description1:
          'Abra la guía completa y comience por el siguiente paso, dentro de Seller Central.',
        description2: 'Continúe con ',
        description3:
          'Para completar los inputs de abajo, complete el siguiente paso.',
        description4:
          'Para completar los inputs de abajo, complete el siguiente paso.',
        description5: 'Luego complete el paso',
        description6: 'Finalice ésta sección con el paso.',
        description7:
          'Para completar los inputs de abajo, comience por el siguiente enlace (dentro de su Seller Central, debe llenar un formulario).',
        description8: 'Luego obtenga sus credenciales en ',
        step1: 'REGISTERING AS A DEVELOPER',
        step2: 'STEP 1. CREATE AN AWS ACCOUNT',
        step3: 'STEP 2. CREATE AN IAM USER',
        step4: 'STEP 3. CREATE AN IAM POLICY.',
        step5: 'STEP 4. CREATE AN IAM ROLE',
        step6: 'STEP 5. ADD AN SECURITY TOKEN SERVICE TO IAM USER.',
        step7: 'REGISTERING YOUR APPLICATION.',
        step8: 'SP CREDENTIALS.',
      },
      descriptionCard1:
        'Debe ingresar al enlace siguiente para visualizar la guía y completar todos los pasos: Registrarse como desarrollador, Crear y configurar políticas y entidades de IAM y Registrando su aplicación. En cada input, puede encontrar una ayuda sobre el símobolo ⓘ .',
      descriptionCard2: 'Lorem Ipsum is simply dummy text of the printing and',
      divider1: 'Account in Amazon Web Service (AWS)',
      divider2: 'Account in Seller Central (SP)',
      ebayLinkToPolicies: 'Enlace a sus políticas',
      ebayPoliciesAlert:
        'Recuerde configurar sus políticas en la región de eBay que desea crear listings.',
      falsePolicy: 'Política no configurada',
      'input1.1': 'Tengo cuenta',
      'input1.2': 'Usar cuenta del Partner',
      'input1.3': 'Seleccione una cuenta',
      'input1.4': 'Conectar mi cuenta de publicidad',
      input1Description: 'Sí',
      input2: 'Estado',
      'input2Description1.1': 'Pendiente',
      'input2Description1.2':
        'Cuando se valide tu cuenta seller, podrás comenzar a trabajar sobre tus listings.',
      'input2Description2.1': 'Cuenta activa',
      inputShopDescription: 'Favor ingrese ID de su tienda de Shopify',
      migrateListing: 'Migración',
      migrateListingInfo:
        'Debe convertir los anuncios de eBay existentes en objetos API para poder realizar la sincronización.',
      shop: 'Tienda',
      switchInfo:
        "Atención: Si seleccionas 'No' se desvinculará la cuenta y habrá que asociarla nuevamente",
      truePolicy: 'Política configurada',
    },
    successfulMessage1: 'Cuenta verificada con éxito.',
    successfulMessage2: 'Credenciales guardadas con éxito.',
  },
  navMenu: {
    couriers: 'Operadores Logísticos',
    data: 'Datos',
    logout: 'Cerrar Sesión',
    marketplaces: 'Marketplaces',
    regulation: 'Regulaciones',
    subscriptions: 'Suscripciones',
    warehouses: 'Bodegas',
  },
  no: 'No',
  notFound: {
    goBack: 'Volver a inicio',
    header: 'Página no encontrada',
  },
  notifications: {
    empty: 'No tienes nuevas notificaciones',
    message: {
      ACCOUNT_STATUS_CHANGED: [
        {
          'At risk': 'En riesgo de desactivación',
          Deactivated: 'Desactivada',
          Normal: 'Activa',
        },
        'El estado de tu cuenta en {{ marketplace }} ha cambiado de "{{ previousAccountStatus }}" a "{{ actualAccountStatus }}". Esto puede deberse a un problema con el método de pago o algún dato faltante. Por favor soluciona el problema con tu cuenta en el marketplace.',
        'Posibles causas: ',
        {
          Mercadolibre: {
            address_empty_state: 'En la dirección falta el campo "Estado".',
            address_empty_city: 'En la dirección falta el campo "Ciudad".',
            rejected_by_regulations: 'Rechazado por regulaciones.',
          },
          defaultError: 'Código de error:',
        },
      ],
      FAILED_CATALOGUE_UPLOAD:
        "No se pudieron cargar correctamente todos tus productos. Revisa los detalles en 'Estado de cargas'.",
      FAILED_SUBSCRIPTION_BILLING:
        'No se pudo completar el cobro por {{ amount }} para renovar el plan {{ plan }}. Revisa que dispones de fondos suficientes en tu tarjeta',
      FULFILLMENT_SALE_ORDER_ERROR:
        'Ocurrió un error al procesar el fulfillment en la orden de venta {{ externalOrderId }} en {{ fulfillmentCompany }}.',
      LOW_STOCK: [
        'Se detectó un bajo nivel de stock en algunos de tus productos:',
        "Recuerda reabastecer tu inventario en la sección 'Restock' o 'Inventario' para evitar quiebres de stock.",
      ],
      NEW_SALE_ORDER: [
        'Tienes nuevas órdenes de venta pendientes de procesar:',
        {
          orders: '{{ count }} orden',
          orders_plural: '{{ count }} órdenes',
        },
        "Recuerda atenderlas a la brevedad en la sección 'Pedidos', para mantener una excelente reputación como vendedor.",
      ],
      OUT_OF_STOCK: [
        'Presentas quiebre de stock en algunos de tus productos:',
        "Te recomendamos reabastecer tu inventario en la sección 'Restock' o 'Inventario' para evitar ventas perdidas.",
      ],
      SET_FBM_INITIAL_STOCK: '',
      SUCCESSFUL_CATALOGUE_UPLOAD:
        "Se han cargado tus productos exitosamente a {{ marketplace }}. Puedes visualizarlos en la sección 'Catálogo'.",
      SYNCED_MARKETPLACE:
        'Se ha completado exitosamente la sincronización de productos, inventario y pedidos en tu cuenta de {{ marketplace }}.',
      UPCOMING_SUBSCRIPTION_BILLING:
        'Tu suscripción de {{ amount }} por el plan {{ plan }} será renovada en 5 días. Recuerda disponer de fondos suficientes en tu tarjeta para no perder sus beneficios.',
    },
    title: 'Centro de Notificaciones',
    type: {
      ACCOUNT_STATUS_CHANGED: 'El estado de tu cuenta ha cambiado',
      FAILED_CATALOGUE_UPLOAD: 'Carga de productos fallida',
      FAILED_SUBSCRIPTION_BILLING: 'Facturación de suscripción fallida',
      FULFILLMENT_SALE_ORDER_ERROR: 'Error de fulfillment en orden de venta.',
      LOW_STOCK: 'Productos con bajo nivel de stock',
      NEW_SALE_ORDER: 'Nuevas órdenes de venta',
      OUT_OF_STOCK: 'Productos con quiebre de stock',
      SET_FBM_INITIAL_STOCK: 'Productos disponibles para ajustar stock inicial',
      SUCCESSFUL_CATALOGUE_UPLOAD: 'Carga de productos exitosa',
      SYNCED_MARKETPLACE: 'Marketplace sincronizado',
      UPCOMING_SUBSCRIPTION_BILLING: 'Próxima facturación de suscripción',
    },
  },
  onboarding: {
    acceptedProposal: {
      description: 'Ya puedes continuar con las siguientes etapas',
      nextStep: 'Próximo paso',
      remake: 'Reformular',
      title: 'Aceptaste la propuesta.',
    },
    add: 'Agregar',
    alertButtonClose: 'Eliminar',
    alertButtonGoBack: 'Volver',
    alertButtonSave: 'Guardar borrador',
    alertButtonSend: 'Enviar',
    alertMessage: 'Tu encargado LAP armó esta propuesta a medida.',
    confirmDelete: 'Está seguro que desea eliminar el listing?',
    confirmGoBack: 'Está seguro que desea retorceder al paso anterior?',
    draft: {
      brand: 'Marca',
      components: 'Ingredientes',
      createAnotherListing: 'Crear otro listing',
      createNewListing: 'Crear listing',
      createOrder: 'Crear orden de venta',
      createUpdateNewListing: 'Crear / Actualizar listing',
      exportExcel: 'Exportar Excel',
      height: 'Alto',
      images: 'Imágenes',
      length: 'Largo',
      measures: 'Medidas',
      modalTitle: 'Vista Previa',
      name: 'Nombre',
      preferredPrice: 'Precio ideal',
      preview: 'Vista previa',
      ready: '¡Ya está todo listo!',
      readyDescription:
        'Beber con moderación prohibida su venta a menores de 18 años.',
      sku: 'SKU',
      subtitle: 'Resumen de tu listing',
      syncOrderItems: 'Sync Order Items',
      syncOrderItemsDescription:
        'Obtener el detalle de los items de cada órden aún no sincronizada',
      title: 'Resumen',
      titleDescription: 'Vista preliminar de tu publicación en ',
      validateListing: 'Validar listing',
      weight: 'Peso',
      width: 'Ancho',
    },
    draftSavedSuccessfully: 'Borrador guardado con éxito',
    fulfillment: {
      FBA: 'FBA - Fulfillment by Amazon',
      FBADescription:
        'Los vendedores o sellers envían sus productos a un centro de almacenamiento y distribución de Amazon en el país de destino. Allí se almacenan hasta que se genera una orden de compra y luego se envía al cliente final.',
      FBL: 'FBL - Fulfillment by LAP',
      FBLDescription:
        'Los vendedores o sellers toman la responsabilidad de almacenamiento (bodega en país de destino o de origen), junto con la gestión de LAP para la distribución de los productos al cliente final.',
      FBM: 'FBM - Fulfillment by Merchant/Seller',
      FBMDescription:
        'Los vendedores o sellers toman la responsabilidad completa de logística, almacenamiento y distribución al cliente final (no se utilizan las bodegas de Amazon), ya sea utilizando bodegas en país de destino o en origen.',
      LAPComment: 'Comentario LAP',
      LAPCommentDescription: 'Comentario LAP',
      title: 'Fulfillment:',
      titleDescription:
        'Proceso de recepción, empaquetado y envío de mercancía',
      type: 'Tipo de Fulfillment',
      typeDescription:
        'Selecciona el tipo de fulfillment que se utilizará para el envío de tus productos.',
    },
    images: {
      alertMessage: 'Carga tus fotos según los lineamientos.',
      amazonFormSubtitle: '',
      descriptionPanelCollapse: '',
      draggerMessage: 'Haga clic o arrastre el archivo aquí.',
      ebayFormSubtitle: 'Lorem Ipsum is simply dummy text of the printing',
      formSubtitle:
        'Compartenos el link de Youtube de un video complementario para publicar tu listing. Es requisito que el seller tenga registro de marca (Trademark) configurado previamente',
      formTitle1: 'Imágenes',
      formTitle2: 'Opcional',
      formTitle3: 'Variaciones',
      headerPanelCollapse:
        'Estándares de todas las imágenes del producto, para el mercado seleccionado.',
      input1: 'Imágenes para Aprobación de Categoría',
      input1Description:
        'Aprobación de Categoría - hasta 10 imágenes y 2 obligatorias',
      input2: 'Imagen Principal de Listing',
      input2Description:
        'Agrega la foto principal del producto con fondo blanco.',
      input3: 'Foto complementaria del producto:',
      input3Description:
        'Agregar fotos profesionales explicativas del producto, mostrando todas sus caras, características diferenciadoras, modo de uso, etc.',
      input4: 'Foto complementaria del producto 2:',
      input4Description:
        'Agregar fotos profesionales explicativas del producto, mostrando todas sus caras, características diferenciadoras, modo de uso, etc.',
      input5: 'Foto complementaria del producto 3:',
      input5Description:
        'Agregar fotos profesionales explicativas del producto, mostrando todas sus caras, características diferenciadoras, modo de uso, etc.',
      input6: 'Video',
      input6Description: 'Video opcional cápsula de la  marca y/o producto.',
      inputTextArea: 'Comentarios LAP',
      inputTextAreaDescription:
        'Lorem Ipsum is simply dummy text of the printing and',
      subtitle: 'Fotografías profesionales de tus productos en Amazon',
      title: 'Imágenes',
      walmartFormSubtitle: 'Lorem Ipsum is simply dummy text of the printing',
    },
    measuresAndPrices: {
      LAPComment: 'Comentario LAP',
      LAPCommentDescription: 'Comentario LAP',
      costoUnitari: 'COSTO DE ENVÍO UNITARIO',
      costoUnitariItem1:
        'Costo de envío en avión o barco según la decisión que se haya tomado con el equipo',
      costoUnitariItem2: 'Se respalda de una cotización de envío',
      costsDescription: 'Costos del producto',
      costsTitle: 'Costos del producto',
      freeLap: 'FEE LAP',
      freeLapItem1: 'Comisión por venta de LAP',
      freeLapItem2:
        'Acordado y respaldado en el Términos y Condiciones firmado',
      freeLapItem3: '% aplica sobre el precio de venta',
      fulfillment: 'FULFILLMENT (FBA)',
      fulfillmentItem1:
        'Costo de Amazon por despacho desde sus bodegas al cliente final',
      fulfillmentItem2:
        'Depende de las dimensiones y peso de la unidad de venta',
      fulfillmentItem3: 'Monto fijo por unidad de venta',
      height: 'Alto',
      heightDescription: 'Alto',
      help: 'Ayuda',
      infoItem:
        'Esta cadena de precios no incluye el costo de envío asociado a la exportación de sus productos. Para mayor información puedes contactar a tu encargado de Onboarding.',
      length: 'Largo',
      lengthDescription: 'Largo',
      measureUnity: 'Unidades',
      netWeight: 'Peso Neto',
      netWeightUnity: 'Unidades',
      referral: 'REFERRAL FEE',
      referralItem1: 'Costo de Amazon por estar publicado',
      referralItem2: 'Depende de la categoría del producto',
      referralItem3: '% aplica sobre el precio de venta',
      storage: 'STORAGE',
      storageItem1:
        'Costo de Amazon por almacenar los productos en sus bodegas',
      storageItem2: 'Depende de las dimensiones de la unidad de venta',
      storageItem3: 'Monto fijo mensual por unidad de venta',
      subtitle: 'Dimensiones y Peso de la Unidad de Venta',
      subtitleDescription: ' ',
      title: 'Pricing',
      titleDescription:
        'Completar la siguiente tabla con las medidas y peso de la unidad de venta seleccionada (producto unitario o pack según corresponda)F',
      volume: 'Volumen',
      volumeDescription: 'Volumen',
      weight: 'Peso Bruto',
      weightDescription: 'Peso',
      weightUnity: 'Unidades',
      width: 'Ancho',
      widthDescription: 'Ancho',
    },
    modalVariantsButtonSave: 'Guardar',
    order: {
      btnSearchCategoriesForLAP: 'Buscar categorías',
      divider1: 'Atributos',
      formListing: {
        age_range_description: ' ',
        arch_type: ' ',
        brand: 'P&M Foods',
        bullet_point:
          '100 calorías por porción de consumo, por lo que contiene un 30% de las calorías de un muffin tradicional. Sin azúcar añadida por lo que es un producto apto para personas con diabetes. 6 gramos de proteínas y 5 gramos de fibra dietaria por porción',
        bullet_point0:
          '100 calorías por porción de consumo, por lo que contiene un 30% de las calorías de un muffin tradicional. Sin azúcar añadida por lo que es un producto apto para personas con diabetes. 6 gramos de proteínas y 5 gramos de fibra dietaria por porción',
        bullet_point1:
          'Contiene prebióticos para tener una mejor salud digestiva',
        bullet_point2:
          'Libre de gluten y lactosa, apto para celiacos e intolerantes a la lactosa',
        bullet_point3:
          'Contiene una proteína completa y de buena calidad, ya que contiene una combinación balanceada de legumbres y cereales',
        bullet_point4:
          'Combinación balanceada de ingredientes con efecto saciador, es decir, quita el hambre por horas',
        bullet_point5: ' ',
        child_parent_sku_relationship: ' ',
        color: ' ',
        condition_note: ' ',
        embellishment_feature: ' ',
        externally_assigned_product_identifier: ' ',
        fcc_radio_frequency_emission_compliance: ' ',
        fulfillment_availability: ' ',
        fur: ' ',
        generic_keyword: ' ',
        hazmat: ' ',
        height_map: ' ',
        import_designation: ' ',
        item_name:
          ' Muffin de Legumbres Sabor Chocolate, Mezcla lista para preparar de forma fácil y en minutos. Con apenas un 30% de las calorías de un muffin tradicional',
        item_type_keyword: 'Muffin Mix',
        item_type_name: ' ',
        lifestyle: ' ',
        lining_description: ' ',
        merchant_release_date: ' ',
        merchant_shipping_group: ' ',
        merchant_suggested_asin: ' ',
        model_name: ' ',
        model_number: ' ',
        occasion_type: ' ',
        pattern_type: ' ',
        pesticide_marking: ' ',
        product_description:
          'Mezcla de ingredientes en polvo, para preparar de forma fácil y en minutos muffin de legumbres sabor chocolate, con apenas un 30% de las calorías de un muffin tradicional, ideal para darse un gusto libre de culpas y sin temor a consumir cantidades excesivas de calorías.  Contiene lentejas, garbanzos y arvejas. Sin azúcar añadida, fuente de fibra dietaria y proteinas. Bajo aporte de grasas total y bajo aporte de grasa saturada. Contiene prebióticos para una mejor y salud digestiva, cacao real y sabor natural a chocolate. Libre de gluten, lactosa y huevo, apto para vegetarianos y flexitarianos. Solo se requiere agregar agua y aceite, batir y hornear. También se puede preparar en microondas para lo cual incorpora la mezcla en una taza, revuelve con una cuchara y lleva al microondas a máxima potencia por 1 minuto y 30 segundos. Lleva tu muffin de legumbres sabor chocolate como snack y disfrútalo en cualquier momento. Contiene 2 sobres de 140 gramos cada uno. Duración 12 meses a partir de la fecha de elaboración. Con el muffin de legumbres chocolate de P&M Foods, lo que tanto te gusta comer ahora te hace bien.',
        product_tax_code: ' ',
        safety_data_sheet_url: ' ',
        sole_material: ' ',
        special_feature: ' ',
        special_size_type: ' ',
        specific_uses_for_product: ' ',
        sport_type: ' ',
        style: ' ',
        subject_character: ' ',
        theme: ' ',
        toe_style: ' ',
      },
      inputCategoryForLAP:
        'Seleccionar la categoría que identifica a tu producto',
      inputCategoryForPartner:
        'Ingrese el nombre de la categoría que identifica a tu producto',
      step1Input1: 'Categoría',
      step1Input10: 'Bullet Point 3',
      step1Input10Description:
        'Atributo diferenciador del producto. Ejemplo: Características, origen, beneficio, certificaciones, forma de uso.',
      step1Input11: 'Bullet Point 4',
      step1Input11Description:
        'Atributo diferenciador del producto. Ejemplo: Características, origen, beneficio, certificaciones, forma de uso.',
      step1Input12: 'Bullet Point 5',
      step1Input12Description:
        'HIstoria de la marca, empresa, producto, elementos diferenciadores',
      step1Input13Description: 'Lorem Ipsum is simply',
      step1Input14: 'Ingredientes o componentes',
      step1Input14Description: 'Lorem Ipsum is simply',
      step1Input15: 'Peso',
      'step1Input15-1': 'Unidad',
      'step1Input15-1Description':
        'Unidad de medida del peso bruto: Gr, Kilos, Libras, ml, cm, Inches, Oz.',
      step1Input15Description:
        'Peso bruto de la unidad de venta (producto más packaging)',
      step1Input16: 'Sabor',
      step1Input16Description: 'Lorem Ipsum is simply',
      step1Input17: 'Precio de referencia',
      step1Input17Description: 'Precio de comercialización',
      step1Input18: 'SKU',
      step1Input18Description:
        'Interno del cliente en caso de aplicar o creado por LAP si viene sin completar',
      step1Input19: 'Código EAN/UPC',
      step1Input19Description:
        'Código de barras el producto. (Es obligatorio que el código esté verificado por GS1)',
      step1Input1Description:
        'Nombre interno del producto simplificado. Por ejemplo: “Botellas- Pack de 2”',
      step1Input2: 'Canal de venta',
      step1Input20: 'Pack',
      step1Input20Description:
        'Seleccionar sólo si la unidad de venta corresponde a un pack, es decir, la unidad de venta incluye más de un producto. Ejemplo: Pack de 4 unidades de mermeladas.',
      step1Input21: 'Comentarios LAP',
      step1Input21Description: 'Lorem Ipsum is simply',
      step1Input22: 'Variantes',
      step1Input22Description: 'Lorem Ipsum is simply',
      step1Input23: 'UPC',
      step1Input23Description: 'Lorem Ipsum is simply',
      step1Input2Description:
        'Seleccionar los canales a los que se quiere comercializar',
      step1Input3: 'Variantes',
      step1Input3Description:
        'Corresponde a sabores, tallas, gramajes, color, etc.',
      step1Input4: 'Referencia',
      step1Input5: 'Título',
      step1Input5Description: 'Corresponde al título del producto en Amazon',
      step1Input6Description: 'Lorem Ipsum is simply',
      step1Input7: 'Marca',
      step1Input7Description:
        'Atributo diferenciador del producto. Ejemplo: Características, origen, beneficio, certificaciones, forma de uso.',
      step1Input8: 'Bullet Point 1',
      step1Input8Description:
        'Atributo diferenciador del producto. Ejemplo: Características, origen, beneficio, certificaciones, forma de uso.',
      step1Input9: 'Bullet Point 2',
      step1Input9Description:
        'Atributo diferenciador del producto. Ejemplo: Características, origen, beneficio, certificaciones, forma de uso.',
      step1Variant1: 'Es un producto único',
      step1Variant2: 'Tiene variantes',
      title: 'Listing',
      titleDescription: '',
    },
    pending: {
      description:
        'Tu encargado LAP analizará la información y te enviará una propuesta a medida. Serás notificado vía e-mail para validar o reformular.',
      title: 'Ahora es nuestro turno',
    },
    price: {
      actions: 'ACCIONES',
      add: 'Agregar',
      btnSearchCategoriesForLAP: 'Buscar categorías',
      clientFile: 'Archivo para el cliente',
      clientFileDescription: 'Client file description',
      clientFilePlaceholder: 'URL del archivo',
      competitors: 'Estudio de competidores',
      competitorsDescription: 'Competitors description',
      delete: 'Eliminar',
      groupTitle: 'Nombre de Referencia del Producto',
      groupTitleDescription:
        'Escriba el nombre general de la familia de productos. No considerar características específicas de variaciones. Por ejemplo, si son barritas de cereal de sabores, escribir sólo “barritas de cereal”',
      inputCategoryForLAP:
        'Seleccionar la categoría que identifica a tu producto',
      inputCategoryForPartner:
        'Ingrese el nombre de la categoría que identifica a tu producto',
      loadingProductType: 'Verificando el Product Type',
      name: 'TITULO',
      price: 'PRECIO DE VENTA',
      product: 'SKU',
      productTypeSuccessMessage:
        'La categoría seleccionada está asociada a un Product Type soportado por la API de Amazon. Podrá crear el producto.',
      productTypeWarningMessage:
        'La categoría seleccionada está asociada a un Product Type no soportado, temporalmente, por la API de Amazon. No podrá crear el producto.',
      suggestedPrice: 'Precio sugerido',
      suggestedPriceDescription: 'Description',
      title: 'Definición de Productos',
      titleDescription:
        'Selecciona el marketplace al que quieres subir un nuevo producto y la categoría a la que pertenece.',
      uploadClientFile: 'Subir archivo',
    },
    proposal: {
      accept: 'Aceptar',
      remake: 'Reformular',
      title: 'Tu encargado LAP armó esta propuesta a medida.',
    },
    rejectMessage: 'Comentario',
    rejectProposal: 'Reformular propuesta',
    search: 'Buscar',
    section: 'Onboarding',
    sentSuccessfully: 'Enviado correctamente',
    sideBarItem1: 'Definición de Productos',
    sideBarItem2: 'Listing',
    sideBarItem3: 'Pricing',
    sideBarItem4: 'Imágenes',
    sideBarItem5: 'Crear borrador',
    sideBarItem6: 'Fulfillment',
    steps: {
      clickOn: 'Haz click en ',
      courier: [
        'tu nombre de usuario',
        [
          'Si aún no tienes número de cuenta, haz click en ',
          '"Ver paso a paso"',
          ' para obtenerla',
        ],
        'tu país y escribe tu número de cuenta FedEx',
        ' cuando los datos solicitados estén completos',
        '"Conectar courier"',
      ],
      dashboards: [
        [
          'En los tableros de la sección ',
          ' tenemos una vista general de distintas estadísticas de todos los marketplaces combinados',
        ],
        '  para ver el siguiente tablero',
        [
          'En ',
          ' podemos ver estadísticas de cada marketplace con más detalle',
        ],
        ' para ver el último tablero',
        ['En ', ' podemos ver el rendimiento de tus campañas de Amazon'],
        [
          'Como ayuda, en todas las secciones encontrarás estos símbolos. Si colocas el cursor sobre ellos aparecerán ',
          '"carteles de ayuda"',
          ', donde se explica qué es la información señalada',
        ],
      ],
      endTutorial: 'Terminar tutorial',
      finishedTour: 'Haz finalizado el tour',
      marketplace: [
        'tu nombre de usuario',
        'Selecciona',
        [
          'Si aún no tienes cuenta, haz click en ',
          '"Ver paso a paso"',
          ' para crearla',
        ],
        'Para conectar la publicidad haz click en ',
        'Conectar marketplace y publicidad',
      ],
      newAccount: [
        '¡BIENVENIDO A LAP!',
        'Te recomendamos seguir las 5 etapas que hemos diseñado para ayudarte a expandir tu negocio a nivel internacional.',
        'cada una de ellas para acceder al tutorial y descubrir los pasos que necesitas seguir para completarlas.',
        'SEGUIR TUTORIAL',
        'SALTAR TUTORIAL',
        'En este modal encontrarás los 6 tutoriales necesarios para usar la plataforma y comenzar a vender tus productos',
        '¡ENTENDIDO!',
      ],
      publish: [
        [
          'Para visualizar este tour primero debes ',
          'conectar un marketplace',
          'Conectar marketplace',
        ],
        [
          '"Publicar productos"',
          ' y selecciona el marketplace en el que quieres publicar',
        ],
        [
          'Lee atentamente cada uno de los pasos. Si necesitas más información, haz click en "VER MÁS"',
          'Cuando los tengas completados, marca las casillas para seguir avanzando',
          'Al marcar todas las casillas, se habilitará la sección de categoría',
        ],
        'En este paso, debes seleccionar la categoría que más se asemeje a tus productos',
        'Deberás descargar la planilla y completar toda la información solicitada',
        'Ahora, deberás subir la planilla ya completada para poder realizar la publicación',
        ['Por último, presiona ', '"Publicar"'],
        [
          '"Ver estado de cargas"',
          ' para hacer seguimiento de los productos que acabas de publicar',
        ],
        [
          'En esta tabla podrás ver cuál es el estado de carga de tus productos. La columna ',
          ' muestra la completitud del proceso o qué errores hay que resolver.',
        ],
        'Si quieres saber más o ver un video de carga de listings, puedes encontrarlo en ',
      ],
      select: 'Selecciona ',
    },
    subtitle: 'Lorem Ipsum is simply dummy text of the printing and',
    subtitle1: 'Lorem Ipsum is simply dummy text of the printing and',
    tab1: 'Tu propuesta',
    tab2: 'Propuesta LAP',
    title1: 'Pedido de listing',
  },
  option: {
    listingsCargados: 'Ya has iniciado la carga de tus Listings!',
    mustAddSeller: 'Debes sincronizar un seller',
    needSyncPermission:
      'Ya estás habilitado para comenzar con la carga de Listings',
    personalComplete: 'Datos generales completados',
    personalIncomplete: 'Completa la sección Mi Cuenta para continuar',
    sellerSync: 'Seller sincronizado',
  },
  orders: {
    alertButtonClose: 'Cancelar',
    alertButtonGoBack: 'Volver',
    alertButtonSave: 'Guardar borrador',
    alertButtonSend: 'Siguiente',
    alertMessage: 'Tu encargado LAP armó esta propuesta a medida.',
    boxes: {
      create: 'Crear caja',
      enterHeight: 'Ingrese la altura de la caja.',
      enterLength: 'Ingrese el largo de la caja.',
      enterName: 'Ingrese el nombre de la caja.',
      enterWeight: 'Ingrese el peso de la caja.',
      enterWidth: 'Ingrese el ancho de la caja.',
      error: {
        name: 'El nombre no puede contener el caracter',
        sameDimension: 'Ya existe una caja con esas dimesiones.',
      },
      exampleBoxName: 'Ej: Caja XXL',
      height: 'Alto',
      heightPlaceholder: 'Ingresa un alto',
      lengthPlaceholder: 'Ingresa un largo',
      measure: 'Medidas de caja',
      name: 'Nombre caja',
      new: 'Nueva caja',
      save: 'Guardar caja',
      width: 'Ancho',
      widthPlaceholder: 'Ingresa un ancho',
    },
    buttonAdd: 'Agregar',
    buttonSave: 'Guardar',
    buttonSearch: 'Buscar',
    confirmDelete: '¿Está seguro que desea eliminar la orden?',
    confirmGoBack: 'Está seguro que desea retorceder al paso anterior?',
    contents: {
      expirationDateTooltip:
        'No es requerido especificar fecha de expiración para este producto',
    },
    documents: {
      AMAZON_BOX_LABEL: {
        comment:
          'Instrucciones: Pegar una al costado de la caja.\nIdentificación: Revisar códigos SKU en la orden de compra. PARA 1 SKU POR CAJA\nIdentificación: Revisar numeración en la orden de compra. PARA +1 SKU POR CAJA',
        label: 'Etiqueta Caja Amazon',
      },
      AMAZON_PRODUCT_LABEL: {
        comment:
          'Instrucciones: Pegar una en cada producto, tapando/retirando los otros códigos de barra.\nIdentificación: Revisar códigos FNSKU en la orden de compra.',
        label: 'Etiqueta Producto Amazon',
      },
      COMMERCIAL_INVOICE: {
        comment: '',
        label: 'Commercial Invoice',
        copies: '3 copias',
      },
      FEDEX_BOX_LABEL: {
        comment:
          'Instrucciones: Pegar una al costado de la caja.\nSeleccionar una caja como principal (número 1). Solicitar una ventana adhesiva (sobre transparente) al recolector y pegarla en la caja 1 para documentos.\nCaja 1 - Pegar Etiqueta: Página 1\nCaja 1 - Ventana: Página 2, 3 y 4 + Commercial Invoice + Certificado de Origen + Prior Notice\nCaja 2 - Pegar Etiqueta: Página 5\nCaja 3 - Pegar Etiqueta: Página 6\nY así sucesivamente hasta la caja n.',
        label: 'Etiqueta Caja FedEX',
        copies: '3 copias',
      },
      ORIGIN_CERTIFICATE: {
        comment: '',
        label: 'Certificado de Origen',
        copies: '1 copia',
      },
      OTHER: {
        comment: '',
        label: 'Otro',
      },
      PRIOR_NOTICE: {
        comment: '',
        label: 'Prior Notice',
        copies: '3 copias',
      },
      COMMERCIAL_INVOICE_INCLUDED:
        'El commercial invoice viene incluido en la descarga de las etiquetas de FedEx.',
      document_uploaded: 'Ya subiste este documento.',
      commercial_invoice_information_box:
        'Es necesario que subas el Commercial Invoice para este origen/destino una vez creado el envío.',
      no_electronic_upload:
        'Para este envío deberás imprimir los siguientes documentos e incluirlos en el momento de pick up o entrega en oficina:',
      error:
        'No se pudo obtener información sobre la documentación para este origen/destino. Por favor contáctanos para obtener esta información. Puedes continuar con el envío.',
    },
    draftSavedSuccessfully: 'Borrador guardado con éxito',
    filter: {
      marketplace: 'Marketplace',
      orderManagement: 'Gestión de pedido',
      period: 'Periodo',
    },
    inputSearch: 'Ver información de ',
    modalVariantsButtonSave: 'Guardar',
    newOrder: {
      boxes: {
        LAPComment: 'Comentario LAP',
        LAPCommentDescription: 'Comentario LAP',
        boxDataColumns: {
          height: 'ALTO',
          id: 'ID CAJA',
          long: 'LARGO',
          weight: 'PESO',
          width: 'ANCHO',
        },
        customsBroker:
          'Los pedidos con un FOB mayor a US $3000 requieren un agente de aduana.',
        erase: '¿Seguro que quieres eliminar esta caja?',
        errors: {
          productBoxWeight:
            'Según el peso del producto en el marketplace, el peso mínimo de esta caja debe ser de: ',
        },
        importer: {
          fda: 'Todos los productos regulados por el FDA deben contar con un importador. Actualmente se agrega automáticamente a LAP Imports por un costo de US $44.90.',
          price:
            'Los pedidos con un FOB mayor a US $2500 deben contar con un importador. Actualmente se agrega automáticamente a LAP Imports por un costo de US $44.90.',
        },
        input8: 'Notas',
        medidasTooltip: 'Maximo 25 in / 63.5 cm ',
        mixedBoxes: 'Caja mixta',
        mixedBoxesReferences: {
          FOB: 'FOB total',
          addBox: 'Agregar caja',
          box: 'Caja',
          boxes: 'Cajas',
          destination: 'Destino',
          height: 'Alto',
          length: 'Largo',
          newBox: 'Nueva caja',
          product: 'Producto',
          totalUnits: 'Unidades totales',
          unitsInOrder: 'Unidades en la orden',
          weight: 'Peso',
          width: 'Ancho',
        },
        mixedBoxesSecondSubtitle: 'Define el peso total de cada caja',
        palletBoxes: 'Carga paletizada',
        pesoTooltip: 'Maximo 50Kgs / 110Lbs',
        productDistribution: 'Distribución de los productos',
        radioGroup1: 'Tipo de envío',
        radioGroup1Checkbox1: 'Sólo un tipo de producto por caja.',
        radioGroup1Checkbox2: 'Más de un tipo de producto por caja.',
        radioGroup1Checkbox3: 'Cajas individuales separadas.',
        radioGroup1Checkbox4:
          'Cajas agrupadas sobre un pallet como un solo bulto.',
        radioGroup1Description: 'Definición de tipo de caja para el envío.',
        radioGroup2: 'Unidad de medida',
        radioGroup2Description: 'Description',
        separatedBoxes: 'Cajas separadas',
        skuPerBoxColumns: {
          amountBoxes: 'CANTIDAD DE CAJAS',
          box: 'CAJA',
          boxes: 'CAJAS',
          sku: 'SKU',
          units: 'UNIDADES DE VENTA',
          unitsPerBox: 'UNIDADES POR CAJA',
        },
        spanSkuPerBoxTable: 'TOTAL POR CAJA',
        subtitle1: 'Datos de cajas',
        subtitle2: 'SKU por caja',
        subtitle2text1: 'UNIDADES EN LA ORDEN',
        subtitle2text2: 'UNIDADES EN CAJA',
        subtitle3: 'Documentos adjuntos',
        subtitle4: 'Registros',
        subtitleDescription1: 'Definición de medidas y pesos por caja.',
        subtitleDescription2:
          'Distribución de unidades por producto y por caja.',
        subtitleDescription3: 'Description',
        subtitleDescription4: 'Description',
        success: {
          description: 'Has completado esta etapa con éxito',
          title: 'Configuración de cajas',
        },
        textAreaForNote: 'Notas de Cajas',
        textAreaForNoteDescription: 'Description',
        title: 'Cajas',
        titleDescription:
          'Configuración de las cajas y distribución de las unidades.',
        titleForm: 'Configuración de cajas',
        titleStep1: 'Elige un sistema de unidades de medida',
        titleStep2: 'Define la configuración de cajas de cada destino',
        unitsPerBox: 'Maximo 150 unidades.',
        validationFail: 'Debe agregar cajas en todos los destinos.',
        warningMessage1: 'esta opción podría resultar en un ',
        warningMessage2: 'aumento del costo de envío ',
        warningMessage3: 'en caso de que Amazon asigne más de un destino.',
      },
      changeStateModal: {
        title: 'Estado',
      },
      confirmButton: 'Confirmar',
      contents: {
        LAPComment: 'Comentario LAP',
        LAPCommentDescription: 'Comentario LAP',
        addCourier: 'Vincular nuevo Courier',
        chooseCourier: 'Selecciona un courier',
        chooseDestination: 'Selecciona un centro de fulfillment',
        chooseWarehouse: 'Selecciona una de tus bodegas',
        courier: 'Operador Logístico',
        destination: 'Destino',
        input1: 'Cuenta',
        input2: 'Tipo de transporte',
        input3: 'Cantidad de pallets',
        input3subinput1: 'Número',
        input3subinput2: 'Link',
        input4: 'Origen',
        input5: 'Destino',
        input6: 'Costo de envío de la orden en Dólares',
        input7: 'Notas de transporte y costos de envío',
        input8: 'Notas',
        nameShipment: 'Nombre del envío',
        newWarehouse: 'Nueva Bodega',
        origin: 'Origen',
        knownPackingInformation: '¿Se conoce la información de embalaje?',
        knownPackingInformationTooltip:
          'Si no conoces la información de embalaje, puedes dejarlo en blanco.',
        shippingDocumentCreate: 'Crear documento de transporte',
        shippingDocumentUpdate: 'Editar documento de transporte',
        subtitle1: 'Transporte y costo de envío',
        subtitle2: 'Listings',
        subtitle3: 'Documentos adjuntos',
        subtitle4: 'Registros / Notas',
        subtitleDescription1: 'Configuración de transporte.',
        subtitleDescription2: 'Definición de cantidades por producto.',
        subtitleDescription3:
          'Documentos relevantes de transporte, permisos y guías.',
        subtitleDescription4: 'Registro de eventos y observaciones.',
        success: {
          description: 'Has completado esta etapa con éxito',
          title: 'Configuración de detalle y contenido',
        },
        title: 'Detalle y contenido',
        titleCheckBox:
          'Consideraciones importantes antes de crear tu envío de restock',
        titleDescription:
          'Configuración de transporte y definición de cantidades totales.',
        titleStep1: 'Completa los detalles de envío',
        titleStep2: 'Selecciona el tipo de caja',
        titleStep3: 'Selecciona el tipo de carga',
        titleStep4:
          'Selecciona los productos y cantidades que quieres incluir en el envío',
        titleStepShipsmartInsurance: 'Información específica Shipsmart',
        uniformBoxes: 'Caja uniforme',
      },
      draftButton: 'Borrador',
      exportButton: 'Exportar',
      exportButtonBoxes: 'Exportar por caja',
      final: {
        title: 'Estado',
      },
      pickUp: {
        radioOneText:
          'Selecciona la fecha en la que harás la entrega de tus productos al courier.',
        radioOneTitle: 'Agendar pick up',
        radioTwoText:
          'Selecciona la fecha en la que harás la entrega de tus productos al courier.',
        radioTwoTitle: 'Entregar en oficina',
      },
      revision: {
        title: 'Revisión del envío',
      },
      shipment: {
        chooseCollect: 'Selecciona el modo de recolección',
        step3Subtitle1: 'Selecciona el tipo de servicio y costo de envío',
        success: {
          description: 'Has completado esta etapa con éxito',
          title: 'Opciones de envío',
        },
        title: 'Opciones de envío',
      },
      shippingButton: 'Despachar',
      state: {
        LAPComment: 'Comentario LAP',
        LAPCommentDescription: 'Comentario LAP',
        cancelByFedEX: 'Cancelar el envío en FedEX',
        confirmCancelByFedEX: '¿Seguro que desea cancelar el envío en FedEX?',
        confirmSendByFedEX: 'Está seguro que desea enviarlo mediante FedEX?',
        getByFedEX: 'Consultar el envío en FedEX',
        sendByFedEX: 'Enviar por FedEX',
        shipmentByFedEXModalTitle: 'Información del envío por FedEX',
        subtitle1: 'Documentos adjuntos',
        subtitle2: 'Registros',
        subtitle3: 'Agregar Nota',
        subtitleDescription1: 'Description',
        success: {
          description: 'Has completado esta etapa con éxito',
          title: 'Creación del envío',
        },
        title: 'Envío',
        titleDescription: 'Estado del envío de la carga.',
        titleStep1: 'Paso 1: Resumen, etiquetas y documentos',
      },
      switchText: 'sólo órdenes',
      tab1: 'Orden',
      tab2: 'Cajas',
      table: {
        buttonFilter: 'Filtros',
        buttonModal: 'Acciones masivas',
        buttonNewOrder: 'Nuevo envío',
        filterOptions: {
          account: 'Cliente',
          asin: 'ASIN',
          input: 'Buscar ',
          order_id: 'LAP ID',
          results: 'resultados',
          shipping_id: 'Amazon ID',
          sku: 'SKU',
        },
        modal: {
          buttonCancel: 'Cancelar',
          buttonSend: 'Enviar',
          buttonUpdate: 'Actualizar',
          changeStateForm: {
            noteTextArea: 'Agregar nota',
            noteTextAreaInfo: 'Registro de eventos y observaciones.',
            stateInput: 'Estado de la órden',
            stateInputInfo: 'Estado del envío de la carga.',
          },
          notificationsForm: {
            contentTextArea: 'Contenido',
            contentTextAreaInfo: 'Registro de eventos y observaciones.',
            templateInput: 'Plantilla',
            templateInputInfo: 'Registro de eventos y observaciones.',
          },
          tab1: 'Doc. de transporte',
          tab1Title: 'Asignar o modificar documentos de transporte',
          tab2: 'Cambiar estado',
          tab2Title: 'Cambiar el estado de las órdenes',
          tab3: 'Notificaciones',
          tab3Title: 'Notificaciones masivas para éstas órdenes',
          title: 'Acciones masivas',
          transportDocumentsForm: {
            containerInput: 'Container',
            containerInputInfo: 'Lorep ipsum .......',
            documentInput: 'Documento de transporte',
            documentInputInfo: 'Documento asociado a reserva del envío.',
            shippingtypeInput: 'Tipo de envío',
            shippingtypeInputInfo: 'Lorep ipsum .......',
          },
        },
        orderColumns: {
          account: 'CLIENTE',
          actions: 'ACTIONS',
          amount: 'UNIDADES',
          cost: 'COSTO',
          created: 'CREACIÓN',
          id: 'LAP ID',
          stage: 'STAGE',
        },
        search: 'Buscar',
        title: 'Listado de Órdenes',
      },
    },
    nextStep: 'Ir al próximo paso',
    notifySectionOne: {
      message1:
        'Verifica las regulaciones del país de destino para tus productos.',
      message2:
        'Revisa los documentos requeridos por el courier para evitar retrasos o problemas.',
      message3:
        'Asegúrate de que tus productos, cajas y pallets estén bien preparados (empaquetado, protección y etiquetado adecuados).',
    },
    notifySectionThree: {
      linkText: 'centro de ayuda.',
      message1:
        'Cada producto debe tener su etiqueta de Amazon y estar correctamente empaquetado',
      message2:
        'Cada caja debe tener su etiqueta de Amazon y su etiqueta de FedEx correspondientes pegadas en el costado',
      message3:
        'La caja principal de cada envío debe tener una ventana adhesiva con la documentación necesaria:',
      message4:
        'Para más información sobre etiquetado, empaquetado y documentación necesaria, visita nuestra sección del',
      submessage1: 'Etiqueta principal de FedEx (3 copias)',
      submessage2: 'Commercial Invoice (3 copias)',
      submessage3: 'Certificado de Origen (1 copia)',
      submessage4:
        'Prior Notice (Sólo aplica para productos regulados por la FDA - 1 copia)',
      title: 'Garantiza un envío exitoso:',
    },
    pallets: {
      add: 'Agregar pallet',
      determine: 'Determina las medidas y peso final de cada pallet',
      enterHeight: 'Ingrese el alto del pallet.',
      enterLength: 'Ingrese el largo del pallet.',
      enterWeight: 'Ingrese el peso del pallet.',
      enterWidth: 'Ingrese el ancho del pallet.',
      erase: '¿Estás seguro que deseas eliminar este pallet?',
      selectPalletPlaceholder: 'Seleccionar pallet',
      create: 'Crear pallet',
      name: 'Nombre name',
      new: 'Nuevo pallet',
      save: 'Guardar pallet',
      examplePalletName: 'Ej: Pallet Normal',
    },
    rejectMessage: 'Comentario',
    rejectProposal: 'Reformular propuesta',
    section1: 'Restock',
    section2: 'Restock > Nueva',
    sentSuccessfully: 'Enviado correctamente',
    shipment: {
      boxConfiguration: {
        addBoxesMessage: 'Por favor, agregar al menos una caja',
        boxSize: 'Tamaño caja',
        saveError: 'Ha ocurrido un error al actualizar las cajas',
        title: 'Paso 3: Determina la configuración de las cajas',
        unitsPerBox: 'Unidades por caja',
      },
      detailShipments: 'Detalle ordenes',
      exampleBoxName: 'Ej: Caja XXL',
      fbmConsideration:
        'Consideraciones importantes antes de crear tu envío FBM',
      height: 'Alto',
      lengthPlaceholder: 'Ingresa un largo',
      unpaidAlert:
        'El envío debe ser pagado para poder descargar las etiquetas y documentos.',
      measure: 'Medidas de caja',
      name: 'Nombre caja',
      new: 'Nueva caja',
      pickUp: {
        error: {
          noCredential:
            'No tienes un operador logístico conectado en tu cuenta.',
        },
        officeDelivery: {
          description:
            'Selecciona la fecha en la que harás la entrega del pedido al courier.',
          required: 'Ingrese fecha de entrega en Fedex',
          title: 'Entregar en oficina',
        },
        schedule: {
          description:
            'Selecciona la fecha y hora para que el courier haga el retiro del pedido.',
          required: 'Ingrese fecha y horas de pick up',
          title: 'Agendar pick up',
        },
        title: 'Paso 2: coordina tu envío con Fedex',
      },
      products: {
        actionsButton: '¿Seguro que quieres eliminar este producto?',
        code: 'Código',
        countryOfManufacture: 'País de manufactura',
        countryOfManufactureTooltip:
          'Esta información es necesaria para determinar las regulaciones que se deben cumplir y los impuestos que deben ser pagados en el país de destino. ',
        declaredFob: 'FOB declarado',
        declaredFobTooltip:
          'Valor que se declara para la carga que se está transportando.',
        description: 'Descripción',
        descriptionValidation:
          'La descripción del producto debe ser de al menos 6 caracteres.',
        harmonizedCode: 'Código arancelario',
        harmonizedCodeValidation:
          'El código arancelario debe ser de 6 dígitos.',
        harmonizedCodeTooltip:
          'Este código se utiliza para clasificar los productos de acuerdo con su naturaleza y características para determinar la tasa de arancel o impuesto aduanero aplicable a la importación o exportación del producto.',
        productDescription: 'Descripción del producto',
        quantity: 'Cantidad',
        saveError: 'Ha ocurrido un error al actualizar los productos',
        select: 'Seleccionar',
        title: 'Paso 2: Completa la información de los productos del envío',
        unitFob: 'FOB unitario',
        unitFobTooltip:
          'Indica el valor en USD que declaras para la carga que se está transportando.',
        unitPrice: 'Precio unitario',
        unitWeight: 'Peso unitario',
      },
      save: 'Guardar caja',
      saveNewBox: 'Guardar caja',
      serviceType: {
        title: 'Paso 2: Seleccionar servicio de envío',
      },
      successDraft: 'Datos guardados con éxito',
      summary: {
        boxConfiguration: 'Configuración de caja',
        boxProductConfiguration: 'Configuración de caja por producto',
        boxSize: 'Tamaño caja',
        boxType: 'Tipo de caja',
        boxUnitWeight: 'Peso unitario caja',
        cargoType: 'Tipo de carga',
        completePickUpInfo: 'Por favor, completa la información de pick up',
        content: 'Contenido',
        courierInformation: 'Información del courier',
        deliveryDate: 'Fecha de entrega',
        editPickUp: 'Editar pick up',
        noPickUpScheduled: 'No tienes un pick up agendado',
        schedulePickUp: 'Agenda pick up',
        labelsAndDocuments: 'Etiquetas y documentos',
        labelsForBoxesPallets: 'Etiquetas para tus cajas y pallets',
        labelsForProducts: 'Etiquetas para tus productos',
        numberOfBoxes: 'Número de cajas',
        palletConfiguration: 'Configuración de pallet',
        perBox: 'por caja',
        pickUpDate: 'Fecha de pick up',
        pickUpMode: 'Modo de recolección',
        pickUpTime: 'Hora de pick up',
        products: 'Productos',
        ratesNotFound:
          'No hay cotizaciones disponibles para los datos del envío ingresados.',
        seeAddress: 'Ver dirección',
        seeAllInformation: 'Ver toda la información',
        sendTo: 'Enviar a',
        serviceType: 'Tipo de servicio',
        shipmentCost: 'Costo de envío',
        shipmentError: 'Ha ocurrido un error al crear el envío',
        shipmentInformation: 'Información del envío',
        shipmentType: 'Tipo de envío',
        success: 'Envío creado exitosamente',
        successfullShipment: {
          amazonLabel:
            'Cada producto debe tener su etiqueta de Amazon y estar correctamente empaquetado.',
          boxLabel:
            'Cada caja debe tener su etiqueta de Amazon y su etiqueta de Fedex correspondientes pegadas en el costado.',
          thirdPartyShipmentBoxLabel:
            'Cada uno de las cajas debe tener su etiqueta correspondiente pegada en un costado.',
          thirdPartyShipmentPalletLabel:
            'Cada uno de los pallets debe tener su etiqueta correspondiente pegada en un costado.',
          title: 'Consideraciones importantes para un envío exitoso:',
        },
        title: 'Paso 3: resumen, etiquetas y documentos',
        title2: 'Resumen de envío',
        unitsPerBox: 'Unidades por caja',
      },
      unitMeasurement: {
        imperial: 'Imperial',
        metric: 'Métrico',
        title: 'Paso 1: Elige un sistema de unidades de medida',
      },
      uploadDocument: {
        success: 'Su documento se ha subido exitosamente.',
      },
      width: 'Ancho',
    },
    sideBarItem1: 'Contenido',
    sideBarItem2: 'Cajas',
    sideBarItem3: 'Estado',
    state: {
      pickUp: {
        failPickUp: '¿Tu pick up no tuvo éxito? Puedes agendar otro pick up',
        finishOfficeDelivery:
          'Ahora tienes que dejar el pedido a una sucursal de Fedex',
        officeDelivery: 'Entregar en oficina Fedex',
        referenceDate: 'Esto es sólo una fecha referencial',
        reschedulePickUp: 'Agendar nuevo pick up',
        schedule: 'Agendar',
        title: 'Paso 2: Opciones de envío a Fedex',
      },
    },
    status: {
      Cancelled: 'Cancelada',
      CheckedIn: 'Registrado',
      Closed: 'Recibido',
      Deleted: 'Eliminado',
      Delivered: 'Entregado',
      Draft: 'Borrador',
      Error: 'Error',
      InTransit: 'En tránsito',
      ReadyToShip: 'Listo para envío',
      Receiving: 'Recibiendo',
      Shipped: 'Enviado',
      Working: 'En preparación',
    },
    subtitle:
      'Planificación de pedidos para envíos hacia centros de distribución de las plataformas de venta.',
    tab1: 'Tu propuesta',
    tab2: 'Propuesta LAP',
  },
  plans: {
    contact: '¡Contáctanos!',
    currentPlan: 'Plan actual',
    header: ['Planes a medida,', 'crecimiento ilimitado'],
    inactiveBoldMessage: 'Tu cuenta está inactiva.',
    inactiveMessage:
      '¡Suscríbete a un plan o contáctanos a través del chat de soporte para expandir tu negocio a un nivel internacional!',
    kam: 'equipo experto',
    kamMeeting: {
      MONTHLY: 'mensual',
      WEEKLY: 'semanal',
    },
    kamMeetingDescription: 'reunión estratégica ',
    kamPlans: 'Servicios adicionales',
    kamPlansDescription:
      'Adicionalmente puedes contratar el apoyo de un equipo experto que te acompañará en la estrategia de entrada a nuevos mercados y gestión de tus cuentas en los marketplaces internacionales. Tu ejecutivo trabajará en conjunto con técnicos especializados en marketing y logística y su objetivo final será ayudarte a tomar decisiones informadas y a maximizar tus resultados.',
    kamRestriction: 'Debes suscribirte a un plan base primero',
    marketplaces: 'Marketplaces',
    oneProductLineAmount: ' línea de productos',
    priceDetails: 'al mes + IVA*',
    priceDetailsMessage:
      '* IVA sólo aplica para facturación a empresas Chilenas',
    productLinesAmount: ' líneas de productos',
    skus: 'SKUs',
    subscribe: 'Suscribirse',
    subscriptionConfirmationModal: {
      description:
        'Muchas gracias por tu compra. Ahora podrás utilizar LAP Marketplace según el plan adquirido.',
      detailsTitle: 'Detalle del pedido:',
      okButton: 'Entendido',
      product: 'Producto: ',
      productCode: 'Código de producto: ',
      subscriptionDate: 'Fecha: ',
      subscriptionFrequencyType: 'Ciclo de facturación: ',
      subscriptionPaid: 'Pagado',
      title: '¡Tu compra ha sido confirmada!',
      validating: 'Validando suscripción...',
    },
    subscriptionDetails: 'Detalles de mi suscripción',
    tech: 'base',
    techPlans: 'Plan Base',
    techPlansDescription:
      'Al suscribirte a uno de nuestros planes, tendrás acceso completo a nuestra plataforma, la cual incluye una variedad de herramientas para integrar marketplaces globales, plataformas de e-commerce, logística y pasarelas de pago. Esto te permitirá gestionar tu negocio de manera eficiente desde un solo lugar y ampliar tu alcance a nivel global.',
    transactions: 'Transacciones',
    unlimited: {
      f: ' ilimitadas',
      m: ' ilimitados',
    },
    updatePlan: 'Actualizar plan',
  },
  productTemplates: {
    fields: {
      withPublishedProducts: 'Con productos publicados',
      withoutPublishedProducts: 'Sin productos publicados',
    },
  },
  products: {
    errors: {
      categoryNotAllowed:
        'No se permite la publicación de productos de esta categoría a través de terceros. Por favor, publícalo directamente en Amazon o selecciona otra categoría.',
    },
    fields: {
      addProduct: 'Agregar producto',
      allMarketplaces: 'Unificado',
      allProducts: 'Todos los productos',
      allSkus: 'Todos los productos',
      allStatus: 'Todos los estados',
      category: 'Categoría',
      categoryPlaceholder: 'Categoría Seleccionada',
      destinationCategory: 'Categoría de destino',
      downloadProductsFile: 'Descargar',
      uploadProductsFile: 'Cargar stock desde archivo',
      downloadTemplate: 'Descargar productos',
      exportProducts: 'Exportar productos',
      groupedMarketplaces: 'Agrupados',
      marketplace: 'Marketplace',
      marketplacePlaceholder: 'Marketplace seleccionado',
      originProducts: 'Productos de origen',
      productName: 'Producto',
      productNamePlaceholder: 'Nombre del producto',
      publishProducts: 'Publicar productos',
      searchCategories: 'Selecciona una categoría',
      searchCategoriesPlaceholder:
        'Escribe en inglés una categoría para tu producto',
      seeImports: 'Ver estado de cargas',
      sku: 'SKU',
      skuPlaceholder: 'SKU seleccionado',
      spanishSearchCategoriesPlaceholder: 'Busca una categoría ...',
      status: 'Estado',
      statusPlaceholder: 'Estado seleccionado',
      title: 'Título',
      titlePlaceholder: 'Ingrese nombre producto',
      uploadAction: 'Publicar',
      uploadTemplate: 'Cargar productos',
    },
    marketplaces: {
      Amazon: 'Amazon US',
      AmazonBrazil: 'Amazon BR',
      AmazonCanada: 'Amazon CA',
      AmazonFrance: 'Amazon FR',
      AmazonGermany: 'Amazon DE',
      AmazonItaly: 'Amazon IT',
      AmazonMexico: 'Amazon MX',
      AmazonSpain: 'Amazon ES',
      Ebay: 'Ebay US',
      EbayCanada: 'Ebay CA',
      EbayGermany: 'Ebay DE',
      EbaySpain: 'Ebay ES',
      Mercadolibre: 'Mercadolibre AR',
      MercadolibreChile: 'Mercadolibre CL',
      MercadolibreMexico: 'Mercadolibre MX',
      Shopify: 'Shopify',
      Walmart: 'Walmart US',
    },
  },
  register: {
    companyName: {
      error: 'Debe ingresar un nombre de marca',
      label: 'Nombre de la marca',
      placeholder: 'NexTech Solutions',
    },
    confirmPassword: {
      error: 'Las contraseñas ingresadas no son iguales',
      label: 'Confirmar contraseña',
      placeholder: 'Repite la contraseña',
    },
    errors: {
      existingUser: 'Ya existe un usuario registrado con este email.',
    },
    formHeader: {
      lapAcademy: 'Regístrate en LAP Academy',
      normal: 'Regístrate para empezar a crecer',
    },
    newPassword: 'Nueva contraseña',
    termsAndConditions: [
      'Acepto los',
      'Términos y condiciones',
      'y la',
      'Política de privacidad',
      'de LAP Global',
    ],
    userName: {
      label: 'Nombre de usuario',
      placeholder: 'Juan Pérez',
    },
    verificationCodeSent:
      'Se ha enviado un código de verificación a su correo.',
    verificationError:
      'Ha ocurrido un error al verificar al usuario. Por favor intente nuevamente.',
    verificationModal: {
      description:
        'Te enviamos un código de 6 dígitos al correo registrado para verificar que eres tú. Este tendrá validez por los próximos 2 minutos.',
      input: 'Ingresa el código:',
      title: 'Necesitamos verificarte',
    },
  },
  regulations: {
    documents: 'Documentos',
    linkAccount: {
      error: 'Error al conectar su cuenta de {{ label }}.',
      success: 'Se ha conectado su cuenta de {{ label }} correctamente.',
    },
    priorNotify: {
      connectAccount: 'Conectar cuenta Prior Notify',
      requiredApiKey: 'Ingrese su API Key de Prior Notify',
    },
  },
  requestAccount: {
    CL: {
      doc1: {
        bullet_point1:
          'Debes llenar la solicitud de forma escrita, luego escanaearla y subirla en formato PDF.',
        bullet_point2:
          'No debe quedar ningún campo vacío salvo aquellos que son para uso exclusivo de FedEx.',
        title: 'Solicitud de apertura de cuenta',
      },
      doc2: {
        bullet_point1:
          'Debes llenar la solicitud de forma digital, sin omitir información y con la firma.',
        bullet_point2: 'Debes subirlo en formato PDF.',
        title: 'Anexo facturación electrónica',
      },
    },
    CO: {
      doc1: {
        bullet_point1:
          'Debes llenar el documento de solicitud y guardarlo en formato PDF para poder subirlo.',
        bullet_point2: 'No debe quedar ningún campo vacío.',
        title: 'Solicitud de apertura de cuenta',
      },
      doc2: {
        bullet_point1: 'Debes llenar el pagaré y guardarlo en formato PDF.',
        bullet_point2:
          'Para más información sobre como llenar el documento puedes ver el siguiente ',
        bullet_point2_linkText: 'instructivo.',
        title: 'Pagaré FedEx',
      },
      doc3: {
        bullet_point1: 'Debes subir el documento en formato PDF.',
        title: 'Cámara de Comercio vigente (No mayor a 30 días)',
      },
      doc4: {
        bullet_point1: 'Debes subir el RUT en formato PDF.',
        title: 'RUT - Registro Único Tributario',
      },
      doc5: {
        bullet_point1: 'Debes subir la cédula en formato PDF.',
        title: 'Cédula del Representante Legal',
      },
    },
    MX: {
      moral: {
        doc1: {
          bullet_point1:
            'Debes llenar la solicitud de forma digital, sin omitir información, con la firma y nombre del representante legal.',
          bullet_point2:
            'Los teléfonos de contacto principales, referencia comercial y banco deben ser números de teléfono fijos.',
          bullet_point3:
            'No debe quedar ningún campo vacío salvo aquellos que son para uso exclusivo de FedEx.',
          bullet_point4:
            'La firma en la solicitud de apertura de cuenta debe ser autógrafa.',
          title: 'Solicitud de apertura de cuenta',
        },
        doc2: {
          bullet_point1:
            'Debes llenar el aviso de privacidad con nombre y firma del representante legal. ',
          title: 'Aviso de privacidad',
        },
        doc3: {
          bullet_point1:
            'Debe ser una copia en formato de Constancia de Situación Fiscal (formato que incluye dirección fiscal y código QR). Puedes obtener el documento ',
          bullet_point1_linkText: 'aquí.',
          bullet_point2: 'Debe tener vigencia no mayor a 30 días.',
          title: 'RFC (Constancia de Situación Fiscal)',
        },
        doc4: {
          bullet_point1:
            'Copia de la identificación vigente del representante legal, que puede ser INE (copia por ambos lados), pasaporte, cédula profesional o visa de residencia temporal.',
          title: 'Identificación oficial del Representante Legal',
        },
        doc5: {
          bullet_point1:
            'Debe ser el comprobante de domicilio fiscal a nombre de la empresa.',
          bullet_point2: 'Debe tener vigencia no mayor a 3 meses.',
          bullet_point3:
            'El domicilio debe coincidir con RFC, si la dirección fiscal o de cobro es diferente, se debe adjuntar el comprobante de domicilio de ambas direcciones a nombre de la empresa.',
          title: 'Comprobante de domicilio',
        },
        doc6: {
          bullet_point1: 'Debe ser una copia del acta constitutiva. ',
          bullet_point2:
            'Entregar documento completo y, cuando aplique, incluir modificaciones. ',
          title: 'Acta constitutiva',
        },
        doc7: {
          bullet_point1:
            'Reporte de buró de crédito, correspondiente al mes de la solicitud. Puedes obtener el reporte ',
          bullet_point1_linkText: 'aquí.',
          bullet_point2:
            'Debe presentar un historial de pagos puntuales y no tener atrasos en sus créditos adquiridos. ',
          title: 'Buró de crédito',
        },
        doc8: {
          bullet_point1:
            'Este documento es requerido solo si el representante legal no es mencionado en el acta constitutiva.',
          title: 'Poder notarial del Representante Legal (opcional)',
        },
      },
      natural: {
        doc4: {
          bullet_point1:
            'Copia de la identificación vigente del solicitante, que puede ser INE (copia por ambos lados), pasaporte, cédula profesional o visa de residencia temporal.',
          title: 'Identificación oficial',
        },
        doc5: {
          bullet_point1:
            'Puede ser un comprobante de un servicio contratado en el domicilio a nombre del solicitante.',
          bullet_point2: 'Debe tener vigencia no mayor a 3 meses.',
          bullet_point3:
            'El domicilio debe coincidir con RFC, si la dirección fiscal o de cobro es diferente, se debe adjuntar el comprobante de domicilio de ambas direcciones a nombre del solicitante.',
          title: 'Comprobante de domicilio',
        },
        doc6: {
          bullet_point1:
            'Reporte de buró de crédito, correspondiente al mes de la solicitud. Puedes obtener el reporte ',
          bullet_point1_linkText: 'aquí.',
          bullet_point2:
            'Debe presentar un historial de pagos puntuales y no tener atrasos en sus créditos adquiridos. ',
          title: 'Buró de crédito',
        },
      },
    },
    contents: {
      cancel: '¿Está seguro que desea cancelar la solicitud?',
      legalEntity: 'Persona Moral',
      legalEntityDescription: 'Soy representate legal de una empresa.',
      naturalPerson: 'Persona Física',
      naturalPersonDescription: 'Soy persona física con actividad empresarial.',
      title: 'Solicitud de apertura de cuenta FedEx',
      titleStep1: 'Selecciona el país de la cuenta',
      titleStep2: 'Selecciona el tipo de persona',
      titleStep3: 'Carga de documentos requeridos',
    },
    error: {
      missingDocuments:
        'Por favor, carga los {{missingFilesAmount}} documentos antes de enviar.',
    },
    success: {
      message1: '¡Hemos recibido tu solicitud con éxito! ',
      message2:
        'Para asegurar la aprobación de tu solicitud, revisaremos tu documentación para confirmar que cumple con los requisitos antes de enviarla a FedEx. En caso de ser necesarias modificaciones, nos pondremos en contacto contigo.',
      message3:
        'Si tu documentación cumple con los requisitos, te haremos envío del número de cuenta de FedEx México por correo electrónico en un plazo aproximado de 20 días hábiles y podrás acceder a nuestras tarifas preferenciales.',
    },
  },
  selectPlaceholder: 'Seleccione',
  shipments: {
    buttons: {
      cancel: 'Cancelar',
      cancelWarning: '¿Estás seguro de que quieres cancelar este envío?',
      checklist: 'Ver documentos e información',
      createShipment: 'Crear envío',
      edit: 'Editar',
      newShipment: 'Nuevo envío',
      track: 'Seguir envío(s)',
    },
    columns: {
      actions: 'Acciones',
      createdAt: 'Creación',
      destination: 'Destino',
      name: 'Nombre de envío',
      origin: 'Origen',
      status: 'Estado',
      type: 'Tipo',
    },
    errors: {
      cancelShipment: {
        success: 'El envío se ha cancelado correctamente.',
      },
      createRestockOrder: 'Error al crear restock',
      createThirdPartyShipment: 'Error al intentar crear envío a terceros',
      deleteRestockOrder: {
        error: 'Error al eliminar restock',
        success: 'El registro se ha eliminado correctamente.',
      },
      getCourierCredentials: 'Error al obtener tus couriers',
      getCourierNamesByCompany: 'Error al obtener tus centros de fulfillment',
      noFulfillmentCenter:
        'No existe un centro de fulfillment conectado para realizar restock.',
    },
    filter: {
      period: 'Período',
      periodRanges: {
        lastMonth: 'Mes Anterior',
        past12Months: 'Últimos 12 meses',
        past30Days: 'Últimos 30 días',
        past7Days: 'Últimos 7 días',
        thisMonth: 'Mes actual',
        thisWeek: 'Semana actual',
        thisYear: 'Año Actual',
        today: 'Hoy',
      },
      status: 'Estado',
      type: 'Tipo de envío',
    },
    pickup: {
      state: {
        completed: 'Confirmado',
        failed: 'Fallido',
        pending: 'Pendiente de confirmación',
      },
      noPickup: 'Sin pickup',
    },
    shipmentTracking: {
      pickUpScheduled: 'Pick Up agendado',
      shipmentCreated: 'Envío creado',
      shipmentTracking: 'Seguimiento del envío',
    },
    status: {
      All: 'Todos los estados',
      Cancelled: 'Cancelada',
      CheckedIn: 'Registrado',
      Closed: 'Recibido',
      Deleted: 'Eliminado',
      Delivered: 'Entregado',
      Draft: 'Borrador',
      Error: 'Error',
      InTransit: 'En tránsito',
      ReadyToShip: 'Listo para envío',
      Receiving: 'Recibiendo',
      Shipped: 'Enviado',
      Working: 'En preparación',
    },
    types: {
      all: 'Todos',
      restock: 'Restock',
      saleOrder: 'Pedidos',
      thirdPartyShipments: 'Terceros',
      toThirdPartyShipments: 'A terceros',
    },
  },
  shipsmart: {
    federalId: 'Identificación fiscal estatal',
    federalTax: 'ID de impuestos federales',
    fiscalNote: 'Nota Fiscal',
    hireInsurance: '¿Quieres contratar un seguro?',
    receiver: 'Destinatario',
    sender: 'Remitente',
    taxesPayer: '¿Quién pagará los impuestos?',
  },
  subscriptions: {
    consume: {
      error: 'Ocurrió un error al obtener el consumo.',
      title: 'Consumo de mi plan',
    },
    currentSubscription: {
      cancelConfirmation:
        '¿Estás seguro de que quieres cancelar tu suscripción actual?',
      cancelSubscription: 'Cancelar suscripción',
      cancelWarning:
        'Al cancelar tu suscripción, seguirás teniendo acceso a sus beneficios hasta la fecha de término. Esta es una acción irreversible.',
      cancelledSubscription: 'Suscripción cancelada',
      cancellingSubscription: 'Cancelando suscripción...',
      daysLeft: 'días restantes',
      endOfSubscription: 'Fin de la suscripción',
      external: 'Pago externo',
      freeTrial: 'Prueba gratuita',
      kam: 'Servicio adicional',
      lastPayment: 'Último pago',
      nextPayment: 'Próximo pago',
      paymentMethod: 'Método de pago',
      processing: 'En proceso',
      subscriptionCode: 'Código de suscripción',
      tech: 'Plan base',
    },
    paymentProcessing:
      'Tu pago por el plan {{plan}} está aún siendo procesado. En caso de no poder acreditarse, tendrás que intentar nuevamente el pago.',
  },
  thirdPartyShipments: {
    boxes: {
      newBox: 'Nueva caja',
      selectBoxPlaceholder: 'Seleccionar caja',
    },
    couriers: {
      noCouriersAvailable: 'No hay courier disponible para este trayecto.',
      tooltip:
        'Selecciona la empresa encargada de la recolección, transporte y entrega de tu envío.',
    },
    destination: {
      originTooltip:
        'Selecciona la ubicación de tu bodega donde el servicio de courier, como FedEx, recolectará tu envío.',
      phoneExtensionUsaError: 'Extensión de teléfono debe ser de USA o Canada',
      phoneUsaError:
        'El número de teléfono debe ser de 10 dígitos para USA o Canada.',
      placeholder: 'Selecciona una dirección de destino',
      tooltip:
        'Completa la información de destino solicitada al hacer click en el espacio designado.',
    },
    errors: {
      loadShipmentData: 'Ha ocurrido un error al obtener los envíos.',
      productBoxWeight:
        'El peso total de los productos no puede ser mayor que el peso total de las cajas. El peso mínimo del total de las cajas debe ser de:',
    },
    int99minutos: {
      errorGetCoverage:
        'Ocurrió un error inesperado al obtener la cobertura de 99Minutos. Inténtalo más tarde.',
      finalMessage:
        'Se realizara el pick up según tus preferencias de envío de 99 minutos',
      noCoverage:
        'El servicio de 99Minutos no está disponible para las direcciones de origen y destino.',
    },
    isPack: 'Cajas sueltas',
    isPackDescription: 'Cajas individuales separadas.',
    palletized: 'Carga paletizada',
    palletizedDescription:
      'Cajas agrupadas sobre un pallet como un solo bulto.',
    pallets: {
      newPallet: 'Nuevo pallet',
      selectPalletPlaceholder: 'Seleccionar pallet',
    },
    shipsmart: {
      finalMessage: 'Se realizará el pick up por el courier seleccionado',
    },
    step2Subtitle1: 'Selecciona el tipo de servicio y costo de envío',
    step2Subtitle2: 'Selecciona el modo de recolección',
    table: {
      infoTooltip: 'Revisar información de envío',
      needCourier:
        'Se necesita conectar una cuenta de FedEx o 99Minutos. Revisa la sección Operadores Logísticos.',
    },
    titleStep2: 'Costo de envío y pick up',
  },
  title: 'Bienvenido a React usando: react-i18next',
  warehouses: {
    confirmDelete: '¿Estás seguro que deseas eliminar esta bodega?',
    createForm: {
      address: {
        additionalAddressInfo: 'Departamento',
        additionalAddressInfoPlaceholder:
          'Número de oficina / departamento / otro',
        city: 'Ciudad o comuna',
        cityPlaceholder: 'Ciudad o comuna',
        countryCode: 'País',
        placeholderStreetName: 'Calle y numeración',
        postalCode: 'Código postal',
        stateOrProvinceCode: 'Estado o provincia',
        streetName: 'Dirección',
      },
      contactName: 'Nombre de contacto',
      email: 'Email',
      name: 'Nombre de bodega',
      phoneNumber: 'Teléfono',
      title: 'Agregar destino',
      validEmail: 'Por favor, ingresa un email válido',
      companyName: 'Nombre de empresa',
    },
    createNewWarehouse: 'Crear bodega',
    edit: {
      error: 'Ha ocurrido un error al editar la bodega',
      success: 'Bodega actualizada correctamente.',
      title: 'Editar Bodega',
      tooltip: 'Editar información de bodega',
    },
    title: 'Mis Bodegas',
  },
  benchmark: {
    title: 'Benchmark',
    classificationComplete: 'Clasificación ha terminado!',
    form: {
      productCategory: 'Categoría de Producto',
      productCategoryError: '¡Por favor, selecciona una categoría de producto!',
      productCategoryPlaceholder: 'Selecciona una categoría de producto',
      searchKeywords: 'Buscar Palabras Clave',
      searchKeywordsError: '¡Por favor, ingresa input de palabras clave!',
      searchKeywordsPlaceholder: 'Ingrese palabras clave del producto',
      useExactMatch: 'Buscar usando coincidencia exacta',
      exactMatch: 'Coincidencia Exacta',
      exactMatchError: '¡Por favor, input para match exacto!',
      exactMatchPlaceholder: 'Ingrese palabras clave de coincidencia exacta',
      maxNumberOfCompetitors: 'Número Máximo de Competidores',
      maxNumberOfCompetitorsError: '¿Cuántos competidores deseas obtener?',
      maxNumberOfCompetitorsPlaceholder:
        'Ingrese el número máximo de competidores',
      searchButton: 'Buscar',
      marketplace: 'Marketplace',
      marketplaceError: 'Error seleccionando Marketplace',
      marketplacePlaceholder: 'Marketplace de Amazon',
      maxNumberOfCompetitorsTooltip:
        'El número máximo de competidores debe estar entre 1 y 50.',
    },
    downloadExcel: 'Descargar Excel',
    uploadExcel: 'Cargar Excel',
    excelUploadSuccess: 'Excel cargado exitosamente',
    generateReport: 'Generar Informe',
    reportGeneratedSuccess: 'Informe generado exitosamente',
    statistics: {
      topBrand: 'Marca Líder',
      totalCompetitors: 'Total Competidores',
      averagePrice: 'Precio Promedio',
      totalRevenue: 'Ingresos Totales',
      averageRating: 'Calificación Promedio',
      topFormat: 'Formato más vendido',
      topUnitsPerPack: 'Unidades/Paquete más vendidas',
    },
    table: {
      link: 'Enlace',
      viewOnAmazon: 'Amazon',
      productTitle: 'Título',
      brand: 'Marca',
      price: 'Precio',
      sales: 'Ventas',
      revenue: 'Ingresos',
      fulfillmentType: 'Fulfillment',
      productFormatQuantity: 'Cant. Formato',
      productFormatUnit: 'Unidad Formato',
      unitsPerPack: 'Unidades/Paquete',
      standardizedFormatUnit: 'Unidad Est. (Kg, L, Unidad)',
      standardizedFormatQuantity: 'Cant. Est.',
      totalFormatQuantity: 'Cant. Total',
      pricePerTotalQuantity: 'Precio/Cant. Total',
      sheetName: 'Competidores',
      rating: 'Calificación',
      reviews: 'Reseñas',
      category: 'Categoría',
      actions: {
        delete: 'Eliminar',
        save: 'Guardar',
        cancel: 'Cancelar',
        edit: 'Editar',
      },
    },
    errors: {
      invalidCompetitorCount:
        'El número de competidores debe estar entre 1 y 50.',
    },
  },
  yes: 'Sí',
};

export default es;
