import React, { useEffect, useState } from 'react';
import {
  Row,
  Checkbox,
  Col,
  Input,
  Spin,
  Space,
  Form,
  Select,
  Tooltip,
  Typography,
  Steps,
  Radio,
} from 'antd';
import {
  InfoCircleTwoTone,
  InfoCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import 'antd/dist/antd.min.css';
import '../steps.css';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  validateMessages,
  orderSteps,
  orderStates,
  orderGeneralStates,
} from '../../../../utils/const';
import openNotification from '../../../../components/Toastr';
import { getErrorMessage } from '../../../../api/api';
import warehousesAPI from '../../../../api/warehouses';
import couriersApi from '../../../../api/courier';
import restockOrdersAPI from '../../../../api/restock-orders';
import partnerApi from '../../../../api/partner';
import { mixPanelCreateEvent } from '../../../../utils/functions';
import CreateWarehouseModal from '../../../shipments/components/CreateWarehouseModal';
import FBMCouriers from '../../../../utils/FBMCouriers';
import InformationBox from '../../../order/components/steps/components/InformationBox';
import ShipsmartSpecificInfo from '../../../order/components/couriers/Shipsmart/components/ShipsmartSpecificInfo';
import ContentsTable from './ContentsTable';
import StickyAlert from '../../../order/components/steps/components/StickyAlert';
import { ProductsModal } from '../../../order/components/steps/components/ProductsModal/ProductsModal';
import restockOrdersV2024API from '../../../../api/restockV2024';

const { Step } = Steps;

function Contents({
  orderId,
  redirected,
  setRedirected,
  setSelected,
  selected,
  setSteps,
  title,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { Item } = Form;
  const { Option } = Select;
  const [warehouses, setWarehouses] = useState();
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [order, setOrder] = useState();
  const [listingsAndProducts, setListingsAndProducts] = useState();
  const [partners] = useState();
  const [selectedPartner, setSelectedPartner] = useState();
  const [loadingPartners] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loadingSend, setLoadingSend] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [quantityValues, setQuantityValues] = useState({});
  const [expirationDates, setExpirationDates] = useState({});
  const [isFoodOrBeautyValues, setIsFoodOrBeautyValues] = useState({}); // Added state for isFoodOrBeauty
  const [marketplace, setMarketplace] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageSize, setPageSize] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataSourceCourier, setDataSourceCourier] = useState();
  const [sendContentLoading, setSendContentLoading] = useState(false);
  const destination = Form.useWatch('destination', form);
  const warehouseId = Form.useWatch('warehouseId', form);
  const [isPartnered, setIsPartnered] = useState(false);
  const [originCountryCode, setOriginCountryCode] = useState('');
  const [selectedCourier, setSelectedCourier] = useState('');

  const [canProceedStep1, setCanProceedStep1] = useState(false);
  const [checkedList, setCheckedList] = useState([]);

  const firstStepsOptions = [
    {
      primaryText: t('orders.notifySectionOne.message1'),
      seeMoreLink:
        'https://ayuda.lapmarketplace.com/hc/es-cl/categories/12348948571803',
    },
    {
      primaryText: t('orders.notifySectionOne.message2'),
      seeMoreLink:
        'https://ayuda.lapmarketplace.com/hc/es-cl/sections/12540784099099',
    },
    {
      primaryText: t('orders.notifySectionOne.message3'),
      seeMoreLink:
        'https://ayuda.lapmarketplace.com/hc/es-cl/sections/12350130967067',
    },
  ];

  const verifyCheckboxCompleteness = (selectedOptions) => {
    setCheckedList(selectedOptions);
    if (selectedOptions.length === firstStepsOptions.length) {
      setCanProceedStep1(true);
    } else {
      setCanProceedStep1(false);
    }
  };

  const history = useHistory();

  const destinationToMarketplaceMap = {
    MX: 'Amazon Mexico',
    CA: 'Amazon Canada',
    US: 'Amazon',
    BR: 'Amazon Brazil',
    ES: 'Amazon Spain',
    FR: 'Amazon France',
    IT: 'Amazon Italy',
    DE: 'Amazon Germany',
  };

  const onDestinationChange = (value) => {
    const newMarketplace = destinationToMarketplaceMap[value];
    form.setFieldsValue({
      name: `${moment(new Date())
        .format('DD-MMM-YYYY')
        .toUpperCase()} - Amazon ${form.getFieldValue('destination')}`,
    });
    setMarketplace(newMarketplace);
  };

  const session = useSelector((store) => store.Session.session);

  const step = order?.steps.find(
    (orderStep) => orderStep.step === orderSteps.CONTENIDO
  );
  const state = order?.state;

  const loadWarehouses = async (companyId) => {
    try {
      const response = await warehousesAPI.getCompanyWarehouses(
        20,
        1,
        companyId
      );
      setWarehouses(response.data.results);
    } catch (error) {
      openNotification({ status: false, content: 'Error al obtener bodegas.' });
    }
  };

  const loadFedexCredentialsTable = async () => {
    const response = await couriersApi.getCourierCredentials(
      pageSize,
      currentPage
    );
    setCurrentPage(response.data.page);
    setPageSize(response.data.limit);
    const data = response.data.results.map((value) => ({
      key: value.id,
      name: value.courierCompanyName,
      accountNumber: value.accountNumber,
    }));
    setDataSourceCourier(data);
  };

  const getOrder = async () => {
    setLoadingOrder(true);
    try {
      const response = await restockOrdersAPI.getContent(orderId);
      await loadFedexCredentialsTable();
      setOrder(response.data);
      setSteps(response.data.steps);
      setMarketplace(destinationToMarketplaceMap[response.data.destination]);
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
    setLoadingOrder(false);
  };

  useEffect(() => {
    getOrder();
    loadWarehouses(session.userInfo.company_id[0]);
  }, []);

  useEffect(() => {
    if (order) {
      if (!redirected) {
        const contentStep = order?.steps.find(
          (orderStep) => orderStep.step === orderSteps.CONTENIDO
        );
        const boxesStep = order?.steps.find(
          (orderStep) => orderStep.step === orderSteps.CAJAS
        );
        const shipmentStep = order?.steps.find(
          (orderStep) => orderStep.step === orderSteps.ESTADO
        );
        if (shipmentStep.state === orderStates.COMPLETED) {
          setRedirected(true);
          setSelected(3);
        } else if (boxesStep.state === orderStates.COMPLETED) {
          setRedirected(true);
          setSelected(2);
        } else if (contentStep.state === orderStates.COMPLETED) {
          setRedirected(true);
          setSelected(1);
        }
      }
      setSelectedProducts(
        order.products.map((product) => ({
          key: product.product.id,
          id: product.product.id,
          name: product.product.title,
          sku: product.product.defaultCode,
          quantity: product.quantity,
          unitsPerPack: product.unitsPerPack,
          expirationDate: product.expirationDate,
          isFoodOrBeauty: product.isFoodOrBeauty || false, // Added isFoodOrBeauty
        }))
      );
      setSelectedRowKeys(order.products.map((product) => product.product.id));
      const productsForm = {};
      order.products.forEach((product) => {
        productsForm[`quantity-${product.product.id}`] = product.quantity;
        productsForm[`expiration_date-${product.product.id}`] =
          product.expirationDate;
        productsForm[`isFoodOrBeauty-${product.product.id}`] =
          product.isFoodOrBeauty || false; // Added isFoodOrBeauty
      });
      setIsFoodOrBeautyValues(
        order.products.reduce((acc, product) => {
          acc[product.product.id] = product.isFoodOrBeauty || false;
          return acc;
        }, {})
      );
      setSelectedPartner(order.clientId);
      form.setFieldsValue({
        ...productsForm,
        account_id: order.clientId,
        originAddress: order.originAddress,
        destination: order.destination,
        shipping_type: order.shippingType,
        name: order.name,
        price: order.shippingAmount,
        warehouseId: order?.warehouse?.id,
        knownPackingInformation: order.knownPackingInformation,
      });
      if (order.name) {
        setCheckedList(firstStepsOptions.map((item) => item.primaryText));
        setCanProceedStep1(true);
      }
      if (order.warehouse) {
        setOriginCountryCode(order.warehouse.address.countryCode);
      }
      if (order.courier && dataSourceCourier) {
        const chosenCourier = dataSourceCourier.find(
          (item) => item.key === Number(order.courier)
        );
        if (chosenCourier) setSelectedCourier(chosenCourier.name);
      }
    }
  }, [loadingOrder]);

  useEffect(() => {
    if (selectedPartner) {
      loadWarehouses(selectedPartner);
    }
  }, [selectedPartner]);

  useEffect(() => {
    const getListingsByPartner = async (companyId) => {
      try {
        if (destination) {
          const response = await partnerApi.listingsAndProducts(
            companyId,
            false,
            destinationToMarketplaceMap[destination]
          );
          setListingsAndProducts(response.data);
        }
      } catch (error) {
        openNotification({ status: false, content: getErrorMessage(error) });
      }
    };
    if (destination) {
      getListingsByPartner(selectedPartner);
    }
  }, [destination]);

  useEffect(() => {
    const getIsPartnered = () => {
      const warehouse = warehouses.find((w) => w.id === warehouseId);
      if (destination === warehouse?.address.countryCode) {
        setIsPartnered(true);
      } else setIsPartnered(false);
    };
    if (destination && warehouseId) {
      getIsPartnered();
    }
  });

  const handlePartnerChange = (partnerId) => {
    setSelectedPartner(partnerId);
  };

  const handleCancelForm = async () => {
    setLoadingDelete(true);
    try {
      await restockOrdersV2024API.cancelShipment(orderId);
      window.location = '/envios';
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
      setLoadingDelete(false);
    }
  };

  const validateFields = (data) => {
    const { name, products, knownPackingInformation } = data;
    const hasProducts = products?.find(
      (prod) => prod.quantity !== undefined || prod.quantity > 0
    );

    if (
      !warehouseId ||
      !destination ||
      !name ||
      !products ||
      products.length === 0 ||
      knownPackingInformation === undefined
    ) {
      openNotification({
        status: false,
        content: 'Por favor complete todos los campos requeridos.',
      });
      return false;
    }
    if (!hasProducts) {
      openNotification({
        status: false,
        content: 'Debe enviar al menos un producto de su listing.',
      });
      return false;
    }

    return true;
  };

  const buildJsonFromForm = (formFields, validate) => {
    const newProducts = [];
    selectedRowKeys.forEach((productId) => {
      const listing = listingsAndProducts.find((l) =>
        l.products.find((prod) => prod.id === productId)
      );
      const product = listing.products.find((prod) => prod.id === productId);
      newProducts.push({
        id: productId,
        sku: product.sku,
        quantity: quantityValues[productId],
        expirationDate: moment(expirationDates[productId]).toISOString(),
        asin: product.asin,
        hasFood: isFoodOrBeautyValues[productId] || false, // Include isFoodOrBeauty
      });
    });
    const filteredProducts = newProducts.filter(
      (product) => product.quantity > 0
    );
    const data = {
      name: formFields.name,
      warehouseId: formFields.warehouseId,
      destination: formFields.destination,
      shippingType: formFields.shipping_type,
      clientId: formFields.companyId,
      courier: formFields.courier,
      products: filteredProducts,
      knownPackingInformation: formFields.knownPackingInformation,
    };
    if (validate && !validateFields(data)) {
      return null;
    }
    return data;
  };

  const handleSaveDraft = async () => {
    mixPanelCreateEvent('Action Button', {
      action: 'Draft Contents Restock',
      step: selected,
    });
    const formFields = form.getFieldsValue();
    setLoadingUpdate(true);
    try {
      const content = buildJsonFromForm(formFields, false);
      await restockOrdersAPI.updateContent(orderId, content);
      openNotification({
        status: true,
        content: t('onboarding.draftSavedSuccessfully'),
      });
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
    setLoadingUpdate(false);
  };

  const handleSendContent = async (formFields) => {
    mixPanelCreateEvent('Action Button', {
      action: 'Send restock order content',
    });
    const jsonToSend = buildJsonFromForm(formFields, true);
    if (!jsonToSend) {
      return;
    }
    setLoadingSend(true);
    try {
      await restockOrdersAPI.sendContent(orderId, jsonToSend);
      await restockOrdersV2024API.createInboundShipmentPlan(
        orderId,
        jsonToSend
      );
      openNotification({
        status: true,
        content: t('orders.newOrder.contents.success.description'),
        info: t('orders.newOrder.contents.success.title'),
      });
      setSelected(1);
      setOrder((prevState) => {
        const otherSteps = prevState.steps.filter(
          (orderStep) => orderStep.step !== orderSteps.CONTENIDO
        );
        return {
          ...prevState,
          steps: [
            ...otherSteps,
            {
              step: orderSteps.CONTENIDO,
              state: orderStates.COMPLETED,
            },
          ],
        };
      });
      form.setFieldValue('warehouse');
    } catch (error) {
      openNotification({ status: false, content: getErrorMessage(error) });
    }
    setLoadingSend(false);
    setSendContentLoading(false);
  };

  const sendContent = () => {
    mixPanelCreateEvent('Action Button', {
      action: 'Send Contents Restock',
      step: selected,
    });
    setSendContentLoading(true);
  };

  const handleWarehouseSelect = (value) => {
    if (value === 'create-warehouse') {
      setIsModalOpen(true);
    } else {
      const actualWarehouse = warehouses.find(
        (warehouse) => warehouse.id === value
      );
      setOriginCountryCode(actualWarehouse.address.countryCode);
    }
  };

  const handleNewCourier = async (value) => {
    if (!value) {
      history.push('/couriers');
    } else {
      const actualCourier = dataSourceCourier.find(
        (courier) => courier.key === value
      );
      setSelectedCourier(actualCourier.name);
    }
  };

  const textInformationCheckBox = (
    <div>
      <p style={{ fontWeight: 500 }}>
        {t('orders.newOrder.contents.titleCheckBox')}:
      </p>
      <Checkbox.Group
        onChange={verifyCheckboxCompleteness}
        disabled={step && step.state && step.state === orderStates.COMPLETED}
        defaultValue={
          step && step.state && step.state === orderStates.COMPLETED
            ? firstStepsOptions.map((item) => item.primaryText)
            : []
        }
        value={
          step && step.state && step.state === orderStates.COMPLETED
            ? firstStepsOptions.map((item) => item.primaryText)
            : checkedList
        }
      >
        <Space direction="vertical">
          {firstStepsOptions.map((item) => (
            <Checkbox key={item.primaryText} value={item.primaryText}>
              <span>{`${item.primaryText} `}</span>
              <a href={item.seeMoreLink} target="blank">
                {t('common.wantKnowMore')}
              </a>
            </Checkbox>
          ))}
        </Space>
      </Checkbox.Group>
    </div>
  );

  return (
    <>
      <div className="title-content">{title}</div>
      <Spin spinning={loadingUpdate || loadingSend || loadingDelete}>
        <InformationBox
          icon={<InfoCircleTwoTone />}
          status="processing"
          textInformationBox={textInformationCheckBox}
          display="block"
        />

        {step && order && (
          <div id="contents">
            <CreateWarehouseModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              reloadTable={loadWarehouses}
              selectForm={form}
              warehouseSelectFormName="warehouseId"
            />
            <Form
              name="contentForm"
              form={form}
              layout="vertical"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              onFinish={handleSendContent}
              validateMessages={validateMessages}
              className="text-align-left form-padding-top restockStepOne"
              scrollToFirstError
            >
              <Row gutter={[24, 24]} style={{ margin: '0 15px' }}>
                {session.userInfo.isAdmin && (
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <Item
                        label={t('orders.newOrder.contents.input1')}
                        name="companyId"
                        rules={[{ required: true }]}
                      >
                        <Select
                          loading={loadingPartners}
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          style={{ width: '100%' }}
                          name="companyId"
                          onChange={handlePartnerChange}
                          disabled={state !== orderGeneralStates.DRAFT}
                        >
                          {partners?.map((partner) => (
                            <Option key={partner.id} value={partner.id}>
                              {partner.name}
                            </Option>
                          ))}
                        </Select>
                      </Item>
                    </Col>
                  </Row>
                )}
                <Steps
                  progressDot
                  direction="vertical"
                  className="detail-steps"
                >
                  <Step
                    key="1"
                    title={
                      <Typography.Text className="shipment-step-title">
                        {t('orders.newOrder.contents.titleStep1')}
                      </Typography.Text>
                    }
                    status={canProceedStep1 ? 'finish' : 'wait'}
                    description={
                      ((step &&
                        step.state &&
                        step.state === orderStates.COMPLETED) ||
                        canProceedStep1) && (
                        <>
                          <Row
                            style={{ marginTop: 24, marginBottom: 24 }}
                            gutter={[16, 16]}
                            className="form-grid"
                          >
                            <Col span={12}>
                              <Item
                                label={
                                  <Row justify="flex-start">
                                    <Typography.Text className="form-title">
                                      {t('orders.newOrder.contents.origin')}{' '}
                                      <Tooltip title="Selecciona la ubicación de tu bodega donde el servicio de courier, como FedEx, recolectará tu envío de reposición de stock.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </Typography.Text>
                                  </Row>
                                }
                                name="warehouseId"
                                rules={[
                                  { required: true, message: 'Requerido' },
                                ]}
                              >
                                <Select
                                  disabled={state !== 'Draft'}
                                  placeholder={t(
                                    'orders.newOrder.contents.chooseWarehouse'
                                  )}
                                  onSelect={handleWarehouseSelect}
                                >
                                  {warehouses &&
                                    warehouses.map((warehouse) => (
                                      <Select.Option
                                        key={warehouse.id}
                                        value={warehouse.id}
                                      >
                                        {warehouse.name}
                                      </Select.Option>
                                    ))}
                                  <Select.Option value="create-warehouse">
                                    <PlusOutlined />{' '}
                                    {t('orders.newOrder.contents.newWarehouse')}
                                  </Select.Option>
                                </Select>
                              </Item>
                            </Col>
                            <Col span={12}>
                              <Item
                                label={
                                  <Row justify="flex-start">
                                    <Typography.Text className="form-title">
                                      {t(
                                        'orders.newOrder.contents.destination'
                                      )}{' '}
                                      <Tooltip title="Selecciona el centro logístico o marketplace al cual se dirige tu envío de reposición de stock.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </Typography.Text>
                                  </Row>
                                }
                                name="destination"
                                rules={[
                                  { required: true, message: 'Requerido' },
                                ]}
                                initialValue={order.destination}
                              >
                                <Select
                                  onChange={onDestinationChange}
                                  disabled={state !== 'Draft'}
                                  placeholder={t(
                                    'orders.newOrder.contents.chooseDestination'
                                  )}
                                >
                                  <Option value="CA">Amazon CA</Option>
                                  <Option value="US">Amazon US</Option>
                                  <Option value="MX">Amazon MX</Option>
                                  <Option value="BR">Amazon BR</Option>
                                  <Option value="ES">Amazon ES</Option>
                                  <Option value="FR">Amazon FR</Option>
                                  <Option value="IT">Amazon IT</Option>
                                  <Option value="DE">Amazon DE</Option>
                                </Select>
                              </Item>
                            </Col>
                            {!isPartnered && (
                              <Col span={12}>
                                <Item
                                  label={
                                    <Row justify="flex-start">
                                      <Typography.Text className="form-title">
                                        {t('orders.newOrder.contents.courier')}{' '}
                                        <Tooltip title="Selecciona la empresa encargada de la recolección, transporte y entrega de tu envío de reposición de stock.">
                                          <InfoCircleOutlined />
                                        </Tooltip>
                                      </Typography.Text>
                                    </Row>
                                  }
                                  name="courier"
                                  rules={[
                                    { required: true, message: 'Requerido' },
                                  ]}
                                  initialValue={
                                    dataSourceCourier.find(
                                      (e) =>
                                        e.key === parseInt(order.courier, 10)
                                    )?.key
                                  }
                                >
                                  <Select
                                    onChange={handleNewCourier}
                                    disabled={state !== 'Draft'}
                                    placeholder={t(
                                      'orders.newOrder.contents.chooseCourier'
                                    )}
                                  >
                                    {dataSourceCourier &&
                                      dataSourceCourier.map((courierCred) => (
                                        <Select.Option
                                          key={courierCred.key}
                                          value={courierCred.key}
                                        >
                                          {courierCred.name}{' '}
                                          {courierCred.accountNumber &&
                                            `- ${courierCred.accountNumber}`}
                                        </Select.Option>
                                      ))}
                                    <Select.Option value="">
                                      <PlusOutlined />
                                      {t('orders.newOrder.contents.addCourier')}
                                    </Select.Option>
                                  </Select>
                                </Item>
                              </Col>
                            )}
                            <Col span={12}>
                              <Item
                                label={
                                  <Row justify="flex-start">
                                    <Typography.Text className="form-title">
                                      {t(
                                        'orders.newOrder.contents.nameShipment'
                                      )}{' '}
                                      <Tooltip title="Ingresa un nombre para identificar tu envío. Te recomendamos que siga un formato que te permita identificarlo fácilmente en el futuro.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </Typography.Text>
                                  </Row>
                                }
                                name="name"
                                rules={[
                                  { required: true, message: 'Requerido' },
                                ]}
                              >
                                <Input disabled={state !== 'Draft'} />
                              </Item>
                            </Col>
                          </Row>
                          <ShipsmartSpecificInfo
                            originCountryCode={originCountryCode}
                            destinationCountryCode={destination?.split(' ')[1]}
                            checkPayerTaxes={false}
                            display={
                              selectedCourier === FBMCouriers.SHIPSMART
                                ? 'block'
                                : 'none'
                            }
                          />
                          {/* New form field for known packing information */}
                          <Row>
                            <Col span={12}>
                              <Item
                                label={
                                  <Row justify="flex-start">
                                    <Typography.Text className="form-title">
                                      {t(
                                        'orders.newOrder.contents.knownPackingInformation'
                                      )}{' '}
                                      <Tooltip
                                        title={t(
                                          'orders.newOrder.contents.knownPackingInformationTooltip'
                                        )}
                                      >
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </Typography.Text>
                                  </Row>
                                }
                                name="knownPackingInformation"
                                rules={[
                                  {
                                    required: true,
                                    message: t('common.required'),
                                  },
                                ]}
                              >
                                <Radio.Group>
                                  <Radio value>{t('common.yes')}</Radio>
                                  <Radio value={false}>{t('common.no')}</Radio>
                                </Radio.Group>
                              </Item>
                            </Col>
                          </Row>
                        </>
                      )
                    }
                  />
                  <Step
                    key="2"
                    title={
                      <Typography.Text className="shipment-step-title">
                        {t('orders.newOrder.contents.titleStep4')}
                      </Typography.Text>
                    }
                    status={
                      originCountryCode &&
                      marketplace &&
                      (selectedCourier || isPartnered)
                        ? 'finish'
                        : 'wait'
                    }
                    description={
                      ((step &&
                        step.state &&
                        step.state === orderStates.COMPLETED) ||
                        (originCountryCode &&
                          marketplace &&
                          (selectedCourier || isPartnered))) && (
                        <ContentsTable
                          order={order}
                          form={form}
                          selectedProducts={selectedProducts}
                          selectedRowKeys={selectedRowKeys}
                          setSelectedRowKeys={setSelectedRowKeys}
                          setListingsAndProducts={setListingsAndProducts}
                          marketplace={marketplace}
                          listingsAndProducts={listingsAndProducts}
                          isPartnered={isPartnered}
                          setQuantityValues={setQuantityValues}
                          expirationDates={expirationDates}
                          setExpirationDates={setExpirationDates}
                          isFoodOrBeautyValues={isFoodOrBeautyValues} // Pass down the state
                          setIsFoodOrBeautyValues={setIsFoodOrBeautyValues} // Pass down the setter
                        />
                      )
                    }
                  />
                </Steps>
              </Row>
            </Form>

            <StickyAlert
              handleCancelForm={handleCancelForm}
              handleSaveDraft={handleSaveDraft}
              sendContent={sendContent}
              firstDisabled={
                state !== orderGeneralStates.DRAFT &&
                state !== orderGeneralStates.WORKING
              }
              secondDisabled={
                state !== orderGeneralStates.DRAFT &&
                state !== orderGeneralStates.WORKING
              }
              thirdDisabled={state !== 'Draft'}
              forthDisabled={state !== 'Draft'}
            />
          </div>
        )}
        <ProductsModal
          selectedRowKeys={selectedRowKeys}
          sendContentLoading={sendContentLoading}
          setSendContentLoading={setSendContentLoading}
          contentsForm={form}
          isPartnered={isPartnered}
        />
      </Spin>
    </>
  );
}

Contents.propTypes = {
  orderId: PropTypes.number.isRequired,
  redirected: PropTypes.bool.isRequired,
  setRedirected: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
  setSteps: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  inputRadioActive: PropTypes.shape({
    border: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
  }).isRequired,
};

export default Contents;
